import { FC, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Dialog from "../dialog";
import { IMyDraft } from "../../interfaces";

interface WarningDialogProps {
  isOpen: boolean;
  onClose: () => void;
  content: string;
  envelope?: IMyDraft;
  onResumeDraft?: (myDraft: IMyDraft) => void;
}

const WarningDialog: FC<WarningDialogProps> = ({
  isOpen,
  onClose,
  content,
  envelope,
  onResumeDraft,
}) => {
  const onWarningSuccess = useCallback(() => {
    const todayDate = new Date();
    if (onResumeDraft && envelope) {
      onResumeDraft({
        caseNumber: envelope.caseNumber,
        transactionid: envelope.transactionid,
        filingType: envelope.filingType,
        filingDescription: envelope.filingDescription,
        draftCreationDate: todayDate.toString(),
      });
    } else {
      onClose();
    }
  }, [envelope, onResumeDraft, onClose]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Warning !!"
      actionClassName="confirmation-dialog-action"
      maxWidth="sm"
    >
      <Box>
        <div className="text-center mb-3"></div>
        <Typography className="remove-item-css text-center">
          <b>{content}</b>
        </Typography>
      </Box>
      <div style={{ textAlign: "right" }}>
        <Button
          type="button"
          variant="contained"
          className="btn primary-button "
          onClick={onWarningSuccess}
          style={{ marginTop: "15px" }}
        >
          Ok
        </Button>
      </div>
    </Dialog>
  );
};

export default WarningDialog;
