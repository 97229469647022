import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { SUBSEQUENT_FILING } from "../../URL";
import { IFeeCalculationRequest } from "../../../interfaces";

interface IAxisErrorResponse {
  response?: {
    data?: string;
  };
}

const subsequentFile = async (filing: IFeeCalculationRequest) => {
  const response = await http.post<number>(SUBSEQUENT_FILING, filing);
  return response?.data;
};

const useSubsequentFiling = () =>
  useMutation<number, IAxisErrorResponse, IFeeCalculationRequest>({
    mutationKey: ["subsequent-filing"],
    mutationFn: subsequentFile,
  });

export default useSubsequentFiling;
