import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { GET_SUBSEQUENT_RETURN_DATE } from "../../URL";
import { IFeeCalculationRequest } from "../../../interfaces";

// interface IReturnDate {
//   message: string;
//   returnDateResponseMessage?: {
//     documentPostDate?: {
//       dateRepresentation?: {
//         value?: {
//           value?: number;
//         };
//       };
//     };
//   };
// }

interface IReturnDate {
  message: string;
  returnDate: string;
}

interface IAxisErrorResponse {
  response?: {
    data?: {
      userMessage?: string;
    };
  };
}

const getReturnDate = async (filing: IFeeCalculationRequest) => {
  const response = await http.post<IReturnDate>(
    GET_SUBSEQUENT_RETURN_DATE,
    filing
  );

  return response?.data;
};

const useSubsequentReturnDate = () =>
  useMutation<IReturnDate, IAxisErrorResponse, IFeeCalculationRequest>({
    mutationKey: ["subsequent-case-return-date"],
    mutationFn: getReturnDate,
  });

export default useSubsequentReturnDate;
