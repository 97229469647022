import { ChangeEvent, FC, useCallback, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Dialog from "../../../../components/dialog";
import { IOptionalServiceType } from "../../../../interfaces";

interface IFeeMultipliers {
  isOpen: boolean;
  services: IOptionalServiceType[];
  closeModal: () => void;
  onServiceQuantityUpdate: (services: IOptionalServiceType[]) => void;
}

const FeeMultipliers: FC<IFeeMultipliers> = ({
  isOpen,
  services: propsServices,
  closeModal,
  onServiceQuantityUpdate,
}) => {
  const [services, setServices] = useState(propsServices);

  const updateQuantity = useCallback(
    (serviceCode: string, e: ChangeEvent<HTMLInputElement>) => {
      const quantity = parseInt(e.target.value);
      if (quantity > 0 && quantity < 1000) {
        setServices((prevProps) =>
          prevProps.map((service) =>
            service.efmOptionalserviceCode === serviceCode
              ? { ...service, quantity }
              : service
          )
        );
      } else {
        setServices((prevProps) =>
          prevProps.map((service) =>
            service.efmOptionalserviceCode === serviceCode
              ? { ...service, quantity: service.quantity || 1 }
              : service
          )
        );
      }
    },
    []
  );

  const onUpdate = useCallback(() => {
    if (!services.some((service) => !service.quantity)) {
      onServiceQuantityUpdate(services);
    }
  }, [services, onServiceQuantityUpdate]);

  return (
    <Dialog
      title="Fee Multipliers"
      maxWidth="md"
      isOpen={isOpen}
      onClose={closeModal}
      action={
        <>
          <div className="text-center">
            <Button
              type="button"
              variant="contained"
              className="btn button-outline-secondary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="contained"
              className="btn primary-button ms-2"
              onClick={onUpdate}
            >
              Ok
            </Button>
          </div>
        </>
      }
    >
      {services?.map((service) => (
        <Box key={service.efmOptionalserviceCode} paddingBottom={2}>
          <Typography>{service.efmOptionalserviceName}</Typography>

          <TextField
            className="w-100"
            placeholder="Enter a Quantity"
            label="Quantity"
            variant="standard"
            type="number"
            value={service.quantity}
            onChange={updateQuantity.bind(this, service.efmOptionalserviceCode)}
          />
        </Box>
      ))}
    </Dialog>
  );
};

export default FeeMultipliers;
