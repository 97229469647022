import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { CREATE_ATTORNEY } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface ICreateAttorneyBody {
  barNumber: string;
  firstName: string;
  lastName: string;
  middleName: string;
  firmAdminCheckbox: boolean;
  filerCheckbox: boolean;
  email: string;
  userId: string;
  firmID: string;
}

const createAttorney = async (fields: ICreateAttorneyBody) => {
  await http.post(CREATE_ATTORNEY, fields);
};

const useCreateAttorney = () =>
  useMutation<void, IAxisErrorResponse, ICreateAttorneyBody>({
    mutationKey: ["create-attorney"],
    mutationFn: createAttorney,
  });

export default useCreateAttorney;
