import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_DEFAULT_FILING_LOCATION_CODE_URL } from "../../URL";

const getdefaultFilingLocationCode = async () => {
  const response = await http.post<string>(
    GET_DEFAULT_FILING_LOCATION_CODE_URL
  );
  return response.data;
};

const useDefaultFilingLocationCode = () =>
  useQuery<string>({
    queryKey: ["default-location"],
    queryFn: getdefaultFilingLocationCode,
  });

export default useDefaultFilingLocationCode;
