import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { IAxisErrorResponse } from "../../../interfaces";

interface IProfileRequest {
  userId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  updatedEmail: string;
}

interface IProfileResponse {}

const updateProfile = async (fields: IProfileRequest) => {
  const response = await http.post<IProfileResponse>(
    process.env.REACT_APP_USER_SERVICE_API_URL + "/api/user/updateUserProfile",
    fields,
    {
      params: {
        userId: fields.userId,
      },
    }
  );
  return response.data;
};

const useUpdateProfile = () =>
  useMutation<IProfileResponse, IAxisErrorResponse, IProfileRequest>({
    mutationKey: ["update-profile"],
    mutationFn: updateProfile,
  });

export default useUpdateProfile;
