import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_LOCATION_FILING_COMPONENTS } from "../../URL";
import { IFilingComponent } from "../../../interfaces";

const getLocationFilingComponents = async (
  locationCode?: string,
  filingTypeCode?: string
) => {
  if (!locationCode || !filingTypeCode) return [];

  if (filingTypeCode === "SERVICE ONLY")
    return [
      {
        allowMultiple: false,
        locationFilingcomponentCode: "332",
        locationFilingcomponentId: 1,
        locationFilingcomponentName: "Service Document",
        required: true,
      },
      {
        allowMultiple: true,
        locationFilingcomponentCode: "331",
        locationFilingcomponentId: 2,
        locationFilingcomponentName: "Add More Documents",
        required: false,
      },
    ];

  const response = await http.post<IFilingComponent[]>(
    GET_LOCATION_FILING_COMPONENTS,
    {
      efmLocationCode: locationCode,
      efmFilingtypeCode: filingTypeCode,
    }
  );

  // Need to show Lead Document above other types
  const sortedData = response.data?.sort((a, b) => {
    if (a.locationFilingcomponentName === "Lead Document") return -1;
    if (b.locationFilingcomponentName === "Lead Document") return 1;
    return a.locationFilingcomponentName.localeCompare(
      b.locationFilingcomponentName
    );
  });

  return sortedData.map((component) =>
    component.locationFilingcomponentName.toLowerCase().includes("lead")
      ? { ...component, required: true }
      : component
  );
};

const useLocationFilingComponents = (
  locationCode?: string,
  filingTypeCode?: string
) =>
  useQuery<IFilingComponent[]>({
    queryKey: ["location-filing-components", locationCode, filingTypeCode],
    queryFn: () => getLocationFilingComponents(locationCode, filingTypeCode),
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default useLocationFilingComponents;
