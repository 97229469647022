import { useQuery } from "@tanstack/react-query";

import http from "../../http";
import { READ_ADMIN_USER_NOTIFICATION_FILES } from "../URL";
import { IHomepageNotification } from "../../interfaces";

const getDashboardCount = async () => {
  const response = await http.post<IHomepageNotification>(
    READ_ADMIN_USER_NOTIFICATION_FILES
  );
  return response.data;
};

const useHomepageNotifcation = () =>
  useQuery<IHomepageNotification>({
    queryKey: ["homepageNotification"],
    queryFn: getDashboardCount,
  });

export default useHomepageNotifcation;
