import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { GET_USER_I2F_LINK } from "../../URL";
import { setIsLinked } from "../../../utills";

interface IAxisResponse {
  redirectURL: string;
  i2fUser: string;
  i2fPWD: string;
  liteUser: string;
  litePWD: string;
  defaultToSupremeCourtCounty: boolean;
  linked: boolean;
}

interface IUser {
  liteUser: string;
  defaultToSupremeCourtCounty: boolean;
  liteUserFirstName: string;
  liteUserLastName: string;
  isUserProfileLinked: boolean;
  liteUserRole: string;
}

const getI2FLink = async (user: IUser) => {
  const response = await http.post<IAxisResponse>(GET_USER_I2F_LINK, user);

  if (response.data.redirectURL) {
    setIsLinked(response.data.linked);

    let i2fUser = response.data.i2fUser;
    let i2fPWD = response.data.i2fPWD;
    let liteUser = response.data.liteUser;
    let litePWD = response.data.litePWD;
    let defaultToSupremeCourt = response.data.defaultToSupremeCourtCounty;
    let uiURL = process.env.REACT_APP_UI_URL;

    let form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", response.data.redirectURL);

    form.setAttribute("target", "_self");

    let i2fUserField = document.createElement("input");
    i2fUserField.setAttribute("type", "hidden");
    i2fUserField.setAttribute("name", "i2fUser");
    i2fUserField.setAttribute("value", i2fUser);
    form.appendChild(i2fUserField);

    let i2fPWDField = document.createElement("input");
    i2fPWDField.setAttribute("type", "hidden");
    i2fPWDField.setAttribute("name", "i2fPWD");
    i2fPWDField.setAttribute("value", i2fPWD);
    form.appendChild(i2fPWDField);

    let liteUserField = document.createElement("input");
    liteUserField.setAttribute("type", "hidden");
    liteUserField.setAttribute("name", "liteUser");
    liteUserField.setAttribute("value", liteUser);
    form.appendChild(liteUserField);

    let litePWDField = document.createElement("input");
    litePWDField.setAttribute("type", "hidden");
    litePWDField.setAttribute("name", "litePWD");
    litePWDField.setAttribute("value", litePWD);
    form.appendChild(litePWDField);

    if (uiURL) {
      let uiUrlField = document.createElement("input");
      uiUrlField.setAttribute("type", "hidden");
      uiUrlField.setAttribute("name", "uiURL");
      uiUrlField.setAttribute("value", uiURL);
      form.appendChild(uiUrlField);
    } else {
      let uiUrlField = document.createElement("input");
      uiUrlField.setAttribute("type", "hidden");
      uiUrlField.setAttribute("name", "uiURL");
      uiUrlField.setAttribute("value", "http://localhost:3000/#/dashboard");
      form.appendChild(uiUrlField);
    }

    let defaultToSupremeCourtField = document.createElement("input");
    defaultToSupremeCourtField.setAttribute("type", "hidden");
    defaultToSupremeCourtField.setAttribute(
      "name",
      "defaultToSupremeCourtCounty"
    );
    defaultToSupremeCourtField.setAttribute(
      "value",
      defaultToSupremeCourt.toString()
    );
    form.appendChild(defaultToSupremeCourtField);

    document.body.appendChild(form);
    window.open("", "_self");

    form.submit();
  }

  return;
};

const useI2FLink = () =>
  useMutation<void, void, IUser>({
    mutationKey: ["i2f-link"],
    mutationFn: getI2FLink,
  });

export default useI2FLink;
