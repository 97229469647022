import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_PAYMENT_ACCOUNT_TYPES } from "../../URL";

interface IResponsePaymentAccountType {
  codeId: { value: string };
  code: string;
  description: string;
}

interface IPaymentAccountType {
  codeId: string;
  code: string;
  description: string;
}

const getPaymentAccountTypes = async () => {
  const response = await http.post<IResponsePaymentAccountType[]>(
    GET_PAYMENT_ACCOUNT_TYPES
  );
  return response.data?.map((paymentAccountType) => ({
    codeId: paymentAccountType.codeId.value,
    code: paymentAccountType.code,
    description: paymentAccountType.description,
  }));
};

const usePaymentAccountTypes = () =>
  useQuery<IPaymentAccountType[]>({
    queryKey: ["payment-accounts-types"],
    queryFn: getPaymentAccountTypes,
  });

export default usePaymentAccountTypes;
