import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_COUNTRIES_URL } from "../../URL";

interface ICountry {
  countryId: number;
  efmCountryCode: string;
  efmCountryName: string;
}

const getCountries = async () => {
  const response = await http.post<ICountry[]>(GET_COUNTRIES_URL);
  return response.data;
};

const useCountries = () =>
  useQuery<ICountry[]>({
    queryKey: ["countries"],
    queryFn: getCountries,
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default useCountries;
