import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SystemUpdateAltOutlinedIcon from "@mui/icons-material/SystemUpdateAltOutlined";
import InfoIcon from "@mui/icons-material/Info";
import subDays from "date-fns/subDays";
import format from "date-fns/format";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid/models";

import Loader from "../../../components/loader";
import GridTable from "../../../components/grid";
import DeleteFilingHistory from "../deleteFilingHistory";
import RejectionDialog from "../rejectionDialog";
import FilingDetailsDialog from "../filingDetailsDialog";
import useProfile from "../../../apis/auth/useProfile";
import useLocations from "../../../apis/common/useLocations";
import useTransactions from "../../../apis/filingHistory/useTransactions";
import useEFMTransactions from "../../../apis/filingHistory/useEFMTransactions";
import useCopyEnvelope from "../../../apis/filingHistory/useCopyEnvelope";
import { differenceInDays, isIndividualUser } from "../../../utills";
import { renderFilingStatus } from "../../../column_helper";
import { NotificationContext } from "../../../context/NotificationContext";
import { ICopyEnvelope, ITransation } from "../../../interfaces";
import WarningDialog from "../../../components/warningDialog";

interface IOption {
  label: string;
  value: string | number;
}

const STATUSES = [
  {
    label: "Accepted",
    value: "Accepted",
  },
  {
    label: "Cancelled",
    value: "Cancelled",
  },
  {
    label: "Rejected",
    value: "Rejected",
  },
  {
    label: "Served",
    value: "Served",
  },
  {
    label: "Submitted",
    value: "Submitted",
  },
  {
    label: "Submitting",
    value: "Submitting",
  },
  {
    label: "Submission Failed",
    value: "Failed",
  },
  {
    label: "Under Review",
    value: "Under Review",
  },
];

const GLOBAL_STATUSES = [
  {
    label: "Accepted",
    value: "accepted",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Served",
    value: "served",
  },
  {
    label: "Submitted",
    value: "submitted",
  },
  {
    label: "Submitting",
    value: "submitting",
  },
  {
    label: "Submission Failed",
    value: "failed",
  },
  {
    label: "Under Review",
    value: "under-review",
  },
];

interface IFilingHistoryGrid {
  showRecentCases?: boolean;
}

const FilingHistoryGrid: FC<IFilingHistoryGrid> = ({
  showRecentCases = false,
}) => {
  const navigate = useNavigate();
  const profile = useProfile();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsValue = queryParams.get("filingStatus");
  const { data: locations } = useLocations();
  const [isRejectionModalVisible, setisRejectionModalVisible] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEnvelop, setSelectedEnvelop] = useState<ITransation | null>(
    null
  );
  const [isDownloadModal, setIsDownloadModal] = useState(false);
  const [showEFMTransactions, setShowEFMTransactions] = useState(false);
  const [filingTypes, setFilingTypes] = useState<IOption[]>([]);
  const [globalFilingTypes, setGlobalFilingTypes] = useState<IOption[]>([]);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [filingType, setFilingType] = useState("-1");
  const [status, setStatus] = useState(
    queryParamsValue ? "under-review" : "-1"
  );
  const [locationId, setLocationId] = useState("-1");
  const [startDate, setStartDate] = useState<Date | null>(
    subDays(new Date(), 90)
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [envelopeId, setEnvelopeId] = useState("");
  const { createNotification } = useContext(NotificationContext);
  const minDate = subDays(new Date(), 365);
  const maxDate = new Date();

  const transactions = useTransactions();
  const efmTansactions = useEFMTransactions();
  const copyEnvelope = useCopyEnvelope();

  const getRowId = useCallback((row: any) => row.uniqueId, []);

  const onFilingTypeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilingType(event.target.value);
    },
    []
  );

  const onStatusChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value);
  }, []);

  const onLocationChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLocationId(event.target.value);
    },
    []
  );

  const onEnvelopeIdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEnvelopeId(event.target.value);
    },
    []
  );

  const onDelete = useCallback(
    (transaction: {
      efmEnvId: string;
      location: string;
      efmFilingId: string;
      fileType: string;
      isReturnDate: boolean;
      ishearingDate: boolean;
      filedDate: string;
    }) => {
      setSelectedEnvelop({
        efmEnvId: transaction.efmEnvId,
        efmLocationId: transaction.location,
        filingId: transaction.efmFilingId,
        efmFilingType: transaction.fileType,
        isReturnDate: false,
        isHearingDate: false,
        filedDate: transaction.filedDate,
      });
      setIsDeleteModalOpen(true);
    },
    []
  );

  const onCopyTransaction = useCallback(
    (copyEnv: ICopyEnvelope) => {
      if (differenceInDays(copyEnv.creationDate)) {
        setIsWarningModalOpen(true);
      } else {
        copyEnvelope.mutate(copyEnv.efmEnvId);
      }
    },
    [copyEnvelope]
  );

  const openRejectionModal = useCallback(() => {
    setisRejectionModalVisible(true);
  }, []);

  const closeRejectionModal = useCallback(() => {
    setisRejectionModalVisible(false);
  }, []);

  const openDetailsModal = useCallback(
    (row: {
      efmEnvId: string;
      location: string;
      efmFilingId: string;
      fileType: string;
      isReturnDate: boolean;
      ishearingDate: boolean;
      filedDate: string;
    }) => {
      const isReturnDate = locations?.find(
        (node) => node.efm_Location_Code === row.location
      )?.isreturndate;

      const isHearingDate = locations?.find(
        (node) => node.efm_Location_Code === row.location
      )?.ishearingdate;

      setSelectedEnvelop({
        efmEnvId: row.efmEnvId,
        efmLocationId: row.location,
        filingId: row.efmFilingId,
        efmFilingType: row.fileType,
        isReturnDate: isReturnDate || false,
        isHearingDate: isHearingDate || false,
        filedDate: row.filedDate,
      });
      setIsDetailsModalVisible(true);
      setIsDownloadModal(false);
    },
    [locations]
  );

  const openDowloadDetailsModal = useCallback(
    (row: {
      efmEnvId: string;
      location: string;
      efmFilingId: string;
      fileType: string;
      isReturnDate: boolean;
      ishearingDate: boolean;
      filedDate: string;
    }) => {
      setSelectedEnvelop({
        efmEnvId: row.efmEnvId,
        efmLocationId: row.location,
        filingId: row.efmFilingId,
        efmFilingType: row.fileType,
        isReturnDate: false,
        isHearingDate: false,
        filedDate: row.filedDate,
      });
      setIsDetailsModalVisible(true);
      setIsDownloadModal(true);
    },
    []
  );

  const onFilter = useCallback(() => {
    setShowEFMTransactions(false);

    transactions.mutate({
      startDate: startDate ? format(startDate, "yyyy-MM-dd") : "",
      endDate: endDate ? format(endDate, "yyyy-MM-dd") : "",
      efmFilingStatus: status,
      location: locationId,
      efmEnvelopeId: envelopeId,
      userId: profile.data?.email || "",
      efmUserId: profile.data?.userID || "",
      efmFirmId: profile.data?.firmID || "",
      isFirmAdmin: (
        profile.data?.roles?.findIndex(
          (role) => role.roleName === "FIRM_ADMIN"
        ) !== -1
      ).toString(),
      filingFilter: filingType,
      isPendingCount: false,
      showTransactionForDashboard: showRecentCases ? true : false,
    });
  }, [
    startDate,
    endDate,
    status,
    locationId,
    envelopeId,
    profile.data,
    filingType,
    showRecentCases,
    transactions,
  ]);

  const onPastFilings = useCallback(() => {
    setShowEFMTransactions(true);

    efmTansactions.mutate({
      startDate: startDate ? format(startDate, "yyyy-MM-dd") : "",
      endDate: endDate ? format(endDate, "yyyy-MM-dd") : "",
      efmFilingStatus: status,
      efmEnvelopeId: envelopeId,
      userId: profile.data?.email || "",
      efmUserId: profile.data?.userID || "",
      efmFirmId: profile.data?.firmID || "",
      isFirmAdmin: (
        profile.data?.roles?.findIndex(
          (role) => role.roleName === "FIRM_ADMIN"
        ) !== -1
      ).toString(),
      filingFilter: filingType,
      isPendingCount: queryParamsValue ? true : false,
      showTransactionForDashboard: showRecentCases ? true : false,
    });
  }, [
    startDate,
    endDate,
    status,
    envelopeId,
    profile.data,
    filingType,
    queryParamsValue,
    showRecentCases,
    efmTansactions,
  ]);

  const closeModal = useCallback(() => {
    setIsWarningModalOpen(false);
  }, []);

  const closeDetailsModal = useCallback(() => {
    if (showEFMTransactions === true) {
      onPastFilings();
    } else {
      onFilter();
    }

    setIsDetailsModalVisible(false);
    setSelectedEnvelop(null);
  }, [showEFMTransactions, onFilter, onPastFilings]);

  const onDeleteModalClose = useCallback(
    (shouldUpdateGrid?: boolean) => {
      if (shouldUpdateGrid) onFilter();

      setSelectedEnvelop(null);
      setIsDeleteModalOpen(false);

      if (shouldUpdateGrid === true) {
        if (showEFMTransactions === true) {
          onPastFilings();
        } else {
          onFilter();
        }
      }
    },
    [showEFMTransactions, onFilter, onPastFilings]
  );

  const columns: GridColDef[] = useMemo(() => {
    const columnList: GridColDef[] = [
      {
        field: "env_filing",
        headerName: "Envelope # - Filing",
        width: 240,
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span
              id={params.row.uniqueId}
              title={`${params.row.efmEnvId} ${params.row.fileTypeName || ""}`}
            >{`${params.row.efmEnvId} ${params.row.fileTypeName ? "-" : ""} ${
              params.row.fileTypeName || ""
            }`}</span>
          );
        },
      },
    ];

    if (!showEFMTransactions) {
      columnList.push({
        field: "location_Name",
        headerName: "Location Name",
        width: 200,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return locations?.find(
            (locationData) =>
              locationData.efm_Location_Code === params.row.location
          )?.efm_Location_Name;
        },
      });
    }

    return [
      ...columnList,
      {
        field: "caseNo",
        headerName: "Case Number",
        width: 150,
        filterable: false,
        disableColumnMenu: true,
        sortable: true,
      },
      {
        field: "filedDate",
        headerName: "Filed Date",
        width: 200,
        filterable: false,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams) => {
          return format(
            new Date(params.row.creationDate),
            "MM/dd/yyyy hh:mm:ss a"
          );
        },
        sortComparator: (v1: string, v2: string) => {
          return new Date(v1).valueOf() - new Date(v2).valueOf();
        },
      },
      {
        field: "fileType",
        headerName: "Filing Type",
        width: 120,
        filterable: false,
        disableColumnMenu: true,
        sortable: true,
      },
      {
        field: "fileDesc",
        headerName: "Filing Description",
        width: 280,
        filterable: false,
        disableColumnMenu: true,
        sortable: true,
      },
      {
        field: "status",
        headerName: "Status",
        width: 200,
        filterable: false,
        disableColumnMenu: true,
        sortable: true,
        renderCell: renderFilingStatus.bind(this, "status", openRejectionModal),
      },
      {
        field: "action",
        headerName: "Action",
        filterable: false,
        disableColumnMenu: true,
        width: 180,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const { status, allowCopyEnvelope, allowDownload } = params.row;
          return (
            <div className="dataGridactionIcons">
              <Tooltip title="View Filing Details">
                <IconButton
                  aria-label="View Filing Details"
                  aria-describedby={params.row.uniqueId}
                  onClick={() => openDetailsModal(params.row)}
                >
                  <VisibilityOutlinedIcon />
                </IconButton>
              </Tooltip>
              {![
                "rejected",
                "accepted",
                "deferred",
                "failed",
                "submission failed",
                "error",
                "reviewed",
                "cancelled",
                "served",
                "under review",
                "under-review",
              ].includes(status.toLowerCase()) && (
                <Tooltip title="Cancel">
                  <IconButton
                    aria-label="Cancle Filing"
                    aria-describedby={params.row.uniqueId}
                    onClick={() => onDelete(params.row)}
                  >
                    <CancelOutlinedIcon className="datagrid-cancel" />
                  </IconButton>
                </Tooltip>
              )}
              {allowCopyEnvelope === "Y" &&
                [
                  "rejected",
                  "failed",
                  "submission failed",
                  "cancelled",
                ].includes(status.toLowerCase()) && (
                  <Tooltip title="Copy Envelope">
                    <IconButton>
                      <ContentCopyOutlinedIcon
                        aria-label="Copy Filing"
                        aria-describedby={params.row.uniqueId}
                        onClick={() => onCopyTransaction(params.row)}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              {allowDownload === "Y" &&
                ["rejected", "accepted"].includes(status.toLowerCase()) && (
                  <Tooltip title="Download Document">
                    <IconButton
                      aria-label="Download Document"
                      aria-describedby={params.row.uniqueId}
                      onClick={() => openDowloadDetailsModal(params.row)}
                    >
                      <SystemUpdateAltOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
            </div>
          );
        },
      },
    ];
  }, [
    showEFMTransactions,
    locations,
    onCopyTransaction,
    openDowloadDetailsModal,
    onDelete,
    openDetailsModal,
    openRejectionModal,
  ]);

  useEffect(() => {
    if (
      profile.data?.roles?.findIndex(
        (role) => role.roleName === "FIRM_ADMIN"
      ) !== -1 &&
      !isIndividualUser()
    ) {
      setFilingTypes([
        {
          label: "My Firm",
          value: profile.data?.firmID || "",
        },
      ]);

      setGlobalFilingTypes([
        {
          label: "My Firm",
          value: "passNull",
        },
      ]);
    }
  }, [profile.data]);

  useEffect(() => {
    if (copyEnvelope.isSuccess) {
      const envelopeJson = JSON.parse(copyEnvelope.data?.efmRequest);
      if (!envelopeJson) return;

      if (envelopeJson?.generalCase?.caseNumber) {
        navigate("/existingcase", {
          state: envelopeJson,
        });
      } else {
        navigate("/newcase", {
          state: envelopeJson,
        });
      }
    }
    // eslint-disable-next-line
  }, [copyEnvelope.isSuccess]);

  useEffect(() => {
    transactions.mutate({
      startDate: queryParamsValue
        ? ""
        : startDate
        ? format(startDate, "yyyy-MM-dd")
        : "",
      endDate: queryParamsValue
        ? ""
        : endDate
        ? format(endDate, "yyyy-MM-dd")
        : "",
      userId: profile.data?.email || "",
      efmUserId: profile.data?.userID || "",
      efmFirmId: profile.data?.firmID || "",
      efmFilingStatus: queryParamsValue ? "under-review" : undefined,
      location: queryParamsValue ? locationId : undefined,
      efmEnvelopeId: queryParamsValue ? envelopeId : undefined,
      isFirmAdmin: (
        profile.data?.roles?.findIndex(
          (role) => role.roleName === "FIRM_ADMIN"
        ) !== -1
      ).toString(),
      filingFilter: queryParamsValue ? filingType : "-1",
      isPendingCount: queryParamsValue ? true : false,
      showTransactionForDashboard: showRecentCases ? true : false,
    });
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (transactions.isError) {
  //     createNotification({
  //       title: "Filing Notification",
  //       type: "error",
  //       message:
  //         transactions.error.response?.data?.userMessage ||
  //         "Error occured while loading filing list",
  //     });
  //   }
  // }, [transactions.isError]);

  // useEffect(() => {
  //   if (efmTansactions.isError) {
  //     createNotification({
  //       title: "Filing Notification",
  //       type: "error",
  //       message:
  //         efmTansactions.error.response?.data?.userMessage ||
  //         "An Error occurred while filing list",
  //     });
  //   }
  // }, [efmTansactions.isError]);

  useEffect(() => {
    if (copyEnvelope.isError) {
      createNotification({
        title: "Filing Notification",
        type: "error",
        message:
          copyEnvelope.error.response?.data?.userMessage ||
          "An Error occurred while processing your data!",
      });
    }
    // eslint-disable-next-line
  }, [copyEnvelope.isError]);

  return (
    <>
      {(copyEnvelope.isLoading ||
        (showEFMTransactions
          ? efmTansactions.isLoading
          : transactions.isLoading)) && <Loader />}

      {!showRecentCases && (
        <div className="filter-wrapper lighter-blue-bg ps-4 pe-4 pb-1">
          <Grid container spacing={2} paddingY={2}>
            <Grid item xs={12} md={6} lg={4} className="mb-3">
              <TextField
                className="w-100"
                select
                InputLabelProps={{
                  shrink: true,
                  htmlFor: "my_filings_id",
                }}
                SelectProps={{
                  displayEmpty: true,
                }}
                inputProps={{
                  id: "my_filings_id",
                }}
                value={filingType}
                onChange={onFilingTypeChange}
                label="My Filings / Firm Filings"
                variant="standard"
              >
                <MenuItem value="-1">My Filings</MenuItem>
                {(showEFMTransactions ? filingTypes : globalFilingTypes)?.map(
                  (type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className="mb-3">
              <TextField
                className="w-100"
                select
                InputLabelProps={{
                  shrink: true,
                  htmlFor: "status_id",
                }}
                SelectProps={{
                  displayEmpty: true,
                }}
                inputProps={{
                  id: "status_id",
                }}
                value={status}
                onChange={onStatusChange}
                label="Status"
                variant="standard"
              >
                <MenuItem value="-1">All</MenuItem>
                {(showEFMTransactions ? STATUSES : GLOBAL_STATUSES)?.map(
                  (type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className="mb-3">
              <TextField
                className="w-100"
                select
                InputLabelProps={{
                  shrink: true,
                  htmlFor: "statusall_locations_id",
                }}
                SelectProps={{
                  displayEmpty: true,
                }}
                inputProps={{
                  id: "statusall_locations_id",
                }}
                value={locationId}
                onChange={onLocationChange}
                label="All Locations"
                variant="standard"
                disabled={showEFMTransactions ? true : false}
              >
                <MenuItem value="-1">Select Location</MenuItem>
                {locations?.map((location) => (
                  <MenuItem
                    key={location.efm_Location_Code}
                    value={location.efm_Location_Code}
                  >
                    {location.efm_Location_Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className="mb-3">
              <DatePicker
                className="w-100 date-picker"
                label="From"
                minDate={minDate}
                maxDate={endDate ? endDate : undefined}
                value={startDate}
                onChange={setStartDate}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className="mb-3">
              <DatePicker
                className="w-100 date-picker"
                label="To"
                minDate={startDate ? startDate : minDate}
                maxDate={maxDate}
                value={endDate}
                onChange={setEndDate}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className="mb-3">
              <TextField
                className="w-100"
                InputLabelProps={{
                  shrink: true,
                }}
                value={envelopeId}
                onChange={onEnvelopeIdChange}
                label="Case or Envelope"
                placeholder="Case or Envelope"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={12} className="text-right">
              <Button
                className="btn primary-button"
                variant="contained"
                onClick={showEFMTransactions ? onPastFilings : onFilter}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
        </div>
      )}

      {!showRecentCases && (
        <Box
          className="info-wrapper lighter-blue01-bg pt-2 pb-2 pe-4 mt-1"
          sx={{ pl: 2 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={8} display="flex" alignItems="center">
              <InfoIcon sx={{ fontSize: 20 }} />
              <Typography className="d-inline-block ps-1 f-w-semibold">
                If you do not see your recent filings below, please click "ALL
                FILINGS" button
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="text-right">
              <Button
                className="btn button-outline-secondary"
                variant="contained"
                onClick={showEFMTransactions ? onFilter : onPastFilings}
              >
                {showEFMTransactions ? "View I2File Filings" : "All Filings"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}<br/>
      <h5>
        <b>
          <center>
          If the Status is 
          <span className="submitting"> "Submitting" </span> 
          or 
          <span className="submitted"> "Submitted" </span> 
          then click on Action &nbsp;
          <span className="">
            <VisibilityOutlinedIcon
              className="vertical-align-text-top me-1"
            />
          </span>
          icon to retrieve the updated status.
          </center>
        </b>
      </h5>
      <div className="datagrid-table-wrap mt-5">
        <div className="datagrid-top mb-3 filing-history-grid-top">
          <div className="f-w-bold f-size18">
            Total No of Records :{" "}
            <span className="round-number">
              {showEFMTransactions
                ? efmTansactions.data?.length || 0
                : transactions.data?.length || 0}
            </span>
          </div>
          <div className="f-size14 filing-history-right">
            <span className="ps-3 grid-top-icons">
              <VisibilityOutlinedIcon
                className="vertical-align-text-top me-1"
                sx={{ fontSize: 16 }}
              />
              View Filing Details
            </span>
            <span className="ps-3 grid-top-icons">
              <ContentCopyOutlinedIcon
                className="vertical-align-text-top me-1"
                sx={{ fontSize: 16 }}
              />
              Copy Envelope
            </span>
            <span className="ps-3 grid-top-icons">
              <CancelOutlinedIcon
                className="vertical-align-text-top me-1"
                sx={{ fontSize: 16 }}
              />
              Cancel Filing
            </span>
            <span className="ps-3 grid-top-icons">
              <SystemUpdateAltOutlinedIcon
                className="vertical-align-text-top me-1"
                sx={{ fontSize: 16 }}
              />
              Download Document
            </span>
          </div>
        </div>
        <div className="themeDataTable">
          <GridTable
            columns={columns}
            rows={showEFMTransactions ? efmTansactions.data : transactions.data}
            hideFooter={false}
            // isLoading={
            //   showEFMTransactions
            //     ? efmTansactions.isLoading
            //     : transactions.isLoading
            // }
            getRowId={getRowId}
          />
        </div>
      </div>

      <RejectionDialog
        isOpen={isRejectionModalVisible}
        onClose={closeRejectionModal}
      />

      {profile.data && isDetailsModalVisible && selectedEnvelop && (
        <FilingDetailsDialog
          efmUserId={profile.data.email}
          efmEnvelopeId={selectedEnvelop.efmEnvId}
          efmLocationId={selectedEnvelop.efmLocationId}
          filingId={selectedEnvelop.filingId}
          efmFilingType={selectedEnvelop.efmFilingType}
          isReturnDate={selectedEnvelop.isReturnDate}
          isHearingDate={selectedEnvelop.isHearingDate}
          filedDate={selectedEnvelop.filedDate}
          isOpen={isDetailsModalVisible}
          onClose={closeDetailsModal}
          isDownloadModal={isDownloadModal}
        />
      )}

      {profile.data && isDeleteModalOpen && selectedEnvelop && (
        <DeleteFilingHistory
          email={profile.data.email}
          filing={selectedEnvelop}
          isOpen={isDeleteModalOpen}
          onClose={onDeleteModalClose}
        />
      )}

      {isWarningModalOpen && (
        <WarningDialog
          isOpen={isWarningModalOpen}
          onClose={closeModal}
          content="This Envelope is more than 1 month old. You will not be able to perform copy Envelope."
        ></WarningDialog>
      )}
    </>
  );
};

export default FilingHistoryGrid;
