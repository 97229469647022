import { FC, useCallback, useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PersonIcon from "@mui/icons-material/Person";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";

import Loader from "../../../components/loader";
import ServiceInfoHistory from "./serviceInfoHistoryModalDialog";
import ServiceContactDetailsModal from "./serviceContactDetailsModalDialog";
import AttachedCaseList from "./attachedCaseListModalDialog/AttachedCaseListModalDialog";
import MasterListModalDialog from "./masterListModalDialog/MasterListModalDialog";
import ServiceContactDetailUpdateForm from "./serviceContactDetailUpdateForm";
import CreateEditServiceContact from "../../serviceContacts/createEditServiceContact";
import useDeAttachedServiceContact from "../../../apis/existingCase/useDeAttachedServiceContact";
import useGetServiceInformation from "../../../apis/existingCase/useGetServiceInformation";
import { ICaseResponse, IServiceResponse } from "../../../interfaces";
import { NotificationContext } from "../../../context/NotificationContext";

interface ManageServiceProps {
  caseDetails: ICaseResponse;
  countyId?: string;
  caseTrackingId?: string;
  isServiceContactTab?: boolean;
  selectedServiceContacts?: string[];
  onClose: (shouldUpdateGrid?: boolean) => void;
  updateServiceContacts?: (
    serviceContactIds: string[],
    serviceContacts: IServiceResponse[],
    tabIndex: number
  ) => void;
  createDraft?: (
    serviceContactIds: string[],
    serviceContacts: IServiceResponse[]
  ) => void;
}

const ManageServiceContact: FC<ManageServiceProps> = ({
  caseDetails,
  countyId,
  caseTrackingId,
  isServiceContactTab = false,
  selectedServiceContacts: selectedServiceContactIds = [],
  onClose,
  updateServiceContacts,
  createDraft,
}) => {
  const {
    data: serviceInformation,
    isLoading: ServieInfoLoading,
    isFetching: ServiceInfoFetching,
    refetch: refetchServiceContact,
  } = useGetServiceInformation({
    countyId,
    caseTrackingId,
  });

  const { createNotification } = useContext(NotificationContext);

  const [selectedServiceContactId, setSelectedServiceContactId] = useState<
    string | undefined
  >();

  const [isMasterListModalVisible, setIsMasterListModalVisible] =
    useState(false);
  const [
    isCreateServiceContactModalVisible,
    setIsCreateServiceContactModalVisible,
  ] = useState(false);

  const [nodeChildren, setNodeChildren] = useState<IServiceResponse[]>([]);
  const [selectedNodeId, setSelectedNodeId] = useState<string>("");
  const [selectedServiceFirmId, setSelectedServiceFirmId] = useState<
    string | undefined
  >("");
  const [isServiceHistoryModalOpen, setIsServiceHistoryModalOpen] =
    useState(false);
  const [isAttachedCaseListModalOpen, setIsAttachedCaseListModalOpen] =
    useState(false);
  const [isServiceDetailModalOpen, setIsServiceDetailModalOpen] =
    useState(false);
  const [isAttachedServiceContactNames, setIsAttachedServiceContactNames] =
    useState<string | undefined>("");
  const [isServiceContactFormOpen, setIsServiceContactFormOpen] =
    useState(false);
  const [selectedServiceContacts, setSelectedServiceContacts] = useState<
    string[]
  >(selectedServiceContactIds);

  const deAttachedServiceContact = useDeAttachedServiceContact();

  const onMasterListModalSave = useCallback(
    (additionalData?: IServiceResponse[], nodeId?: string) => {
      if (!additionalData) return [];

      setIsMasterListModalVisible(false);
      setNodeChildren((prevState) => [
        ...prevState,
        ...additionalData.map((data) => ({
          serviceContactId: data.serviceContactId || "",
          firstName: data.firstName || "",
          surName: data.surName || "",
          email: data.email || "",
          participantId: nodeId || "",
          partyFirmName: data.partyFirmName || "",
          telNo: data.telNo || "",
          action: data.action || "",
          actionTimeStamp: data.actionTimeStamp || "",
          mailingAddress: data.mailingAddress || "",
          firmId: data.firmId || "",
          efmPartyId: data.efmPartyId || "",
          isSelected: data.isSelected || false,
        })),
      ]);
    },
    [setNodeChildren]
  );

  const onMasterListModalOpen = useCallback(() => {
    setIsMasterListModalVisible(true);
  }, []);

  const onMasterListModalClose = useCallback(() => {
    setIsMasterListModalVisible(false);
  }, []);

  const onNodeSelected = useCallback((selectedNodeId: string) => {
    setSelectedNodeId(selectedNodeId);
  }, []);

  const onPartyClick = useCallback(
    (partyId: string) => {
      if (isServiceContactTab) {
        const childNodes = nodeChildren.filter(
          (node) => node.efmPartyId === partyId
        );

        setSelectedServiceContacts((prevState) =>
          !childNodes.some(
            (childNode) => !prevState.includes(childNode.serviceContactId)
          )
            ? prevState.filter(
                (serviceContact) =>
                  childNodes.findIndex(
                    (childNode) => childNode.serviceContactId === serviceContact
                  ) === -1
              )
            : [
                ...prevState,
                ...childNodes
                  .filter(
                    (childNode) =>
                      !prevState.includes(childNode.serviceContactId)
                  )
                  .map((childNode) => childNode.serviceContactId),
              ]
        );
      }
    },
    [isServiceContactTab, nodeChildren]
  );

  const onServiceContactDetached = useCallback(
    async (nodeDetachedId: string) => {
      const serviceContactId =
        nodeChildren.find((index) => index.serviceContactId === nodeDetachedId)
          ?.serviceContactId || "";

      deAttachedServiceContact.mutate({
        caseTrackingId: caseTrackingId || "",
        serviceContactId,
      });

      setSelectedServiceContactId(serviceContactId);
      setIsServiceContactFormOpen(false);
    },
    [caseTrackingId, nodeChildren, deAttachedServiceContact]
  );

  const onServiceInfoHistory = useCallback(() => {
    setIsServiceHistoryModalOpen(true);
  }, []);

  const onServiceHistoryModalClose = useCallback(() => {
    setIsServiceHistoryModalOpen(false);
  }, []);

  const onAttachedCaseList = useCallback(
    (nodeIndex: string) => {
      setIsAttachedCaseListModalOpen(true);
      setSelectedServiceContactId(
        nodeChildren.find((index) => index.serviceContactId === nodeIndex)
          ?.serviceContactId
      );
      setIsAttachedServiceContactNames(
        `${
          nodeChildren.find((index) => index.serviceContactId === nodeIndex)
            ?.firstName
        } ${
          nodeChildren.find((index) => index.serviceContactId === nodeIndex)
            ?.surName
        }`
      );
    },
    [nodeChildren]
  );

  const onAttachedCaseListClose = useCallback(() => {
    setIsAttachedCaseListModalOpen(false);
  }, []);

  const onServiceContactDetails = useCallback(
    (serviceContactId: string, isserviceDetailModalVisible: boolean) => {
      if (isServiceContactTab) {
        setSelectedServiceContacts((prevState) =>
          prevState.includes(serviceContactId)
            ? prevState.filter(
                (serviceContact) => serviceContact !== serviceContactId
              )
            : [...prevState, serviceContactId]
        );
      }

      if (isserviceDetailModalVisible || !isServiceContactTab) {
        setIsServiceDetailModalOpen(isserviceDetailModalVisible);
        setIsServiceContactFormOpen(true);
        setSelectedServiceContactId(serviceContactId);
        setSelectedServiceFirmId(
          serviceInformation?.find(
            (info) => info.serviceContactId === serviceContactId
          )?.firmId
        );
      }
    },
    [serviceInformation, isServiceContactTab]
  );

  const onCreateServiceContactToCase = useCallback(() => {
    setIsCreateServiceContactModalVisible(true);
  }, []);

  const closeCreateServiceContactModal = useCallback(
    (shouldUpdateGrid?: boolean, serviceContactId?: string) => {
      if (serviceContactId) {
        setSelectedServiceContacts((prev) => [...prev, serviceContactId]);
        refetchServiceContact();
      }

      setIsCreateServiceContactModalVisible(false);
    },
    [refetchServiceContact]
  );

  useEffect(() => {
    if (deAttachedServiceContact.isSuccess) {
      setNodeChildren((prevState) =>
        prevState.filter(
          (node) =>
            node.serviceContactId !==
            deAttachedServiceContact.variables?.serviceContactId
        )
      );

      createNotification({
        title: "Service Contact Notification",
        type: "success",
        message: "Service Contacted Detached",
      });
    }
    // eslint-disable-next-line
  }, [deAttachedServiceContact.isSuccess]);

  const onServiceDetailsModalClose = useCallback(() => {
    setIsServiceDetailModalOpen(false);
  }, []);

  useEffect(() => {
    if (serviceInformation) {
      setNodeChildren(serviceInformation);

      setSelectedServiceContacts((prevState) =>
        prevState.length
          ? prevState
          : serviceInformation.map(
              (serviceContact) => serviceContact.serviceContactId
            )
      );
    }
  }, [serviceInformation]);

  useEffect(() => {
    if (deAttachedServiceContact.isError) {
      createNotification({
        title: "Service Contact Notification",
        type: "error",
        message:
          deAttachedServiceContact.error.response?.data?.userMessage ||
          "Something went wrong while detaching service contact",
      });
    }
    // eslint-disable-next-line
  }, [deAttachedServiceContact.isError]);

  return (
    <>
      <div style={{ marginBottom: "15px" }}>
        <span>Select a Party to add Service Contacts</span>
      </div>

      <div className="i2file-treeview">
        {deAttachedServiceContact.isLoading && <Loader />}
        {ServieInfoLoading || ServiceInfoFetching ? (
          <Loader />
        ) : (
          <>
            {caseDetails?.partyResponses?.map((partyResponse) => (
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                <TreeItem
                  nodeId={partyResponse.efmPartyId}
                  label={
                    <span
                      onClick={onPartyClick.bind(
                        this,
                        partyResponse.efmPartyId
                      )}
                    >
                      {isServiceContactTab && (
                        <Checkbox
                          checked={
                            !nodeChildren
                              .filter(
                                (node) =>
                                  node.efmPartyId === partyResponse.efmPartyId
                              )
                              ?.some(
                                (childNode) =>
                                  !selectedServiceContacts.includes(
                                    childNode.serviceContactId
                                  )
                              )
                          }
                          disableRipple
                        />
                      )}
                      {`${partyResponse.efmPartytypeRoleName || "Unknown"}: ${
                        partyResponse.partyFirstName || ""
                      } ${partyResponse.partyMiddleName || ""} ${
                        partyResponse.partyLastName || ""
                      }`}
                    </span>
                  }
                  onClick={() => onNodeSelected(partyResponse.efmPartyId)}
                >
                  {nodeChildren
                    .filter(
                      (node) => node.efmPartyId === partyResponse.efmPartyId
                    )
                    ?.map((childNode) => (
                      <>
                        <div className="treetable-custom-list">
                          <div className="treelist-name">
                            <TreeItem
                              key={childNode.serviceContactId}
                              nodeId={childNode.email}
                              label={
                                <>
                                  {isServiceContactTab && (
                                    <Checkbox
                                      checked={selectedServiceContacts.includes(
                                        childNode.serviceContactId
                                      )}
                                      disableRipple
                                    />
                                  )}
                                  {`${childNode.firstName} ${childNode.surName} (${childNode.email})`}
                                </>
                              }
                              onClick={() =>
                                onServiceContactDetails(
                                  childNode.serviceContactId,
                                  false
                                )
                              }
                            />
                          </div>
                          <div>
                            <span>
                              <Tooltip title="View Service Contact Details">
                                <IconButton
                                  aria-label="View Service Contact Details"
                                  onClick={() =>
                                    onServiceContactDetails(
                                      childNode.serviceContactId,
                                      true
                                    )
                                  }
                                >
                                  <PersonIcon className="theme-blue" />
                                </IconButton>
                              </Tooltip>
                            </span>
                            <span>
                              <Tooltip title="View Attached Case List">
                                <IconButton
                                  aria-label="View Attached Case List"
                                  onClick={() =>
                                    onAttachedCaseList(
                                      childNode.serviceContactId
                                    )
                                  }
                                >
                                  <AttachFileIcon className="theme-blue" />
                                </IconButton>
                              </Tooltip>
                            </span>

                            {isServiceContactTab && (
                              <span>
                                <Tooltip title="Update Service Contact">
                                  <IconButton
                                    aria-label="Update Service Contact"
                                    onClick={() =>
                                      onServiceContactDetached(
                                        childNode.serviceContactId
                                      )
                                    }
                                  >
                                    <EditIcon className="theme-blue" />
                                  </IconButton>
                                </Tooltip>
                              </span>
                            )}

                            <span>
                              <Tooltip title="Detach Contact">
                                <IconButton
                                  aria-label="Detach Contact"
                                  onClick={() =>
                                    onServiceContactDetached(
                                      childNode.serviceContactId
                                    )
                                  }
                                >
                                  <DeleteIcon color="error" />
                                </IconButton>
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                      </>
                    ))}
                </TreeItem>
              </TreeView>
            ))}

            <TreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              <TreeItem
                nodeId="0"
                label={
                  <span onClick={onPartyClick.bind(this, "0")}>
                    {isServiceContactTab && (
                      <Checkbox
                        checked={
                          !nodeChildren
                            .filter((node) => node.efmPartyId === "0")
                            ?.some(
                              (childNode) =>
                                !selectedServiceContacts.includes(
                                  childNode.serviceContactId
                                )
                            )
                        }
                        disableRipple
                      />
                    )}
                    Other Service Contacts
                  </span>
                }
                onClick={() => onNodeSelected("0")}
              >
                {nodeChildren
                  .filter((node) => node.efmPartyId === "0")
                  ?.map((childNode) => (
                    <>
                      <div className="treetable-custom-list">
                        <div className="treelist-name">
                          <TreeItem
                            key={childNode.serviceContactId}
                            nodeId={childNode.email}
                            label={
                              <>
                                {isServiceContactTab && (
                                  <Checkbox
                                    checked={selectedServiceContacts.includes(
                                      childNode.serviceContactId
                                    )}
                                    disableRipple
                                  />
                                )}
                                {`${childNode.firstName} ${childNode.surName} (${childNode.email})`}
                              </>
                            }
                            onClick={() =>
                              onServiceContactDetails(
                                childNode.serviceContactId,
                                false
                              )
                            }
                          />
                        </div>
                        <div>
                          <span>
                            <Tooltip title="View Service Contact Details">
                              <IconButton
                                aria-label="View Service Contact Details"
                                onClick={() =>
                                  onServiceContactDetails(
                                    childNode.serviceContactId,
                                    true
                                  )
                                }
                              >
                                <PersonIcon className="theme-blue" />
                              </IconButton>
                            </Tooltip>
                          </span>
                          <span>
                            <Tooltip title="View Attached Case List">
                              <IconButton
                                aria-label="View Attached Case List"
                                onClick={() =>
                                  onAttachedCaseList(childNode.serviceContactId)
                                }
                              >
                                <AttachFileIcon className="theme-blue" />
                              </IconButton>
                            </Tooltip>
                          </span>
                          {isServiceContactTab && (
                            <span>
                              <Tooltip title="Update Service Contact">
                                <IconButton
                                  aria-label="Update Service Contact"
                                  onClick={() =>
                                    onServiceContactDetached(
                                      childNode.serviceContactId
                                    )
                                  }
                                >
                                  <EditIcon className="theme-blue" />
                                </IconButton>
                              </Tooltip>
                            </span>
                          )}
                          <span>
                            <Tooltip title="Detach Contact">
                              <IconButton
                                aria-label="Detach Contact"
                                onClick={() =>
                                  onServiceContactDetached(
                                    childNode.serviceContactId
                                  )
                                }
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                    </>
                  ))}
              </TreeItem>
            </TreeView>
          </>
        )}
      </div>
      <div>
        <div className="text-right mt-3 mb-3">
          {isServiceContactTab && (
            <Button
              className="btn button-outline-secondary me-2 mb-3"
              variant="contained"
              onClick={onCreateServiceContactToCase}
            >
              Add Service Contact To Case
            </Button>
          )}
          {!isServiceContactTab && (
            <Button
              className="btn button-outline-secondary me-2 mb-3"
              variant="contained"
              onClick={onServiceInfoHistory}
            >
              Show History
            </Button>
          )}
          <Button
            className="btn primary-button mb-3"
            variant="contained"
            type="button"
            onClick={onMasterListModalOpen}
          >
            Add From Master List
          </Button>
        </div>
      </div>
      <div>
        <ServiceContactDetailUpdateForm
          isServiceContactFormOpen={isServiceContactFormOpen}
          onClose={onClose}
          selectedServiceContactId={selectedServiceContactId}
          selectedServiceFirmId={selectedServiceFirmId}
        />
      </div>

      {!isServiceContactTab && (
        <div className="text-right">
          <Button
            className="btn button-outline-secondary"
            variant="contained"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </div>
      )}
      {isMasterListModalVisible && selectedNodeId && (
        <MasterListModalDialog
          isOpen={isMasterListModalVisible}
          nodeId={selectedNodeId}
          caseTrackingId={caseDetails?.caseTrackingId}
          attachedNodes={nodeChildren}
          onSave={onMasterListModalSave}
          onClose={onMasterListModalClose}
        />
      )}
      {isServiceHistoryModalOpen && countyId && caseTrackingId && (
        <ServiceInfoHistory
          isOpen={isServiceHistoryModalOpen}
          onClose={onServiceHistoryModalClose}
          locationCounty={countyId}
          caseTrackingId={caseTrackingId}
        />
      )}
      {isAttachedCaseListModalOpen &&
        countyId &&
        selectedServiceContactId &&
        isAttachedServiceContactNames && (
          <AttachedCaseList
            isOpen={isAttachedCaseListModalOpen}
            onClose={onAttachedCaseListClose}
            locationCounty={countyId}
            serviceContactId={selectedServiceContactId}
            serviceContactNames={isAttachedServiceContactNames}
          />
        )}
      {isServiceDetailModalOpen && selectedServiceContactId && (
        <ServiceContactDetailsModal
          isOpen={isServiceDetailModalOpen}
          onClose={onServiceDetailsModalClose}
          serviceContactId={selectedServiceContactId}
        />
      )}

      {isServiceContactTab && selectedServiceContacts.length === 0 && (
        <Alert severity="error">
          At least one service contact must be selected if the filing includes
          service.
        </Alert>
      )}
      {isServiceContactTab && (
        <Box pt={4} pb={2} display="flex" justifyContent="center">
          <Button
            className="btn button-outline-secondary me-3"
            variant="contained"
            type="button"
            onClick={() =>
              updateServiceContacts?.(selectedServiceContacts, nodeChildren, 2)
            }
          >
            Previous
          </Button>
          <Button
            className="btn primary-button me-3"
            variant="contained"
            type="button"
            onClick={() => createDraft?.(selectedServiceContacts, nodeChildren)}
          >
            Save As Draft
          </Button>
          <Button
            className="btn primary-button"
            variant="contained"
            type="submit"
            onClick={() =>
              selectedServiceContacts.length &&
              updateServiceContacts?.(selectedServiceContacts, nodeChildren, 4)
            }
          >
            Next
          </Button>
        </Box>
      )}

      {isCreateServiceContactModalVisible && (
        <CreateEditServiceContact
          isOpen={isCreateServiceContactModalVisible}
          caseTrackingId={caseDetails?.caseTrackingId}
          parties={caseDetails?.partyResponses}
          onClose={closeCreateServiceContactModal}
        />
      )}
    </>
  );
};

export default ManageServiceContact;
