import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid/models";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";

import ExistingParty from "./existingParty";
import Party from "../../newCase/parties/party";
import AddPartyModal from "../../newCase/parties/addParty";
import GridTable from "../../../components/grid";
import CrossReferenceNumberDialog from "../../newCase/caseInformation/crossReferenceNumberDialog";
import useCrossReferenceNumber from "../../../apis/common/useCrossReferenceNumber";
import useSaveForFuture from "../../../apis/newCase/useSaveForFuture";
import {
  DUPAGE_LOCATION_CODE,
  INDIVIDUAL_USER_DEFAULT_CROSS_REFERENCE_NUMBER,
} from "../../../constants";
import { isIndividualUser } from "../../../utills";
import {
  ICaseResponse,
  ICrossReferenceNumber,
  IParty,
  IReference,
} from "../../../interfaces";

interface IParties {
  caseInfo: ICaseResponse;
  parties: IParty[];
  crossReferenceNumbers: ICrossReferenceNumber[];
  updateParties: (
    updatedParties: IParty[],
    updatedCrossReferenceNumbers: ICrossReferenceNumber[],
    tabIndex: number
  ) => void;
}

const Parties: FC<IParties> = ({
  caseInfo,
  parties,
  crossReferenceNumbers,
  updateParties,
}) => {
  const [isAddPartyModalOpen, setIsAddPartyModalOpen] = useState(false);
  const [showCrossReferenceNumberModal, setShowCrossReferenceNumberDialog] =
    useState(false);
  const [requiredCrossReferenceCodeName, setRequiredCrossReferenceCodeName] =
    useState("");
  const [caseCrossReferenceType, setCaseCrossReferenceType] =
    useState<IReference | null>(null);
  const [caseCrossReferenceNumber, setCaseCrossReferenceNumber] = useState("");
  const [crossReferenceErrorMessage, setCrossReferenceErrorMessage] =
    useState("");
  const [
    showCrossReferenceValidationError,
    setShowCrossReferenceValidationError,
  ] = useState(false);
  const { data: crossReferences } = useCrossReferenceNumber(
    caseInfo.locationId,
    caseInfo.caseTypeCode
  );
  const saveForFuture = useSaveForFuture();

  const { watch, control, setValue, handleSubmit, getValues } = useForm<{
    parties: IParty[];
    caseCrossReferenceNumbers?: ICrossReferenceNumber[];
  }>({
    defaultValues: {
      parties: parties.length
        ? parties
        : caseInfo.partyResponses.map((party) => ({
            ...party,
            code: party.efmPartytypeRoleCode,
            name: party.efmPartytypeRoleName,
            firstName: party.partyFirstName,
            middleName: party.partyMiddleName,
            lastName: party.partyLastName,
            streetAddressLine1: party.address1,
            streetAddressLine2: party.address2,
            stateCode: party.state,
            type: party.partyType || "person",
            additionalAttorneys: [],
            existingLeadAttorney: caseInfo.caseOfficialResponses?.find(
              (attorney) =>
                attorney.attorneyIsLead &&
                attorney.attorneyCaseRepPartyId === party.partyId
            ),
            existingAdditionalAttorneys: caseInfo.caseOfficialResponses?.filter(
              (attorney) =>
                !attorney.attorneyIsLead &&
                attorney.attorneyCaseRepPartyId === party.partyId
            ),
          })),
      caseCrossReferenceNumbers: crossReferenceNumbers || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "parties",
    control,
  });

  const onRemoveCrossReferenceNumber = useCallback(
    (efmCrossreferencecodeCode: string) => {
      setValue(
        "caseCrossReferenceNumbers",
        watch("caseCrossReferenceNumbers")?.filter(
          (item) => item.efmCrossreferencecodeCode !== efmCrossreferencecodeCode
        )
      );
    },
    [setValue, watch]
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "efmCrossreferencecodeName",
        headerName: "Case Cross Reference Type",
        width: 750,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "userCrossReferenceNumber",
        headerName: "Case Cross Reference Number",
        width: 700,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "action",
        headerName: "",
        width: 50,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <IconButton
              onClick={onRemoveCrossReferenceNumber.bind(
                this,
                params.row.efmCrossreferencecodeCode
              )}
            >
              <DeleteIcon color="error" />
            </IconButton>
          );
        },
      },
    ],
    [onRemoveCrossReferenceNumber]
  );

  const showCrossReferenceNumber = useMemo(() => {
    return crossReferences?.locationCrossReferenceCodeList?.length;
  }, [crossReferences]);

  const showSaveForFuture = useMemo(() => {
    return crossReferences?.crossReferenceItemForPrepopulateList?.length;
  }, [crossReferences]);

  const onAddCrossReferenceNumber = useCallback(
    (isSaveForFuture?: boolean) => {
      if (caseCrossReferenceType) {
        if (caseCrossReferenceNumber) {
          if (
            caseCrossReferenceType.required &&
            caseCrossReferenceType.validationregex
          ) {
            if (
              new RegExp(caseCrossReferenceType.validationregex).test(
                caseCrossReferenceNumber
              )
            ) {
              setCrossReferenceErrorMessage("");

              const crossReferenceNumberList = watch(
                "caseCrossReferenceNumbers"
              )?.filter(
                (item) =>
                  item.efmCrossreferencecodeCode !==
                  caseCrossReferenceType.efmCrossreferencecodeCode
              );

              crossReferenceNumberList?.push({
                efmCrossreferencecodeCode:
                  caseCrossReferenceType.efmCrossreferencecodeCode,
                efmCrossreferencecodeName:
                  caseCrossReferenceType.efmCrossreferencecodeName,
                userCrossReferenceNumber: caseCrossReferenceNumber,
              });

              setValue("caseCrossReferenceNumbers", crossReferenceNumberList);
              setCaseCrossReferenceNumber("");
              setShowCrossReferenceValidationError(false);

              if (isSaveForFuture && crossReferenceNumberList) {
                saveForFuture.mutate({
                  efmLocationCode: caseInfo.locationId,
                  caseCrossReferenceNumberList: crossReferenceNumberList.map(
                    (crossReferenceNumber) => ({
                      efmCrossreferencecodeName:
                        crossReferenceNumber.efmCrossreferencecodeName,
                      userCrossReferenceNumber:
                        crossReferenceNumber.userCrossReferenceNumber,
                    })
                  ),
                });
              }
            } else {
              setCrossReferenceErrorMessage(
                `The value "${caseCrossReferenceNumber}" does not match the format expected for the "${caseCrossReferenceType.efmCrossreferencecodeName}" Case Cross Reference number.`
              );
            }
          }
        } else if (
          !watch("caseCrossReferenceNumbers")?.some(
            (item) =>
              item.efmCrossreferencecodeCode ===
              caseCrossReferenceType.efmCrossreferencecodeCode
          )
        ) {
          setCrossReferenceErrorMessage(
            `Case type requires a Case Cross Reference number of type "${caseCrossReferenceType.efmCrossreferencecodeName}".`
          );
        }
      }
    },
    [
      caseCrossReferenceType,
      caseCrossReferenceNumber,
      caseInfo.locationId,
      saveForFuture,
      setValue,
      watch,
    ]
  );

  const onCaseCrossReferenceTypeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCaseCrossReferenceType(
        crossReferences?.locationCrossReferenceCodeList?.find(
          (item) => item.efmCrossreferencecodeCode === event.target.value
        ) || null
      );
    },
    [crossReferences?.locationCrossReferenceCodeList]
  );

  const onCaseCrossReferenceNumberChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCaseCrossReferenceNumber(event.target.value.trim());
    },
    []
  );

  const openAddPartyModal = useCallback(() => {
    setIsAddPartyModalOpen(true);
  }, []);

  const closeAddPartyModal = useCallback(() => {
    setIsAddPartyModalOpen(false);
  }, []);

  const addAdditionalParty = useCallback(
    (party: IParty) => {
      append(party);
      setIsAddPartyModalOpen(false);
    },
    [append]
  );

  const closeCrossReferenceNumberModal = useCallback(() => {
    setShowCrossReferenceNumberDialog(false);
    window.setTimeout(() => {
      document.getElementById("crossReferenceNumber")?.focus();
    }, 100);
  }, []);

  const onPrevious = useCallback(() => {
    const formData = getValues();

    updateParties(
      formData.parties,
      formData.caseCrossReferenceNumbers || [],
      0
    );
  }, [getValues, updateParties]);

  const onSubmit = useCallback(
    (formData: {
      parties: IParty[];
      caseCrossReferenceNumbers?: ICrossReferenceNumber[];
    }) => {
      if (crossReferences?.locationCrossReferenceCodeList.length) {
        let requiredCrossReferenceNumbers: IReference[] = [];

        if (caseInfo.locationId.toLowerCase().includes(DUPAGE_LOCATION_CODE)) {
          requiredCrossReferenceNumbers =
            crossReferences.locationCrossReferenceCodeList;
        } else {
          requiredCrossReferenceNumbers =
            crossReferences.locationCrossReferenceCodeList.filter(
              (item) => item.required
            );
        }

        if (requiredCrossReferenceNumbers.length) {
          if (
            formData.caseCrossReferenceNumbers?.some(
              (item) =>
                item.efmCrossreferencecodeCode ===
                requiredCrossReferenceNumbers[0].efmCrossreferencecodeCode
            )
          ) {
            setShowCrossReferenceValidationError(false);
          } else {
            setCrossReferenceErrorMessage(
              `Case type requires a Case Cross Reference number of type "${requiredCrossReferenceNumbers[0].efmCrossreferencecodeName}".`
            );
            setShowCrossReferenceValidationError(true);
            return;
          }
        }
      }

      updateParties(
        formData.parties,
        formData.caseCrossReferenceNumbers || [],
        2
      );
    },
    // eslint-disable-next-line
    [crossReferences]
  );

  useEffect(() => {
    if (crossReferenceNumbers.length) return;

    if (crossReferences?.locationCrossReferenceCodeList?.length) {
      setCaseCrossReferenceType(
        crossReferences.locationCrossReferenceCodeList[0]
      );

      if (crossReferences?.filerCrossReferenceMappingList?.length) {
        const crossReferenceNumberList = [];

        for (const filerCrossReferenceMapping of crossReferences.filerCrossReferenceMappingList) {
          const crossReferenceNumber =
            crossReferences?.locationCrossReferenceCodeList.find(
              (item) =>
                item.efmCrossreferencecodeName ===
                filerCrossReferenceMapping.efmCrossreferencecodeName
            );

          if (
            crossReferenceNumber &&
            filerCrossReferenceMapping.userCrossreferencecodeCode
          ) {
            crossReferenceNumberList.push({
              efmCrossreferencecodeCode:
                crossReferenceNumber.efmCrossreferencecodeCode,
              efmCrossreferencecodeName:
                crossReferenceNumber.efmCrossreferencecodeName,
              userCrossReferenceNumber:
                filerCrossReferenceMapping.userCrossreferencecodeCode,
            });
          }
        }

        setValue("caseCrossReferenceNumbers", crossReferenceNumberList);
      } else if (
        isIndividualUser() &&
        caseInfo.locationId.toLowerCase().includes(DUPAGE_LOCATION_CODE)
      ) {
        setValue(`caseCrossReferenceNumbers`, [
          {
            efmCrossreferencecodeCode:
              crossReferences.locationCrossReferenceCodeList[0]
                .efmCrossreferencecodeCode,
            efmCrossreferencecodeName:
              crossReferences.locationCrossReferenceCodeList[0]
                .efmCrossreferencecodeName,
            userCrossReferenceNumber:
              INDIVIDUAL_USER_DEFAULT_CROSS_REFERENCE_NUMBER,
          },
        ]);
      }

      if (watch("caseCrossReferenceNumbers")?.length) return;

      if (caseInfo.locationId.toLowerCase().includes(DUPAGE_LOCATION_CODE)) {
        setShowCrossReferenceNumberDialog(true);
        setRequiredCrossReferenceCodeName("");
      } else {
        const requiredCrossReferenceNumber =
          crossReferences.locationCrossReferenceCodeList.find(
            (item) => item.efmCrossreferencecodeCode !== null && item.required
          );

        if (requiredCrossReferenceNumber) {
          setShowCrossReferenceNumberDialog(true);
          setRequiredCrossReferenceCodeName(
            caseInfo.locationId.toLowerCase().startsWith("cook")
              ? ""
              : requiredCrossReferenceNumber.efmCrossreferencecodeName
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [crossReferences, crossReferenceNumbers]);

  return (
    <Box sx={{ p: 0 }} className="existing-case-parties">
      <div className="filings-row">
        <div className="d-flex justify-content-center">
          <span className="case-id-title">
            {`CASE # ${caseInfo.caseNumber} (${caseInfo.jurisdiction}) - ${caseInfo.description}`}
          </span>
        </div>
      </div>

      {Boolean(showCrossReferenceNumber) && (
        <>
          <Typography variant="h3">Case Cross Reference Number</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} mt={2}>
              {crossReferenceErrorMessage && (
                <Alert severity="error">
                  <b>ERROR :</b> {crossReferenceErrorMessage}
                </Alert>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                className="w-100"
                id="crossReferenceType"
                InputLabelProps={{
                  shrink: true,
                  required: true,
                  htmlFor: "crossReferenceType_id",
                }}
                inputProps={{
                  id: "crossReferenceType_id",
                }}
                SelectProps={{
                  displayEmpty: true,
                }}
                select
                label="Case Cross Reference Type"
                variant="standard"
                value={caseCrossReferenceType?.efmCrossreferencecodeCode || ""}
                onChange={onCaseCrossReferenceTypeChange}
                error={
                  showCrossReferenceValidationError && !caseCrossReferenceType
                }
              >
                <MenuItem value="">Select Case Cross Reference Type</MenuItem>
                {crossReferences?.locationCrossReferenceCodeList?.map(
                  (reference) => (
                    <MenuItem
                      key={reference.efmCrossreferencecodeCode}
                      value={reference.efmCrossreferencecodeCode}
                    >
                      {reference.efmCrossreferencecodeName}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                className="w-100 m-0 remove-item-css"
                id="crossReferenceNumber"
                InputLabelProps={{ required: true }}
                placeholder="Case Cross Reference Number"
                label="Case Cross Reference Number"
                variant="standard"
                value={caseCrossReferenceNumber}
                onChange={onCaseCrossReferenceNumberChange}
                error={
                  showCrossReferenceValidationError && !caseCrossReferenceNumber
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                type="button"
                variant="contained"
                className="btn button-outline-secondary"
                onClick={onAddCrossReferenceNumber.bind(this, false)}
              >
                Add
              </Button>
              {Boolean(showSaveForFuture) && (
                <Button
                  type="button"
                  variant="contained"
                  className="btn primary-button ms-4"
                  onClick={onAddCrossReferenceNumber.bind(this, true)}
                >
                  Save For Future
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <GridTable
                columns={columns}
                rows={watch("caseCrossReferenceNumbers")}
                hideFooter={false}
                getRowId={(row) => row.efmCrossreferencecodeCode}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Typography variant="h3" marginTop={2}>
        Parties
      </Typography>
      <Box
        className="existing-case-parties-top"
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5">
          Enter the details for the Parties Involved in this Case
        </Typography>
        <Button
          variant="contained"
          className="btn button-outline-secondary"
          startIcon={<AddIcon />}
          onClick={openAddPartyModal}
        >
          Add Additional Party
        </Button>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => {
          return field.isAdditinalParty ? (
            <Party
              key={field.id}
              field={field}
              control={control}
              index={index}
              locationCode={"dupage"}
              watch={watch}
              setValue={setValue}
              remove={remove}
            />
          ) : (
            <ExistingParty
              key={field.id}
              control={control}
              field={field}
              watch={watch}
              index={index}
            />
          );
        })}

        <Box pt={4} pb={2} display="flex" justifyContent="center">
          <Button
            className="btn button-outline-secondary me-3"
            variant="contained"
            type="button"
            onClick={onPrevious}
          >
            Previous
          </Button>

          <Button
            className="btn primary-button"
            variant="contained"
            type="submit"
          >
            Next
          </Button>
        </Box>
      </form>

      <AddPartyModal
        isOpen={isAddPartyModalOpen}
        locationCode={caseInfo.locationId}
        addAdditionalParty={addAdditionalParty}
        closeModal={closeAddPartyModal}
      />

      <CrossReferenceNumberDialog
        isOpen={showCrossReferenceNumberModal}
        locationCode={caseInfo.locationId}
        locationName={caseInfo.jurisdiction}
        requiredCrossReferenceCodeName={requiredCrossReferenceCodeName}
        onClose={closeCrossReferenceNumberModal}
      />
    </Box>
  );
};

export default Parties;
