import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_SERVICE_CONTACT } from "../../URL";
import { IAxisErrorResponse, IServiceContact } from "../../../interfaces";

const getServiceContact = async (serviceContactID?: string) => {
  if (!serviceContactID) return null;

  const response = await http.post<{ serviceContact: IServiceContact }>(
    GET_SERVICE_CONTACT,
    {
      serviceContactID,
    }
  );

  return response.data.serviceContact;
};

const useServiceContact = (serviceContactId?: string) =>
  useQuery<IServiceContact | null, IAxisErrorResponse>({
    queryKey: ["service-contact-details", serviceContactId],
    queryFn: () => getServiceContact(serviceContactId),
  });

export default useServiceContact;
