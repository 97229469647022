import Button from "@mui/material/Button";
import { FC, useCallback, useContext, useEffect, useState } from "react";

import Dialog from "../../../../components/dialog/Dialog";
import TransferList from "../../../../components/transferList";
import { ICaseParty, IServiceResponse } from "../../../../interfaces";

import Loader from "../../../../components/loader/Loader";
import ServiceContactDetailsModal from "../serviceContactDetailsModalDialog";
import { NotificationContext } from "../../../../context/NotificationContext";
import useAttachedServiceContact from "../../../../apis/existingCase/useAttachedServiceContact";
import useServiceContactList from "../../../../apis/existingCase/useServiceContactList/useServiceContactList";

interface MasterListServiceProps {
  isOpen: boolean;
  caseTrackingId: string;
  nodeId: string;
  attachedNodes: IServiceResponse[];
  parties?: ICaseParty[];
  onSave: (additionalDetails?: IServiceResponse[], nodeId?: string) => void;
  onClose: (shouldUpdateGrid?: boolean) => void;
}

const MasterListModalDialog: FC<MasterListServiceProps> = ({
  isOpen,
  caseTrackingId,
  nodeId,
  attachedNodes,
  onSave,
  onClose,
}) => {
  const serviceContactList = useServiceContactList();

  const attachedServiceContact = useAttachedServiceContact();

  const { createNotification } = useContext(NotificationContext);

  const [selectServiceContact, setSelectedServiceContact] = useState<
    IServiceResponse[]
  >([]);
  const [serviceContact, setServiceContact] = useState<IServiceResponse[]>([]);

  const [isServiceDetailModalOpen, setIsServiceDetailModalOpen] =
    useState(false);

  const onServiceDetailsModalClose = useCallback(() => {
    setIsServiceDetailModalOpen(false);
  }, []);

  const [selectedServiceContactId, setSelectedServiceContactId] = useState<
    string | undefined
  >();

  const onServiceSelect = useCallback(
    (ids: string[]) => {
      if (serviceContact?.length && ids.length) {
        const serviceContactList = serviceContact.filter((serviceContactList) =>
          ids.includes(serviceContactList.serviceContactId)
        );

        if (serviceContactList.length)
          setSelectedServiceContact(serviceContactList);

        setServiceContact((prevProps) =>
          prevProps.map((serviceContact) =>
            ids.includes(serviceContact.serviceContactId)
              ? { ...serviceContact, isSelected: true }
              : { ...serviceContact }
          )
        );
      }
    },
    [serviceContact]
  );

  const onServiceUnselect = useCallback((ids: string[]) => {
    setServiceContact((prevProps) =>
      prevProps.map((serviceContact) =>
        ids.includes(serviceContact.serviceContactId)
          ? { ...serviceContact, isSelected: false }
          : { ...serviceContact }
      )
    );

    setSelectedServiceContact((prevState) =>
      prevState.filter(
        (serviceContact) => !ids.includes(serviceContact.serviceContactId)
      )
    );
  }, []);

  const onSubmit = useCallback(() => {
    if (!selectServiceContact.length) return;

    attachedServiceContact.mutate({
      caseTrackingId: caseTrackingId || "",
      listOfServiceContact: selectServiceContact.map((contact) => ({
        partyId: nodeId,
        serviceContactID: contact.serviceContactId || "",
      })),
    });
  }, [selectServiceContact, nodeId, caseTrackingId, attachedServiceContact]);

  useEffect(() => {
    if (attachedServiceContact.isSuccess) {
      onSave(selectServiceContact, nodeId);

      createNotification({
        title: "Service Contact Notification",
        type: "success",
        message: "Service Contacted Attached",
      });
    }
    // eslint-disable-next-line
  }, [attachedServiceContact.isSuccess]);

  useEffect(() => {
    if (attachedServiceContact.isError) {
      createNotification({
        title: "Service Contact Notification",
        type: "error",
        message:
          attachedServiceContact.error.response?.data?.userMessage ||
          "Something went wrong while  service contact",
      });
    }
    // eslint-disable-next-line
  }, [attachedServiceContact.isError, createNotification]);

  useEffect(() => {
    if (serviceContactList.isSuccess) {
      const mappedServiceContacts = serviceContactList.data.map((contact) => ({
        serviceContactId: contact.serviceContactID,
        firstName: contact.firstName,
        surName: contact.lastName,
        email: contact.email,
        middleName: contact.middleName,
        phoneNumber: contact.phoneNumber,
        firmID: contact.firmID,
        partyFirmName: contact.firmName,
        addByFirmName: contact.addByFirmName,
        administrativeCopy: contact.administrativeCopy,
        address: contact.address,
        isSelected: contact.isSelected,
        participantId: nodeId,
        telNo: "",
        action: "",
        actionTimeStamp: "",
        mailingAddress: "",
        firmId: "",
        efmPartyId: nodeId,
      }));

      setServiceContact(mappedServiceContacts);
    }
    // eslint-disable-next-line
  }, [serviceContactList.isSuccess]);

  const handleSearchClick = useCallback((nodeId: string) => {
    setIsServiceDetailModalOpen(true);
    setSelectedServiceContactId(nodeId);
  }, []);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose.bind(this, false)}
      title="Add Service Contacts from MasterList"
      maxWidth="md"
    >
      {serviceContactList.isLoading || serviceContactList.isFetching ? (
        <Loader />
      ) : (
        <>
          <div className="pick-list">
            <TransferList
              leftTitle="Firm Service Contacts"
              options={
                serviceContact
                  .filter(
                    (node) =>
                      !attachedNodes.some(
                        (childNode) =>
                          childNode.serviceContactId === node.serviceContactId
                      )
                  )
                  .map((item) => ({
                    id: item.serviceContactId,
                    label: `${item.firstName} ${item.surName}`,
                    subTitle: `${item.email}`,
                    isSelected: item.isSelected || false,
                  })) ?? []
              }
              disableTransfer={false}
              onSelect={onServiceSelect}
              onUnselect={onServiceUnselect}
              showSearch={true}
              searchToolTipTitle="View Service Contact Details"
              onSearchClick={handleSearchClick}
            ></TransferList>
          </div>
          <div className="text-right mt-3">
            <Button
              className="btn primary-button"
              variant="contained"
              type="submit"
              disabled={!selectServiceContact.length}
              onClick={onSubmit}
            >
              Save
            </Button>
          </div>
        </>
      )}
      {isServiceDetailModalOpen && selectedServiceContactId && (
        <ServiceContactDetailsModal
          isOpen={isServiceDetailModalOpen}
          onClose={onServiceDetailsModalClose}
          serviceContactId={selectedServiceContactId}
        />
      )}
    </Dialog>
  );
};
export default MasterListModalDialog;
