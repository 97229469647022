import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_SUPPORT_DATA } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface IFAQ {
  categoryQuestionId: string;
  categoryId: number;
  categoryName: string;
  questionId: string;
  questionText: string;
  answerText: string;
  status: string;
  displayOrder: string;
}

const getSupport = async () => {
  const response = await http.post<IFAQ[]>(GET_SUPPORT_DATA);
  return response.data;
};

const useSupport = () =>
  useQuery<IFAQ[], IAxisErrorResponse>({
    queryKey: ["support-data"],
    queryFn: getSupport,
  });

export default useSupport;
