import { FC, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import { IAdvancedSearch } from "../../../../interfaces";
import useLocations from "../../../../apis/common/useLocations";
import useLocationCaseTypeSubsequentFiling from "../../../../apis/common/useLocationCaseTypeSubsequentFiling";
import Dialog from "../../../../components/dialog/Dialog";
import useSearchCase from "../../../../apis/searchCase";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface AdvancedSearchProps {
  isOpen: boolean;
  onClose: (shouldUpdateGrid?: boolean) => void;
}

const AdvancedSerach: FC<AdvancedSearchProps> = ({ isOpen, onClose }) => {
  const { watch, control, handleSubmit, reset, setValue } =
    useForm<IAdvancedSearch>();

  const { data: locations } = useLocations();
  const searchCase = useSearchCase();

  const { data: caseCategoriesSubsequentFiling } =
    useLocationCaseTypeSubsequentFiling(watch("locationName"));

  const caseTypeOptions = caseCategoriesSubsequentFiling;

  const onSubmitAdvancedSearch = useCallback(
    (formData: IAdvancedSearch) => {
      searchCase.mutate({
        countyId: formData.locationName || "",
        caseNumber: formData.caseNumber || "",
        searchBy: formData.partyRole || "person",
        location: formData.locationName || "",
        firstName: formData.firstName || "",
        middleName: formData.middleName || "",
        surname: formData.lastName || "",
        businessName: formData.businessName,
        caseTypeFilter: formData.caseType || "",
        sortByFilter: formData.sortedResult || "",
      });
      onClose(true);
    },
    [onClose, searchCase]
  );

  const onPartyTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue("partyRole", event.target.value);
    },
    [setValue]
  );

  const handleReset = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose.bind(this, false)}
      title="Advanced Search"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmitAdvancedSearch)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Item className="remove-item-css">
              <Controller
                name="locationName"
                control={control}
                rules={{
                  required: "Error: Location is required.",
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                      htmlFor: `${field.name}_id`,
                    }}
                    inputProps={{
                      id: `${field.name}_id`,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      SelectDisplayProps: {
                        "aria-required": true,
                      },
                    }}
                    {...field}
                    select
                    placeholder="Select Location"
                    label="Select Location"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    <MenuItem value="">Select Location</MenuItem>
                    {locations?.map((location) => (
                      <MenuItem
                        key={location.efm_Location_Code}
                        value={location.efm_Location_Code}
                      >
                        {location.efm_Location_Name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Item>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              name="partyRole"
              control={control}
              render={({ field: { ref, ...field }, fieldState }) => (
                <>
                  <label>Party Role</label>
                  <RadioGroup
                    aria-label="PartyRole"
                    name="partyRole"
                    value={field.value}
                    defaultValue="person"
                    onChange={onPartyTypeChange}
                    row
                  >
                    <FormControlLabel
                      value="person"
                      control={<Radio />}
                      label="Person"
                    />
                    <FormControlLabel
                      value="business"
                      control={<Radio />}
                      label="Business"
                    />
                  </RadioGroup>
                  <FormHelperText error={!!fieldState.error}>
                    {fieldState.error ? fieldState.error.message : null}
                  </FormHelperText>
                </>
              )}
            />
          </Grid>
          {watch("partyRole") === "business" ? (
            <Grid item xs={12} sm={12} className="pt-0">
              <Controller
                name="businessName"
                control={control}
                rules={{
                  required: "Error: BusinessName is required.",
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100 m-0"
                    id={field.name}
                    inputRef={ref}
                    autoFocus
                    inputProps={{
                      "aria-required": true,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    placeholder="Business Name"
                    label="Business Name"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: "Error: FirstName is required.",
                  }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      className="w-100 m-0"
                      id={field.name}
                      inputRef={ref}
                      autoFocus
                      inputProps={{
                        "aria-required": true,
                      }}
                      InputLabelProps={{
                        required: true,
                      }}
                      {...field}
                      onBlur={() => {
                        field.onChange(field.value?.trim());
                        field.onBlur();
                      }}
                      placeholder="First Name"
                      label="First Name"
                      variant="standard"
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="middleName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      className="w-100 m-0"
                      id={field.name}
                      {...field}
                      onBlur={() => {
                        field.onChange(field.value?.trim());
                        field.onBlur();
                      }}
                      placeholder="Middle Name"
                      label="Middle Name"
                      variant="standard"
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: "Error: LastName is required.",
                  }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      className="w-100 m-0"
                      id={field.name}
                      inputRef={ref}
                      inputProps={{
                        "aria-required": true,
                      }}
                      InputLabelProps={{
                        required: true,
                      }}
                      {...field}
                      onBlur={() => {
                        field.onChange(field.value?.trim());
                        field.onBlur();
                      }}
                      placeholder="Last Name"
                      label="Last Name"
                      variant="standard"
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                    />
                  )}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={12}>
            <Item className="remove-item-css">
              <Controller
                name="caseType"
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                      htmlFor: `${field.name}_id`,
                    }}
                    inputProps={{
                      id: `${field.name}_id`,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      SelectDisplayProps: {
                        "aria-required": true,
                      },
                    }}
                    {...field}
                    select
                    placeholder="Case Type"
                    label="Case Type"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    <MenuItem value="">AllCase Type</MenuItem>
                    {caseTypeOptions?.map((categorySubFiling) => (
                      <MenuItem
                        key={categorySubFiling.efmCasetypeCode}
                        value={categorySubFiling.efmCasetypeCode}
                      >
                        {categorySubFiling.efmCasetypeName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={12}>
            <Item className="remove-item-css">
              <Controller
                name="sortedResult"
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                      htmlFor: `${field.name}_id`,
                    }}
                    inputProps={{
                      id: `${field.name}_id`,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      SelectDisplayProps: {
                        "aria-required": true,
                      },
                    }}
                    {...field}
                    select
                    placeholder="Show Result Sorted By"
                    label="Show Result Sorted By"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    <MenuItem value="caseDocketID"> Case Number</MenuItem>
                    <MenuItem value="caseTypeName"> Case Type</MenuItem>
                    <MenuItem value="countyName"> Location</MenuItem>
                  </TextField>
                )}
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box display={"flex"} justifyContent={"end"}>
              <Button
                type="submit"
                variant="contained"
                className="btn primary-button"
              >
                Search
              </Button>
              <Button
                type="reset"
                variant="contained"
                className="btn primary-button ms-2"
                onClick={handleReset}
              >
                Reset
              </Button>
              <Button
                type="button"
                variant="contained"
                className="btn button-outline-secondary ms-2"
                onClick={onClose.bind(this, false)}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default AdvancedSerach;
