import { FC } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Dialog from "../../../../components/dialog";
import Loader from "../../../../components/loader";
import useAttachededCaseList from "../../../../apis/existingCase/useAttachedCaseList";

interface AttachedCaseListProps {
  isOpen: boolean;
  onClose: () => void;
  locationCounty: string;
  serviceContactId: string;
  serviceContactNames: string;
}

const AttachedCaseListModalDialog: FC<AttachedCaseListProps> = ({
  isOpen,
  onClose,
  locationCounty,
  serviceContactId,
  serviceContactNames,
}) => {
  const {
    data: attachedCaseList,
    isLoading: attachedCaseListLoading,
    isFetching,
  } = useAttachededCaseList({
    locationCounty,
    serviceContactId,
  });

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={`Attached Case for: ${serviceContactNames || ""} `}
      maxWidth="sm"
    >
      {attachedCaseListLoading || isFetching ? (
        <Loader />
      ) : (
        <>
          {attachedCaseList?.map((attachedcaseDetail, index) => (
            <Box
              className="mb-2"
              key={index}
              sx={{
                border: 1,
                borderColor: "lightgray",
              }}
              padding={1}
              borderRadius={2}
            >
              <Typography>
                <b>
                  {attachedcaseDetail.caseNumber
                    ? `Case #${attachedcaseDetail.caseNumber} - `
                    : ""}
                </b>{" "}
                {`${attachedcaseDetail.description}`}
              </Typography>
            </Box>
          ))}
          <div className="text-right mt-3">
            <Button
              className="btn primary-button"
              variant="contained"
              type="button"
              onClick={onClose}
            >
              Close
            </Button>
          </div>
        </>
      )}
    </Dialog>
  );
};

export default AttachedCaseListModalDialog;
