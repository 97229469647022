import { FC } from "react";

import Dialog from "../../../components/dialog/Dialog";
import ManageServiceContact from "./ManageServiceContact";
import { ICaseResponse } from "../../../interfaces";

interface IManageServiceModalDialog {
  isOpen: boolean;
  caseDetails: ICaseResponse;
  countyId?: string;
  caseTrackingId?: string;
  onClose: (shouldUpdateGrid?: boolean) => void;
}

const ManageServiceModalDialog: FC<IManageServiceModalDialog> = ({
  isOpen,
  caseDetails,
  countyId,
  caseTrackingId,
  onClose,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose.bind(this, false)}
      title="Manage Case Service Contacts"
      maxWidth="sm"
    >
      <ManageServiceContact
        caseDetails={caseDetails}
        countyId={countyId}
        caseTrackingId={caseTrackingId}
        onClose={onClose}
      />
    </Dialog>
  );
};

export default ManageServiceModalDialog;
