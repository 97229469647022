import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import format from "date-fns/format";

import Page from "../../components/page";
import CaseInformation from "./caseInformation";
import Parties from "./parties";
import Filings from "./filings";
import Summary from "./summary";
import Loader from "../../components/loader";
import ConfirmationDialog from "./summary/confirmationDialog";
import useProfile from "../../apis/auth/useProfile";
import useFirmInformation from "../../apis/firm/useFirmInformation";
import useCreateDraft from "../../apis/draft/useCreateDraft";
import useLocationCaseCategories from "../../apis/common/useLocationCaseCategories";
import { NotificationContext } from "../../context/NotificationContext";
import {
  IAdditionalParty,
  IAttorney,
  ICaseInformation,
  ICaseOtherEntityAttorney,
  ICasePerson,
  IDecedentPartyAssociation,
  IFilingInformation,
  ILocationParty,
  IParty,
} from "../../interfaces";
import { isFirmAdmin, isIndividualUser } from "../../utills";
import { parseFilingInformation, parseParties } from "./utills";

const NewCase = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const profile = useProfile();
  const firmInformation = useFirmInformation();
  const [caseInformation, setCaseInformation] = useState<ICaseInformation>({
    caseTypeName:
      location.state?.generalCase?.locationCaseType?.efmCasetypeCode || "",
    categoryName:
      location.state?.generalCase?.caseCategoryText?.efmCasecategoryCode || "",
    filingAttorney:
      location.state?.generalCase?.caseParticipant?.find?.(
        (participant: ICasePerson) =>
          participant.entityPerson?.id === "Attorney0"
      )?.entityPerson?.personOtherIdentification?.identificationID || "",
    locationName:
      location.state?.generalCase?.caseCourt?.organizationIdentification
        ?.identificationID?.efmLocationCode || "",
    paymentAccount:
      location.state?.paymentMessage?.allowanceCharge?.paymentMeans
        ?.paymentID || "",
    caseCrossReferenceNumbers:
      location?.state?.caseCrossReferenceNumberList || [],
    draftEnvelopeId: location?.state?.draftEnvelopeId,
  });
  const [locationParties, setLocationParties] = useState<ILocationParty[]>([]);
  const [parties, setParties] = useState<IParty[]>(
    parseParties(location.state)
  );
  const [filingInformation, setFilingInformation] = useState<
    IFilingInformation | undefined
  >(parseFilingInformation(location.state));
  const [activeTab, setActiveTab] = useState(0);
  const [draftNo, setDraftNo] = useState<number>();
  const createDraft = useCreateDraft();
  const { data: caseCategories } = useLocationCaseCategories(
    caseInformation.locationName
  );
  const { createNotification } = useContext(NotificationContext);

  const updateCaseInformation = useCallback(
    (
      caseInformationData: ICaseInformation,
      locationParties: ILocationParty[],
      additionalParties: IAdditionalParty[]
    ) => {
      if (
        caseInformation.caseTypeName !== caseInformationData.caseTypeName ||
        caseInformation.locationName !== caseInformationData.locationName
      ) {
        setParties([]);
        setFilingInformation(undefined);
      } else if (parties.length) {
        const partyList = [...parties];

        for (const party of partyList) {
          const partyObj = locationParties.find(
            (locationParty) => locationParty.efmPartytypeCode === party.code
          );

          if (partyObj) {
            party.name = partyObj.efmPartytypeName;
            party.dateOfDeathCondition = partyObj.dateOfDeath;
          } else {
            const additionalPartyObj = additionalParties.find(
              (additionalParty) =>
                additionalParty.efmPartytypeCode === party.code
            );

            if (additionalPartyObj) {
              party.name = additionalPartyObj.efmPartytypeName;
            }
          }
        }

        setParties(partyList);
      }

      setCaseInformation(caseInformationData);
      setLocationParties(locationParties);
      setActiveTab(1);
    },
    [caseInformation, parties]
  );

  const updateParties = useCallback((partyList: IParty[], tabIndex: number) => {
    setParties(partyList);
    setActiveTab(tabIndex);
  }, []);

  const updateFilings = useCallback(
    (
      filingInfo: IFilingInformation,
      paymentAccount: string,
      filingAttorney: string,
      tabIndex: number
    ) => {
      setFilingInformation(filingInfo);
      setCaseInformation((caseInfo) => ({
        ...caseInfo,
        paymentAccount,
        filingAttorney,
      }));
      setActiveTab(tabIndex);
    },
    []
  );

  const updatePayment = useCallback(
    (
      tabIndex: number,
      paymentAccount: string,
      filingAttorney: string,
      partyResponsibleForFees: number | string
    ) => {
      setCaseInformation((caseInfo) => ({
        ...caseInfo,
        paymentAccount,
        filingAttorney,
      }));

      setFilingInformation((prevState) =>
        prevState
          ? {
              ...prevState,
              partyResponsibleForFeesIndex: partyResponsibleForFees,
            }
          : prevState
      );
      setActiveTab(tabIndex);
    },
    []
  );

  const formatPersonParty = useCallback(
    (party: IParty, partyIndex: number) => ({
      entityPerson: {
        id: `Party${partyIndex}`,
        personName: {
          personGivenName: party.firstName,
          personMiddleName: party.middleName,
          personSurName: party.lastName,
          personNameSuffixText: party.suffix,
          personFullName: `${party.firstName} ${party.lastName}`,
          personConcatName: `${party.firstName} ${party.middleName || ""} ${
            party.lastName
          } ${party.suffix || ""}`,
        },
        personOtherIdentification: {
          identificationCategoryText: "SSN",
        },
        personAugmentation: {
          contactInformation: {
            contactTelephoneNumber: {
              fullTelephoneNumber: {
                telephoneNumberFullID: party.phoneNumber,
              },
            },
            contactMailingAddress: {
              structuredAddress: {
                locationStreet: {
                  streetFullText: party.streetAddressLine1,
                },
                addressSecondaryUnitText: party.streetAddressLine2,
                locationCityName: party.city,
                locationStateName: party.stateCode,
                locationCountry: party.countryCode,
                locationPostalCode: party.zipCode,
              },
            },
          },
        },
        iAmThisParty: party.iAmThisParty,
      },
      caseParticipantRoleCode: party.code,
    }),
    []
  );

  const formatOrgParty = useCallback(
    (party: IParty, organizationIndex: number) => ({
      entityOrganization: {
        id: `OrgParty${organizationIndex}`,
        organizationName: party.businessName,
        organizationPrimaryContactInformation: {
          contactTelephoneNumber: {
            fullTelephoneNumber: {
              telephoneNumberFullID: party.phoneNumber,
            },
          },
        },
        organizationLocation: {
          locationAddress: {
            structuredAddress: {
              locationStreet: {
                streetFullText: party.streetAddressLine1,
              },
              addressSecondaryUnitText: party.streetAddressLine2,
              locationCityName: party.city,
              locationStateName: party.stateCode,
              locationCountry: party.countryCode,
              locationPostalCode: party.zipCode,
            },
          },
        },
      },
      caseParticipantRoleCode: party.code,
    }),
    []
  );

  const formatAttorney = useCallback(
    (attorney: IAttorney, index: number) => ({
      caseParticipantRoleCode: "ATTY",
      entityPerson: {
        id: `Attorney${index}`,
        personName: {
          personGivenName: attorney.firstName,
          personMiddleName: attorney.middleName,
          personSurName: attorney.lastName,
        },
        personOtherIdentification: {
          identificationID: attorney.attorneyID,
          identificationCategoryText: "ATTORNEYID",
        },
      },
    }),
    []
  );

  const formatToFeeCalculation = useCallback(
    (
      paymentAccountId: string,
      filingAttorneyId: string,
      filingsInfo: IFilingInformation,
      partyResponsibleForFees: number | string,
      attorneys?: IAttorney[],
      caseParties?: IParty[]
    ) => {
      if (!firmInformation.data || !profile.data) return;

      let attorneyName = "";
      let index = 1;
      let partyIndex = 0;
      let organizationIndex = 0;
      let attorneyIndex = 1;
      let responsiblePartyRefernceValue: string | undefined;
      const partiesList: ICasePerson[] = [];
      const caseOtherEntityAttornies: ICaseOtherEntityAttorney[] = [];
      const decedentPartyAssociation: IDecedentPartyAssociation[] = [];

      for (const party of caseParties || parties) {
        if (party.type === "person") {
          partiesList.push(formatPersonParty(party, partyIndex));
          partyIndex++;
        } else {
          partiesList.push(formatOrgParty(party, organizationIndex));
          organizationIndex++;
        }

        if (party.dateOfDeath && party.dateOfDeathCondition) {
          decedentPartyAssociation.push({
            personDeathDate: {
              dateRepresentation: format(party.dateOfDeath, "yyyy-MM-dd"),
              status: party.dateOfDeathCondition,
            },

            partyReference:
              party.type === "person"
                ? `Party${partyIndex - 1}`
                : `OrgParty${organizationIndex - 1}`,
          });
        }

        if (partyResponsibleForFees && partyResponsibleForFees === index) {
          responsiblePartyRefernceValue =
            party.type === "person"
              ? `Party${partyIndex - 1}`
              : `OrgParty${organizationIndex - 1}`;
        }

        index++;
      }

      if (!isIndividualUser()) {
        const filingAttorney = attorneys?.find(
          (attorney) => attorney.attorneyID === filingAttorneyId
        );
        if (!filingAttorney) return;

        partiesList.push(formatAttorney(filingAttorney, 0));

        attorneyName = `${filingAttorney.firstName} ${filingAttorney.lastName}`;
      }

      partyIndex = 0;
      organizationIndex = 0;

      for (const party of caseParties || parties) {
        if (party.leadAttorney) {
          // if (party.leadAttorney === "prose") {
          //   partiesList.push({
          //     caseParticipantRoleCode: "ATTY",
          //     entityPerson: {
          //       id: `Attorney${attorneyIndex}`,
          //       personName: {
          //         personGivenName: "",
          //         personMiddleName: "",
          //         personSurName: "",
          //       },
          //       personOtherIdentification: {
          //         identificationID: "",
          //         identificationCategoryText: "ATTORNEYID",
          //       },
          //     },
          //   });

          //   caseOtherEntityAttornies.push({
          //     caseOfficialRoleText: "LEAD",
          //     roleOfPersonReference: {
          //       reference: `Attorney${attorneyIndex}`,
          //     },
          //     caseRepresentedPartyReference: {
          //       reference:
          //         party.type === "person"
          //           ? `Party${partyIndex}`
          //           : `OrgParty${organizationIndex}`,
          //     },
          //   });

          //   attorneyIndex++;
          // }

          const leadAttorney = attorneys?.find(
            (attorney) => attorney.attorneyID === party.leadAttorney
          );

          if (leadAttorney) {
            partiesList.push(formatAttorney(leadAttorney, attorneyIndex));

            caseOtherEntityAttornies.push({
              caseOfficialRoleText: "LEAD",
              roleOfPersonReference: {
                reference: `Attorney${attorneyIndex}`,
              },
              caseRepresentedPartyReference: {
                reference:
                  party.type === "person"
                    ? `Party${partyIndex}`
                    : `OrgParty${organizationIndex}`,
              },
            });

            attorneyIndex++;
          }
        }

        for (const additionalAttorneyId of party.additionalAttorneys) {
          if (!attorneys?.length) return;

          const additionalAttorney = attorneys.find(
            (attorney) => attorney.attorneyID === additionalAttorneyId
          );

          if (additionalAttorney) {
            partiesList.push(formatAttorney(additionalAttorney, attorneyIndex));

            caseOtherEntityAttornies.push({
              caseOfficialRoleText: "",
              roleOfPersonReference: {
                reference: `Attorney${attorneyIndex}`,
              },
              caseRepresentedPartyReference: {
                reference:
                  party.type === "person"
                    ? `Party${partyIndex}`
                    : `OrgParty${organizationIndex}`,
              },
            });

            attorneyIndex++;
          }
        }

        if (party.type === "person") partyIndex++;
        else organizationIndex++;
      }

      return {
        generalCase: {
          caseCategoryText: {
            efmCasecategoryCode: caseInformation.categoryName,
            efmEcfCasetype: caseCategories?.find(
              (category) =>
                category.efmCasecategoryCode === caseInformation.categoryName
            )?.efmEcfCasetype,
          },
          caseCourt: {
            organizationIdentification: {
              identificationID: {
                efmLocationCode: caseInformation.locationName,
              },
            },
          },
          caseParticipant: partiesList,
          caseOtherEntityAttorney: caseOtherEntityAttornies,
          locationCaseType: {
            efmCasetypeCode: caseInformation.caseTypeName,
          },
          isEFile: "E-File",
          // "service": false,
          isBusiness: false,
          isPerson: true,
          responsiblePartyRefernceValue: responsiblePartyRefernceValue,
          decedentPartyAssociation: decedentPartyAssociation,
        },
        paymentMessage: {
          allowanceCharge: {
            amount: {
              currencyID: "USD",
              value: 0,
            },
            taxCategory: {
              percent: 0,
              taxScheme: "",
            },
            taxTotal: {
              taxAmount: {
                currencyID: "USD",
                taxAmount: 0,
              },
            },
            paymentMeans: {
              paymentID: paymentAccountId,
            },
          },
        },
        filingConfidentialityIndicator: "false",
        filingLeadDocument: {
          id: filingsInfo.filings[0].id,
          documentDescriptionText:
            filingsInfo.filings[0].documentDescriptionText,
          documentMetadata: {
            registerActionDescriptionText: filingsInfo.filings[0].filingCode,
          },
          documentRendition: {
            documentRenditionMetadata: {
              documentAttachment: filingsInfo.filings[0].attachments.map(
                (attachment, index) => ({
                  ...attachment,
                  attachmentSequenceID: index.toString(),
                })
              ),
            },
          },
          filingCommentsText: filingsInfo.filings[0].filingCommentsText,
          documentOptionalService: filingsInfo.filings[0].optionalServices,
          courtesyCopiesText: filingsInfo.filings[0].courtesyCopiesText,
          referenceNumber: filingsInfo.filings[0].referenceNumber,
          type: "E-File",
        },
        filingConnectedDocument: filingsInfo.filings
          .filter((filing, index) => index > 0)
          .map((filing) => ({
            id: filing.id,
            documentDescriptionText: filing.documentDescriptionText,
            documentMetadata: {
              registerActionDescriptionText: filing.filingCode,
            },
            documentRendition: {
              documentRenditionMetadata: {
                documentAttachment: filing.attachments.map(
                  (attachment, index) => ({
                    ...attachment,
                    attachmentSequenceID: index.toString(),
                  })
                ),
              },
            },
            filingCommentsText: filing.filingCommentsText,
            documentOptionalService: filing.optionalServices,
            courtesyCopiesText: filing.courtesyCopiesText,
            referenceNumber: filing.referenceNumber,
            type: "E-File",
          })),
        efmUserId: profile.data.userID,
        efmFirmId: firmInformation.data.firmID,
        efmFirmName: firmInformation.data.firmName,
        filligAttorneyName: attorneyName,

        // TODO pending (Return date section) - for country - Cook County - Domestic Relations - District 2 - Skokie
        isCopiedEnvelope: "",
        efmEnvelopeId: "",
        draftEnvelopeId: location.state?.draftEnvelopeId || "",
        isDraftEnvelope: "",
        caseCrossReferenceNumberList: caseInformation.caseCrossReferenceNumbers,
        amountincontroversy: filingsInfo.amountInControversy?.toString() || "",
        returnDate: filingsInfo.returnDate
          ? format(filingsInfo.returnDate, "yyyy-MM-dd")
          : "",
        checkboxForReturnDateNotApplicable: "",
        checkboxForOutOfStateService:
          filingsInfo.outOfStateService?.toString() || "",
        individualAttorney: isIndividualUser().toString(),
        allowanceChargeAmountValue:
          filingsInfo.totalProviderFee > 0 ? "1" : "0",
        individual: isIndividualUser(),
        isFirmAdmin: isFirmAdmin(),
      };
    },
    [
      firmInformation.data,
      profile.data,
      caseInformation,
      parties,
      caseCategories,
      formatAttorney,
      formatOrgParty,
      formatPersonParty,
      location.state?.draftEnvelopeId,
    ]
  );

  const onCreateDraft = useCallback(
    (
      paymentAccountId: string,
      filingAttorneyId: string,
      filingsInfo: IFilingInformation,
      partyResponsibleForFees: number | string,
      attorneys?: IAttorney[],
      caseParties?: IParty[]
    ) => {
      const requestObj = formatToFeeCalculation(
        paymentAccountId,
        filingAttorneyId,
        filingsInfo,
        partyResponsibleForFees,
        attorneys,
        caseParties
      );

      requestObj && createDraft.mutate(requestObj);
    },
    [formatToFeeCalculation, createDraft]
  );

  const goToDraft = useCallback(() => {
    navigate("/myDrafts");
  }, [navigate]);

  useEffect(() => {
    if (createDraft.isSuccess) {
      setDraftNo(createDraft.data);
    }
    // eslint-disable-next-line
  }, [createDraft.isSuccess]);

  useEffect(() => {
    if (createDraft.isError) {
      createNotification({
        message:
          createDraft.error?.response?.data ||
          "An Error occurred while creating draft!",
        type: "error",
        title: "New Case Notification",
      });
    }
    // eslint-disable-next-line
  }, [createDraft.isError]);

  return (
    <Page>
      <div className="content-wrapper">
        <div className="new-case-row">
          <div className="mb-3">
            <Typography variant="h2" className="dash-title-h2">
              New Case
            </Typography>
          </div>
          <Box className="p-tabview">
            <Box className="p-tabview-nav-content">
              <Tabs
                className="p-tabview-nav "
                value={activeTab}
                aria-label="new case tabs"
              >
                <Tab
                  label="1  Case Information"
                  id="simple-tab-0"
                  className="p-tabview-nav-link"
                  iconPosition="start"
                  icon={activeTab > 0 ? <CheckCircleIcon /> : undefined}
                />
                <Tab
                  label="2  Parties"
                  id="simple-tab-1"
                  className="p-tabview-nav-link"
                  iconPosition="start"
                  icon={activeTab > 1 ? <CheckCircleIcon /> : undefined}
                />
                <Tab
                  label="3  Filings"
                  id="simple-tab-2"
                  className="p-tabview-nav-link"
                  iconPosition="start"
                  icon={activeTab > 2 ? <CheckCircleIcon /> : undefined}
                />
                <Tab
                  label="4  Summary"
                  id="simple-tab-3"
                  className="p-tabview-nav-link tabe-last"
                />
              </Tabs>
            </Box>

            {activeTab === 0 && (
              <CaseInformation
                caseInformation={caseInformation}
                updateCaseInformation={updateCaseInformation}
              />
            )}
            {activeTab === 1 && caseInformation && (
              <Parties
                caseInformation={caseInformation}
                locationParties={locationParties}
                parties={parties}
                filingInformation={filingInformation}
                updateParties={updateParties}
                createDraft={onCreateDraft}
              />
            )}
            {activeTab === 2 && caseInformation && parties && (
              <Filings
                caseInformation={caseInformation}
                parties={parties}
                filingInformation={filingInformation}
                formatToFeeCalculation={formatToFeeCalculation}
                updateFilings={updateFilings}
                createDraft={onCreateDraft}
              />
            )}
            {activeTab === 3 &&
              caseInformation &&
              parties &&
              filingInformation && (
                <Summary
                  caseInformation={caseInformation}
                  parties={parties}
                  filingInformation={filingInformation}
                  formatToFeeCalculation={formatToFeeCalculation}
                  updatePayment={updatePayment}
                  draftEnvelopeId={location.state?.draftEnvelopeId}
                />
              )}
          </Box>
        </div>
      </div>

      {createDraft.isLoading && <Loader />}

      <ConfirmationDialog
        message={
          draftNo
            ? `Draft# ${draftNo} has been ${
                location.state?.draftEnvelopeId ? "updated" : "created"
              }.`
            : undefined
        }
        subtext={`You can find saved drafts by navigating to "My Drafts" from sidebar.`}
        closeModal={goToDraft}
      />
    </Page>
  );
};

export default NewCase;
