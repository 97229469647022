import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Dialog from "../../../../components/dialog";

interface IIndexedCaseModal {
  isOpen: boolean;
  location: string;
  caseNumber: string;
  onClose: () => void;
}

const IndexedCaseModal: FC<IIndexedCaseModal> = ({
  isOpen,
  location,
  caseNumber,
  onClose,
}) => {
  const navigate = useNavigate();

  const gotoIndexCase = useCallback(() => {
    navigate("/nonindexedcase", {
      state: {
        location,
        caseNumber,
      },
    });
  }, [location, caseNumber, navigate]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="Note" maxWidth="sm">
      <Box padding={4} bgcolor="lightgray">
        You are attempting to efile on a case that is not submitted
        electronically in Odyssey File & Serve and is not searchable from the
        court's case management system.
      </Box>

      <Typography padding={4}>
        You will be able to file into this case, but will have to manually input
        the case information. Any additional filings on this case will not
        require the manual data entry.
      </Typography>

      <Button
        className="btn primary-button me-3"
        variant="contained"
        fullWidth
        onClick={gotoIndexCase}
      >
        File Into An Existing Case
      </Button>
    </Dialog>
  );
};

export default IndexedCaseModal;
