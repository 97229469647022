import { useCallback, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import EditIcon from "@mui/icons-material/EditOutlined";
import { GridRenderCellParams, GridColDef } from "@mui/x-data-grid/models";

import Page from "../../../components/page";
import GridTable from "../../../components/grid";
import EnableDisableCountyAdmin from "./EnableDisableCountyAdmin";
import CreateEditCountyAdmin from "./CreateEditCountyAdmin";
import queryClient from "../../../queryClient";
import { COUNTY_ADMIN_LIST } from "../../../apis/URL";
import { ICountyAdmin } from "../../../interfaces";

const CountyAdmin = () => {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [selectedCountyAdmin, setSelectedCountyAdmin] = useState<
    ICountyAdmin | undefined
  >();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onEdit = useCallback((countyAdmin: ICountyAdmin) => {
    setIsCreateEditModalOpen(true);
    setSelectedCountyAdmin(countyAdmin);
  }, []);

  const onCreate = useCallback(() => {
    setIsCreateEditModalOpen(true);
    setSelectedCountyAdmin(undefined);
  }, []);

  const onStatusChange = useCallback((countyAdmin: ICountyAdmin) => {
    setSelectedCountyAdmin(countyAdmin);
    setIsDeleteModalOpen(true);
  }, []);

  const onClose = useCallback((shouldUpdateGrid?: boolean) => {
    setIsCreateEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setSelectedCountyAdmin(undefined);

    if (shouldUpdateGrid)
      queryClient.refetchQueries(["grid", COUNTY_ADMIN_LIST]);
  }, []);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "firstname",
        headerName: "First Name",
        width: 150,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "lastname",
        headerName: "Last Name",
        width: 150,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "username",
        headerName: "Username",
        width: 230,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "countyname",
        headerName: "County",
        width: 150,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "createdby",
        headerName: "Created By",
        width: 200,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "last_login_time",
        headerName: "Last Login",
        width: 200,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "isactive",
        headerName: "Active",
        width: 80,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.isactive ? (
            <Typography color="green">Yes</Typography>
          ) : (
            <Typography color="error">No</Typography>
          );
        },
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "action",
        headerName: "Action",
        width: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <div className="dataGridactionIcons">
              <Tooltip title="Edit Service Contact">
                <IconButton
                  aria-label="Edit Service Contact"
                  onClick={onEdit.bind(this, params.row)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  params.row.isactive
                    ? "Disable County Admin"
                    : "Enable County Admin"
                }
              >
                <IconButton
                  aria-label="StatusChange"
                  onClick={() => onStatusChange(params.row)}
                >
                  {params.row.isactive ? (
                    <PersonRemoveIcon />
                  ) : (
                    <PersonAddAlt1Icon />
                  )}
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [onEdit, onStatusChange]
  );

  const getRowId = useCallback((row: any) => row.adminprofileid, []);

  return (
    <Page>
      <div className="content-wrapper">
        <h2 className="dash-title-h2 mb-4">Manage County Admin</h2>

        <div className="themeDataTable">
          <Box sx={{ height: "auto", overflow: "auto" }}>
            <GridTable
              url={COUNTY_ADMIN_LIST}
              columns={columns}
              searchKeys={["firstname", "lastname", "username", "createdby"]}
              showSearch
              rows={[]}
              hideFooter={false}
              getRowId={getRowId}
              action={
                <Button
                  size="large"
                  variant="contained"
                  className="btn primary-button"
                  onClick={onCreate}
                >
                  Add County Admin
                </Button>
              }
            />
          </Box>
        </div>
      </div>

      <CreateEditCountyAdmin
        key={selectedCountyAdmin?.adminprofileid}
        isOpen={isCreateEditModalOpen}
        countyAdmin={selectedCountyAdmin}
        onClose={onClose}
      />

      {selectedCountyAdmin && isDeleteModalOpen && (
        <EnableDisableCountyAdmin
          enableDisable={selectedCountyAdmin}
          isOpen={isDeleteModalOpen}
          onClose={onClose}
        />
      )}
    </Page>
  );
};

export default CountyAdmin;
