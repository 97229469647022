import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Dialog from "../../../components/dialog";

interface RejectionDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const RejectionDialog: FC<RejectionDialogProps> = ({ isOpen, onClose }) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Rejection Reason"
      maxWidth="md"
      action={
        <Button
          className="btn primary-button"
          variant="contained"
          type="button"
          onClick={onClose}
        >
          Ok
        </Button>
      }
    >
      <Box>
        <div className="mb-3">
          <Typography pb={3}>
            Odyssey File & Serve could not open the pdf file because it is
            either not a supported file type or because the file has been
            damaged (for example, the file upload did not complete correctly)
          </Typography>
          <Typography pb={3}>
            One or more documents contain formatting that will prevent the
            system from submitting your envelope. Please try re-saving or
            re-creating the document(s) as a PDF or printing and scanning the
            document(s) as a PDF, and re-file the envelope with the recreated
            document(s).
          </Typography>
          <Typography>
            <a
              href="https://tylertech.egain.cloud/kb/ilh5/content/PROD-5854/What-does-submission-failed-mean-and-how-do-I-correct-my-filing-5854"
              target="_blank"
              rel="noreferrer"
              aria-label="Subission failure information docuemnt"
            >
              <b>Click Here</b>
            </a>
            &nbsp; to view additional information on addressing any document
            submission failures.
          </Typography>
        </div>

        <Box></Box>
      </Box>
    </Dialog>
  );
};

export default RejectionDialog;
