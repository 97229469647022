import { useMutation } from "@tanstack/react-query";

import http from "../../http";
import { ALL_USERS_FILTER_LIST_WEBSERVICE_API } from "../URL";
import { IAxisErrorResponse } from "../../interfaces";

interface IAllUsers {
  userProfileId: number;
  email: string;
  firstname: string;
  middlename: string;
  lastname: string;
  usertype: string;
  city: string;
  state: string;
  country: string;
  creationDate: string;
  username: string;
  efmCountryCode: string;
  efmStateCode: string;
  userTypeCode: string;
  activeCode: string;
  active: boolean;
}

interface IFilter {
  username?: string;
  efmCountryCode?: string;
  efmStateCode?: string;
  userTypeCode?: string;
  activeCode?: string;
}

const getAllUsers = async (filters: IFilter) => {
  const response = await http.post<IAllUsers[]>(
    ALL_USERS_FILTER_LIST_WEBSERVICE_API,
    filters
  );
  return response.data;
};

const useAllUsers = () =>
  useMutation<IAllUsers[], IAxisErrorResponse, IFilter>({
    mutationKey: ["allUsers"],
    mutationFn: getAllUsers,
  });

export default useAllUsers;
