import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { MODIFY_FILER_CROSS_REFERENCE_MAPPING } from "../../URL";

interface ISaveForFuture {
  efmLocationCode: string;
  caseCrossReferenceNumberList: {
    efmCrossreferencecodeName: string;
    userCrossReferenceNumber: string;
  }[];
}

interface IAxisErrorResponse {
  response?: {
    data?: {
      userMessage?: string;
    };
  };
}

const getUpdateFirmInformation = async (fields: ISaveForFuture) => {
  await http.post(MODIFY_FILER_CROSS_REFERENCE_MAPPING, fields);
};

const useSaveForFuture = () =>
  useMutation<void, IAxisErrorResponse, ISaveForFuture>({
    mutationKey: ["save-for-future"],
    mutationFn: getUpdateFirmInformation,
  });

export default useSaveForFuture;
