import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { DELETE_ATTORNEY } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

const deleteAttorney = async (attorneyId: string) => {
  await http.post(DELETE_ATTORNEY, {
    attorneyId,
  });
};

const useDeleteAttorney = () =>
  useMutation<void, IAxisErrorResponse, string>({
    mutationKey: ["delete-attorney"],
    mutationFn: deleteAttorney,
  });

export default useDeleteAttorney;
