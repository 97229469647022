import { FC, useCallback, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Dialog from "../../../../components/dialog";
import CountyHearingDate from "../../../FilingHistory/countyHearingDateDialog";

interface IConfirmationDialog {
  message?: string;
  subtext?: string;
  isHearingDate?: boolean;
  closeModal: () => void;
}

const ConfirmationDialog: FC<IConfirmationDialog> = ({
  message,
  subtext,
  isHearingDate,
  closeModal,
}) => {
  const [isHearingDateModalOpen, setIsHearingDateModalOpen] = useState(false);

  const viewHearingDateDetailsClose = useCallback(() => {
    setIsHearingDateModalOpen(false);
  }, []);
  const viewHearingDateDetailsOpen = useCallback(() => {
    setIsHearingDateModalOpen(true);
  }, []);
  return (
    <Dialog
      title="Confirmation"
      maxWidth="sm"
      isOpen={!!message}
      onClose={closeModal}
    >
      <Typography variant="h4" color="blue">
        {message}
      </Typography>
      {subtext && <Typography variant="subtitle1">{subtext}</Typography>}
      {isHearingDate &&
        "To schedule a hearing date please go to filings and click on view filing details icon."}

      <Box display="flex" mt={2} justifyContent="center">
        {isHearingDate && (
          <Button
            className="btn primary-button me-2"
            variant="contained"
            onClick={viewHearingDateDetailsOpen}
          >
            Request Hearing Date
          </Button>
        )}
        <Button
          className="btn primary-button"
          variant="contained"
          onClick={closeModal}
        >
          Ok
        </Button>
      </Box>
      {isHearingDateModalOpen && (
        <CountyHearingDate
          isOpen={isHearingDateModalOpen}
          onClose={viewHearingDateDetailsClose}
        />
      )}
    </Dialog>
  );
};

export default ConfirmationDialog;
