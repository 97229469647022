import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_LOCATION_CASE_TYPES } from "../../URL";

interface ICaseType {
  locationCasetypeId: number;
  efmCasetypeCode: string;
  efmCasetypeName: string;
  fee: string;
}

const getLocationCaseTypes = async (
  locationCode?: string,
  caseCategoryCode?: string,
  isNonIndexedCase?: boolean
) => {
  if (!locationCode || !caseCategoryCode) return [];

  const response = await http.post<ICaseType[]>(GET_LOCATION_CASE_TYPES, {
    efmLocationCode: locationCode,
    efmCasecategoryCode: caseCategoryCode,
    isNonIndexedCase,
  });
  return response.data;
};

const useLocationCaseTypes = (
  locationCode?: string,
  caseCategoryCode?: string,
  isNonIndexedCase: boolean = false
) =>
  useQuery<ICaseType[]>({
    queryKey: [
      "location-case-types",
      locationCode,
      caseCategoryCode,
      isNonIndexedCase,
    ],
    queryFn: () =>
      getLocationCaseTypes(locationCode, caseCategoryCode, isNonIndexedCase),
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default useLocationCaseTypes;
