import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { UPDATE_USER_NOTIFICATIONS } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface INotification {
  code: string;
  description: string;
  isActive: boolean;
}

const updateNotifications = async (notifications: INotification[]) => {
  await http.post(UPDATE_USER_NOTIFICATIONS, {
    lstNotification: notifications,
  });
};

const useUpdateNotifications = () =>
  useMutation<void, IAxisErrorResponse, INotification[]>({
    mutationKey: ["update-notifications"],
    mutationFn: updateNotifications,
  });

export default useUpdateNotifications;
