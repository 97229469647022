import { ChangeEvent, useCallback, useContext, useMemo, useState } from "react";
import format from "date-fns/format";
import Grid from "@mui/material/Grid";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { GridRenderCellParams, GridColDef } from "@mui/x-data-grid/models";

import Page from "../../../components/page";
import GridTable from "../../../components/grid";
import DeletePendingRequest from "../deletePendingRequest/deletePendingRequest";
import usePendingRequest from "../../../apis/pendingRequests/usePendingRequests";
import usePendingRequestsByEmail from "../../../apis/pendingRequestByEmail/usePendingRequestByEmail";
import { NotificationContext } from "../../../context/NotificationContext";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const PendingRequests = () => {
  const [emailId, setEmailId] = useState("");
  const [showAll, setShowAll] = useState<boolean | null>(null);
  const [selectedPendingRequest, setSelectedPendingRequest] =
    useState<string>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { createNotification } = useContext(NotificationContext);

  const pendingRequests = usePendingRequest();
  const pendingRequestsByEmail = usePendingRequestsByEmail();

  const onDelete = useCallback((pendingRequest: string) => {
    setSelectedPendingRequest(pendingRequest);
    setIsDeleteModalOpen(true);
  }, []);

  const onDeleteModalClose = useCallback(
    (shouldUpdateGrid?: boolean) => {
      if (shouldUpdateGrid) {
        if (showAll) pendingRequests.mutate();
        else pendingRequestsByEmail.mutate(emailId);
      }

      setSelectedPendingRequest(undefined);
      setIsDeleteModalOpen(false);
    },
    [showAll, emailId, pendingRequests, pendingRequestsByEmail]
  );

  const onEmailIdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEmailId(event.target.value);
    },
    []
  );

  const openInNewTab = (url: string) => {
    let element = document.createElement("a");

    if (url.startsWith("http://") || url.startsWith("https://")) {
      element.href = url;
    } else {
      element.href = "http://" + url;
    }
    element.target = "_blank";
    element.click();
    // window.open(url, "_blank", "noreferrer");
  };

  const copyText = useCallback(
    (url: string) => {
      navigator.clipboard.writeText(url);
      createNotification({
        title: "Pending Request Notification",
        type: "success",
        message: "URL copied!",
      });
    },
    [createNotification]
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "email",
        headerName: "Email",
        width: 300,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "eventtypecode",
        headerName: "Event Type Code",
        width: 250,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "event_date",
        headerName: "Date",
        width: 250,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return format(
            new Date(params.row.event_date),
            "MM/dd/yyyy hh:mm:ss a"
          );
        },
      },
      {
        field: "url",
        headerName: "Activation / Reset Password URL",
        width: 350,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <div>
              <div className="activation-url">
                {/* {params.row.url} */}
                <Input className="me-3" value={params.row.url} />
                <Button
                  className="theme-blue"
                  onClick={() => openInNewTab(params.row.url)}
                  startIcon={<OpenInNewIcon />}
                ></Button>
                <Button
                  className="theme-blue"
                  onClick={() => copyText(params.row.url)}
                  startIcon={<ContentCopyIcon />}
                ></Button>
                <IconButton
                  aria-label="delete"
                  onClick={() => onDelete(params.row.email)}
                >
                  <span className="material-symbols-outlined red-error">
                    delete
                  </span>
                </IconButton>
              </div>
            </div>
          );
        },
      },
    ],
    [copyText, onDelete]
  );

  const getRowId = useCallback((row: any) => row.notifyeventid, []);

  const onShowAll = useCallback(() => {
    setShowAll(true);
    pendingRequests.mutate();
  }, [pendingRequests]);

  const onSearchByEmail = useCallback(() => {
    setShowAll(false);
    pendingRequestsByEmail.mutate(emailId);
  }, [emailId, pendingRequestsByEmail]);

  // useEffect(() => {
  //   if (pendingRequests.isError) {
  //     createNotification({
  //       type: "error",
  //       title: "Request Notification",
  //       message:
  //         pendingRequests.error.response?.data?.userMessage ||
  //         "An Error occurred while getting pending requests",
  //     });
  //   }
  // }, [pendingRequests.isError]);

  // useEffect(() => {
  //   if (pendingRequestsByEmail.isError) {
  //     createNotification({
  //       type: "error",
  //       title: "Request Notification",
  //       message:
  //         pendingRequestsByEmail.error.response?.data?.userMessage ||
  //         "An Error occurred while getting pending requests",
  //     });
  //   }
  // }, [pendingRequestsByEmail.isError]);

  return (
    <Page>
      <div className="content-wrapper">
        <h2 className="dash-title-h2 mb-4">Manage List of Pending Requests</h2>

        <div className="datagrid-table-wrap mt-2">
          <div className="datagrid-top mb-3 d-flex">
            <Grid container spacing={2}>
              <Grid className="" item xs={8}>
                <Item className="remove-item-css">
                  <TextField
                    className="w-100"
                    id="emailId"
                    value={emailId}
                    onChange={onEmailIdChange}
                    label="Search by Email*"
                    variant="standard"
                  />
                </Item>
              </Grid>
              <Grid className="" item xs={4}>
                <Item className="remove-item-css pt-2">
                  <Button
                    className="btn primary-button me-2"
                    variant="contained"
                    onClick={onSearchByEmail}
                    sx={{ minWidth: 120 }}
                  >
                    Search
                  </Button>
                  <Button
                    className="btn btn-yellow-bg "
                    variant="contained"
                    onClick={onShowAll}
                    sx={{ minWidth: 120 }}
                  >
                    SHOW ALL
                    <SearchIcon className="case-search ms-2 vertical-align-middle" />
                  </Button>
                </Item>
              </Grid>
            </Grid>
          </div>
          <div className="themeDataTable">
            {showAll != null && (
              <GridTable
                columns={columns}
                rows={
                  showAll ? pendingRequests.data : pendingRequestsByEmail.data
                }
                getRowId={getRowId}
                isLoading={
                  showAll
                    ? pendingRequests.isLoading
                    : pendingRequestsByEmail.isLoading
                }
                hideFooter={false}
              />
            )}
          </div>
        </div>
      </div>

      <DeletePendingRequest
        email={selectedPendingRequest}
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
      />
    </Page>
  );
};

export default PendingRequests;
