import { FC, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import SearchCaseParty from "../SearchCaseParty";
import Dialog from "../../../components/dialog";
import Loader from "../../../components/loader";
import useGetCase from "../../../apis/searchCase/getCase";

interface IGetCaseProps {
  isOpen: boolean;
  countyId: string;
  caseTrackingId: string;
  onClose: () => void;
}

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const CaseDetailsDialog: FC<IGetCaseProps> = ({
  isOpen,
  countyId,
  caseTrackingId,
  onClose,
}) => {
  const getCase = useGetCase();

  useEffect(() => {
    getCase.mutate({
      countyId: countyId,
      caseTrackingId: caseTrackingId,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Case Details"
      maxWidth="md"
    >
      {getCase.isLoading ? (
        <Loader />
      ) : (
        <div className="popup-content-slide">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} className="mb-2">
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name"> Case Category </div>
                  <div className="label-print-field">
                    {" "}
                    {getCase?.data?.caseCategory}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name"> Case Tracking ID </div>
                  <div className="label-print-field">
                    {getCase?.data?.caseTrackingId}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name"> Case Number </div>
                  <div className="label-print-field">
                    {" "}
                    {getCase?.data?.caseNumber}{" "}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name"> Case Type </div>
                  <div className="label-print-field">
                    {getCase?.data?.caseType}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name"> Location </div>
                  <div className="label-print-field">
                    {" "}
                    {getCase?.data?.jurisdiction}{" "}
                  </div>
                </Item>
              </Grid>
            </Grid>

            {getCase?.data?.partyResponses.map((field, index) => (
              <SearchCaseParty
                key={field.efmPartyId}
                party={field}
                partyIndex={index}
              />
            ))}
          </Box>
        </div>
      )}
    </Dialog>
  );
};

export default CaseDetailsDialog;
