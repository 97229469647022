import { FC, useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Dialog from "../../../../components/dialog";
import useProfile from "../../../../apis/auth/useProfile";
import useLocationAdditionalPartyTypes from "../../../../apis/common/useLocationAdditionalPartyTypes";
import useLocationNameSuffixes from "../../../../apis/common/useLocationNameSuffixes";
import { IParty } from "../../../../interfaces";
import { isIndividualUser } from "../../../../utills";

interface AddPartyModalProps {
  isOpen: boolean;
  locationCode: string;
  addAdditionalParty: (party: IParty) => void;
  closeModal: () => void;
}

const AddPartyModal: FC<AddPartyModalProps> = ({
  isOpen,
  locationCode,
  addAdditionalParty,
  closeModal,
}) => {
  const defaultValues = useMemo(
    () => ({
      code: "",
      type: "person",
      partyNotAvailable: false,
      firstName: "",
      middleName: "",
      lastName: "",
      businessName: "",
      suffix: "",
      leadAttorney: "",
      additionalAttorneys: [],
      countryCode: "US",
      stateCode: "",
      iAmThisParty: false,
    }),
    []
  );

  const { data: parties } = useLocationAdditionalPartyTypes(locationCode);
  const { data: suffixes } = useLocationNameSuffixes(locationCode);
  const { data: profile } = useProfile();
  const { watch, control, setValue, handleSubmit, reset } = useForm<IParty>({
    defaultValues,
  });

  const onPartyTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const partyType = event.target.value;

      setValue("type", partyType);
      setValue("partyNotAvailable", false);
      setValue("firstName", "");
      setValue("lastName", "");
      setValue("businessName", "");
    },
    [setValue]
  );

  const onPartyNotAvailableChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isPartyNoteAvailable = event.target.checked;
      const partyType = watch("type");

      setValue("partyNotAvailable", isPartyNoteAvailable);

      if (partyType === "person") {
        setValue("firstName", isPartyNoteAvailable ? "Not available" : "");
        setValue("lastName", isPartyNoteAvailable ? "Not available" : "");
      } else {
        setValue("businessName", isPartyNoteAvailable ? "Not available" : "");
      }
    },
    [watch, setValue]
  );

  const onIamThisPartyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const iamThisParty = event.target.checked;

      if (iamThisParty) {
        setValue("iAmThisParty", true);
        setValue("type", "person");
        setValue("businessName", "");

        if (profile) {
          setValue("firstName", profile.firstName);
          setValue("middleName", profile.middleName);
          setValue("lastName", profile.lastName);
        }
      } else {
        setValue("iAmThisParty", false);
        setValue("firstName", "");
        setValue("middleName", "");
        setValue("lastName", "");
      }
    },
    [profile, setValue]
  );

  const onSubmit = useCallback(
    (formData: IParty) => {
      const partyObj = parties?.find(
        (party) => party.efmPartytypeCode === formData.code
      );
      if (partyObj) {
        addAdditionalParty({
          ...formData,
          name: partyObj.efmPartytypeName,
          isAdditinalParty: true,
        });
        reset(defaultValues);
      }
    },
    [parties, addAdditionalParty, reset, defaultValues]
  );

  return (
    <Dialog
      title="Add Additional Party"
      maxWidth="md"
      isOpen={isOpen}
      onClose={closeModal}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="code"
              control={control}
              rules={{
                required: "Error: Party Type is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  inputRef={ref}
                  select
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    SelectDisplayProps: {
                      "aria-required": true,
                    },
                  }}
                  {...field}
                  label="Party Type"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                >
                  <MenuItem value="">Select Party Type</MenuItem>
                  {parties?.map((party) => (
                    <MenuItem
                      key={party.efmPartytypeCode}
                      value={party.efmPartytypeCode}
                    >
                      {party.efmPartytypeName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          {isIndividualUser() && (
            <Grid item xs={12}>
              <Controller
                name="iAmThisParty"
                control={control}
                render={({ field }) => (
                  <InputLabel className="label-checkbox">
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={onIamThisPartyChange}
                      size="medium"
                      className="ps-0"
                    />
                    I am this Party
                  </InputLabel>
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <RadioGroup row {...field} onChange={onPartyTypeChange}>
                  <FormControlLabel
                    value="person"
                    control={<Radio />}
                    label="Person"
                  />
                  <FormControlLabel
                    value="business"
                    disabled={watch("iAmThisParty")}
                    control={<Radio />}
                    label="Business"
                  />
                </RadioGroup>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="partyNotAvailable"
              control={control}
              render={({ field }) => (
                <InputLabel className="label-checkbox">
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={onPartyNotAvailableChange}
                    disabled={watch("iAmThisParty")}
                    size="medium"
                    className="ps-0"
                  />
                  <span>
                    <strong>Not available</strong>
                    {` `}
                    (Check this if you don't know Person name or Business name)
                  </span>
                </InputLabel>
              )}
            />
          </Grid>
          {watch("type") === "person" ? (
            <>
              <Grid item xs={3}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: "Error: First Name is required.",
                  }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      className="w-100"
                      id={field.name}
                      inputRef={ref}
                      InputLabelProps={{ required: true }}
                      {...field}
                      onBlur={() => {
                        field.onChange(field.value?.trim());
                        field.onBlur();
                      }}
                      placeholder="First Name"
                      label="First Name"
                      variant="standard"
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="middleName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      className="w-100"
                      id={field.name}
                      {...field}
                      onBlur={() => {
                        field.onChange(field.value?.trim());
                        field.onBlur();
                      }}
                      placeholder="Middle Name"
                      label="Middle Name"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: "Error: Last Name is required.",
                  }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      className="w-100"
                      id={field.name}
                      inputRef={ref}
                      InputLabelProps={{ required: true }}
                      {...field}
                      onBlur={() => {
                        field.onChange(field.value?.trim());
                        field.onBlur();
                      }}
                      placeholder="Last Name"
                      label="Last Name"
                      variant="standard"
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="suffix"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      className="w-100"
                      id={field.name}
                      select
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                      {...field}
                      label="Suffix"
                      variant="standard"
                    >
                      <MenuItem value="">Select Suffix</MenuItem>
                      {suffixes?.map((suffix) => (
                        <MenuItem
                          key={suffix.efmNamesuffixCode}
                          value={suffix.efmNamesuffixCode}
                        >
                          {suffix.efmNamesuffixName}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Controller
                name="businessName"
                control={control}
                rules={{
                  required: "Error: Business Name is required.",
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    InputLabelProps={{ required: true }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    placeholder="Business Name"
                    label="Business Name"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
        <Box pt={4} pb={2} display="flex" justifyContent="end">
          <Button
            className="btn primary-button"
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </Box>
      </form>
    </Dialog>
  );
};

export default AddPartyModal;
