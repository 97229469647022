import {
  FC,
  useCallback,
  useState,
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useContext,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

import UploadedFile from "./uploadedFile";
import useUploadDocument from "../../../../../apis/newCase/useUploadDocument";
import useLocationDocumentTypes from "../../../../../apis/common/useLocationDocumentTypes";
import useUploadSubsequentDocument from "../../../../../apis/existingCase/useUploadSubsequentDocument";
import useUploadNonIndexedCaseDocument from "../../../../../apis/nonIndexedCase/useUploadNonIndexedCaseDocument";
import { IDocumentAttachment, IUploadResponse } from "../../../../../interfaces";
import useLocations from "../../../../../apis/common/useLocations";
import { NotificationContext } from "../../../../../context/NotificationContext";
import Loader from "../../../../../components/loader";

interface ComponentProps {
  component: {
    allowMultiple: boolean;
    locationFilingcomponentCode: string;
    locationFilingcomponentId: number;
    locationFilingcomponentName: string;
    required: boolean;
  };
  locationCode?: string;
  filingCode?: string;
  filingName?: string;
  attachments?: IDocumentAttachment[];
  validateComponentFileRequired?: number;
  isExistingCase?: boolean;
  isNonIndexedCase?: boolean;
  addAttachment: (attachment: IDocumentAttachment) => void;
  updateAttachment: (updatedAttachment: IDocumentAttachment) => void;
  removeAttachment: (sequence: string, binaryCategory?: string) => void;
  updateUploadResponseDataState: (uploadResponseData: IUploadResponse) => void;
}

const Component: FC<ComponentProps> = ({
  component,
  locationCode,
  filingCode,
  filingName,
  attachments,
  validateComponentFileRequired,
  isExistingCase,
  isNonIndexedCase,
  addAttachment,
  updateAttachment,
  removeAttachment,
  updateUploadResponseDataState,
}) => {
  const uploadDocument = useUploadDocument();
  const uploaSubsequentdDocument = useUploadSubsequentDocument();
  const uploadNonIndexedDocument = useUploadNonIndexedCaseDocument();
  const descriptionRef = useRef<HTMLInputElement>();
  const { data: locations } = useLocations();
  const [file, setFile] = useState<File | null>(null);
  const [security, setSecurity] = useState("");
  const [description, setDescription] = useState("");
  const { createNotification } = useContext(NotificationContext);

  const files = useMemo(() => {
    if (filingCode === "SERVICE ONLY") {
      if (component.required)
        return (
          attachments?.filter(
            (attachment) => attachment.generalDocType === "L"
          ) || []
        );

      return (
        attachments?.filter(
          (attachment) => attachment.generalDocType !== "L"
        ) || []
      );
    }
    return (
      attachments?.filter(
        (attachment) =>
          attachment.binaryCategoryText ===
          component.locationFilingcomponentCode
      ) || []
    );
  }, [filingCode, component, attachments]);

  const { data: documentTypes } = useLocationDocumentTypes(
    locationCode,
    filingCode
  );

  const formatSize = (bytes: number) => {
    if (bytes === 0) {
      return "0 B";
    }
    const k = 1000;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + " " + sizes[i];
  };

  const changeFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event?.target?.files?.length || !filingCode) return;

      const fileObj = event.target.files[0];
      const location = locations?.find(
        (location) => location.efm_Location_Code === locationCode
      );
      if (location && location.maximumAllowedAttachmentSize) {
        const maxSize = parseInt(
          location.maximumAllowedAttachmentSize.toString()
        );

        if (fileObj.size > maxSize) {
          createNotification({
            type: "error",
            message: `Document size can not exceed ${formatSize(
              parseInt(maxSize.toString())
            )}.`,
            title: `${fileObj.name}: Invalid file size.`,
          });
          return;
        }
      }

      setFile(fileObj);
      if (isExistingCase) {
        uploaSubsequentdDocument.mutate({
          filingCode,
          file: fileObj,
        });
      } else if (isNonIndexedCase) {
        uploadNonIndexedDocument.mutate({
          filingCode,
          file: fileObj,
        });
      } else {
        uploadDocument.mutate({ filingCode, file: fileObj });
      }
    },
    [
      filingCode,
      locations,
      isExistingCase,
      isNonIndexedCase,
      locationCode,
      uploaSubsequentdDocument,
      uploadDocument,
      uploadNonIndexedDocument,
      createNotification,
    ]
  );

  const onSecurityUpdate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSecurity(event.target.value);
    },
    []
  );

  const onDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDescription(event.target.value);
    },
    []
  );

  useEffect(() => {
    if (uploadDocument.isSuccess && file) {
      if (!filingCode || !filingName) return;

      let documentType = documentTypes?.find(
        (type) => type.efmDocumenttypeCode === security
      )?.efmDocumenttypeName;
      let binaryFormatStandardName = security;

      if (!documentType) {
        const nonConfidentialType = documentTypes?.find(
          (type) => type.efmDocumenttypeName === "Non-Confidential"
        );

        if (!nonConfidentialType) return;

        setSecurity(nonConfidentialType.efmDocumenttypeCode);

        binaryFormatStandardName = nonConfidentialType.efmDocumenttypeCode;
        documentType = documentTypes?.find(
          (type) => type.efmDocumenttypeCode === binaryFormatStandardName
        )?.efmDocumenttypeName;
      }

      if (!description) {
        setDescription(filingName);
      }

      addAttachment({
        binarySizeValue: file.size,
        binaryFormatStandardName,
        binaryDescriptionText: description || filingName,
        generalSecurityText: documentType,
        binaryCategoryText: component.locationFilingcomponentCode,
        attachmentSequenceID: attachments?.length?.toString() || "0",
        binaryLocationURI: file.name,
        generalDocType:
          component.locationFilingcomponentName === "Lead Document" ? "L" : "A",
        uploadResponse: uploadDocument.data?.responseObj
      });

      if (
        uploadDocument.data?.status === "Error" &&
        uploadDocument.data?.responseObj?.fileContainsVirus
      ) {
        createNotification({
          message: uploadDocument.data.responseObj.fileContainsVirus,
          type: "error",
          title: "New Case Notification",
        });
      } else {
        updateUploadResponseDataState(uploadDocument.data?.responseObj);
        createNotification({
          message: "File Uploaded",
          type: "success",
          title: "New Case Notification",
        });
      }
    }
    // eslint-disable-next-line
  }, [uploadDocument.isSuccess]);

  useEffect(() => {
    if (uploaSubsequentdDocument.isSuccess && file) {
      if (!filingCode || !filingName) return;

      let documentType = documentTypes?.find(
        (type) => type.efmDocumenttypeCode === security
      )?.efmDocumenttypeName;
      let binaryFormatStandardName = security;

      if (!documentType) {
        const nonConfidentialType = documentTypes?.find(
          (type) => type.efmDocumenttypeName === "Non-Confidential"
        );

        if (!nonConfidentialType) return;

        setSecurity(nonConfidentialType.efmDocumenttypeCode);

        binaryFormatStandardName = nonConfidentialType.efmDocumenttypeCode;
        documentType = documentTypes?.find(
          (type) => type.efmDocumenttypeCode === binaryFormatStandardName
        )?.efmDocumenttypeName;
      }

      if (!description) {
        setDescription(filingName);
      }

      updateUploadResponseDataState(uploaSubsequentdDocument.data?.responseObj);

      addAttachment({
        binarySizeValue: file.size,
        binaryFormatStandardName,
        binaryDescriptionText: description || filingName,
        generalSecurityText: documentType,
        binaryCategoryText: component.locationFilingcomponentCode,
        attachmentSequenceID: attachments?.length?.toString() || "0",
        binaryLocationURI: file.name,
        generalDocType:
          component.required ||
          component.locationFilingcomponentName === "Lead Document"
            ? "L"
            : "A",
        uploadResponse: uploaSubsequentdDocument.data.responseObj
      });

      if (
        uploaSubsequentdDocument.data?.status === "Error" &&
        uploaSubsequentdDocument.data?.responseObj?.fileContainsVirus
      ) {
        createNotification({
          message: uploaSubsequentdDocument.data.responseObj.fileContainsVirus,
          type: "error",
          title: "Existing Case Notification",
        });
      } else {
        createNotification({
          message: "File Uploaded",
          type: "success",
          title: "Existing Case Notification",
        });
      }
    }
    // eslint-disable-next-line
  }, [uploaSubsequentdDocument.isSuccess]);

  useEffect(() => {
    if (uploadNonIndexedDocument.isSuccess && file) {
      if (!filingCode || !filingName) return;

      let documentType = documentTypes?.find(
        (type) => type.efmDocumenttypeCode === security
      )?.efmDocumenttypeName;
      let binaryFormatStandardName = security;

      if (!documentType) {
        const nonConfidentialType = documentTypes?.find(
          (type) => type.efmDocumenttypeName === "Non-Confidential"
        );

        if (!nonConfidentialType) return;

        setSecurity(nonConfidentialType.efmDocumenttypeCode);

        binaryFormatStandardName = nonConfidentialType.efmDocumenttypeCode;
        documentType = documentTypes?.find(
          (type) => type.efmDocumenttypeCode === binaryFormatStandardName
        )?.efmDocumenttypeName;
      }

      if (!description) {
        setDescription(filingName);
      }

      addAttachment({
        binarySizeValue: file.size,
        binaryFormatStandardName,
        binaryDescriptionText: description || filingName,
        generalSecurityText: documentType,
        binaryCategoryText: component.locationFilingcomponentCode,
        attachmentSequenceID: attachments?.length?.toString() || "0",
        binaryLocationURI: file.name,
        generalDocType:
          component.locationFilingcomponentName === "Lead Document" ? "L" : "A",
        uploadResponse: uploadNonIndexedDocument.data?.responseObj
      });

      if (
        uploadNonIndexedDocument.data?.status === "Error" &&
        uploadNonIndexedDocument.data?.responseObj?.fileContainsVirus
      ) {
        createNotification({
          message: uploadNonIndexedDocument.data.responseObj.fileContainsVirus,
          type: "error",
          title: "Nonindex Case Notification",
        });
      } else {
        createNotification({
          message: "File Uploaded",
          type: "success",
          title: "Nonindex Case Notification",
        });
      }
    }
    // eslint-disable-next-line
  }, [uploadNonIndexedDocument.isSuccess]);

  useEffect(() => {
    if (uploadDocument.isError) {
      createNotification({
        message:
          uploadDocument.error?.response?.data?.userMessage ||
          "An Error occurred while uploading document!",
        type: "error",
        title: "New Case Notification",
      });
    }
    // eslint-disable-next-line
  }, [uploadDocument.isError]);

  useEffect(() => {
    if (uploaSubsequentdDocument.isError) {
      createNotification({
        message:
          uploaSubsequentdDocument.error?.response?.data?.userMessage ||
          "An Error occurred while uploading document!",
        type: "error",
        title: "Existing Case Notification",
      });
    }
    // eslint-disable-next-line
  }, [uploaSubsequentdDocument.isError]);

  useEffect(() => {
    if (uploadNonIndexedDocument.isError) {
      createNotification({
        message:
          uploadNonIndexedDocument.error?.response?.data?.userMessage ||
          "An Error occurred while uploading document!",
        type: "error",
        title: "Nonindex Case Notification",
      });
    }
    // eslint-disable-next-line
  }, [uploadNonIndexedDocument.isError]);

  useEffect(() => {
    if (validateComponentFileRequired && !files.length && component.required) {
      setDescription("");
      descriptionRef.current?.focus();
    }
    // eslint-disable-next-line
  }, [validateComponentFileRequired]);

  return (
    <Box
      sx={{
        border: 1,
        borderColor: "lightgray",
        borderRadius: "5",
      }}
      padding={4}
      className="mb-4"
    >
      <Typography className="theme-dark-grey f-w-bold">{`Documents - ${
        component.locationFilingcomponentName
      } ${component.required ? "*" : ""}`}</Typography>

      {files.map((file) => (
        <UploadedFile
          key={file.attachmentSequenceID}
          file={file}
          documentTypes={documentTypes}
          updateAttachment={updateAttachment}
          removeAttachment={removeAttachment}
        />
      ))}

      {(files.length === 0 ||
        (files.length > 0 && component.allowMultiple)) && (
        <Grid container spacing={4}>
          <Grid item xl={6} xs={12}>
            <TextField
              className="w-100"
              id={`component-${component.locationFilingcomponentCode}-description`}
              placeholder="Document Description"
              label="Description"
              variant="standard"
              inputRef={descriptionRef}
              value={description}
              onChange={onDescriptionChange}
              error={Boolean(
                validateComponentFileRequired &&
                  !files.length &&
                  component.required &&
                  !description
              )}
            />
          </Grid>
          <Grid item xl={6} xs={12}>
            <TextField
              className="w-100"
              id={`${component.locationFilingcomponentCode}-security`}
              select
              InputLabelProps={{
                shrink: true,
                required: true,
                htmlFor: "my_filings_id",
              }}
              SelectProps={{
                displayEmpty: true,
                SelectDisplayProps: {
                  "aria-required": true,
                },
              }}
              inputProps={{
                id: "my_filings_id",
              }}
              label="Security"
              variant="standard"
              value={security}
              onChange={onSecurityUpdate}
            >
              <MenuItem disabled value="">
                Select Security
              </MenuItem>
              {documentTypes?.map((documentType) => (
                <MenuItem
                  key={documentType.efmDocumenttypeCode}
                  value={documentType.efmDocumenttypeCode}
                >
                  {documentType.efmDocumenttypeName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                className="btn button-outline-secondary mar-0 "
                variant="contained"
                component="label"
                startIcon={<AddIcon />}
                disabled={
                  component.allowMultiple ? false : Boolean(files.length)
                }
              >
                Upload Document
                <input
                  hidden
                  accept="application/pdf"
                  multiple
                  type="file"
                  value=""
                  onChange={changeFile}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}

      {(uploadDocument.isLoading ||
        uploaSubsequentdDocument.isLoading ||
        uploadNonIndexedDocument.isLoading) && <Loader />}
    </Box>
  );
};

export default Component;
