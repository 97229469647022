import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import format from "date-fns/format";

import Page from "../../components/page";
import SearchCase from "./searchCase";
import Parties from "./parties";
import Filings from "./filings";
import Summary from "./summary";
import ServiceContacts from "./serviceContacts/ServiceContacts";
import ConfirmationDialog from "../newCase/summary/confirmationDialog";
import Loader from "../../components/loader";
import useProfile from "../../apis/auth/useProfile";
import useFirmInformation from "../../apis/firm/useFirmInformation";
import useCreateExistingCaseDraft from "../../apis/draft/useCreateExistingCaseDraft";
import { NotificationContext } from "../../context/NotificationContext";
import { isFirmAdmin, isIndividualUser } from "../../utills";
import {
  IAttorney,
  ICaseOtherEntityAttorney,
  ICasePerson,
  ICaseResponse,
  ICrossReferenceNumber,
  IDecedentPartyAssociation,
  IElectronicServices,
  IFilingInformation,
  IParty,
  IServiceResponse,
} from "../../interfaces";
import { parseFilingInformation, parseParties } from "../newCase/utills";
import useLocationCaseCategories from "../../apis/common/useLocationCaseCategories";

const ExistingCase = () => {
  const profile = useProfile();
  const location = useLocation();
  const navigate = useNavigate();
  const firmInformation = useFirmInformation();
  const createDraft = useCreateExistingCaseDraft();
  const { createNotification } = useContext(NotificationContext);

  const [selectedCase, setSelectedCase] = useState<ICaseResponse>();
  const [parties, setParties] = useState<IParty[]>(
    parseParties(location.state)
  );
  const [filingInfo, setFilingInfo] = useState<IFilingInformation | undefined>(
    parseFilingInformation(location.state)
  );
  const [crossReferenceNumbers, setCrossReferenceNumbers] = useState<
    ICrossReferenceNumber[]
  >(location?.state?.caseCrossReferenceNumberList || []);
  const [activeTab, setActiveTab] = useState(0);
  const [showServiceContact, setShowServiceContact] = useState(false);
  const [serviceContacts, setServiceContacts] = useState<IServiceResponse[]>(
    []
  );
  const [selectedServiceContacts, setSelectedServiceContacts] = useState<
    string[]
  >(
    location.state?.electronicServiceInformation?.map(
      (service: IElectronicServices) =>
        service.serviceRecipientID.identificationID
    ) || []
  );
  const [draftNo, setDraftNo] = useState<number>();
  const { data: caseCategories } = useLocationCaseCategories(
    selectedCase?.locationId
  );

  const updateCase = useCallback(
    (caseResponse: ICaseResponse) => {
      if (selectedCase && selectedCase.caseNumber !== caseResponse.caseNumber) {
        setParties([]);
        setFilingInfo(undefined);
        setCrossReferenceNumbers([]);
      } else if (parties.length) {
        const partyList = [...parties];

        for (const party of partyList) {
          const partyObj = caseResponse?.partyResponses.find(
            (locationParty) => locationParty.efmPartyId === party.efmPartyId
          );

          if (partyObj) {
            party.name = partyObj.efmPartytypeRoleName;
            party.code = partyObj.efmPartytypeRoleCode;
            // party.dateOfDeathCondition = partyObj.;
          }
        }

        setParties(partyList);
      }

      setSelectedCase(caseResponse);
      setActiveTab(1);
    },
    [selectedCase, parties]
  );

  const updateParties = useCallback(
    (
      updatedParties: IParty[],
      updatedCrossReferenceNumbers: ICrossReferenceNumber[],
      tabIndex: number
    ) => {
      setParties(updatedParties);
      setCrossReferenceNumbers(updatedCrossReferenceNumbers);
      setActiveTab(tabIndex);
    },
    []
  );

  const updateFilings = useCallback(
    (filingInformation: IFilingInformation, tabIndex: number) => {
      setFilingInfo(filingInformation);
      setActiveTab(tabIndex);
    },
    []
  );

  const updatePayment = useCallback(
    (
      tabIndex: number,
      paymentAccount: string,
      filingAttorney: string,
      partyResponsibleForFeesIndex: number | string
    ) => {
      setFilingInfo((prevState) =>
        prevState
          ? {
              ...prevState,
              paymentAccount,
              filingAttorney,
              partyResponsibleForFeesIndex,
            }
          : prevState
      );
      setActiveTab(tabIndex);
    },
    []
  );

  const updateServiceContacts = useCallback(
    (
      serviceContactIds: string[],
      serviceContacts: IServiceResponse[],
      tabIndex: number
    ) => {
      setSelectedServiceContacts(serviceContactIds);
      setServiceContacts(serviceContacts);
      setActiveTab(tabIndex);
    },
    []
  );

  const formatPersonParty = useCallback((party: IParty, partyIndex: number) => {
    return party.efmPartyId
      ? {
          entityPerson: {
            id: `Party${partyIndex}`,
            personName: {
              personGivenName: party.firstName,
              personMiddleName: party.middleName,
              personSurName: party.lastName,
              personNameSuffixText: party.suffix,
              personFullName: `${party.firstName} ${party.lastName}`,
              personConcatName: `${party.firstName} ${party.middleName || ""} ${
                party.lastName
              } ${party.suffix || ""}`,
            },
            personOtherIdentification: {
              identificationID: party.efmPartyId,
            },
            iAmThisParty: false,
          },
          efmPartyId: party.efmPartyId,
          caseParticipantRoleCode: party.code,
        }
      : {
          entityPerson: {
            id: `Party${partyIndex}`,
            personName: {
              personGivenName: party.firstName,
              personMiddleName: party.middleName,
              personSurName: party.lastName,
              personNameSuffixText: party.suffix,
              personFullName: `${party.firstName} ${party.lastName}`,
              personConcatName: `${party.firstName} ${party.middleName || ""} ${
                party.lastName
              } ${party.suffix || ""}`,
            },
            personOtherIdentification: {
              identificationCategoryText: "SSN",
            },
            personAugmentation: {
              contactInformation: {
                contactTelephoneNumber: {
                  fullTelephoneNumber: {
                    telephoneNumberFullID: party.phoneNumber,
                  },
                },
                contactMailingAddress: {
                  structuredAddress: {
                    locationStreet: {
                      streetFullText: party.streetAddressLine1,
                    },
                    addressSecondaryUnitText: party.streetAddressLine2,
                    locationCityName: party.city,
                    locationStateName: party.stateCode,
                    locationCountry: party.countryCode,
                    locationPostalCode: party.zipCode,
                  },
                },
              },
            },
            iAmThisParty: party.iAmThisParty,
          },
          caseParticipantRoleCode: party.code,
        };
  }, []);

  const formatOrgParty = useCallback(
    (party: IParty, organizationIndex: number) => ({
      entityOrganization: {
        id: `OrgParty${organizationIndex}`,
        organizationIdentification: party.efmPartyId || "",
        organizationName: party.businessName,
        organizationPrimaryContactInformation: {
          contactTelephoneNumber: {
            fullTelephoneNumber: {
              telephoneNumberFullID: party.phoneNumber,
            },
          },
        },
        organizationLocation: {
          locationAddress: {
            structuredAddress: {
              locationStreet: {
                streetFullText: party.streetAddressLine1,
              },
              addressSecondaryUnitText: party.streetAddressLine2,
              locationCityName: party.city,
              locationStateName: party.stateCode,
              locationCountry: party.countryCode,
              locationPostalCode: party.zipCode,
            },
          },
        },
      },
      caseParticipantRoleCode: party.code,
      efmPartyId: party.efmPartyId,
    }),
    []
  );

  const formatAttorney = useCallback(
    (attorney: IAttorney, index: number) => ({
      caseParticipantRoleCode: "ATTY",
      entityPerson: {
        id: `Attorney${index}`,
        personName: {
          personGivenName: attorney.firstName,
          personMiddleName: attorney.middleName,
          personSurName: attorney.lastName,
        },
        personOtherIdentification: {
          identificationID: attorney.attorneyID,
          identificationCategoryText: "ATTORNEYID",
        },
      },
    }),
    []
  );

  const formatToFeeCalculation = useCallback(
    (
      filingsInfo: IFilingInformation,
      paymentAccount: string,
      filingAttorneyId?: string,
      partyResponsibleForFees?: number | string,
      attorneys?: IAttorney[],
      serviceContactIds?: string[],
      serviceContactList?: IServiceResponse[]
    ) => {
      if (!firmInformation.data || !profile.data || !selectedCase) return;

      let attorneyName = "";
      let index = 1;
      let partyIndex = 0;
      let organizationIndex = 0;
      let attorneyIndex = 1;
      let responsiblePartyRefernceValue: string | undefined;
      const partiesList: ICasePerson[] = [];
      const caseOtherEntityAttornies: ICaseOtherEntityAttorney[] = [];
      const decedentPartyAssociation: IDecedentPartyAssociation[] = [];

      for (const party of parties) {
        if (party.type === "person") {
          partiesList.push(formatPersonParty(party, partyIndex));
          partyIndex++;
        } else {
          partiesList.push(formatOrgParty(party, organizationIndex));
          organizationIndex++;
        }

        if (party.dateOfDeath && party.dateOfDeathCondition) {
          decedentPartyAssociation.push({
            personDeathDate: {
              dateRepresentation: format(party.dateOfDeath, "yyyy-MM-dd"),
              status: party.dateOfDeathCondition,
            },
            partyReference:
              party.type === "person"
                ? `Party${partyIndex - 1}`
                : `OrgParty${organizationIndex - 1}`,
          });
        }

        if (partyResponsibleForFees && partyResponsibleForFees === index) {
          responsiblePartyRefernceValue =
            party.type === "person"
              ? `Party${partyIndex - 1}`
              : `OrgParty${organizationIndex - 1}`;
        }

        index++;
      }

      if (!isIndividualUser()) {
        const filingAttorney = attorneys?.find(
          (attorney) => attorney.attorneyID === filingAttorneyId
        );

        if (filingAttorney) {
          partiesList.push(formatAttorney(filingAttorney, 0));
          attorneyName = `${filingAttorney.firstName} ${filingAttorney.lastName}`;
        }
      }

      partyIndex = 0;
      organizationIndex = 0;

      for (const party of parties) {
        if (party.leadAttorney) {
          // if (party.leadAttorney === "prose") {
          //   partiesList.push({
          //     caseParticipantRoleCode: "ATTY",
          //     entityPerson: {
          //       id: `Attorney${attorneyIndex}`,
          //       personName: {
          //         personGivenName: "",
          //         personMiddleName: "",
          //         personSurName: "",
          //       },
          //       personOtherIdentification: {
          //         identificationID: "",
          //         identificationCategoryText: "ATTORNEYID",
          //       },
          //     },
          //   });

          //   caseOtherEntityAttornies.push({
          //     caseOfficialRoleText: "LEAD",
          //     roleOfPersonReference: {
          //       reference: `Attorney${attorneyIndex}`,
          //     },
          //     caseRepresentedPartyReference: {
          //       reference:
          //         party.type === "person"
          //           ? `Party${partyIndex}`
          //           : `OrgParty${organizationIndex}`,
          //     },
          //   });

          //   attorneyIndex++;
          // }

          const leadAttorney = attorneys?.find(
            (attorney) => attorney.attorneyID === party.leadAttorney
          );

          if (leadAttorney) {
            partiesList.push(formatAttorney(leadAttorney, attorneyIndex));

            caseOtherEntityAttornies.push({
              caseOfficialRoleText: "LEAD",
              roleOfPersonReference: {
                reference: `Attorney${attorneyIndex}`,
              },
              caseRepresentedPartyReference: {
                reference:
                  party.type === "person"
                    ? `Party${partyIndex}`
                    : `OrgParty${organizationIndex}`,
              },
            });

            attorneyIndex++;
          }
        }

        for (const additionalAttorneyId of party.additionalAttorneys) {
          if (!attorneys?.length) return;

          const additionalAttorney = attorneys.find(
            (attorney) => attorney.attorneyID === additionalAttorneyId
          );

          if (additionalAttorney) {
            partiesList.push(formatAttorney(additionalAttorney, attorneyIndex));

            caseOtherEntityAttornies.push({
              caseOfficialRoleText: "",
              roleOfPersonReference: {
                reference: `Attorney${attorneyIndex}`,
              },
              caseRepresentedPartyReference: {
                reference:
                  party.type === "person"
                    ? `Party${partyIndex}`
                    : `OrgParty${organizationIndex}`,
              },
            });

            attorneyIndex++;
          }
        }

        if (party.type === "person") partyIndex++;
        else organizationIndex++;
      }

      const ids = serviceContactIds || selectedServiceContacts;
      const electronicServices: IElectronicServices[] = [];
      for (const serviceContact of ids) {
        const serviceContactObj = (serviceContactList || serviceContacts).find(
          (item) => item.serviceContactId === serviceContact
        );

        if (serviceContactObj) {
          const party = partiesList.find(
            (party) => party.efmPartyId === serviceContactObj.efmPartyId
          );

          electronicServices.push({
            caseRepresentedPartyReference: {
              reference:
                party?.entityPerson?.id || party?.entityOrganization?.id || "",
            },
            serviceRecipientID: {
              identificationID: serviceContact,
            },
          });
        }
      }

      return {
        electronicServiceInformation: electronicServices,
        generalCase: {
          caseTrackingID: selectedCase.caseTrackingId,
          caseNumber: selectedCase.caseNumber,
          caseCategoryText: {
            efmCasecategoryCode: selectedCase.caseCatCode,
            efmEcfCasetype: caseCategories?.find(
              (category) =>
                category.efmCasecategoryCode === selectedCase.caseCatCode
            )?.efmEcfCasetype,
          },
          caseCourt: {
            organizationIdentification: {
              identificationID: {
                efmLocationCode: selectedCase.locationId,
              },
            },
          },
          caseParticipant: partiesList,
          caseOtherEntityAttorney: caseOtherEntityAttornies,
          locationCaseType: {
            efmCasetypeCode: selectedCase.caseTypeCode,
          },
          isEFile: filingsInfo?.filings?.some((filing) => filing.eFile)
            ? "E-File"
            : "",
          isEFileAndServe: filingsInfo?.filings?.some(
            (filing) => filing.eFile && filing.service
          )
            ? "EFileAndServe"
            : "",
          isService: filingsInfo?.filings?.some((filing) => filing.service)
            ? "isService"
            : "",
          isBusiness: false,
          isPerson: true,
          responsiblePartyRefernceValue: responsiblePartyRefernceValue,
          decedentPartyAssociation: decedentPartyAssociation,
        },
        paymentMessage: {
          allowanceCharge: {
            amount: {
              currencyID: "USD",
              value: 0,
            },
            taxCategory: {
              percent: 0,
              taxScheme: "",
            },
            taxTotal: {
              taxAmount: {
                currencyID: "USD",
                taxAmount: 0,
              },
            },
            paymentMeans: {
              paymentID: paymentAccount,
            },
          },
        },
        filingConfidentialityIndicator: "false",
        filingLeadDocument: {
          id: filingsInfo.filings[0].id,
          documentDescriptionText:
            filingsInfo.filings[0].documentDescriptionText,
          documentMetadata: {
            registerActionDescriptionText: filingsInfo.filings[0].filingCode,
          },
          documentRendition: {
            documentRenditionMetadata: {
              documentAttachment: filingsInfo.filings[0].attachments.map(
                (attachment, index) => ({
                  ...attachment,
                  attachmentSequenceID: index.toString(),
                })
              ),
            },
          },
          filingCommentsText: filingsInfo.filings[0].filingCommentsText,
          documentOptionalService: filingsInfo.filings[0].optionalServices,
          courtesyCopiesText: filingsInfo.filings[0].courtesyCopiesText,
          referenceNumber: filingsInfo.filings[0].referenceNumber,
          type:
            filingsInfo.filings[0].eFile && filingsInfo.filings[0].service
              ? "EFileAndServe"
              : filingsInfo.filings[0].service
              ? "isService"
              : "E-File",
        },
        filingConnectedDocument: filingsInfo.filings
          .filter((filing, index) => index > 0)
          .map((filing) => ({
            id: filing.id,
            documentDescriptionText: filing.documentDescriptionText,
            documentMetadata: {
              registerActionDescriptionText: filing.filingCode,
            },
            documentRendition: {
              documentRenditionMetadata: {
                documentAttachment: filing.attachments.map(
                  (attachment, index) => ({
                    ...attachment,
                    attachmentSequenceID: index.toString(),
                  })
                ),
              },
            },
            filingCommentsText: filing.filingCommentsText,
            documentOptionalService: filing.optionalServices,
            courtesyCopiesText: filing.courtesyCopiesText,
            referenceNumber: filing.referenceNumber,
            type:
              filing.eFile && filing.service
                ? "EFileAndServe"
                : filing.service
                ? "isService"
                : "E-File",
          })),
        efmUserId: profile.data.userID,
        efmFirmId: firmInformation.data.firmID,
        efmFirmName: firmInformation.data.firmName,
        filligAttorneyName: attorneyName,

        isCopiedEnvelope: "",
        efmEnvelopeId: "",
        draftEnvelopeId: location.state?.draftEnvelopeId || "",
        isDraftEnvelope: Boolean(location.state?.draftEnvelopeId).toString(),
        caseCrossReferenceNumberList: crossReferenceNumbers,
        amountincontroversy: filingsInfo.amountInControversy?.toString() || "",
        returnDate: filingsInfo.returnDate
          ? format(filingsInfo.returnDate, "yyyy-MM-dd")
          : "",
        checkboxForReturnDateNotApplicable: "",
        checkboxForOutOfStateService:
          filingsInfo.outOfStateService?.toString() || "",
        individualAttorney: isIndividualUser().toString(),
        allowanceChargeAmountValue:
          filingsInfo.totalProviderFee > 0 ? "1" : "0",
        individual: isIndividualUser(),
        isFirmAdmin: isFirmAdmin(),
      };
    },
    [
      firmInformation.data,
      profile.data,
      selectedCase,
      parties,
      selectedServiceContacts,
      serviceContacts,
      caseCategories,
      crossReferenceNumbers,
      formatAttorney,
      formatOrgParty,
      formatPersonParty,
      location.state?.draftEnvelopeId,
    ]
  );

  const onCreateDraft = useCallback(
    (
      filingsInfo: IFilingInformation,
      paymentAccount: string,
      filingAttorneyId?: string,
      partyResponsibleForFees?: number | string,
      attorneys?: IAttorney[],
      serviceContactIds?: string[],
      serviceContacts?: IServiceResponse[]
    ) => {
      const requestObj = formatToFeeCalculation(
        filingsInfo,
        paymentAccount,
        filingAttorneyId,
        partyResponsibleForFees,
        attorneys,
        serviceContactIds,
        serviceContacts
      );

      requestObj && createDraft.mutate(requestObj);
    },
    [formatToFeeCalculation, createDraft]
  );

  const goToDraft = useCallback(() => {
    navigate("/myDrafts");
  }, [navigate]);

  useEffect(() => {
    if (createDraft.isSuccess) {
      setDraftNo(createDraft.data);
    }
    // eslint-disable-next-line
  }, [createDraft.isSuccess]);

  useEffect(() => {
    if (createDraft.isError) {
      createNotification({
        message:
          createDraft.error?.response?.data ||
          "An Error occurred while creating draft!",
        type: "error",
        title: "New Case Notification",
      });
    }
    // eslint-disable-next-line
  }, [createDraft.isError]);

  return (
    <Page>
      <div className="content-wrapper">
        <div className="new-case-row">
          <div className="mb-3">
            <h2 className="dash-title-h2">Existing Case</h2>
          </div>
          <Box className="p-tabview">
            <Box className="p-tabview-nav-content">
              <Tabs
                className="p-tabview-nav "
                aria-label="existing case tabs"
                value={activeTab}
              >
                <Tab
                  label="1  Search Case"
                  id="simple-tab-0"
                  className="p-tabview-nav-link"
                  iconPosition="start"
                  icon={activeTab > 0 ? <CheckCircleIcon /> : undefined}
                />
                <Tab
                  label="2  Parties"
                  id="simple-tab-1"
                  className="p-tabview-nav-link"
                  iconPosition="start"
                  icon={activeTab > 1 ? <CheckCircleIcon /> : undefined}
                />
                <Tab
                  label="3  Filings"
                  id="simple-tab-2"
                  className="p-tabview-nav-link"
                  iconPosition="start"
                  icon={activeTab > 2 ? <CheckCircleIcon /> : undefined}
                />
                {showServiceContact && (
                  <Tab
                    label="4  Service Contacts"
                    id="simple-tab-3"
                    className="p-tabview-nav-link"
                    iconPosition="start"
                    icon={activeTab > 3 ? <CheckCircleIcon /> : undefined}
                  />
                )}
                <Tab
                  label={`${showServiceContact ? "5" : "4"}  Summary`}
                  id="simple-tab-4"
                  className="p-tabview-nav-link tabe-last"
                />
              </Tabs>
            </Box>
          </Box>
          <Box display={activeTab === 0 ? "block" : "none"}>
            <SearchCase
              selectedCase={selectedCase}
              updateCase={updateCase}
              pageTitle="existing"
            />
          </Box>
          {activeTab === 1 && selectedCase && (
            <Parties
              caseInfo={selectedCase}
              parties={parties}
              crossReferenceNumbers={crossReferenceNumbers}
              updateParties={updateParties}
            />
          )}
          {activeTab === 2 && selectedCase && (
            <Filings
              caseInfo={selectedCase}
              parties={parties}
              filingInfo={filingInfo}
              formatToFeeCalculation={formatToFeeCalculation}
              updateFilings={updateFilings}
              setShowServiceContact={setShowServiceContact}
              createDraft={onCreateDraft}
            />
          )}
          {activeTab === 3 &&
            selectedCase &&
            filingInfo &&
            showServiceContact && (
              <ServiceContacts
                caseInfo={selectedCase}
                filingInfo={filingInfo}
                selectedServiceContacts={selectedServiceContacts}
                updateServiceContacts={updateServiceContacts}
                createDraft={onCreateDraft}
              />
            )}
          {activeTab === (showServiceContact ? 4 : 3) &&
            selectedCase &&
            filingInfo && (
              <Summary
                caseInfo={selectedCase}
                parties={parties}
                crossReferenceNumbers={crossReferenceNumbers}
                filingInfo={filingInfo}
                serviceContacts={serviceContacts}
                selectedServiceContacts={selectedServiceContacts}
                updatePayment={updatePayment}
                formatToFeeCalculation={formatToFeeCalculation}
                draftEnvelopeId={location.state?.draftEnvelopeId}
              />
            )}
        </div>
      </div>

      {createDraft.isLoading && <Loader />}
      <ConfirmationDialog
        message={
          draftNo
            ? `Draft# ${draftNo} has been ${
                location.state?.draftEnvelopeId ? "updated" : "created"
              } .`
            : undefined
        }
        subtext={`You can find saved drafts by navigating to "My Drafts" from sidebar.`}
        closeModal={goToDraft}
      />
    </Page>
  );
};

export default ExistingCase;
