import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { DELETE_TRANSACTION } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface ITransaction {
  efmFilingId: string;
  userId: string;
}

const deleteTransaction = async (transaction: ITransaction) => {
  await http.post(DELETE_TRANSACTION, transaction);
};

const useDeleteTransaction = () =>
  useMutation<void, IAxisErrorResponse, ITransaction>({
    mutationKey: ["delete-transaction"],
    mutationFn: deleteTransaction,
  });

export default useDeleteTransaction;
