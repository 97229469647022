import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { RESET_USER_PASSWORD } from "../../URL";

interface IResetUserPasswordRequest {
  userId: string;
  email: string;
  password: string;
}

interface IAxisResponse {
  error?: {
    errorCode?: string;
    errorText?: string;
  };
}

interface IAxisErrorResponse {
  response?: {
    data?: IAxisResponse;
  };
}

const resetUserPassword = async (data: IResetUserPasswordRequest) => {
  const response = await http.post<IAxisResponse>(RESET_USER_PASSWORD, {
    ...data,
  });

  return response?.data.error?.errorText;
};

const useResetUserPassword = () =>
  useMutation<
    string | undefined,
    IAxisErrorResponse,
    IResetUserPasswordRequest
  >({
    mutationKey: ["reset-user-password"],
    mutationFn: resetUserPassword,
  });

export default useResetUserPassword;
