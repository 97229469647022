import { useMutation } from "@tanstack/react-query";

import http from "../../http";
import { IMPORT_TYLER_PAYMENT } from "../URL";
import { IAxisErrorResponse } from "../../interfaces";

const importTylerPayement = async (requestObject: File) => {
  const formData = new FormData();
  formData.append("tylerpaymentfile", requestObject, requestObject.name);

  await http.post(IMPORT_TYLER_PAYMENT, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const useImportTylerPayment = () =>
  useMutation<void, IAxisErrorResponse, File>({
    mutationKey: ["import-document"],
    mutationFn: importTylerPayement,
  });

export default useImportTylerPayment;
