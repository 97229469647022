import { FC, ReactNode } from "react";
import { styled } from "@mui/material/styles";
import MaterialDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";

const BootstrapDialog = styled(MaterialDialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

interface DialogProps {
  title: string;
  isOpen: boolean;
  children: ReactNode;
  maxWidth?: "lg" | "sm" | "md" | "xs" | "xl";
  action?: ReactNode;
  actionClassName?: string;
  onClose: () => void;
}

const Dialog: FC<DialogProps> = ({
  isOpen,
  title,
  children,
  maxWidth,
  action,
  actionClassName,
  onClose,
}) => {
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="popup-title"
      open={isOpen}
      maxWidth={maxWidth || "xl"}
      fullWidth
      PaperProps={
        {
          "aria-modal": true,
        }
      }
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <DialogTitle id="popup-title" className="popup-title red-pastel" sx={{ m: 0, p: 2 }}>
          {title}
        </DialogTitle>
        <Box padding={2}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[900],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <DialogContent>{children}</DialogContent>
      {action && <DialogActions className={actionClassName}>{action}</DialogActions>}
    </BootstrapDialog>
  );
};

export default Dialog;
