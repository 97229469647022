import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_LOCATION_CROSS_REFERENCE_NUMBER } from "../../URL";
import {
  IReference,
  IFilerCrossReferenceMapping,
  ICrossReferenceItemForPopulate,
} from "../../../interfaces";

interface ICrossReferenceNumberResponse {
  locationCrossReferenceCodeList: IReference[];
  filerCrossReferenceMappingList: IFilerCrossReferenceMapping[];
  crossReferenceItemForPrepopulateList: ICrossReferenceItemForPopulate[];
}

const getCrossReferenceNumber = async (
  locationCode?: string,
  caseTypeCode?: string
) => {
  if (!locationCode || !caseTypeCode)
    return {
      locationCrossReferenceCodeList: [],
      filerCrossReferenceMappingList: [],
      crossReferenceItemForPrepopulateList: [],
    };

  const response = await http.post<ICrossReferenceNumberResponse>(
    GET_LOCATION_CROSS_REFERENCE_NUMBER,
    {
      efmLocationCode: locationCode,
      efmCaseTypeCode: caseTypeCode,
    }
  );

  return {
    locationCrossReferenceCodeList:
      response.data.locationCrossReferenceCodeList?.filter(
        (reference) => reference.efmCrossreferencecodeCode !== null
      ) || [],
    filerCrossReferenceMappingList:
      response.data.filerCrossReferenceMappingList,
    crossReferenceItemForPrepopulateList:
      response.data.crossReferenceItemForPrepopulateList,
  };
};

const useCrossReferenceNumber = (
  locationCode?: string,
  caseTypeCode?: string
) =>
  useQuery<ICrossReferenceNumberResponse>({
    queryKey: ["location-cross-reference-number", locationCode, caseTypeCode],
    queryFn: () => getCrossReferenceNumber(locationCode, caseTypeCode),
  });

export default useCrossReferenceNumber;
