import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_LOCATION_DOCUMENT_TYPES } from "../../URL";
import { ILocationType } from "../../../interfaces";

const getLocationDocumentTypes = async (
  locationCode?: string,
  filingTypeCode?: string
) => {
  if (!locationCode || !filingTypeCode) return [];

  const response = await http.post<ILocationType[]>(
    GET_LOCATION_DOCUMENT_TYPES,
    {
      efmLocationCode: locationCode,
      efmFilingtypeCode: filingTypeCode,
    }
  );
  return response.data;
};

const useLocationDocumentTypes = (
  locationCode?: string,
  filingTypeCode?: string
) =>
  useQuery<ILocationType[]>({
    queryKey: ["location-documents-types", locationCode, filingTypeCode],
    queryFn: () => getLocationDocumentTypes(locationCode, filingTypeCode),
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default useLocationDocumentTypes;
