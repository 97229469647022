import { FC, useCallback, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import Dialog from "../../../components/dialog";
import useCountries from "../../../apis/common/useCountries";
import useStates from "../../../apis/common/useStates";
import useCreateServiceContact from "../../../apis/serviceContacts/useCreateServiceContact";
import useUpdateServiceContact from "../../../apis/serviceContacts/useUpdateServiceContact";
import { NotificationContext } from "../../../context/NotificationContext";
import { getZipCodePlaceholder, validateZipCode } from "../../../utills";
import { ICaseParty, IServiceContact } from "../../../interfaces";
import useAttachedServiceContact from "../../../apis/existingCase/useAttachedServiceContact";

interface CreateEditServiceContactProps {
  serviceContact?: IServiceContact | null;
  isOpen: boolean;
  parties?: ICaseParty[];
  caseTrackingId?: string;
  onClose: (shouldUpdateGrid?: boolean, serviceContactId?: string) => void;
}

const CreateEditServiceContact: FC<CreateEditServiceContactProps> = ({
  isOpen,
  serviceContact,
  parties,
  caseTrackingId,
  onClose,
}) => {
  const { watch, control, handleSubmit, reset } = useForm<IServiceContact>({
    defaultValues: {
      partyId: "",
      firstName: serviceContact?.firstName,
      middleName: serviceContact?.middleName,
      lastName: serviceContact?.lastName,
      email: serviceContact?.email,
      phoneNumber: serviceContact?.phoneNumber,
      administrativeCopy: serviceContact?.administrativeCopy,
      address: {
        addressLine1: serviceContact?.address?.addressLine1,
        addressLine2: serviceContact?.address?.addressLine2,
        country: serviceContact?.address?.country || "US",
        state: serviceContact?.address?.state || "",
        city: serviceContact?.address?.city,
        zipCode: serviceContact?.address?.zipCode,
      },
    },
  });

  const { data: countries } = useCountries();
  const { data: states } = useStates(watch("address.country"));
  const createContact = useCreateServiceContact();
  const updateContact = useUpdateServiceContact();
  const attachServiceContact = useAttachedServiceContact();
  const { createNotification } = useContext(NotificationContext);

  const attachNewContact = useCallback(
    (partyId: string, serviceContactID: string) => {
      if (!caseTrackingId) return;

      attachServiceContact.mutate({
        caseTrackingId,
        listOfServiceContact: [
          {
            partyId,
            serviceContactID,
          },
        ],
      });
    },
    [caseTrackingId, attachServiceContact]
  );

  const onSubmit = useCallback(
    (data: IServiceContact) => {
      if (serviceContact) {
        updateContact.mutate({
          serviceContactID: serviceContact.serviceContactID,
          firmID: serviceContact.firmID,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          email: data.email,
          administrativeCopy: data.administrativeCopy,
          phoneNumber: data.phoneNumber,
          addressType: data.address,
          isSelected: false,
        });
      } else {
        createContact.mutate({
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          email: data.email,
          administrativeCopy: data.administrativeCopy,
          phoneNumber: data.phoneNumber,
          addressType: data.address,
        });
      }
    },
    [serviceContact, createContact, updateContact]
  );

  useEffect(() => {
    if (createContact.isSuccess) {
      createNotification({
        title: "Service Contact Notification",
        type: "success",
        message: "Service Contact created successfully",
      });

      const partyId = watch("partyId");
      if (caseTrackingId && partyId) {
        attachNewContact(partyId, createContact.data);
      } else {
        onClose(true);
        reset();
      }
    }
    // eslint-disable-next-line
  }, [createContact.isSuccess, createNotification]);

  useEffect(() => {
    if (createContact.isError) {
      createNotification({
        title: "Service Contact Notification",
        type: "error",
        message:
          createContact.error.response?.data?.userMessage ||
          "Something went wrong while creating service contact",
      });
    }
    // eslint-disable-next-line
  }, [createContact.isError, createNotification]);

  useEffect(() => {
    if (updateContact.isSuccess) {
      createNotification({
        title: "Service Contact Notification",
        type: "success",
        message: "Service Contact updated successfully",
      });
      onClose(true);
    }
    // eslint-disable-next-line
  }, [updateContact.isSuccess, createNotification]);

  useEffect(() => {
    if (updateContact.isError) {
      createNotification({
        title: "Service Contact Notification",
        type: "error",
        message:
          updateContact.error.response?.data?.userMessage ||
          "Something went wrong while updating service contact",
      });
    }
    // eslint-disable-next-line
  }, [updateContact.isError, createNotification]);

  useEffect(() => {
    if (
      attachServiceContact.isSuccess &&
      attachServiceContact.variables?.listOfServiceContact[0].serviceContactID
    ) {
      onClose(
        true,
        attachServiceContact.variables?.listOfServiceContact[0].serviceContactID
      );
    }
    // eslint-disable-next-line
  }, [attachServiceContact.isSuccess]);

  useEffect(() => {
    if (attachServiceContact.isError) {
      createNotification({
        title: "Service Contact Notification",
        type: "error",
        message:
          attachServiceContact.error.response?.data?.userMessage ||
          "Something went wrong while attaching service contact",
      });

      onClose();
    }
    // eslint-disable-next-line
  }, [attachServiceContact.isError]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose.bind(this, false, undefined)}
      title={serviceContact ? "Edit Service Contact" : "Create Service Contact"}
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {Boolean(parties?.length) && (
            <Grid item xs={12}>
              <Controller
                name="partyId"
                control={control}
                rules={{
                  required: "Error: Case Party is required.",
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    inputProps={{
                      "aria-required": true,
                      id: `${field.name}_id`,
                    }}
                    select
                    InputLabelProps={{
                      shrink: true,
                      htmlFor: `${field.name}_id`,
                      required: true,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      SelectDisplayProps: {
                        "aria-required": true,
                      },
                    }}
                    {...field}
                    label="Case Party to New Service Contact"
                    variant="standard"
                  >
                    <MenuItem value="" disabled>
                      Select Party
                    </MenuItem>
                    {parties?.map((party) => (
                      <MenuItem key={party.efmPartyId} value={party.efmPartyId}>
                        {party.efmPartytypeRoleName}
                      </MenuItem>
                    ))}
                    <MenuItem value="0">Other Service Contacts</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={4}>
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: "Error: First Name is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  inputRef={ref}
                  autoFocus
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{
                    required: true,
                  }}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="First Name"
                  label="First Name"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="middleName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Middle Name"
                  label="Middle Name"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: "Error: Last Name is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  inputRef={ref}
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{
                    required: true,
                  }}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Last Name"
                  label="Last Name"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Error: Email Address is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Error: Invalid email.",
                },
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  inputRef={ref}
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{ required: true }}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Email Address"
                  label="Email Address"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="administrativeCopy"
              control={control}
              render={({ field }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Administrative Copy"
                  label="Administrative Copy"
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="address.addressLine1"
              control={control}
              rules={{
                required: "Error: Address Line 1 is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  inputRef={ref}
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{
                    required: true,
                  }}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Address Line 1"
                  label="Address Line 1"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="address.addressLine2"
              control={control}
              render={({ field }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Address Line 2"
                  label="Address Line 2"
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="address.country"
              control={control}
              rules={{
                required: "Error: Country is required.",
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  inputRef={ref}
                  inputProps={{
                    "aria-required": true,
                    id: `${field.name}_id`,
                  }}
                  select
                  InputLabelProps={{
                    shrink: true,
                    htmlFor: `${field.name}_id`,
                    required: true,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    SelectDisplayProps: {
                      "aria-required": true,
                    },
                  }}
                  {...field}
                  label="Country"
                  variant="standard"
                >
                  <MenuItem value="">Select Country</MenuItem>
                  {countries?.map((country) => (
                    <MenuItem
                      key={country.efmCountryCode}
                      value={country.efmCountryCode}
                    >
                      {country.efmCountryName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="address.city"
              control={control}
              rules={{
                required: "Error: City is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  inputRef={ref}
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{
                    required: true,
                  }}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="City"
                  label="City"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="address.state"
              control={control}
              rules={{
                required: "Error: State is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  inputRef={ref}
                  inputProps={{
                    "aria-required": true,
                    id: `${field.name}_id`,
                  }}
                  select
                  InputLabelProps={{
                    shrink: true,
                    htmlFor: `${field.name}_id`,
                    required: true,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    SelectDisplayProps: {
                      "aria-required": true,
                    },
                  }}
                  {...field}
                  label="State"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                >
                  <MenuItem value="">Select State</MenuItem>
                  {states?.map((state) => (
                    <MenuItem
                      key={state.efmStateCode}
                      value={state.efmStateCode}
                    >
                      {state.efmStateName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="address.zipCode"
              control={control}
              rules={{
                required: "Error: Zip Code is required.",
                validate: validateZipCode.bind(this, "address.country"),
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  {...field}
                  inputRef={ref}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{
                    required: true,
                  }}
                  placeholder={getZipCodePlaceholder(watch("address.country"))}
                  label="Zip Code"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="999-999-9999"
                  label="Phone Number"
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid xs={12} pt={4} display="flex" justifyContent="center">
            <Button
              className="btn button-outline-secondary me-3"
              variant="contained"
              type="button"
              onClick={onClose.bind(this, false, undefined)}
            >
              Cancel
            </Button>

            <Button
              className="btn primary-button"
              variant="contained"
              type="submit"
            >
              {createContact.isLoading || updateContact.isLoading ? (
                <CircularProgress
                  sx={{
                    height: "25px !important",
                    width: "25px !important",
                    "--CircularProgress-size": "8px",
                    "--CircularProgress-trackThickness": "1px",
                    "--CircularProgress-progressThickness": "1px",
                  }}
                  color="inherit"
                />
              ) : (
                "Save"
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default CreateEditServiceContact;
