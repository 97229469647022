import { useQuery } from "@tanstack/react-query";
import http from "../../../http";
import { FETCH_CASE_DOCUMENT } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";


interface IFetchDocumentParams {
  date: string;
  filingCode: string;
  binaryLocationURI: string
  fileName: string;
  viewUploadedPdf: boolean;
  draftEnvelopeId: string;
}


interface IFetchDocumentResponse {
  fileUrl: string; 
  fileName: string;
}


  const fetchDocument = async ({ binaryLocationURI, date, filingCode, fileName,viewUploadedPdf, draftEnvelopeId }: IFetchDocumentParams) => {
    console.log("USEFETCH")
    const formData = new FormData();
    formData.append('date', date); 
    formData.append('filingCode', filingCode);
    formData.append('binaryLocationURI', fileName);
    formData.append('fileName', fileName);
    formData.append('draftEnvelopeId', draftEnvelopeId);
    const response = await http.post(FETCH_CASE_DOCUMENT, formData,{
      params:{
          filingCode,
          date,
          binaryLocationURI,
          fileName,
          viewUploadedPdf,
          draftEnvelopeId
      },
      responseType: "blob",
    });

    console.log("NEW RESPONSE FOR DOWNLOAD", response);
    const fileUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

    return {
      fileUrl,
      fileName,
    };
};

const useFetchDocument = (params: IFetchDocumentParams) =>
  useQuery<IFetchDocumentResponse, IAxisErrorResponse>(
    ["fetch-document", params.binaryLocationURI, params.viewUploadedPdf, params.date, params.filingCode, params.fileName, params.draftEnvelopeId],
    () => fetchDocument(params),
    {
      // enabled: !!params.binaryLocationURI && !!params.viewUploadedPdf && !!params.date && !!params.filingCode && !!params.fileName && !!params.draftEnvelopeId, 
      enabled: !!params.viewUploadedPdf,
    }
  );

export default useFetchDocument;
