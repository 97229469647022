import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { UPDATE_DEFAULT_FILING_LOCATION_CODE_URL } from "../../URL";

interface IAxisResponse {
  error: {
    errorCode: string;
    errorText: string;
  };
}

const updateDefaultFilingLocationCode = async (locationCode: string) => {
  const response = await http.post<IAxisResponse>(
    UPDATE_DEFAULT_FILING_LOCATION_CODE_URL,
    {
      defaultFilingLocationCode: locationCode,
    }
  );

  return response.data;
};

const useUpdateDefaultFilingLocationCode = () =>
  useMutation<IAxisResponse, IAxisResponse, string>({
    mutationKey: ["update-default-location"],
    mutationFn: updateDefaultFilingLocationCode,
  });

export default useUpdateDefaultFilingLocationCode;
