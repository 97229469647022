import { useMutation } from "@tanstack/react-query";
import http from "../../../http";
import { GET_SERVICE_INFO_HISTORY } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface IFilter {
  caseTrackingId?: string;
  locationCounty?: string;
}

interface IServiceInfoHistory {
  firstName?: string;
  surName?: string;
  serviceContactId?: string;
  participantId?: string;
  partyFirmName?: string;
  telNo?: string;
  email?: string;
  mailingAddress?: string;
  action: string[];
  actionTimeStamp: string[];
}

const getServiceInfoHistory = async (filters: IFilter) => {
  const response = await http.post<IServiceInfoHistory[]>(
    GET_SERVICE_INFO_HISTORY,
    filters
  );
  return response.data;
};

const useServiceInfoHistory = () =>
  useMutation<IServiceInfoHistory[], IAxisErrorResponse, IFilter>({
    mutationKey: ["getServiceInfoHistory"],
    mutationFn: getServiceInfoHistory,
  });

export default useServiceInfoHistory;
