import { useMutation } from "@tanstack/react-query";

import http from "../../../../http";
import { GET_RESERVE_COURT_DATE } from "../../../URL";
import { IReservedCourtDate } from "../../../../interfaces";

interface IFilter {
  envelopeId: string;
  locationCode: string;
  scheduledStartDate: string;
  scheduledStartTime: string;
  schedeuledEndDate: string;
  scheduledEndTime: string;
}

interface IAxisErrorResponse {
  response?: {
    data?: {
      userMessage?: string;
    };
  };
}

//This api is not working from tyler side
const getReservedCourtDate = async (filters: IFilter) => {
  try {
    const response = await http.post(GET_RESERVE_COURT_DATE, filters);

    return response.data;
  } catch (error) {
    // TODO REMOVE, Static response as API is not working
    // const staticresponse = {
    //   courtSchedule: [
    //     { startDate: "2022-01-12T08:30:00Z", endDate: "2022-01-12T08:30:00Z" },
    //     { startDate: "2022-01-13T09:00:00Z", endDate: "2022-01-13T10:30:00Z" },
    //     { startDate: "2022-01-14T10:30:00Z", endDate: "2022-01-14T11:30:00Z" },
    //   ],
    // };
    // return staticresponse;
  }
};

const useReservedCourtDate = () =>
  useMutation<IReservedCourtDate, IAxisErrorResponse, IFilter>({
    mutationKey: ["reservedCourtDate"],
    mutationFn: getReservedCourtDate,
  });

export default useReservedCourtDate;
