import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { REGISTER_USER_URL } from "../../URL";
import {
  IAxisErrorResponse,
  IRegisterFirmInformation,
  IRegisterUserInformation,
} from "../../../interfaces";

interface IUserData extends IRegisterFirmInformation, IRegisterUserInformation {
  registrationType: string;
}

interface IAxisResponse {
  error: {
    errorCode: string;
    errorText: string;
  };
}

const registerUser = async (fields: IUserData) => {
  const response = await http.post<IAxisResponse>(REGISTER_USER_URL, fields);
  return response.data;
};

const useRegisterUser = () =>
  useMutation<IAxisResponse, IAxisErrorResponse, IUserData>({
    mutationKey: ["register"],
    mutationFn: registerUser,
  });

export default useRegisterUser;
