import { FC, useCallback } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";

import Dialog from "../dialog";
import { isIndividualUser, isLinked } from "../../utills";
import useI2FLink from "../../apis/auth/useI2FLink";
import useProfile from "../../apis/auth/useProfile";

interface FilingHistoryDialogProps {
  isOpen: boolean;
  onClose: () => void;
  gotoFilingHistory: () => void;
}

const FilingHistoryDialog: FC<FilingHistoryDialogProps> = ({
  isOpen,
  onClose,
  gotoFilingHistory,
}) => {
  const profile = useProfile();
  const i2fLink = useI2FLink();

  const location = useLocation();

  const getI2FLink = useCallback(async () => {
    if (profile.data) {
      i2fLink.mutateAsync({
        liteUser: profile.data.email,
        defaultToSupremeCourtCounty: false,
        isUserProfileLinked: isLinked(),
        liteUserFirstName: profile.data.firstName,
        liteUserLastName: profile.data.lastName,
        liteUserRole: isIndividualUser() ? "prose" : "Attorney",
      });
    }
    onClose();
  }, [profile.data, onClose, i2fLink]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="Filings" maxWidth="lg">
      <Box padding={1}>
        <Card variant="outlined">
          <CardContent className="grey-card">
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item xs={12} md={8}>
                <Typography>
                  If you have filed using{" "}
                  <b className="theme-blue">New I2File EFSP</b>
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} alignItems="end" className="text-right">
                <Button
                  type="button"
                  variant="contained"
                  className="btn button-outline-secondary"
                  autoFocus
                  aria-label="filingHistoryDialog"
                  onClick={
                    location.pathname === "/filingHistory"
                      ? onClose
                      : gotoFilingHistory
                  }
                >
                  Click Here
                </Button>
              </Grid>
            </Grid>

            <Typography className="mt-4">
              <b className="theme-blue">
                [You should have Envelope # as your confirmation number.
                Envelope number is usually 7 digit number]
              </b>
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box padding={1}>
        <Card variant="outlined">
          <CardContent className="grey-card">
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item xs={12} md={8}>
                <Typography>
                  If you have filed using{" "}
                  <b className="theme-blue">Classic I2File application</b>
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} alignItems="end" className="text-right">
                <Button
                  type="button"
                  variant="contained"
                  className="btn button-outline-secondary"
                  aria-label="I2FLinking"
                  onClick={getI2FLink}
                >
                  Click Here
                </Button>
              </Grid>
            </Grid>

            <Typography className="mt-4">
              <b className="theme-blue">
                [You should have the Transaction # as your confirmation number.
                Transaction number is usually 11-12 digit number]
              </b>
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Dialog>
  );
};

export default FilingHistoryDialog;
