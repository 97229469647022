import { useMutation } from "@tanstack/react-query";

import http from "../../../http";

interface ILoginBody {
  emailId: string;
  password: string;
}

interface ILoginResponse {
  emailId: string;
  firstName: string;
  lastName: string;
  accessToken: string;
  roles: string[];
  individual: boolean;
  linked: boolean;
  closeFillingForLocation: string;
  allowanceChargeAmountValue: string;
  locationsExcludedFromI2fileFee: string;
}

interface IAxisResponse {
  response?: {
    data?: {
      userMessage?: string;
    };
  };
}

const login = async (fields: ILoginBody) => {
  const response = await http.post<ILoginResponse>(
    process.env.REACT_APP_USER_SERVICE_API_URL + "/api/user/login",
    fields
  );
  return response.data;
};

const useLogin = () =>
  useMutation<ILoginResponse, IAxisResponse, ILoginBody>({
    mutationKey: ["login"],
    mutationFn: login,
  });

export default useLogin;
