import { FC, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import Dialog from "../../../components/dialog";
import useCreatePaymentAccount from "../../../apis/miscellaneous/useCreatePaymentAccount";

interface PaymentAccountRedirectModalProps {
  isOpen: boolean;
  accountName: string;
  accountTypeCode: string;
  onClose: () => void;
}

const PaymentAccountRedirectModal: FC<PaymentAccountRedirectModalProps> = ({
  isOpen,
  accountName,
  accountTypeCode,
  onClose,
}) => {
  const createPaymentAccount = useCreatePaymentAccount();

  const onSave = useCallback(async () => {
    const response = await createPaymentAccount.mutateAsync({
      accountName,
      paymentAccountTypeCode: accountTypeCode,
    });

    if (response.uniqueId) {
      window.location.href = `${process.env.REACT_APP_PAYMENT_REDIRECT_URL}${response.uniqueId}`;
    }
  }, [accountName, accountTypeCode, createPaymentAccount]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Payment Account"
      maxWidth="sm"
      action={
        <Button
          type="button"
          variant="contained"
          className="btn primary-button"
          onClick={onSave}
        >
          {createPaymentAccount.isLoading ? (
            <CircularProgress
              sx={{
                height: "25px !important",
                width: "25px !important",
                "--CircularProgress-size": "8px",
                "--CircularProgress-trackThickness": "1px",
                "--CircularProgress-progressThickness": "1px",
              }}
              color="inherit"
            />
          ) : (
            "Ok"
          )}
        </Button>
      }
    >
      <Box display="flex">
        <Typography pl={1}>
          You will be redirected to the state efileIL vendor's payment setup
          website. You will be configuring your payment account on{" "}
          <b>Tyler Online Gateway</b>.
        </Typography>
      </Box>
    </Dialog>
  );
};

export default PaymentAccountRedirectModal;
