import { FC, useCallback, useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import Header from "../landingPage/Header";
import FirmInformation from "./FirmInformation";
import UserInformation from "./UserInformation";
import RegistrationInformation from "./RegistrationInformation";
import useRegisterUser from "../../apis/auth/useRegisterUser";
import {
  IRegisterFirmInformation,
  IRegisterUserInformation,
} from "../../interfaces";
import { NotificationContext } from "../../context/NotificationContext";

interface RegisterProps {
  isFirmAccount: boolean;
}

const Register: FC<RegisterProps> = ({ isFirmAccount }) => {
  const [firmInformation, setFirmInformation] = useState<
    IRegisterFirmInformation | undefined
  >();
  const [userInformation, setUserInformation] = useState<
    IRegisterUserInformation | undefined
  >();
  const [email, setEmail] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const registerUser = useRegisterUser();
  const { createNotification } = useContext(NotificationContext);

  const updateFirmInformation = useCallback(
    (firm: IRegisterFirmInformation) => {
      setFirmInformation({
        ...firm,
      });
      setActiveStep(1);
    },
    []
  );

  const onPrevious = useCallback((userInfo: IRegisterUserInformation) => {
    setUserInformation(userInfo);
    setActiveStep((prevStep) => prevStep - 1);
  }, []);

  const register = useCallback(
    (userInformation: IRegisterUserInformation) => {
      if (firmInformation) {
        registerUser.mutate({
          ...firmInformation,
          ...userInformation,
          registrationType: isFirmAccount
            ? "RegistrationType.FIRM_ADMINISTRATOR"
            : "RegistrationType.INDIVIDUAL",
        });
      }
    },
    [firmInformation, isFirmAccount, registerUser]
  );

  useEffect(() => {
    if (registerUser.isSuccess && registerUser.variables) {
      if (registerUser.data.error.errorCode === "0") {
        createNotification({
          type: "success",
          title: "Register Notification",
          message: isFirmAccount
            ? "Firm Admin registered successfully!!!"
            : "Independent user registered successfully!!!",
        });
        setEmail(registerUser.variables.email);
        setActiveStep(2);
      } else {
        createNotification({
          type: "error",
          title: "Register Notification",
          message: registerUser.data.error.errorText,
        });
      }
    }
    // eslint-disable-next-line
  }, [registerUser.isSuccess]);

  useEffect(() => {
    if (registerUser.isError) {
      createNotification({
        type: "error",
        title: "Register Notification",
        message:
          registerUser.error.response?.data?.userMessage ||
          "Issue While Registration of New User, Please Try Again After Some Time",
      });
    }
    // eslint-disable-next-line
  }, [registerUser.isError]);

  useEffect(() => {
    setFirmInformation(undefined);
    setUserInformation(undefined);
    setActiveStep(0);
  }, [isFirmAccount]);

  return (
    <div className="main">
      <Header />
      <Box className="content-row">
        <Container fixed>
          <div className="firm-info-main">
            <Box className="p-tabview">
              <Box className="p-tabview-nav-content">
                <Tabs
                  className="p-tabview-nav "
                  value={activeStep}
                  aria-label="Account registration tabs"
                >
                  <Tab
                    label={`1 ${
                      isFirmAccount ? "Firm" : "Contact"
                    } Information`}
                    id="firm-information"
                    className="p-tabview-nav-link"
                    iconPosition="start"
                    icon={activeStep > 0 ? <CheckCircleIcon /> : undefined}
                  />
                  <Tab
                    label="2  User Information"
                    id="user-information"
                    className="p-tabview-nav-link"
                    iconPosition="start"
                    icon={activeStep > 1 ? <CheckCircleIcon /> : undefined}
                  />
                  <Tab
                    label="3  Complete Registration"
                    id="complete-registration"
                    className="p-tabview-nav-link tabe-last"
                  />
                </Tabs>
              </Box>

              {activeStep === 0 && (
                <FirmInformation
                  key={isFirmAccount.toString()}
                  isFirmAccount={isFirmAccount}
                  firmInformation={firmInformation}
                  setFirmInformation={updateFirmInformation}
                />
              )}
              {activeStep === 1 && (
                <UserInformation
                  isLoading={registerUser.isLoading}
                  userInformation={userInformation}
                  onPrevious={onPrevious}
                  register={register}
                />
              )}
              {activeStep === 2 && firmInformation && (
                <RegistrationInformation
                  isFirmAccount={isFirmAccount}
                  firmId={firmInformation.firmName}
                  email={email}
                />
              )}
            </Box>
          </div>
        </Container>
      </Box>
      <footer className="dash-footer text-center p-2">
        <span className="d-sm-inline-block">
          Copyright © 2007 - {new Date().getFullYear()} Conscisys Corporation
          <a
            href="https://www.conscisys.com"
            target="_blank"
            rel="noreferrer"
            aria-label="Conscisys"
          >
            Powered by Conscisys{" "}
          </a>
        </span>
      </footer>
    </div>
  );
};

export default Register;
