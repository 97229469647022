import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import Page from "../../components/page";
import FilingHistoryGrid from "../FilingHistory/filingHistoryGrid";
import useDashboardCount from "../../apis/dashboard";
import { NotificationContext } from "../../context/NotificationContext";
import RequestDocumentDialog from "../../components/requestDocumentDialog/RequestDocumentDialog";
import FilingHistoryDialog from "../../components/filingHistoryDialog/FilingHistoryDialog";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Dashboard = () => {
  const { createNotification } = useContext(NotificationContext);
  const [isRequestDocumentModalOpen, setIsRequestDocumentModalOpen] =
  useState(false);
  const dashboardCount = useDashboardCount();
  const [isFilingHistoryModalOpen, setIsFilingHistoryModalOpen] =
  useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (dashboardCount.isError) {
      createNotification({
        message:
          dashboardCount.error?.response?.data?.userMessage ||
          "An Error occurred while getting count",
        type: "error",
        title: "Dashboard Notification",
      });
    }
    // eslint-disable-next-line
  }, [dashboardCount.isError]);

  const openRequestDocumentModal = useCallback(() => {
    setIsRequestDocumentModalOpen(true);
  }, []);

  const closeRequestDocumentModal = useCallback(() => {
    setIsRequestDocumentModalOpen(false);
  }, []);

  const openFilingHistoryModal = useCallback(() => {
    setIsFilingHistoryModalOpen(true);
  }, []);

  const closeFilingHistoryModal = useCallback(() => {
    setIsFilingHistoryModalOpen(false);
  }, []);

  const gotoFilingHistory = useCallback(() => {
    navigate("/filingHistory");
  }, [navigate]);

  return (
    <Page>
      <div className="content-wrapper">
        <div className="d-flex justify-content-between main-title-wrap">
          <h2 className="dash-title-h2">Dashboard</h2>
          <span className="p-input-icon-right"></span>
        </div>
        <div className="card-sec">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid
                className="pt-0 mb-5 left-card-box"
                item
                xs={12}
                sm={12}
                md={6}
              >
                <Item className="remove-item-css">
                  <h3 className="dash-title-h3">Filing</h3>
                  <span className="option-text">
                    Select what you want to do from below options
                  </span>

                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6}>
                        <Link to="/newcase" className="d-block">
                          <Item className="remove-item-css">
                            <div className="box-card new-case-card red-pastel-bg">
                              <h4 className="box-card-title">
                                Start a <br /> New Case
                              </h4>
                            </div>
                          </Item>
                        </Link>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Link to="/existingcase" className="d-block">
                          <Item className="remove-item-css">
                            <div className="box-card existing-case-card Primary-light-bg">
                              <h4 className="box-card-title">
                                File on <br /> Existing Case
                              </h4>
                            </div>
                          </Item>
                        </Link>
                      </Grid>
                      <Grid item xs={6}>
                          <Link to="" className="d-block">
                          <div onClick={openRequestDocumentModal}>
                            <Item className="remove-item-css">
                              <div className="box-card theme-green-bg pt-3 mb-4 text-center">
                                <h4 className="white-text">
                                  Document Request
                                </h4>
                              </div>
                            </Item>
                          </div>
                          </Link>
                        </Grid>
                        <Grid item xs={6}>
                          <Link to="" className="d-block">
                          <div onClick={openFilingHistoryModal}>
                            <Item className="remove-item-css">
                              <div className="box-card theme-grey-bg pt-3 mb-4 text-center">
                                <h4 className="white-text">
                                  Filing History
                                </h4>
                              </div>
                            </Item>
                            </div>
                          </Link>
                        </Grid>
                    </Grid>
                  </Box>
                </Item>
              </Grid>
              <Grid
                className="pt-0 mb-5 border-left-col right-card-box"
                item
                xs={12}
                md={6}
              >
                <Item className="remove-item-css">
                  <div>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Link to="/myDrafts" className="d-block">
                            <Item className="remove-item-css">
                              <div className="box-card drafts-card theme-yellow-bg mb-4 text-center">
                                <h3>{dashboardCount?.data?.draftCount}</h3>
                                <h4 className="box-card-title">My Drafts</h4>
                              </div>
                            </Item>
                          </Link>
                        </Grid>
                        <Grid item xs={6}>
                          <Link
                            to="/filingHistory?filingStatus=underReview"
                            className="d-block"
                          >
                            <Item className="remove-item-css">
                              <div className="box-card pending-card green-pastel-bg mb-4 text-center">
                                <h3>
                                  {
                                    dashboardCount?.data
                                      ?.pendingTransactionCount
                                  }
                                </h3>
                                <h4 className="box-card-title">Pending</h4>
                              </div>
                            </Item>
                          </Link>
                        </Grid>
                        <Grid item xs={6}>
                          <Link to="/serviceContacts" className="d-block">
                            <Item className="remove-item-css">
                              <div className="box-card darker-mid-blue-bg pt-3 mb-4 text-center">
                                <h4 className="white-text">
                                  Manage Your <br /> Service Contacts
                                </h4>
                              </div>
                            </Item>
                          </Link>
                        </Grid>
                        <Grid item xs={6}>
                          <Link to="/transactionReport" className="d-block">
                            <Item className="remove-item-css">
                              <div className="box-card theme-light-blue-bg pt-3 mb-4 text-center">
                                <h4 className="white-text">
                                  I2File Transaction <br /> Report
                                </h4>
                              </div>
                            </Item>
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </div>
        <div className="grid-sec themeDataTable">
          <h3 className="dash-title-h3">Recent Cases</h3>
          <div className="grid-col">
            <FilingHistoryGrid showRecentCases />
          </div>
        </div>

        <FilingHistoryDialog
        isOpen={isFilingHistoryModalOpen}
        onClose={closeFilingHistoryModal}
        gotoFilingHistory={gotoFilingHistory}
      />

        <RequestDocumentDialog
        isOpen={isRequestDocumentModalOpen}
        onClose={closeRequestDocumentModal}
      />

      </div>
    </Page>
  );
};

export default Dashboard;
