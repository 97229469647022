import { useMutation } from "@tanstack/react-query";

import http from "../../../../http";
import { GET_REQUEST_COURT_DATE } from "../../../URL";

interface IFilter {
  envelopeId: string;
  locationCode: string;
  scheduledStartDate: string;
  scheduledEndDate: string;
  scheduledEndTime: string;
}

interface IAxisErrorResponse {
  response?: {
    data?: {
      userMessage?: string;
    };
  };
}

const getRequestCourtDate = async (filters: IFilter) => {
  const response = await http.post(GET_REQUEST_COURT_DATE, filters);
  return response.data;
};

const useRequestCourtDate = () =>
  useMutation<void, IAxisErrorResponse, IFilter>({
    mutationKey: ["efm-transactions"],
    mutationFn: getRequestCourtDate,
  });

export default useRequestCourtDate;
