import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_LOCATION_DISCLAIMER_REQUIREMENT } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface IDisclainerResposne {
  efmDisclaimerCode: string;
  efmDisclaimerName: string;
  efmLocationCode: string;
  requirementtext: string;
}

const getDisclaimerRequirement = async (locationCode?: string) => {
  if (!locationCode) return [];

  const response = await http.post<IDisclainerResposne[]>(
    GET_LOCATION_DISCLAIMER_REQUIREMENT,
    {
      efmLocationCode: locationCode,
    }
  );
  return response.data;
};

const useDisclaimerRequirement = (locationCode?: string) =>
  useQuery<IDisclainerResposne[], IAxisErrorResponse>({
    queryKey: ["location-disclaimer-requirement", locationCode],
    queryFn: () => getDisclaimerRequirement(locationCode),
  });

export default useDisclaimerRequirement;
