import { FC } from "react";
import Button from "@mui/material/Button";

import Dialog from "../../../../components/dialog";

interface ITechnicalIssueModal {
  isOpen: boolean;
  locationName: string;
  onOk: () => void;
  onClose: () => void;
}

const FilingClosedForCountyDialog: FC<ITechnicalIssueModal> = ({
  isOpen,
  locationName,
  onOk,
  onClose,
}) => {
  return (
    <Dialog
      title="Technical Issue"
      maxWidth="md"
      isOpen={isOpen}
      onClose={onClose}
      action={
        <Button className="btn primary-button" type="submit" onClick={onOk}>
          Ok
        </Button>
      }
    >
      <label>
        We are facing some technical issues for <b>{locationName}</b> because of
        which you will not be able to file documents.
      </label>
      <br />
      <br />
      <label>
        In case of an emergency, you can use any other EFSP to submit your
        filings.
      </label>
      <br />
      <br />
      <label>
        <a
          href={process.env.REACT_APP_EFILEIL_INFO_WEBSITE}
          className="theme-blue f-w-bold text-underline"
          target="_blank"
          rel="noreferrer"
          aria-label="eFileIL System"
        >
          Click Here
        </a>{" "}
        to view the list of certified service providers for eFileIL system.
      </label>
    </Dialog>
  );
};

export default FilingClosedForCountyDialog;
