import { useQuery } from "@tanstack/react-query";

import http from "../../http";

const getGridData = async (
  url?: string,
  addUniqueId?: boolean,
  responseListKey?: string
) => {
  if (!url) return;

  const response = await http.post<any[]>(url);

  if (addUniqueId)
    return response.data?.map((row, index) => ({ ...row, id: index + 1 }));

  //@ts-ignore
  if (responseListKey) return response.data[responseListKey];

  return response.data;
};

const useGrid = (
  url?: string,
  addUniqueId?: boolean,
  responseListKey?: string
) =>
  useQuery<any[] | undefined>({
    queryKey: ["grid", url || ""],
    queryFn: () => getGridData(url, addUniqueId, responseListKey),
    networkMode: "always",
    cacheTime: 0,
  });

export default useGrid;
