import { FC, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

import { IRegisterUserInformation } from "../../../interfaces";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface UserInformationProps {
  isLoading: boolean;
  userInformation?: IRegisterUserInformation;
  onPrevious: (userInformation: IRegisterUserInformation) => void;
  register: (userInformation: IRegisterUserInformation) => void;
}

const UserInformation: FC<UserInformationProps> = ({
  isLoading,
  userInformation,
  onPrevious,
  register,
}) => {
  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      firstName: userInformation?.firstName || "",
      middleName: userInformation?.middleName || "",
      lastName: userInformation?.lastName || "",
      email: userInformation?.email || "",
      verifyEmail: userInformation?.verifyEmail || "",
      password: userInformation?.password || "",
      verifyPassword: userInformation?.verifyPassword || "",
      passwordQuestion: userInformation?.passwordQuestion || "",
      passwordAnswer: userInformation?.passwordAnswer || "",
    },
    resolver: yupResolver(
      yup
        .object({
          firstName: yup
            .string()
            .trim()
            .required("Error: First Name is required."),
          middleName: yup.string(),
          lastName: yup
            .string()
            .trim()
            .required("Error: Last Name is required."),
          email: yup
            .string()
            .lowercase()
            .trim()
            .required("Error: Email Address is required.")
            .email("Error: Email Address must be a valid email."),
          verifyEmail: yup
            .string()
            .lowercase()
            .trim()
            .required("Error: Verify Email Address is required.")
            .email("Error: Verify Email Address must be a valid email.")
            .oneOf([yup.ref("email")], "Error: Email Address does not match."),
          password: yup
            .string()
            .trim()
            .required("Error: Password is required.")
            .matches(
              /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d\W]).*$/,
              "Password must be at least eight characters with at least one lower case, at least one upper case, and at least one number or symbol."
            ),
          verifyPassword: yup
            .string()
            .trim()
            .required("Error: Verify Password is required.")
            .oneOf([yup.ref("password")], "Error: Password does not match."),
          passwordQuestion: yup
            .string()
            .trim()
            .required("Error: Security Question is required."),
          passwordAnswer: yup
            .string()
            .trim()
            .required("Error: Security Answer is required."),
        })
        .required()
    ),
  });

  const onPreviousClick = useCallback(() => {
    const values = getValues();
    onPrevious(values);
  }, [getValues, onPrevious]);

  const onSubmit = useCallback(
    (userInformation: IRegisterUserInformation) => {
      register(userInformation);
    },
    [register]
  );

  return (
    <Box sx={{ p: 3 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Item className="remove-item-css">
              <Controller
                name="firstName"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    placeholder="First Name"
                    label="First Name"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item className="remove-item-css">
              <Controller
                name="middleName"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    placeholder="MI"
                    label="MI"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item className="remove-item-css">
              <Controller
                name="lastName"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    placeholder="Last Name"
                    label="Last Name"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="remove-item-css">
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    placeholder="Email Address"
                    label="Email Address"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="remove-item-css">
              <Controller
                name="verifyEmail"
                control={control}
                rules={{
                  required: "Verify Email Address is required.",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    placeholder="Verify Email Address"
                    label="Verify Email Address"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="remove-item-css">
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    placeholder="********"
                    label="Password"
                    variant="standard"
                    type="password"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="remove-item-css">
              <Controller
                name="verifyPassword"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    placeholder="********"
                    label="Verify Password"
                    variant="standard"
                    type="password"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item className="remove-item-css">
              <Controller
                name="passwordQuestion"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    placeholder="Security Question"
                    label="Security Question"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item className="remove-item-css">
              <Controller
                name="passwordAnswer"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    placeholder="Security Answer"
                    label="Security Answer"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Item>
          </Grid>
        </Grid>

        <div className="text-center bottom-button-row">
          <Button
            className="btn button-outline-secondary me-3"
            variant="contained"
            type="button"
            onClick={onPreviousClick}
          >
            Previous
          </Button>
          <Button
            className="btn primary-button"
            variant="contained"
            type="submit"
          >
            {isLoading ? (
              <CircularProgress
                sx={{
                  height: "25px !important",
                  width: "25px !important",
                  "--CircularProgress-size": "8px",
                  "--CircularProgress-trackThickness": "1px",
                  "--CircularProgress-progressThickness": "1px",
                }}
                color="inherit"
              />
            ) : (
              "Register"
            )}
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default UserInformation;
