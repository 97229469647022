import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { COUNTY_ADMIN_UPDATE } from "../../URL";

interface IUpdateCountyAdmin {
  adminprofileid: number;
  firstname: string;
  middlename: string;
  lastname: string;
  username: string;
  countycode: string;
  isactive: boolean;
}

interface IAxisErrorResponse {
  response?: {
    data?: {
      userMessage?: string;
    };
  };
}

const updateCountyAdmin = async (fields: IUpdateCountyAdmin) => {
  await http.post(COUNTY_ADMIN_UPDATE, {
    ...fields,
  });
};

const useUpdateCountyAdmin = () =>
  useMutation<void, IAxisErrorResponse, IUpdateCountyAdmin>({
    mutationKey: ["update-service-contact"],
    mutationFn: updateCountyAdmin,
  });

export default useUpdateCountyAdmin;
