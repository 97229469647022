import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid/models";

import Filing from "../../newCase/filings/filing";
import GridTable from "../../../components/grid";
import Loader from "../../../components/loader";
import useLocationFilingTypes from "../../../apis/common/useLocationFilingTypes";
import useAttorneys from "../../../apis/firm/useAttorneys";
import usePaymentAccounts from "../../../apis/miscellaneous/usePaymentAccounts";
import useSubsequentFeesCalculation from "../../../apis/existingCase/useSubsequentFeesCalculation";
import useFirmInformation from "../../../apis/firm/useFirmInformation";
import useProfile from "../../../apis/auth/useProfile";
import useLocationFilingComponents from "../../../apis/common/useLocationFilingComponents";
import { NotificationContext } from "../../../context/NotificationContext";
import {
  getProviderFee,
  isIndividualUser,
  renderAmount,
} from "../../../utills";
import {
  IAttorney,
  ICaseResponse,
  IDocumentAttachment,
  IFeeCalculationRequest,
  IFiling,
  IFilingFees,
  IFilingInformation,
  IOptionalService,
  IParty,
  IUploadResponse,
} from "../../../interfaces";
import ReturnDate from "../../newCase/filings/returnDate";
import AdDamnum from "../../newCase/filings/adDamnum";
import useLocations from "../../../apis/common/useLocations";

interface IFilings {
  caseInfo: ICaseResponse;
  parties: IParty[];
  filingInfo?: IFilingInformation;
  formatToFeeCalculation: (
    filingsInfo: IFilingInformation,
    paymentAccount: string,
    filingAttorneyId?: string,
    partyResponsibleForFees?: number | string,
    attorneys?: IAttorney[]
  ) => IFeeCalculationRequest | undefined;
  updateFilings: (filingInfo: IFilingInformation, tabIndex: number) => void;
  setShowServiceContact: React.Dispatch<React.SetStateAction<boolean>>;
  createDraft: (
    filingsInfo: IFilingInformation,
    paymentAccount: string,
    filingAttorneyId?: string,
    partyResponsibleForFees?: number | string,
    attorneys?: IAttorney[]
  ) => void;
}

const Filings: FC<IFilings> = ({
  caseInfo,
  parties,
  filingInfo,
  formatToFeeCalculation,
  updateFilings,
  setShowServiceContact,
  createDraft,
}) => {
  const [activeRow, setActiveRow] = useState(0);
  const [validateComponentFileRequired, setValidateComponentFileRequired] =
    useState<number>();
  const [returnDateErrorMessage, setReturnDateErrorMessage] = useState("");

  const profile = useProfile();
  const firmInformation = useFirmInformation();
  const { data: locations } = useLocations();
  const { data: filingTypes, isLoading: filingTypeIsLoading } =
    useLocationFilingTypes(
      caseInfo.locationId,
      caseInfo.caseCatCode,
      caseInfo.caseTypeCode,
      "subsequent"
    );
  const { data: attorneys, isLoading: attorneyIsLoading } = useAttorneys();
  const { data: paymentAccounts, isLoading: paymentAccountIsLoading } =
    usePaymentAccounts();
  const feeCalculation = useSubsequentFeesCalculation();
  const { createNotification } = useContext(NotificationContext);
  const [uploadResponseData, setUploadResponseData] =  useState <IUploadResponse[]>([]);

  const updateUploadResponseDataState = (newValues: IUploadResponse) => {
    setUploadResponseData((prevData) => [...prevData, newValues]);
  }

  const { watch, control, setValue, handleSubmit, getValues } = useForm<{
    paymentAccount: string;
    filingAttorney: string;
    partyResponsibleForFeesIndex: number | string;
    filings: IFiling[];
    filingFees: IFilingFees[];
    caseInitiationFee: number;
    totalProviderFee: number;
    conveninceFee: number;
    feeCalculationAmount: number;
    amountInControversy?: number;
    isReturnDateVerified?: boolean;
    returnDate?: Date;
    outOfStateService?: boolean;
  }>({
    defaultValues: {
      filings: filingInfo?.filings?.length
        ? filingInfo.filings
        : [
            {
              id: "Filing1",
              eFileType: "E-File",
              eFile: true,
              service: false,
              filingCode: "",
              attachments: [],
              optionalServices: [],
            },
          ],
      filingAttorney: filingInfo?.filingAttorney,
      paymentAccount: filingInfo?.paymentAccount,
      partyResponsibleForFeesIndex:
        filingInfo?.partyResponsibleForFeesIndex || "",
      filingFees: filingInfo?.filingFees || [],
      caseInitiationFee: filingInfo?.caseInitiationFee || 0,
      conveninceFee: filingInfo?.conveninceFee || 0,
      totalProviderFee: filingInfo?.totalProviderFee || 0,
      feeCalculationAmount: filingInfo?.feeCalculationAmount || 0,
      amountInControversy: filingInfo?.amountInControversy,
      isReturnDateVerified: filingInfo?.isReturnDateVerified,
      returnDate: filingInfo?.returnDate,
      outOfStateService: filingInfo?.outOfStateService,
    },
  });

  const { append, remove } = useFieldArray({
    name: "filings",
    control,
  });

  const { data: components, isLoading: componentIsLoading } =
    useLocationFilingComponents(
      caseInfo.locationId,
      watch(`filings.${activeRow}.filingCode`)
    );

    useEffect(() => {
      if (components) {
        console.log("Fetched components:", components);
      }
    }, [components]);

  const isWaiverPayment = useMemo(() => {
    const paymentAccount = watch("paymentAccount");
    if (!paymentAccount) return false;

    return (
      paymentAccounts?.some(
        (item) =>
          item.paymentAccountTypeCode === "WV" &&
          item.paymentAccountID === paymentAccount
      ) || false
    );
    // eslint-disable-next-line
  }, [watch("paymentAccount"), paymentAccounts]);

  const updateAmountInControversy = useCallback(
    (amount: number | undefined) => {
      setValue("amountInControversy", amount);
      setValue("returnDate", undefined);
      setValue("outOfStateService", false);
      setValue("isReturnDateVerified", false);
    },
    [setValue]
  );

  const updateReturnDate = useCallback(
    (
      returnDate?: Date,
      outOfStateService?: boolean,
      isReturnDateVerified?: boolean
    ) => {
      setValue("returnDate", returnDate);
      setValue("outOfStateService", outOfStateService);
      setValue("isReturnDateVerified", isReturnDateVerified);
    },
    [setValue]
  );

  const getReturnDateRequest = useCallback(() => {
    const formData = getValues();

    return formatToFeeCalculation(
      formData,
      formData.paymentAccount,
      formData.filingAttorney,
      formData.partyResponsibleForFeesIndex,
      attorneys
    );
  }, [attorneys, formatToFeeCalculation, getValues]);

  const removeFiling = useCallback(
    (index: number) => {
      remove(index);
      setActiveRow(0);
    },
    [remove]
  );

  const onFilingSelect = useCallback(
    (row: GridRowParams<any>) => {
      const formData = getValues();

      let rowIndex = 0;
      formData.filings.forEach((filing, index) => {
        if (filing.id === row.id) {
          rowIndex = index;
        }
      });

      if (rowIndex === activeRow || formData.filings.length <= activeRow)
        return;

      if (!formData.filings[activeRow].filingCode) {
        createNotification({
          type: "error",
          title: "New Case Notification",
          message: "Please fill in all required fields.",
        });

        return;
      }

      for (const component of components || []) {
        if (
          component.required &&
          !formData.filings[activeRow].attachments.filter(
            (attachment) =>
              attachment.binaryCategoryText ===
                component.locationFilingcomponentCode &&
              attachment.binaryDescriptionText
          ).length
        ) {
          createNotification({
            type: "error",
            title: "New Case Notification",
            message: "Please fill in all required fields.",
          });

          return;
        }
      }

      setActiveRow(rowIndex);
    },
    [components, activeRow, createNotification, getValues]
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "filingCode",
        headerName: "Code",
        width: 200,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.row?.filingCode) return "";

          return filingTypes?.find(
            (type) => type.efmFilingtypeCode === params.row.filingCode
          )?.efmFilingtypeName;
        },
      },
      {
        field: "eFileType",
        headerName: "Type",
        width: 150,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          if (params.row?.eFile && params.row?.service) return "EFileAndServe";
          else if (!params.row?.eFile && params.row?.service) return "Serve";
          else return "EFile";
        },
      },
      {
        field: "documentDescriptionText",
        headerName: "Description",
        width: 400,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "referenceNumber",
        headerName: "Reference Number",
        width: 300,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "action",
        headerName: "",
        width: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          if (params.id === "Filing1") return "";

          return (
            <IconButton
              aria-label="delete"
              onClick={() =>
                removeFiling(
                  parseInt(params.id.toString().replace("Filing", "")) - 1
                )
              }
            >
              <span className="material-symbols-outlined datagrid-cancel">
                delete
              </span>
            </IconButton>
          );
        },
      },
    ],
    [filingTypes, removeFiling]
  );

  const onPrevious = useCallback(() => {
    updateFilings(getValues(), 1);
  }, [updateFilings, getValues]);

  const onSubmit = useCallback(
    (formData: {
      paymentAccount: string;
      filingAttorney: string;
      partyResponsibleForFeesIndex: number | string;
      filings: IFiling[];
      filingFees: IFilingFees[];
      caseInitiationFee: number;
      totalProviderFee: number;
      conveninceFee: number;
      feeCalculationAmount: number;
      amountInControversy?: number;
      isReturnDateVerified?: boolean;
      returnDate?: Date;
      outOfStateService?: boolean;
    }) => {
      if (!firmInformation.data || !profile.data) return;

      for (const component of components || []) {
        if (
          component.required &&
          !formData.filings[activeRow].attachments.filter(
            (attachment) =>
              attachment.binaryCategoryText ===
              component.locationFilingcomponentCode
          ).length
        ) {
          setValidateComponentFileRequired(Math.random());

          return;
        }
      }

      if (
        Boolean(
          locations?.find(
            (location) => location.efm_Location_Code === caseInfo.locationId
          )?.isreturndate
        ) &&
        !formData.isReturnDateVerified
      ) {
        setReturnDateErrorMessage("Please select a return date.");
        return;
      }

      return updateFilings(formData, 3);
    },
    [
      firmInformation.data,
      profile.data,
      components,
      activeRow,
      locations,
      caseInfo,
      updateFilings,
    ]
  );

  const addAttachment = useCallback(
    (attachment: IDocumentAttachment) => {
      setValue(`filings.${activeRow}.attachments`, [
        ...watch(`filings.${activeRow}.attachments`),
        {
          ...attachment,
          generalFilingType: filingTypes?.find(
            (type) =>
              type.efmFilingtypeCode ===
              watch(`filings.${activeRow}.filingCode`)
          )?.efmFilingtypeName,
        },
      ]);
    },
    [activeRow, filingTypes, setValue, watch]
  );

  const updateAttachment = useCallback(
    (updatedAttachment: IDocumentAttachment) => {
      setValue(
        `filings.${activeRow}.attachments`,
        watch(`filings.${activeRow}.attachments`).map((attachment) =>
          attachment.attachmentSequenceID ===
            updatedAttachment.attachmentSequenceID &&
          attachment.binaryCategoryText === updatedAttachment.binaryCategoryText
            ? { ...updatedAttachment }
            : { ...attachment }
        )
      );
    },
    [activeRow, setValue, watch]
  );

  const removeAttachment = useCallback(
    (sequence: string, binaryCategory?: string) => {
      setValue(
        `filings.${activeRow}.attachments`,
        watch(`filings.${activeRow}.attachments`)
          .filter(
            (attachment) =>
              !(
                attachment.attachmentSequenceID === sequence &&
                attachment.binaryCategoryText === binaryCategory
              )
          )
          .map((attachment, index) => ({
            ...attachment,
            attachmentSequenceID: index.toString(),
          }))
      );
    },
    [activeRow, setValue, watch]
  );

  const updateOptionalServices = useCallback(
    (services: IOptionalService[]) => {
      setValue(`filings.${activeRow}.optionalServices`, services);
    },
    [activeRow, setValue]
  );

  const onAddAnotherFiling = useCallback(() => {
    const formData = getValues();

    if (!formData.filings[activeRow].filingCode) {
      createNotification({
        type: "error",
        title: "New Case Notification",
        message: "Please fill in all required fields.",
      });

      return;
    }

    for (const component of components || []) {
      if (
        component.required &&
        !formData.filings[activeRow].attachments.filter(
          (attachment) =>
            attachment.binaryCategoryText ===
            component.locationFilingcomponentCode
        ).length
      ) {
        createNotification({
          type: "error",
          title: "New Case Notification",
          message: "Please fill in all required fields.",
        });

        return;
      }
    }

    append({
      id: `Filing${formData.filings.length + 1}`,
      eFileType: "E-File",
      eFile: true,
      filingCode: "",
      courtesyCopiesText: "",
      documentDescriptionText: "",
      filingCommentsText: "",
      referenceNumber: "",
      service: false,
      attachments: [],
      optionalServices: [],
    });

    setActiveRow(formData.filings.length);
  }, [components, activeRow, append, createNotification, getValues]);

  const calculateFee = useCallback(
    (requestObject: IFeeCalculationRequest) => {
      feeCalculation.mutateAsync(requestObject);
    },
    [feeCalculation]
  );

  const onCreateDraft = useCallback(() => {
    const formData = getValues();

    createDraft(
      formData,
      formData.paymentAccount,
      formData.filingAttorney,
      formData.partyResponsibleForFeesIndex,
      attorneys
    );
  }, [createDraft, attorneys, getValues]);

  useEffect(() => {
    if (paymentAccounts?.length) {
      const creditCard = paymentAccounts.find(
        (account) => account.paymentAccountTypeCode === "CC"
      );

      if (creditCard) setValue("paymentAccount", creditCard.paymentAccountID);
      else setValue("paymentAccount", paymentAccounts[0].paymentAccountID);
    }
  }, [paymentAccounts, setValue]);

  useEffect(() => {
    if (feeCalculation.isSuccess) {
      if (feeCalculation.data?.allowanceCharge.length === 0) {
        createNotification({
          title: "Fee Calculation",
          type: "error",
          message:
            feeCalculation.data?.messageStatus?.messageContentError?.[0]
              .errorDescription?.errorCodeDescriptionText?.value ||
            "Error Occured While Fees Calculation.",
        });
      }
    }
    // eslint-disable-next-line
  }, [feeCalculation.isSuccess]);

  useEffect(() => {
    if (feeCalculation.isError) {
      createNotification({
        title: "Fee Calculation",
        type: "error",
        message:
          feeCalculation.error?.response?.data?.messageStatus
            ?.messageContentError?.[0].errorDescription
            ?.errorCodeDescriptionText?.value ||
          "Error Occured While Fees Calculation.",
      });
    }
    // eslint-disable-next-line
  }, [feeCalculation.isError]);

  useEffect(() => {
    if (
      watch(`filings.${activeRow}.filingCode`) &&
      firmInformation.data &&
      profile.data
    ) {
      const formData = getValues();

      const accountType = paymentAccounts?.find(
        (account) => account.paymentAccountID === formData.paymentAccount
      );

      if (!accountType || accountType.paymentAccountTypeCode === "WV") return;

      if (!isIndividualUser() && !attorneys?.length) return;

      if (
        !formData.filings.some(
          (filing) => filing.filingCode && filing.filingCode !== "SERVICE ONLY"
        )
      )
        return;

      const requestObject = formatToFeeCalculation(
        formData,
        formData.paymentAccount,
        formData.filingAttorney,
        formData.partyResponsibleForFeesIndex,
        attorneys
      );

      if (requestObject) {
        calculateFee(requestObject);
      }
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    watch(`filings.${activeRow}.filingCode`),
    // eslint-disable-next-line
    watch(`filings.${activeRow}.optionalServices`).length,
    // eslint-disable-next-line
    watch("paymentAccount"),
  ]);

  useEffect(() => {
    setValue(
      "filingFees",
      getValues("filings")
        .filter((filing) => filing.filingCode)
        .map((filing, filingIndex) => ({
          id: filingIndex + 1,
          filingTypeCode: filing.filingCode,
          filingTypeName:
            filingTypes?.find(
              (type) => type.efmFilingtypeCode === filing.filingCode
            )?.efmFilingtypeName || "",
          total: 0,
          optionalServices: filing.optionalServices.map((optionalService) => ({
            efmOptionalservicCode: optionalService.identificationID,
            efmOptionalserviceName: optionalService.efmOptionalserviceName,
          })),
        }))
    );
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    watch(`filings.${activeRow}.filingCode`),
    // eslint-disable-next-line
    watch(`filings.${activeRow}.optionalServices`).length,
    // eslint-disable-next-line
    watch(`filings.${activeRow}.optionalServices`).some(
      (service) => !service.efmOptionalserviceName
    ),
  ]);

  useEffect(() => {
    const filings = watch("filings");
    if (filings.some((filing) => filing.service)) {
      setShowServiceContact(true);
    } else {
      setShowServiceContact(false);
    }
    // eslint-disable-next-line
  }, [watch(`filings.${activeRow}.service`)]);

  useEffect(() => {
    if (feeCalculation.isSuccess && feeCalculation.data) {
      const filingFees = getValues("filingFees");

      let index = 0;
      for (const filingFee of filingFees) {
        filingFee.amount = feeCalculation.data?.allowanceCharge?.find(
          (item: any) =>
            item.id?.value === filingFee.filingTypeCode &&
            item?.allowanceChargeReason?.[0]?.value === "Filing Fee"
        )?.amount?.value;

        let filingTotal = filingFee.amount || 0;
        for (const optionalService of filingFee.optionalServices) {
          optionalService.fee = feeCalculation.data?.allowanceCharge?.find(
            // eslint-disable-next-line
            (item: any) =>
              item.id?.value === optionalService.efmOptionalservicCode &&
              item.id?.schemeID === index.toString()
          )?.amount?.value;

          filingTotal += optionalService.fee || 0;
        }

        filingFee.total = filingTotal;

        index++;
      }

      let providerFee = 0;
      let feeCalculationAmount =
        feeCalculation.data?.feesCalculationAmount?.amount?.value || 0;
      if (feeCalculationAmount > 0) {
        providerFee = getProviderFee(caseInfo.locationId);
        feeCalculationAmount += providerFee;
      }

      setValue("filingFees", filingFees);

      setValue(
        "caseInitiationFee",
        feeCalculation.data?.allowanceCharge?.find(
          (item: any) =>
            item?.allowanceChargeReason?.[0]?.value === "Case Initiation Fee"
        )?.amount?.value || 0
      );

      setValue(
        "conveninceFee",
        feeCalculation.data?.allowanceCharge?.find(
          (item: any) =>
            item?.allowanceChargeReason?.[0]?.value === "Convenience Fee"
        )?.amount?.value || 0
      );

      setValue("totalProviderFee", providerFee);

      setValue("feeCalculationAmount", feeCalculationAmount);
    }
    // eslint-disable-next-line
  }, [feeCalculation.isSuccess]);

  return (
    <Box sx={{ p: 0 }}>
      <div className="filings-row">
        <div className="d-flex justify-content-center">
          <span className="case-id-title">
            {`CASE # ${caseInfo.caseNumber} (${caseInfo.jurisdiction}) - ${caseInfo.description}`}
          </span>
        </div>
      </div>

      <div className="filings-row">
        <div className="existing-case-parties mt-2">
          <h3 className="dash-title-h3 mb-3 theme-blue">Filings</h3>
          <div className="existing-case-parties-top d-flex justify-content-between mb-3">
            <h5>Enter Filing Details</h5>
            <Button
              className="btn button-outline-secondary mar-0"
              variant="contained"
              onClick={onAddAnotherFiling}
              disabled={componentIsLoading}
            >
              <AddIcon className="me-2" />
              Add Another Filing
            </Button>
          </div>
        </div>
        <div className="themeDataTable">
          <Box sx={{ height: "auto", overflow: "auto" }}>
            <GridTable
              columns={columns}
              rows={getValues().filings?.length ? [...getValues().filings] : []}
              onRowClick={onFilingSelect}
            />
          </Box>
        </div>
        <div>
          {(filingTypeIsLoading ||
            attorneyIsLoading ||
            paymentAccountIsLoading) && <Loader />}

          <form onSubmit={handleSubmit(onSubmit)}>
            <Filing
              index={activeRow}
              key={activeRow}
              locationCode={caseInfo.locationId}
              filingTypes={filingTypes}
              components={components}
              validateComponentFileRequired={validateComponentFileRequired}
              disableServiceUpdate={feeCalculation.isLoading}
              isExistingCase
              addAttachment={addAttachment}
              updateAttachment={updateAttachment}
              removeAttachment={removeAttachment}
              updateOptionalServices={updateOptionalServices}
              control={control}
              watch={watch}
              setValue={setValue}
              updateUploadResponseDataState = {updateUploadResponseDataState}
            />

            <div className="payment-fees">
              <Grid container spacing={4}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <h4 className="mb-3">
                    <MonetizationOnIcon className="payment-icon vertical-align-middle red-pastel" />{" "}
                    Payment & Fees
                  </h4>

                  {watch("filings").some(
                    (filing) => filing.filingCode !== "SERVICE ONLY"
                  ) && (
                    <Controller
                      name="paymentAccount"
                      control={control}
                      rules={{
                        required: "Error: Payment Account is required.",
                      }}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          className="w-100 mb-4"
                          id={field.name}
                          inputRef={ref}
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                            htmlFor: `${field.name}_id`,
                          }}
                          SelectProps={{
                            displayEmpty: true,
                            SelectDisplayProps: {
                              "aria-required": true,
                            },
                          }}
                          inputProps={{
                            id: `${field.name}_id`,
                          }}
                          key={field.value}
                          {...field}
                          select
                          placeholder="Select Payment Account"
                          label="Select Payment Account"
                          variant="standard"
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                        >
                          <MenuItem value="">Select Payment Account</MenuItem>
                          {paymentAccounts?.map((paymentAccount) => (
                            <MenuItem
                              key={paymentAccount.paymentAccountID}
                              value={paymentAccount.paymentAccountID}
                            >
                              {paymentAccount.accountName}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  )}
                  {!isIndividualUser() && (
                    <Controller
                      name="filingAttorney"
                      control={control}
                      rules={{
                        required: "Error: Filing Attorney is required.",
                      }}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          className="w-100 mb-4"
                          id={field.name}
                          inputRef={ref}
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                            htmlFor: `${field.name}_id`,
                          }}
                          SelectProps={{
                            displayEmpty: true,
                            SelectDisplayProps: {
                              "aria-required": true,
                            },
                          }}
                          inputProps={{
                            id: `${field.name}_id`,
                          }}
                          defaultValue={field.value}
                          {...field}
                          select
                          placeholder="Select Filing Attorney"
                          label="Select Filing Attorney"
                          variant="standard"
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                        >
                          <MenuItem value="">Select Filing Attorney</MenuItem>
                          {attorneys?.map((attorney) => (
                            <MenuItem
                              key={attorney.attorneyID}
                              value={attorney.attorneyID}
                            >
                              {`${attorney.firstName} ${attorney.lastName}`}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  )}
                  {!isWaiverPayment &&
                    watch("filings").some(
                      (filing) => filing.filingCode !== "SERVICE ONLY"
                    ) && (
                      <Controller
                        name="partyResponsibleForFeesIndex"
                        control={control}
                        rules={{
                          required:
                            "Error: Party Responsible for Fees is required.",
                        }}
                        render={({ field: { ref, ...field }, fieldState }) => (
                          <TextField
                            className="w-100"
                            id={field.name}
                            inputRef={ref}
                            InputLabelProps={{
                              shrink: true,
                              required: true,
                              htmlFor: `${field.name}_id`,
                            }}
                            SelectProps={{
                              displayEmpty: true,
                              SelectDisplayProps: {
                                "aria-required": true,
                              },
                            }}
                            inputProps={{
                              id: `${field.name}_id`,
                            }}
                            defaultValue={field.value}
                            {...field}
                            select
                            placeholder="Party Responsible for Fees"
                            label="Party Responsible for Fees"
                            variant="standard"
                            error={!!fieldState.error}
                            helperText={
                              fieldState.error ? fieldState.error.message : null
                            }
                          >
                            <MenuItem value="">Select Party </MenuItem>
                            {parties?.map((party, index) => (
                              <MenuItem
                                key={`${party.code}-${index}`}
                                value={index + 1}
                              >
                                {party.firstName ||
                                party.lastName ||
                                party.businessName
                                  ? party.type === "person"
                                    ? `${party.firstName} ${party.lastName}`
                                    : `${party.businessName}`
                                  : party.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    )}
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{ p: 3 }} className="payment-box">
                    {feeCalculation.isLoading && (
                      <Loader className="section-loader" />
                    )}
                    <div className="payment-box-row">
                      <Grid container>
                        {watch("filingFees").map((filingFee, index) => (
                          <>
                            <Grid item xs={6}>
                              {filingFee.filingTypeName}
                            </Grid>

                            <Grid item xs={6}>
                              <div className="d-flex justify-content-between mb-3">
                                <span className="w-50">Filing Fee</span>
                                <p>
                                  ${" "}
                                  {renderAmount(
                                    isWaiverPayment ? 0 : filingFee.amount
                                  )}
                                </p>
                              </div>

                              {filingFee.optionalServices.map(
                                (optionalService) => (
                                  <div
                                    key={optionalService.efmOptionalservicCode}
                                    className="mb-3 d-flex justify-content-between"
                                  >
                                    <span className="w-50">
                                      {optionalService.efmOptionalserviceName}
                                    </span>
                                    <p>
                                      ${" "}
                                      {renderAmount(
                                        isWaiverPayment
                                          ? 0
                                          : optionalService.fee
                                      )}
                                    </p>
                                  </div>
                                )
                              )}

                              <div className="d-flex justify-content-between">
                                <p className="w-50">
                                  <strong>Total</strong>
                                </p>
                                <p>
                                  <strong>
                                    ${" "}
                                    {renderAmount(
                                      isWaiverPayment ? 0 : filingFee.total
                                    )}
                                  </strong>
                                </p>
                              </div>
                            </Grid>

                            {index + 1 < watch("filingFees").length && (
                              <Grid item xs={12} paddingY={2}>
                                <hr />
                              </Grid>
                            )}
                          </>
                        ))}
                      </Grid>
                    </div>
                    <div className="payment-box-row d-flex justify-content-between">
                      <span>Case Initiation Fee</span>
                      <p>
                        ${" "}
                        {renderAmount(
                          isWaiverPayment ? 0 : watch("caseInitiationFee")
                        )}
                      </p>
                    </div>
                    <div className="payment-box-row d-flex justify-content-between">
                      <span>I2File Fee</span>
                      <p>
                        ${" "}
                        {renderAmount(
                          isWaiverPayment ? 0 : watch("totalProviderFee")
                        )}
                      </p>
                    </div>
                    <div className="payment-box-row d-flex justify-content-between">
                      <span>Payment Service Fee</span>
                      <p>
                        ${" "}
                        {renderAmount(
                          isWaiverPayment ? 0 : watch("conveninceFee")
                        )}
                      </p>
                    </div>
                    <div className="payment-box-row d-flex justify-content-between">
                      <p>
                        <strong>Envelope Total</strong>
                      </p>
                      <p>
                        <strong>
                          ${" "}
                          {renderAmount(
                            isWaiverPayment ? 0 : watch("feeCalculationAmount")
                          )}
                        </strong>
                      </p>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </div>

            <Box display="flex">
              {Boolean(
                locations?.find(
                  (location) =>
                    location.efm_Location_Code === caseInfo.locationId
                )?.isreturndate
              ) && (
                <ReturnDate
                  isSubsequentFiling
                  hasFilingCode={watch("filings").every(
                    (filing) => filing.filingCode
                  )}
                  returnDate={watch("returnDate")}
                  outOfStateService={watch("outOfStateService")}
                  isReturnDateVerified={watch("isReturnDateVerified")}
                  returnDateErrorMessage={returnDateErrorMessage}
                  updateReturnDate={updateReturnDate}
                  getReturnDateRequest={getReturnDateRequest}
                />
              )}

              {Boolean(
                filingTypes?.find(
                  (type) =>
                    type.efmFilingtypeCode ===
                    watch(`filings.${activeRow}.filingCode`)
                )?.amountincontroversy
              ) && (
                <AdDamnum
                  control={control}
                  amountInControversy={watch("amountInControversy")}
                  updateAmountInControversy={updateAmountInControversy}
                />
              )}
            </Box>

            <div className="disclaimer-note note-line error-bg text-center">
              <p className="f-w-bold">
                <InfoIcon /> Disclaimer : I2file does not process or directly
                collect payment. This payment is processed by state efileIL
                vendor (Tylertech).
              </p>
            </div>

            <Box pt={4} pb={2} display="flex" justifyContent="center">
              <Button
                className="btn button-outline-secondary me-3"
                variant="contained"
                type="button"
                onClick={onPrevious}
              >
                Previous
              </Button>
              <Button
                className="btn primary-button me-3"
                variant="contained"
                type="button"
                disabled={componentIsLoading}
                onClick={onCreateDraft}
              >
                Save As Draft
              </Button>
              <Button
                className="btn primary-button"
                variant="contained"
                type="submit"
                disabled={componentIsLoading || feeCalculation.isLoading}
              >
                Next
              </Button>
            </Box>
          </form>
        </div>
      </div>
    </Box>
  );
};

export default Filings;
