import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_ATTACHED_CASES_LIST } from "../../URL";

interface IFilter {
  locationCounty?: string;
  serviceContactId?: string;
}

interface IAttachedCaseResponse {
  caseNumber: string;
  description: string;
  caseType?: string;
  jurisdiction?: string;
  caseTrackingId?: string;
  caseCategory?: string;
  partyFirstName?: string;
  partyLastName?: string;
  partyMiddleName?: string;
  partyAddress?: string;
  partyRoleCode?: string;
  judge?: string;
  organIdValue?: string;
}

const getAttachedCaseList = async (filters: IFilter) => {
  if (!filters.serviceContactId) return;

  const response = await http.post<IAttachedCaseResponse[]>(
    GET_ATTACHED_CASES_LIST,
    filters
  );
  return response.data;
};

const useAttachededCaseList = (filters: IFilter) =>
  useQuery<IAttachedCaseResponse[] | undefined>({
    queryKey: ["getAttachedCaseList"],
    queryFn: () => getAttachedCaseList(filters),
  });
export default useAttachededCaseList;
