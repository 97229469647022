import { useCallback, useMemo, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import useSupport from "../../../apis/help/support";

const FAQs = () => {
  const { data: supportData } = useSupport();
  const [activeQuestion, setActiveQuestion] = useState<string | boolean>(false);

  const onAccordianChange = useCallback((questionId: string) => {
    return (event: React.SyntheticEvent, newExpanded: boolean) => {
      setActiveQuestion(newExpanded ? questionId : false);
    };
  }, []);

  const filteredSupport = useMemo(() => {
    return supportData?.filter((supportsdata) => supportsdata.categoryId === 1);
  }, [supportData]);

  const sanitizeHtml = useCallback((htmlString: string) => {
    const sanitizedHtml = htmlString.replace(/\\+/g, "/");

    return {
      __html: sanitizedHtml,
    };
  }, []);

  return (
    <div className="vertical-tabs-col-right">
      {filteredSupport?.map((supportdata, index) => (
        <div key={supportdata.questionId} className="vertical-tabs-dtl">
          <Accordion
            className="accordion-slide"
            slotProps={{ transition: { timeout: 0 } }}
            expanded={activeQuestion === supportdata.questionId}
            onChange={onAccordianChange(supportdata.questionId)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="ExpandMoreIcon" />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
              className="accordion-title"
            >
              <h4>
                <div
                  dangerouslySetInnerHTML={sanitizeHtml(
                    supportdata.questionText
                  )}
                />
              </h4>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div
                  dangerouslySetInnerHTML={sanitizeHtml(supportdata.answerText)}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  );
};

export default FAQs;
