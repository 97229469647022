import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_TRANSACTION_DETAILS } from "../../URL";
import { IAxisErrorResponse, ITransactionDetails } from "../../../interfaces";

interface IFilter {
  efmUserId: string;
  efmLocationId: string;
  efmEnvelopeId: string;
  filingId: string;
  efmFilingType: string;
}

const getTransactionDetails = async (filters: IFilter) => {
  const response = await http.post<ITransactionDetails>(
    GET_TRANSACTION_DETAILS,
    {
      ...filters,
    }
  );
  return response.data;
};

const useTransactionDetails = (filters: IFilter) =>
  useQuery<ITransactionDetails, IAxisErrorResponse>({
    queryKey: ["transactions", filters.efmEnvelopeId],
    queryFn: () => getTransactionDetails(filters),
  });

export default useTransactionDetails;
