import axios from "axios";
import CryptoJS from "crypto-js";

import { clearLocalStorage, getAccessToken } from "./utills";

const http = axios.create({
  // baseURL: process.env.REACT_APP_BACKEND_API_URL,
  timeout: 3500000,
  headers: {
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use((config) => {
  const token = getAccessToken();
  if (
    token &&
    !config.url?.includes("login") &&
    !config.url?.includes("readAdminUserNotificationFiles")
  )
    config.headers.Authorization = token ? `Bearer ${token}` : "";

  try {
    if (
      config.data &&
      config.headers["Content-Type"] === "application/json" &&
      process.env.REACT_APP_ALLOW_ENCRYPTION === "true"
    ) {
      // console.log("sk...", "request", config.url, config.data);

      const iv = CryptoJS.lib.WordArray.random(128 / 8).toString(
        CryptoJS.enc.Hex
      );
      const salt = CryptoJS.lib.WordArray.random(128 / 8).toString(
        CryptoJS.enc.Hex
      );
      const key = CryptoJS.PBKDF2(
        "eFileIL.I2File",
        CryptoJS.enc.Hex.parse(salt),
        {
          iterations: 1000,
          keySize: 128 / 32,
        }
      );

      const encrypedData = CryptoJS.AES.encrypt(
        JSON.stringify(config.data),
        key,
        {
          iv: CryptoJS.enc.Hex.parse(iv),
        }
      );

      config.data = {
        i2f: salt,
        i2flite: iv,
        //@ts-ignore
        i2fliteRequest: encrypedData.ciphertext.toString(CryptoJS.enc.Base64),
      };
    }
  } catch (ex) {
    console.log("ex", ex);
  }

  return config;
});

http.interceptors.response.use(
  (res) => {
    const responseData = res.data?.responseObj || res.data;
    if (responseData?.i2f) {
      // console.log("sk...111", "response", res.config.url, res.data);

      const key = CryptoJS.PBKDF2(
        "eFileIL.I2File",
        CryptoJS.enc.Hex.parse(responseData.i2f),
        {
          iterations: 1000,
          keySize: 128 / 32,
        }
      );

      //@ts-ignore
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(responseData.i2fliteResponse),
      });

      const data = CryptoJS.AES.decrypt(cipherParams, key, {
        iv: CryptoJS.enc.Hex.parse(responseData.i2flite),
      }).toString(CryptoJS.enc.Utf8);

      try {
        res.data = JSON.parse(
          data.replaceAll("â€“", "-").replaceAll("â€™", "'")
        );
      } catch {
        res.data = data;
      }

      // console.log("sk...", "response", res.config.url, res.data);
    }

    return res;
  },
  (res) => {
    if (res.code === "ERR_NETWORK") {
      res.response = {
        data: {
          userMessage: "Service unavailable. Please try again later",
        },
      };
    }
    if (
      (res?.response?.status === 401 || res?.response?.status === 403) &&
      window.location.hash !== "" &&
      window.location.hash !== "#/support" &&
      window.location.hash !== "#/#aboutus" &&
      window.location.hash !== "#/#features"
    ) {
      getAccessToken() &&
        alert("Your Session has been Expired,Please Login Again");
      clearLocalStorage();
      window.location.assign("/");
    }

    if (res?.response?.data?.i2f) {
      const key = CryptoJS.PBKDF2(
        "eFileIL.I2File",
        CryptoJS.enc.Hex.parse(res.response.data.i2f),
        {
          iterations: 1000,
          keySize: 128 / 32,
        }
      );

      //@ts-ignore
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(
          res.response.data.i2fliteResponse
        ),
      });

      const data = CryptoJS.AES.decrypt(cipherParams, key, {
        iv: CryptoJS.enc.Hex.parse(res.response.data.i2flite),
      }).toString(CryptoJS.enc.Utf8);

      try {
        res.response.data = JSON.parse(data);
      } catch {
        res.response.data = data;
      }

      // console.log("sk...", "response", res.config.url, res.response.data);
    }

    throw res;
  }
);

export default http;
