import { FC, useCallback, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import CloseIcon from "@mui/icons-material/Close";

import useForgotPassword from "../../../../apis/auth/useForgotPassword/useForgotPassword";
import { NotificationContext } from "../../../../context/NotificationContext";

interface ForgotPasswordModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
}

const ForgotPasswordModal: FC<ForgotPasswordModalProps> = ({
  isModalOpen,
  closeModal,
}) => {
  const forgotPassword = useForgotPassword();
  const { createNotification } = useContext(NotificationContext);
  const { control, handleSubmit } = useForm({
    defaultValues: { emailAddress: "" },
  });

  const onSubmit = useCallback(
    (data: { emailAddress: string }) => {
      forgotPassword.mutate(data.emailAddress);
    },
    [forgotPassword]
  );

  useEffect(() => {
    if (forgotPassword.isSuccess) {
      createNotification({
        message: "Password reset link has been sent",
        type: "success",
        title: "Forgot Password Notification",
      });
    }
  }, [forgotPassword.isSuccess, createNotification]);

  useEffect(() => {
    if (forgotPassword.isError) {
      createNotification({
        message:
          forgotPassword.error.response?.data?.userMessage ||
          "An Error occurred while sending forgot password link",
        type: "error",
        title: "Forgot Password Notification",
      });
    }
    // eslint-disable-next-line
  }, [forgotPassword.isError]);

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle
        className="firm-account-title red-pastel"
        sx={{ m: 0, p: 2 }}
        id="scroll-dialog-title"
      >
        Forgot Password
        <IconButton
          onClick={closeModal}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <DialogContent>
          <div className="filed-slide mb-4">
            <Controller
              name="emailAddress"
              control={control}
              rules={{
                required: "Error: Email is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address.",
                },
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  autoFocus
                  inputRef={ref}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Your eFileIL email address"
                  label="eFileIL email address"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MarkunreadIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          <DialogContentText>
            A Link for resetting your password will be sent to the email address
            associated with your eFile IL Account.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            className="btn primary-button"
            type="submit"
            disabled={forgotPassword.isLoading}
          >
            {forgotPassword.isLoading ? (
              <CircularProgress
                sx={{
                  height: "25px !important",
                  width: "25px !important",
                  "--CircularProgress-size": "8px",
                  "--CircularProgress-trackThickness": "1px",
                  "--CircularProgress-progressThickness": "1px",
                }}
                color="inherit"
              />
            ) : (
              "Ok"
            )}
          </IconButton>
          <Button
            type="button"
            variant="contained"
            className="btn button-outline-secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ForgotPasswordModal;
