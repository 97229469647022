import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import styled from "@mui/material/styles/styled";
import IconButton from "@mui/material/IconButton";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import SearchCaseForm from "./searchCaseForm";
import Loader from "../../../components/loader";
import GridTable from "../../../components/grid";
import IndexedCaseModal from "./indexedCaseModal";
import CaptchaVerification from "./captchaVerification";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ManageServiceModalDialog from "../manageServiceContact/ManageServiceModalDialog";
import CaseDetailsDialog from "../../SearchCases/SearchcaseDialog";
import AdvancedSearchModalDialog from "./advancedSearch/AdvancedSearchModalDialog";
import useLocations from "../../../apis/common/useLocations";
import useSearchCase from "../../../apis/searchCase";
import useGetCase from "../../../apis/searchCase/getCase";
import { NotificationContext } from "../../../context/NotificationContext";
import {
  ISelectedSearchCase,
  ICaseResponse,
  IGetCaseReq,
  ISearchFilter,
} from "../../../interfaces";
import service_contact_img from "../../../assets/images/manage-service-contact.svg";
import view_case_img from "../../../assets/images/view_case.svg";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));

interface ISearchCase {
  selectedCase?: ICaseResponse;
  updateCase: (caseResponse: ICaseResponse) => void;
  pageTitle: string;
}

const SearchCase: FC<ISearchCase> = ({
  selectedCase: selectedCaseProps,
  updateCase,
  pageTitle,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isAdvancedSearchModalOpen, setIsAdvancedSearchModalOpen] =
    useState(false);
  const [isManageServiceModalOpen, setIsManageServiceModalOpen] =
    useState(false);
  const [selectedCase, setSelectedCase] = useState<ISelectedSearchCase | null>(
    null
  );
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedEnvelop, setSelectedEnvelop] = useState<IGetCaseReq | null>(
    null
  );
  const [showIndexedCaseModal, setShowIndexedCaseModal] = useState(false);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);
  const [searchCaseCount, setSearchCaseCount] = useState(0);
  const [filterData, setFilterData] = useState<ISearchFilter>();

  const { data: locations, isLoading: locationLoading } = useLocations();
  const searchCase = useSearchCase();
  const getCase = useGetCase();
  const { createNotification } = useContext(NotificationContext);

  const onSearch = useCallback(
    (formData: ISearchFilter) => {
      if (searchCaseCount >= 10) {
        setShowCaptchaModal(true);
        setFilterData(formData);
        return;
      }

      searchCase.mutate({
        location: formData.locationName || "",
        caseNumber: formData.caseNumber || "",
        countyId: formData.locationName || "",
        searchBy: "Docket",
        firstName: "",
        middleName: "",
        surname: "",
        businessName: "",
        caseTypeFilter: "",
        sortByFilter: "caseDocketID",
      });
    },
    [searchCaseCount, searchCase]
  );

  const onSearchWithCaptcha = useCallback(
    (captchaValue: string) => {
      if (!filterData) return;

      searchCase.mutate({
        location: filterData.locationName || "",
        caseNumber: filterData.caseNumber || "",
        countyId: filterData.locationName || "",
        searchBy: "Docket",
        firstName: "",
        middleName: "",
        surname: "",
        businessName: "",
        caseTypeFilter: "",
        sortByFilter: "caseDocketID",
        captchaValue,
      });

      setShowCaptchaModal(false);
    },
    [filterData, searchCase]
  );

  const goToDashboard = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  const closeIndexedCaseModal = useCallback(() => {
    setShowIndexedCaseModal(false);
  }, []);

  const closeCaptchaModal = useCallback(() => {
    setShowCaptchaModal(false);
  }, []);

  const onAdvancedSearch = useCallback(() => {
    setIsAdvancedSearchModalOpen(true);
  }, []);

  const onAdvancedSearchModalClose = useCallback(() => {
    setIsAdvancedSearchModalOpen(false);
  }, []);

  const onManageServiceModalOpen = useCallback(
    (selectedCase: ISelectedSearchCase) => {
      setIsManageServiceModalOpen(true);
      setSelectedCase({
        jurisdiction: selectedCase.jurisdiction,
        caseTrackingId: selectedCase.caseTrackingId,
      });

      getCase.mutate({
        countyId: selectedCase?.jurisdiction,
        caseTrackingId: selectedCase?.caseTrackingId,
      });
    },
    [getCase]
  );

  const onManageServiceModalClose = useCallback(() => {
    setIsManageServiceModalOpen(false);
    setSelectedCase(null);
  }, []);

  const openDetailsModal = useCallback(
    (row: { jurisdiction: string; caseTrackingId: string }) => {
      setSelectedEnvelop({
        jurisdiction: row.jurisdiction,
        caseTrackingId: row.caseTrackingId,
      });
      setIsDetailsModalVisible(true);
    },
    []
  );

  const onSubsequentFiling = useCallback(
    (selectedCase: ISelectedSearchCase) => {
      setSelectedCase({
        jurisdiction: selectedCase.jurisdiction,
        caseTrackingId: selectedCase.caseTrackingId,
      });

      getCase.mutate({
        countyId: selectedCase?.jurisdiction,
        caseTrackingId: selectedCase?.caseTrackingId,
      });
    },
    [getCase]
  );

  const closeDetailsModal = useCallback(() => {
    setIsDetailsModalVisible(false);
    setSelectedEnvelop(null);
  }, []);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "caseNumber",
        headerName: "Case Number",
        flex: 1,
        minWidth: 100,
        filterable: false,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span
              id={params.row.caseNumber}
              title={`Case ${params.row.caseNumber}`}
            >
              {params.row.caseNumber}
            </span>
          );
        },
      },
      {
        field: "jurisdiction",
        headerName: "Jurisdiction",
        flex: 1,
        minWidth: 100,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return locations?.find(
            (locationData) =>
              locationData.efm_Location_Code === params.row.jurisdiction
          )?.efm_Location_Name;
        },
      },
      {
        field: "description",
        headerName: "Description ",
        flex: 1,
        minWidth: 100,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
      },
      {
        field: "caseCategoryName",
        headerName: "Case Category",
        flex: 1,
        minWidth: 100,
        filterable: false,
        disableColumnMenu: true,
        sortable: true,
      },
      {
        field: "caseTypeName",
        headerName: "Case Type",
        flex: 1,
        minWidth: 100,
        filterable: false,
        disableColumnMenu: true,
        sortable: true,
      },
      {
        field: "action",
        headerName: "Action ",
        flex: 1,
        minWidth: 50,
        filterable: false,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <div className="dataGridactionIcons">
              {pageTitle === "existing" ? (
                <LightTooltip
                  title="Start a new subsequent filing for this case"
                  placement="top"
                >
                  <IconButton
                    aria-label="Start a new subsequent filing for this case"
                    aria-describedby={params.row.caseNumber}
                    disableRipple={false}
                    onClick={() => {
                      onSubsequentFiling(params.row);
                    }}
                  >
                    <img
                      src={view_case_img}
                      width={30}
                      height={30}
                      alt="Start a new subsequent filing for this case"
                    />
                  </IconButton>
                </LightTooltip>
              ) : (
                <LightTooltip title="View Case Details" placement="top">
                  <IconButton
                    aria-label="View Case Details"
                    aria-describedby={params.row.caseNumber}
                    disableRipple={false}
                    onClick={() => {
                      openDetailsModal(params.row);
                    }}
                  >
                    <VisibilityOutlinedIcon />
                  </IconButton>
                </LightTooltip>
              )}
              <LightTooltip title="Manage Service Contacts" placement="top">
                <IconButton
                  aria-label="Manage Service Contacts"
                  aria-describedby={params.row.caseNumber}
                  className="ms-2"
                  onClick={() => {
                    onManageServiceModalOpen(params.row);
                  }}
                >
                  <img
                    src={service_contact_img}
                    width={30}
                    height={30}
                    alt="Manage Service Contact"
                  />
                </IconButton>
              </LightTooltip>
            </div>
          );
        },
      },
    ],
    [
      locations,
      pageTitle,
      onManageServiceModalOpen,
      onSubsequentFiling,
      openDetailsModal,
    ]
  );

  useEffect(() => {
    const locationCode =
      location.state?.generalCase?.caseCourt?.organizationIdentification
        ?.identificationID?.efmLocationCode;
    const caseNumber = location.state?.generalCase?.caseTrackingID;

    if (locationCode && caseNumber) {
      onSubsequentFiling({
        jurisdiction: locationCode,
        caseTrackingId: caseNumber,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getCase.isSuccess && getCase.data && !isManageServiceModalOpen) {
      updateCase(getCase.data);
    }
    // eslint-disable-next-line
  }, [getCase.isSuccess]);

  useEffect(() => {
    if (searchCase.isSuccess) {
      const locationObj = locations?.find(
        (location) =>
          location.efm_Location_Code === searchCase.variables?.location
      );
      if (
        locationObj?.allowFilingIntoOnIndexeIntegerse &&
        searchCase.data.tylerMessageCode === "136"
      ) {
        setShowIndexedCaseModal(true);
      }

      setSearchCaseCount(searchCase.data.searchCaseCount);
    }
    // eslint-disable-next-line
  }, [searchCase.isSuccess]);

  useEffect(() => {
    if (searchCase.isError) {
      createNotification({
        title: "Existing Case Notification",
        type: "error",
        message:
          searchCase.error.response?.data?.userMessage ||
          "Something went wrong while searching",
      });

      // setSearchCaseCount(searchCase.error.response?.data?.searchCaseCount || 0);
    }
    // eslint-disable-next-line
  }, [searchCase.isError]);

  useEffect(() => {
    if (getCase.isError) {
      createNotification({
        title: "Existing Case Notification",
        type: "error",
        message:
          getCase.error.response?.data?.userMessage ||
          "Unable to load the information for this case.",
      });
    }
    // eslint-disable-next-line
  }, [getCase.isError]);

  return (
    <>
      <div className="d-flex justify-content-between search-case-title mb-4">
        {pageTitle === "existing" ? (
          <h3 className="dash-title-h3 ">Search Case</h3>
        ) : (
          <h2 className="dash-title-h2 mb-4">Search Case</h2>
        )}

        {/* {pageTitle == "existing" && (
          <a
            className="btn btn-yellow-bg d-inline-block cursor-pointer"
            onClick={onAdvancedSearch}
          >
            Advanced Search
            <FontAwesomeIcon className="ms-2" icon={faMagnifyingGlassPlus} />
          </a>
        )} */}
      </div>

      <SearchCaseForm
        pageTitle={pageTitle}
        locations={locations}
        onAdvancedSearch={onAdvancedSearch}
        onSearch={onSearch}
      />

      <div className="datagrid-table-wrap mt-5">
        <div className="datagrid-top mb-3 filing-history-grid-top">
          <div className="f-w-bold f-size18">
            Total No of Records :{" "}
            <span className="round-number">
              {searchCase.data?.responseObject?.length || 0}
            </span>
          </div>
        </div>
        <div className="themeDataTable" style={{ width: '100%', height: '400px', overflowY: 'auto' }}>
          <GridTable
            columns={columns}
            rows={searchCase.data?.responseObject}
            // isLoading={searchCase.isLoading}
            hideFooter={true}
            getRowId={(row) => row.caseNumber}
          />
        </div>
      </div>

      {selectedCaseProps && (
        <Grid item xs={12} paddingTop={2}>
          <Box justifyContent="center" width="100%" display="flex">
            <Button
              className="btn button-outline-secondary me-3"
              variant="contained"
              type="button"
              onClick={goToDashboard}
            >
              Exit
            </Button>
            <Button
              className="btn primary-button"
              variant="contained"
              type="submit"
              onClick={() => {
                updateCase(selectedCaseProps);
              }}
            >
              Next
            </Button>
          </Box>
        </Grid>
      )}

      {isAdvancedSearchModalOpen && (
        <AdvancedSearchModalDialog
          isOpen={isAdvancedSearchModalOpen}
          onClose={onAdvancedSearchModalClose}
        />
      )}

      {(getCase.isLoading || locationLoading || searchCase.isLoading) && (
        <Loader />
      )}

      {isManageServiceModalOpen && getCase.data && (
        <ManageServiceModalDialog
          isOpen={isManageServiceModalOpen}
          onClose={onManageServiceModalClose}
          countyId={selectedCase?.jurisdiction}
          caseTrackingId={selectedCase?.caseTrackingId}
          caseDetails={getCase.data}
        />
      )}

      {isDetailsModalVisible && selectedEnvelop && (
        <CaseDetailsDialog
          countyId={selectedEnvelop.jurisdiction}
          caseTrackingId={selectedEnvelop.caseTrackingId}
          isOpen={isDetailsModalVisible}
          onClose={closeDetailsModal}
        />
      )}

      {showIndexedCaseModal && searchCase.variables && (
        <IndexedCaseModal
          isOpen
          location={searchCase.variables.location}
          caseNumber={searchCase.variables.caseNumber}
          onClose={closeIndexedCaseModal}
        />
      )}

      {showCaptchaModal && (
        <CaptchaVerification
          isOpen={showCaptchaModal}
          onClose={closeCaptchaModal}
          onVerify={onSearchWithCaptcha}
        />
      )}
    </>
  );
};

export default SearchCase;
