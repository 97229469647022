import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { GET_ENVELOPE_PAYMENTS } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface IPayment {
  userId: string;
  fees: string;
  convenienceFee: string;
  location: string;
  efmLocationId: string;
  efmEnvelopeId: string;
  caseNumber: string;
  efmFilingStatus: string;
  efmSyncDate: string;
  amount: string;
  efmFilingId: string;
  startDate: string;
  endDate: string;
  efmFirmId: string;
  fullName: string;
  filterFilingstatus: string;
  filterPaymentStatus: string;
  isI2FAdminUser: string;
  efmFilingCode: string;
  category: string;
  efmFilingTypeName: string;
  i2FileFeeStatus: string;
  i2FileFeeReceivedStatus: string;
  efmUserId: string;
}

interface IFilters {
  efmEnvelopeId: string;
  efmFirmId: string;
  startDate: string;
  endDate: string;
  efmLocationId: string;
  filterFilingstatus: string;
  filterPaymentStatus: string;
  isI2FAdminUser: string;
  i2fileFeeStatus: string;
  i2fileFeeReceivedStatus: string;
  efmUserId: string;
  userId: string;
}

const getEnvelopePayments = async (filters: IFilters) => {
  const response = await http.post<IPayment[]>(GET_ENVELOPE_PAYMENTS, {
    ...filters,
  });
  return response.data;
};

const useEnvelopePayments = () =>
  useMutation<IPayment[], IAxisErrorResponse, IFilters>({
    mutationKey: ["envelope-payments"],
    mutationFn: getEnvelopePayments,
  });

export default useEnvelopePayments;
