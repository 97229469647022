import { FC, useCallback, useContext, useEffect } from "react";

import useDeletePendingRequestByEmail from "../../../apis/deletePendingRequestByEmail";
import DeleteDialog from "../../../components/DeleteDialog";
import { NotificationContext } from "../../../context/NotificationContext";

interface IPendingRequests {
  isOpen: boolean;
  email?: string;
  onClose: (shouldUpdateGrid?: boolean) => void;
}

const DeletePendingRequest: FC<IPendingRequests> = ({
  isOpen,
  email,
  onClose,
}) => {
  const deletePendingRequest = useDeletePendingRequestByEmail();
  const { createNotification } = useContext(NotificationContext);

  const onDelete = useCallback(() => {
    if (email) {
      deletePendingRequest.mutate(email);
    } else {
      onClose();
    }
  }, [email, deletePendingRequest, onClose]);

  useEffect(() => {
    if (deletePendingRequest.isSuccess) {
      createNotification({
        title: "Pending Request Notification",
        type: "success",
        message: "Selected Pending Request removed",
      });
      onClose(true);
    }
    // eslint-disable-next-line
  }, [deletePendingRequest.isSuccess, createNotification]);

  useEffect(() => {
    if (deletePendingRequest.isError) {
      createNotification({
        title: "Pending Request Notification",
        type: "error",
        message:
          deletePendingRequest.error.response?.data?.userMessage ||
          "Something went wrong while Removing Pending Request",
      });
    }
    // eslint-disable-next-line
  }, [deletePendingRequest.isError, createNotification]);

  return (
    <DeleteDialog
      entity="PendingRequest"
      isOpen={isOpen}
      isLoading={deletePendingRequest.isLoading}
      onClose={onClose.bind(this, false)}
      onDelete={onDelete}
    />
  );
};

export default DeletePendingRequest;
