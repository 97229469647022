import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_PAYMENT_ACCOUNTS } from "../../URL";

interface IPaymentAccount {
  paymentAccountID: string;
  accountName: string;
  paymentAccountTypeCode: string;
}

const getPaymentAccounts = async () => {
  const response = await http.post<{ paymentAccountList: IPaymentAccount[] }>(
    GET_PAYMENT_ACCOUNTS
  );

  return response.data?.paymentAccountList || [];
};

const usePaymentAccounts = () =>
  useQuery<IPaymentAccount[]>({
    queryKey: ["payment-accounts"],
    queryFn: getPaymentAccounts,
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default usePaymentAccounts;
