import { FC, useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import Dialog from "../../../components/dialog/Dialog";
import { Controller, useForm } from "react-hook-form";

import { IReservedCourtDate, IScheduleHearing } from "../../../interfaces";
import useRequestCourtDate from "../../../apis/filingHistory/HearingDetails/useRequestCourtDate";

interface CountyHearingDateProps {
  isOpen: boolean;
  onClose: (shouldUpdateGrid?: boolean) => void;
  envelopeId?: string;
  locationCode?: string;
  reserveCourtList?: IReservedCourtDate;
}

interface TimeOption {
  startTime: string;
  endTime: string;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CountyHearingDate: FC<CountyHearingDateProps> = ({
  isOpen,
  onClose,
  envelopeId,
  locationCode,
  reserveCourtList,
}) => {
  const [hearingDateOptions, setHearingDateOptions] = useState<string[]>([]);

  const [hearingTimeOptions, setHearingTimeOptions] = useState<TimeOption[]>(
    []
  );

  useEffect(() => {
    if (reserveCourtList !== null) {
      const formattedDate = reserveCourtList?.courtSchedule.map(
        (courtStartDate) =>
          format(
            new Date(courtStartDate.startDate.replace("Z", "")),
            "MM/dd/yyyy",
            { timeZone: "UTC" } as any
          )
      );

      setHearingDateOptions(formattedDate || []);
    } else {
      setHearingDateOptions([]);
    }
  }, [reserveCourtList]);

  useEffect(() => {
    if (reserveCourtList !== null) {
      const formattedTimes = reserveCourtList?.courtSchedule.map((time) => ({
        startTime: new Date(time.startDate.replace("Z", "")).toLocaleTimeString(
          [],
          { hour: "2-digit", minute: "2-digit" }
        ),
        endTime: new Date(time.endDate.replace("Z", "")).toLocaleTimeString(
          [],
          { hour: "2-digit", minute: "2-digit" }
        ),
      }));
      setHearingTimeOptions(formattedTimes || []);
    } else {
      setHearingTimeOptions([]);
    }
  }, [reserveCourtList]);

  const { control, watch, handleSubmit } = useForm<IScheduleHearing>();

  const requestcourtDate = useRequestCourtDate();

  const onSubmitHearingDetails = useCallback(
    (formData: IScheduleHearing) => {
      requestcourtDate.mutate({
        envelopeId: envelopeId || "",
        locationCode: locationCode || "",
        scheduledStartDate: formData.scheduledHearingDate,
        scheduledEndDate: formData.scheduledHearingDate,
        scheduledEndTime: formData.scheduledHearingTime,
      });
    },
    [requestcourtDate, envelopeId, locationCode]
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose.bind(this, true)}
      title="Request Hearning Date"
      maxWidth="md"
    >
      <form
        className="firm-info-main"
        onSubmit={handleSubmit(onSubmitHearingDetails)}
      >
        <Grid container spacing={2}>
          <Grid className="" item xs={6}>
            <Item className="remove-item-css">
              <Controller
                name="scheduledHearingDate"
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    inputProps={{
                      "aria-required": true,
                      id: `${field.name}_id`,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                      htmlFor: `${field.name}_id`,
                    }}
                    {...field}
                    select
                    SelectProps={{
                      SelectDisplayProps: {
                        "aria-required": true,
                      },
                    }}
                    placeholder="Select Hearing Date"
                    label="Select a Hearing Date(Optional)"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    {hearingDateOptions.map((date) => (
                      <MenuItem key={date} value={date}>
                        {date}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Item>
          </Grid>

          <Grid className="" item xs={6}>
            <Item className="remove-item-css">
              <Controller
                name="scheduledHearingTime"
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    inputProps={{
                      "aria-required": true,
                      id: `${field.name}_id`,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                      htmlFor: `${field.name}_id`,
                    }}
                    {...field}
                    select
                    SelectProps={{
                      SelectDisplayProps: {
                        "aria-required": true,
                      },
                    }}
                    placeholder="Select Hearing Time"
                    label="Select a Hearing Time"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    {hearingTimeOptions.map((time) => (
                      <MenuItem
                        key={`${time.startTime}-${time.endTime}`}
                        value={`${time.startTime}-${time.endTime}`}
                      >
                        {`${time.startTime} - ${time.endTime}`}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Item>
          </Grid>
        </Grid>

        <div className="mt-3">
          <div className="d-flex align-items-start mb-2">
            <InfoIcon className="mt-1 theme-blue" sx={{ fontSize: 20 }} />
            <Typography className="ps-1 theme-blue">
              If you want to schedule a Hearing Date and Time, please select a
              date and time above and click 'Save' below.
            </Typography>
          </div>

          <div className="d-flex align-items-start">
            <WarningIcon className="mt-1 theme-mustard" sx={{ fontSize: 20 }} />
            <Typography className="ps-1 theme-mustard">
              If you do not want to schedule a Hearing Date and Time, click 'Do
              Not Schedule' below. You will be unable to schedule a hearing
              later if you do not schedule one now.
            </Typography>
          </div>
        </div>

        <div>
          {!(
            hearingDateOptions.length > 0 && hearingTimeOptions.length > 0
          ) && (
            <Alert severity="error">
              ERROR : No Scheduled Date and Time Available at this moment.Please
              try again later.
            </Alert>
          )}
        </div>

        <div className="mt-3 text-right">
          <Button
            className="btn button-outline-secondary me-2"
            variant="contained"
            type="button"
            onClick={onClose.bind(this, true)}
          >
            Do Not Schedule
          </Button>
          <Button
            className="btn primary-button"
            variant="contained"
            type="submit"
            disabled={
              !Boolean(
                watch("scheduledHearingDate") || watch("scheduledHearingTime")
              )
            }
          >
            Save
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default CountyHearingDate;
