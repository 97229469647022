import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import deepOrange from "@mui/material/colors/deepOrange";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import Tooltip from "@mui/material/Tooltip";

import useProfile from "../../../apis/auth/useProfile";
import bar_icon from "../../../assets/images/bar-icon.svg";
import bar_icon_right from "../../../assets/images/bar-icon-right.svg";
import queryClient from "../../../queryClient";
import {
  clearLocalStorage,
  getIsAdmin,
  isIndividualUser,
  isLinked,
} from "../../../utills";
import i2file_request_check from "../../../assets/images/Brand-logo-mini.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import useI2FLink from "../../../apis/auth/useI2FLink";
import Loader from "../../loader/Loader";

interface HeaderProps {
  onToggleSideBar: () => void;
}

const Header: FC<HeaderProps> = ({ onToggleSideBar }) => {
  const profile = useProfile();
  const isAdmin = getIsAdmin();
  const userMenuRef = useRef<HTMLDivElement>(null);
  const [showUserMenu, setShowUserMenu] = useState(false);

  const i2fLink = useI2FLink();

  const getI2FLink = useCallback(async () => {
    if (profile.data) {
      i2fLink.mutateAsync({
        liteUser: profile.data.email,
        defaultToSupremeCourtCounty: false,
        isUserProfileLinked: isLinked(),
        liteUserFirstName: profile.data.firstName,
        liteUserLastName: profile.data.lastName,
        liteUserRole: isIndividualUser() ? "prose" : "Attorney",
      });
    }
  }, [profile.data, i2fLink]);

  const initials = useMemo(
    () =>
      `${profile.data?.firstName?.[0] || ""}${
        profile.data?.lastName?.[0] || ""
      }`,
    [profile.data]
  );

  const openUserMenu = useCallback(() => {
    setShowUserMenu(true);
  }, []);

  const onLogout = useCallback(async () => {
    // await logout.mutateAsync();
    queryClient.clear();
    clearLocalStorage();
  }, []);

  const keyDownListener = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setShowUserMenu(false);
    }
  }, []);

  const mouseDownListener = useCallback((event: any) => {
    if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
      setShowUserMenu(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", mouseDownListener);
    document.addEventListener("keydown", keyDownListener);

    return () => {
      document.removeEventListener("mousedown", mouseDownListener);
      document.removeEventListener("keydown", keyDownListener);
    };
  }, [keyDownListener, mouseDownListener]);

  if (isAdmin === "admin") {
    return (
      <div className="main_content-top d-flex">
        {i2fLink.isLoading && <Loader />}
        <div className="toggle-col">
          <div className="toggle-bar toggle-bar-left d-inline-block vertical-align-middle">
            <img src={bar_icon} alt="I2File" onClick={onToggleSideBar} />
          </div>
          <div className="toggle-bar toggle-bar-right d-inline-block vertical-align-middle">
            <img src={bar_icon_right} alt="I2File" onClick={onToggleSideBar} />
          </div>
        </div>

        {/* <a href="#main" className="skip-to-main-content-link">
          Skip to main content
        </a> */}

        {/* <div className="main_content-top-note d-inline-block ">
          <p>
            <a href="#">Click here</a> to file Criminal, DUI and Traffic filings
            (for DuPage, DeKalb, Kendall and McHenry jurisdictions)
          </p>
        </div> */}
        <ul className="profile-right">
          <li className="nav-profile">
            <span
              role="button"
              className="d-inline-block cursor-pointer"
              onClick={openUserMenu}
              onKeyDown={(e: React.KeyboardEvent) => {
                e.key === "Enter" && openUserMenu();
              }}
              tabIndex={0}
            >
              <div className="profile-photo">
                <Tooltip
                  title={`${profile.data?.firstName || ""} ${
                    profile.data?.lastName || ""
                  }`}
                >
                  <Avatar sx={{ bgcolor: deepOrange[900] }}>{initials}</Avatar>
                </Tooltip>
                <KeyboardArrowDownIcon className="profile-downarrow ms-2" />
              </div>
            </span>

            {showUserMenu && (
              <div ref={userMenuRef} className="profile-options">
                <ul>
                  {/* <li>
                    <Link to="/myprofile">Edit Profile</Link>
                  </li>
                  <li>
                    <Link to="/changePassword">Change password</Link>
                  </li> */}
                  <li>
                    <a
                      href={isAdmin === "admin" ? "/#/admin" : "/"}
                      role="button"
                      className="cursor-pointer"
                      onClick={onLogout}
                    >
                      <ExitToAppOutlinedIcon className="me-2 v-align" />
                      <span className="v-align">Logout</span>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className="main_content-top">
      <div className=" d-flex">
        {i2fLink.isLoading && <Loader />}
        <div className="toggle-col">
          <div className="toggle-bar toggle-bar-left d-inline-block vertical-align-middle">
            <img src={bar_icon} alt="I2File" onClick={onToggleSideBar} />
          </div>
          <div className="toggle-bar toggle-bar-right d-inline-block vertical-align-middle">
            <img src={bar_icon_right} alt="I2File" onClick={onToggleSideBar} />
          </div>
        </div>

        <div>
          <div className="main_content-top-note pt-3">
            {isLinked() ? (
              <>
                <img
                  height={30}
                  className="me-2 cursor-pointer"
                  src={i2file_request_check}
                  alt="I2File"
                  onClick={getI2FLink}
                />
                <p>
                  <span
                    className="cursor-pointer text-underline"
                    role="button"
                    onClick={getI2FLink}
                  >
                    Click here
                  </span>{" "}
                  to file Criminal, DUI and Traffic filings (for DuPage, DeKalb,
                  Kendall and McHenry jurisdictions)
                </p>
              </>
            ) : (
              <>
                <div className="me-2 i2file_request_link cursor-pointer">
                  <FontAwesomeIcon icon={faLink} onClick={getI2FLink} />
                </div>
                <p>
                  Click this icon to link Non-EFM I2File user profile for filing
                  documents in Criminal, DUI and Traffic filings (for DuPage,
                  DeKalb, Kendall and McHenry jurisdictions)
                </p>
              </>
            )}
          </div>
        </div>

        <ul className="profile-right">
          <li className="nav-profile">
            <span
              role="button"
              className="d-inline-block cursor-pointer"
              onClick={openUserMenu}
              onKeyDown={(e: React.KeyboardEvent) => {
                e.key === "Enter" && openUserMenu();
              }}
              tabIndex={0}
            >
              <div className="profile-photo">
                <Tooltip
                  title={`${profile.data?.firstName || ""} ${
                    profile.data?.lastName || ""
                  }`}
                >
                  <Avatar sx={{ bgcolor: deepOrange[900] }}>{initials}</Avatar>
                </Tooltip>
                <KeyboardArrowDownIcon className="profile-downarrow ms-2" />
              </div>
            </span>

            {showUserMenu && (
              <div ref={userMenuRef} className="profile-options">
                <ul>
                  <li>
                    <Link to="/myprofile">
                      <AccountCircleOutlinedIcon className="me-2 v-align" />
                      <span className="v-align">Edit Profile</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/notifications">
                      <NotificationsIcon className="me-2 v-align" />
                      <span className="v-align">Manage Notifications</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/changePassword">
                      <HttpsOutlinedIcon className="me-2 v-align" />
                      <span className="v-align">Change password</span>
                    </Link>
                  </li>
                  <li>
                    <a
                      href={isAdmin === "admin" ? "/#/admin" : "/"}
                      role="button"
                      className="cursor-pointer"
                      onClick={onLogout}
                    >
                      <ExitToAppOutlinedIcon className="me-2 v-align" />
                      <span className="v-align">Logout</span>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
