import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { DELETE_PAYMENT_ACCOUNT } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

const deletePaymentAccount = async (paymentAccountID: string) => {
  await http.post(DELETE_PAYMENT_ACCOUNT, {
    paymentAccountID,
  });
};

const useDeletePaymentAccount = () =>
  useMutation<void, IAxisErrorResponse, string>({
    mutationKey: ["delete-payment-account"],
    mutationFn: deletePaymentAccount,
  });

export default useDeletePaymentAccount;
