import { useQuery } from "@tanstack/react-query";

import http from "../../http";
import { READ_ADMIN_USER_NOTIFICATION_FILES } from "../URL";
import { IAxisErrorResponse } from "../../interfaces";

interface IUserNotification {
  homePageNotification: string;
  homePageTicker: string;
}

const getUserNotification = async () => {
  const response = await http.post(READ_ADMIN_USER_NOTIFICATION_FILES);
  return response.data;
};

const useUserNotification = () =>
  useQuery<IUserNotification, IAxisErrorResponse>({
    queryKey: ["userNotification"],
    queryFn: getUserNotification,
  });

export default useUserNotification;
