import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { GET_TRANSACTIONS } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface IPaymentAccount {
  paymentAccountID: string;
  accountName: string;
  paymentAccountTypeCode: string;
  efmFilingDetailsId: string;
  efmFilingId: string;
}

interface IFilter {
  startDate?: string;
  endDate?: string;
  efmFilingStatus?: string;
  location?: string;
  efmEnvelopeId?: string;
  userId: string;
  efmUserId: string;
  efmFirmId?: string;
  isFirmAdmin?: string;
  filingFilter: string;
  isPendingCount: boolean;
  showTransactionForDashboard: boolean;
}

const getTransactions = async (filters: IFilter) => {
  const response = await http.post<IPaymentAccount[]>(
    GET_TRANSACTIONS,
    filters
  );
  return response.data.map((transaction) => ({
    ...transaction,
    uniqueId: transaction.efmFilingId + Math.random(),
  }));
};

const useTransactions = () =>
  useMutation<IPaymentAccount[], IAxisErrorResponse, IFilter>({
    mutationKey: ["transactions"],
    mutationFn: getTransactions,
  });

export default useTransactions;
