import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_ATTORNEYS } from "../../URL";
import { isIndividualUser } from "../../../utills";

interface IAttorney {
  attorneyID: string;
  barNumber: string;
  firmID: string;
  firstName: string;
  middleName: string;
  lastName: string;
}

const getAttorneys = async () => {
  if (isIndividualUser()) return [];

  const response = await http.post<IAttorney[]>(GET_ATTORNEYS);
  return response.data;
};

const useAttorneys = () =>
  useQuery<IAttorney[]>({
    queryKey: ["attorney"],
    queryFn: getAttorneys,
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default useAttorneys;
