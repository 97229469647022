import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import complete_registration_img from "../../../assets/images/complete-registration-img.png";

interface RegistrationInformationProps {
  isFirmAccount?: boolean;
  firmId: string;
  email: string;
}

const RegistrationInformation: FC<RegistrationInformationProps> = ({
  isFirmAccount,
  firmId,
  email,
}) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ p: 3 }}>
      <div className="complete-registration-row">
        <div className="complete-registration-top-banner d-flex justify-content-center align-items-center">
          <div className="complete-registration-img">
            <img
              src={complete_registration_img}
              alt="Complete Registration Img"
            />
          </div>

          <div className="complete-registration-text">
            <h3>Congratulations!</h3>
            <h5>Your {isFirmAccount && "Firm"} Registration is Completed.</h5>
          </div>
        </div>
        <div className="complete-registration-content">
          <p>
            Your login information is mentioned below and will also be emailed
            to you. You must verify your email address before you can log in. A
            verification email has been sent to you, please open and click the
            link inside.
          </p>
          <ul>
            {isFirmAccount && (
              <li>
                <label>Firm ID:</label>
                <span>{firmId}</span>
              </li>
            )}
            <li>
              <label>Email Address: </label>
              <span>{email}</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="text-center bottom-button-row">
        <Button
          className="btn primary-button"
          variant="contained"
          onClick={() => navigate("/")}
        >
          Finish
        </Button>
      </div>
    </Box>
  );
};

export default RegistrationInformation;
