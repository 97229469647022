import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_SERVICE_CONTACTS } from "../../URL";
import { IServiceContact } from "../../../interfaces";

const getServiceContactList = async () => {
  const response = await http.post<IServiceContact[]>(GET_SERVICE_CONTACTS);
  return response.data;
};

const useServiceContactList = () =>
  useQuery<IServiceContact[]>({
    queryKey: ["serviceContactList"],
    queryFn: () => getServiceContactList(),
  });

export default useServiceContactList;
