import { GridRenderCellParams } from "@mui/x-data-grid/models";
import TaskIcon from "@mui/icons-material/Task";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

type Function = () => void;

export const renderFilingStatus = (
  statusKey: string,
  openRejectionModal: Function | null,
  params: GridRenderCellParams
) => {
  const status = params.row[statusKey];

  if (status.toLowerCase() === "accepted") {
    return (
      <div className="statusIcon accepted">
        <TaskIcon className="vertical-align-text-top me-1" />
        <span>Accepted</span>
      </div>
    );
  } else if (status.toLowerCase() === "rejected") {
    return (
      <div className="statusIcon rejected">
        <span className="material-symbols-outlined vertical-align-text-top me-1">
          scan_delete
        </span>
        <span>Rejected</span>
      </div>
    );
  } else if (
    status === "Under Review" ||
    status === "under review" ||
    status.toLowerCase() === "under-review"
  ) {
    return (
      <div className="statusIcon underReview">
        <span className="material-symbols-outlined vertical-align-text-top me-1">
          overview
        </span>
        <span>Under Review</span>
      </div>
    );
  } else if (status.toLowerCase() === "cancelled") {
    return (
      <div className="statusIcon canceled">
        <span className="material-symbols-outlined vertical-align-text-top me-1">
          scan
        </span>
        <span>Cancelled</span>
      </div>
    );
  } else if (status.toLowerCase() === "submitted") {
    return (
      <div className="statusIcon submitted">
        <span className="material-symbols-outlined vertical-align-text-top me-1">
          drive_folder_upload
        </span>
        <span>Submitted</span>
      </div>
    );
  } else if (status.toLowerCase() === "submitting") {
    return (
      <div className="statusIcon submitting">
        <span className="material-symbols-outlined vertical-align-text-top me-1">
          drive_folder_upload
        </span>
        <span>Submitting</span>
      </div>
    );
  } else if (
    status.toLowerCase() === "failed" ||
    status.toLowerCase() === "submission failed"
  ) {
    return (
      <div className="statusIcon submissionFailed">
        <span className="material-symbols-outlined vertical-align-text-top me-1">
          tab_close_right
        </span>
        <span>Submission Failed</span>
        {/* {openRejectionModal && (
          <Button
            onClick={openRejectionModal}
            size="small"
            variant="text"
            sx={{ color: "#c05360", textTransform: "none" }}
          >
            &nbsp;(View Reason)
          </Button>
        )} */}
      </div>
    );
  } else if (status.toLowerCase() === "deferred") {
    return (
      <div className="statusIcon deferred">
        <span className="material-symbols-outlined vertical-align-text-top me-1">
          clock
        </span>
        <span>Deferred</span>
      </div>
    );
  } else if (status.toLowerCase() === "reviewed") {
    return (
      <div className="statusIcon underReview">
        <CheckBoxIcon className="me-1 vertical-align-text-top" />
        <span>Reviewed</span>
      </div>
    );
  } else if (status.toLowerCase() === "served") {
    return (
      <div className="statusIcon served">
        <span className="material-symbols-outlined vertical-align-text-top me-1">
          file_open
        </span>
        <span>Served</span>
      </div>
    );
  } else if (status.toLowerCase() === "error") {
    return (
      <div className="statusIcon submissionFailed">
        <span className="material-symbols-outlined vertical-align-text-top me-1">
          ban
        </span>
        <span>Error</span>
      </div>
    );
  } else {
    return <span>{status}</span>;
  }
};
