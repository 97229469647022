import { useMutation } from "@tanstack/react-query";
import http from "../../../http";
import { DEATTACHED_SERVICE_CONTACT } from "../../URL";

interface IDeAttachedServiceContact {
  caseTrackingId: string;
  serviceContactId: string;
}

interface IAxisErrorResponse {
  response?: {
    data?: {
      userMessage?: string;
    };
  };
}

const deAttachedServiceContact = async (filters: IDeAttachedServiceContact) => {
  const response = await http.post(DEATTACHED_SERVICE_CONTACT, filters);
  return response.data;
};

const useDeAttachedServicecontact = () =>
  useMutation<void, IAxisErrorResponse, IDeAttachedServiceContact>({
    mutationKey: ["deAttachedservicecontact"],
    mutationFn: deAttachedServiceContact,
  });

export default useDeAttachedServicecontact;
