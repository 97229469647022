import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { styled } from "@mui/material/styles";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid/models";

import GridTable from "../../../components/grid";
import ConfirmationDialog from "../../../components/confirmationDialog";
import FilingClosedForCountyDialog from "./filingClosedForCountyDialog";
import CrossReferenceNumberDialog from "./crossReferenceNumberDialog";
import Loader from "../../../components/loader";
import useLocations from "../../../apis/common/useLocations";
import useDefaultFilingLocationCode from "../../../apis/common/useDefaultFilingLocationCode";
import useLocationCaseCategories from "../../../apis/common/useLocationCaseCategories";
import useLocationCaseTypes from "../../../apis/common/useLocationCaseTypes";
import useAttorneys from "../../../apis/firm/useAttorneys";
import usePaymentAccounts from "../../../apis/miscellaneous/usePaymentAccounts";
import useUpdateDefaultFilingLocationCode from "../../../apis/common/useUpdateDefaultFilingLocationCode";
import useLocationPartyTypes from "../../../apis/common/useLocationPartyTypes";
import useCrossReferenceNumber from "../../../apis/common/useCrossReferenceNumber";
import useSaveForFuture from "../../../apis/newCase/useSaveForFuture";
import useLocationAdditionalPartyTypes from "../../../apis/common/useLocationAdditionalPartyTypes";
import useI2FLink from "../../../apis/auth/useI2FLink";
import useProfile from "../../../apis/auth/useProfile";
import { NotificationContext } from "../../../context/NotificationContext";
import {
  getCloseFillingForLocation,
  isIndividualUser,
  isLinked,
} from "../../../utills";
import {
  DUPAGE_LOCATION_CODE,
  INDIVIDUAL_USER_DEFAULT_CROSS_REFERENCE_NUMBER,
} from "../../../constants";
import {
  IAdditionalParty,
  ICaseInformation,
  ILocationParty,
  IReference,
} from "../../../interfaces";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface CaseInformationProps {
  caseInformation?: ICaseInformation;
  updateCaseInformation: (
    caseInformation: ICaseInformation,
    locationParties: ILocationParty[],
    additionalParties: IAdditionalParty[]
  ) => void;
}

const CaseInformation: FC<CaseInformationProps> = ({
  caseInformation,
  updateCaseInformation,
}) => {
  const [showUpdateLocationConfirmation, setShowUpdateLocationConfirmation] =
    useState(false);
  const [showFilingClosedForCounty, setShowFilingClosedForCounty] =
    useState(false);
  const [locationConfirmationContent, setLocationConfirmationContent] =
    useState("");
  const [caseCrossReferenceType, setCaseCrossReferenceType] =
    useState<IReference | null>(null);
  const [caseCrossReferenceNumber, setCaseCrossReferenceNumber] = useState("");
  const [crossReferenceErrorMessage, setCrossReferenceErrorMessage] =
    useState("");
  const [showCrossReferenceNumberModal, setShowCrossReferenceNumberDialog] =
    useState(false);
  const [requiredCrossReferenceCodeName, setRequiredCrossReferenceCodeName] =
    useState("");
  const [
    showCrossReferenceValidationError,
    setShowCrossReferenceValidationError,
  ] = useState(false);

  const { createNotification } = useContext(NotificationContext);
  const navigate = useNavigate();
  const { watch, control, setValue, handleSubmit } = useForm<ICaseInformation>({
    defaultValues: caseInformation,
  });

  const { data: locations, isLoading: locationLoading } = useLocations();
  const { data: defaultLocationCode } = useDefaultFilingLocationCode();
  const updateDefaultFilingLocationCode = useUpdateDefaultFilingLocationCode();
  const { data: caseCategories, isLoading: categoryLoading } =
    useLocationCaseCategories(watch("locationName"));
  const { data: caseTypes, isLoading: caseTypeLoading } = useLocationCaseTypes(
    watch("locationName"),
    watch("categoryName")
  );
  const { data: parties, isLoading: partyIsLoading } = useLocationPartyTypes(
    watch("locationName"),
    watch("caseTypeName")
  );
  const { data: additionalParties, isLoading: additionalPartiesIsLoading } =
    useLocationAdditionalPartyTypes(watch("locationName"));
  const { data: crossReferences, isLoading: crossReferenceLoading } =
    useCrossReferenceNumber(watch("locationName"), watch("caseTypeName"));
  const { data: attorneys, isLoading: attorneyLoading } = useAttorneys();
  const { data: paymentAccounts, isLoading: paymentLoading } =
    usePaymentAccounts();
  const saveForFuture = useSaveForFuture();
  const profile = useProfile();
  const i2fLink = useI2FLink();

  const redirectToClassicI2File = useCallback(async () => {
    if (profile.data) {
      i2fLink.mutateAsync({
        liteUser: profile.data.email,
        defaultToSupremeCourtCounty: false,
        isUserProfileLinked: isLinked(),
        liteUserFirstName: profile.data.firstName,
        liteUserLastName: profile.data.lastName,
        liteUserRole: isIndividualUser() ? "prose" : "Attorney",
      });
    }
  }, [profile.data, i2fLink]);

  const setDefaultFilingLocation = useCallback(() => {
    const selectedLocationCode = watch("locationName");
    if (defaultLocationCode === selectedLocationCode) {
      createNotification({
        title: "New Case Notification",
        type: "error",
        message: "Selected location is already set as a default location.",
      });
    } else {
      setShowUpdateLocationConfirmation(true);
      setLocationConfirmationContent(
        `Do you want to change default location from ${
          locations?.find(
            (location) => location.efm_Location_Code === defaultLocationCode
          )?.efm_Location_Name
        } to ${
          locations?.find(
            (location) => location.efm_Location_Code === selectedLocationCode
          )?.efm_Location_Name
        } ?`
      );
    }
  }, [defaultLocationCode, locations, createNotification, watch]);

  const closeUpdateLocationConfirmation = useCallback(() => {
    setShowUpdateLocationConfirmation(false);
  }, []);

  const updateDefaultLocation = useCallback(async () => {
    await updateDefaultFilingLocationCode.mutateAsync(watch("locationName"));

    setShowUpdateLocationConfirmation(false);
    createNotification({
      title: "New Case Notification",
      type: "success",
      message: "Default Location set successfully.",
    });
  }, [createNotification, updateDefaultFilingLocationCode, watch]);

  const closeFilingClosedForCountyModal = useCallback(() => {
    setShowFilingClosedForCounty(false);
  }, []);

  const closeCrossReferenceNumberModal = useCallback(() => {
    setShowCrossReferenceNumberDialog(false);
    window.setTimeout(() => {
      document.getElementById("crossReferenceNumber")?.focus();
    }, 100);
  }, []);

  const goToDashboard = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  const showClassicI2FileNavigation = useMemo(() => {
    return locations
      ?.find((location) => location.efm_Location_Code === watch("locationName"))
      ?.efm_Location_Name?.toLocaleLowerCase()
      ?.includes("dupage");
    // eslint-disable-next-line
  }, [locations, watch("locationName")]);

  const showCrossReferenceNumber = useMemo(() => {
    return (
      watch("caseTypeName") &&
      crossReferences?.locationCrossReferenceCodeList?.length
    );
  }, [crossReferences, watch]);

  const showSaveForFuture = useMemo(() => {
    return (
      watch("caseTypeName") &&
      crossReferences?.crossReferenceItemForPrepopulateList?.length
    );
  }, [crossReferences, watch]);

  const getAmount = useCallback((amount: string) => {
    if (!amount || isNaN(parseFloat(amount))) return;

    return `$${parseFloat(amount).toFixed(2)}`;
  }, []);

  const onCaseCrossReferenceTypeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCaseCrossReferenceType(
        crossReferences?.locationCrossReferenceCodeList?.find(
          (item) => item.efmCrossreferencecodeCode === event.target.value
        ) || null
      );
    },
    [crossReferences?.locationCrossReferenceCodeList]
  );

  const onCaseCrossReferenceNumberChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCaseCrossReferenceNumber(event.target.value.trim());
    },
    []
  );

  const onAddCrossReferenceNumber = useCallback(
    (isSaveForFuture?: boolean) => {
      if (caseCrossReferenceType) {
        if (caseCrossReferenceNumber) {
          if (
            caseCrossReferenceType.required &&
            caseCrossReferenceType.validationregex
          ) {
            if (
              new RegExp(caseCrossReferenceType.validationregex).test(
                caseCrossReferenceNumber
              )
            ) {
              setCrossReferenceErrorMessage("");

              const crossReferenceNumberList = watch(
                "caseCrossReferenceNumbers"
              ).filter(
                (item) =>
                  item.efmCrossreferencecodeCode !==
                  caseCrossReferenceType.efmCrossreferencecodeCode
              );

              crossReferenceNumberList.push({
                efmCrossreferencecodeCode:
                  caseCrossReferenceType.efmCrossreferencecodeCode,
                efmCrossreferencecodeName:
                  caseCrossReferenceType.efmCrossreferencecodeName,
                userCrossReferenceNumber: caseCrossReferenceNumber,
              });

              setValue("caseCrossReferenceNumbers", crossReferenceNumberList);
              setCaseCrossReferenceNumber("");
              setShowCrossReferenceValidationError(false);

              if (isSaveForFuture) {
                saveForFuture.mutate({
                  efmLocationCode: watch("locationName"),
                  caseCrossReferenceNumberList: crossReferenceNumberList.map(
                    (crossReferenceNumber) => ({
                      efmCrossreferencecodeName:
                        crossReferenceNumber.efmCrossreferencecodeName,
                      userCrossReferenceNumber:
                        crossReferenceNumber.userCrossReferenceNumber,
                    })
                  ),
                });
              }
            } else {
              setCrossReferenceErrorMessage(
                `The value "${caseCrossReferenceNumber}" does not match the format expected for the "${caseCrossReferenceType.efmCrossreferencecodeName}" Case Cross Reference number.`
              );
            }
          }
        } else if (
          !watch("caseCrossReferenceNumbers").some(
            (item) =>
              item.efmCrossreferencecodeCode ===
              caseCrossReferenceType.efmCrossreferencecodeCode
          )
        ) {
          setCrossReferenceErrorMessage(
            `Case type requires a Case Cross Reference number of type "${caseCrossReferenceType.efmCrossreferencecodeName}"."`
          );
        }
      }
    },
    // eslint-disable-next-line
    [caseCrossReferenceType, caseCrossReferenceNumber]
  );

  const onRemoveCrossReferenceNumber = useCallback(
    (efmCrossreferencecodeCode: string) => {
      setValue(
        "caseCrossReferenceNumbers",
        watch("caseCrossReferenceNumbers").filter(
          (item) => item.efmCrossreferencecodeCode !== efmCrossreferencecodeCode
        )
      );
    },
    // eslint-disable-next-line
    []
  );

  const onSubmit = useCallback(
    (formData: ICaseInformation) => {
      if (crossReferences?.locationCrossReferenceCodeList.length) {
        let requiredCrossReferenceNumbers: IReference[] = [];

        if (
          formData.locationName.toLowerCase().includes(DUPAGE_LOCATION_CODE)
        ) {
          requiredCrossReferenceNumbers =
            crossReferences.locationCrossReferenceCodeList;
        } else {
          requiredCrossReferenceNumbers =
            crossReferences.locationCrossReferenceCodeList.filter(
              (item) => item.required
            );
        }

        if (requiredCrossReferenceNumbers.length) {
          if (
            formData.caseCrossReferenceNumbers.some(
              (item) =>
                item.efmCrossreferencecodeCode ===
                requiredCrossReferenceNumbers[0].efmCrossreferencecodeCode
            )
          ) {
            setShowCrossReferenceValidationError(false);
          } else {
            setCrossReferenceErrorMessage(
              `Case type requires a Case Cross Reference number of type "${requiredCrossReferenceNumbers[0].efmCrossreferencecodeName}".`
            );
            setShowCrossReferenceValidationError(true);
            return;
          }
        }
      }

      if (!partyIsLoading && !additionalPartiesIsLoading) {
        formData.categoryEcfCaseType = caseCategories?.find(
          (category) => category.efmCasecategoryCode === formData.categoryName
        )?.efmEcfCasetype;

        formData.isReturnDate = locations?.find(
          (location) => location.efm_Location_Code === formData.locationName
        )?.isreturndate;

        updateCaseInformation(formData, parties || [], additionalParties || []);
      }
    },
    [
      parties,
      partyIsLoading,
      caseCategories,
      crossReferences,
      locations,
      additionalParties,
      additionalPartiesIsLoading,
      updateCaseInformation,
    ]
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "efmCrossreferencecodeName",
        headerName: "Case Cross Reference Type",
        width: 750,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "userCrossReferenceNumber",
        headerName: "Case Cross Reference Number",
        width: 700,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "action",
        headerName: "",
        width: 50,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <IconButton
              onClick={onRemoveCrossReferenceNumber.bind(
                this,
                params.row.efmCrossreferencecodeCode
              )}
            >
              <DeleteIcon color="error" />
            </IconButton>
          );
        },
      },
    ],
    [onRemoveCrossReferenceNumber]
  );

  useEffect(() => {
    if (
      defaultLocationCode &&
      defaultLocationCode.length > 0 &&
      !caseInformation?.locationName
    )
      setValue("locationName", defaultLocationCode);
    // eslint-disable-next-line
  }, [defaultLocationCode]);

  useEffect(() => {
    if (
      (caseInformation?.locationName &&
        watch("locationName") !== caseInformation.locationName) ||
      !caseInformation?.locationName
    ) {
      setValue("categoryName", "");
      setValue("caseTypeName", "");

      const closeFillingForLocation = getCloseFillingForLocation();
      if (closeFillingForLocation) {
        const locations = closeFillingForLocation.split(",");
        if (locations.includes(watch("locationName"))) {
          setShowFilingClosedForCounty(true);
        }
      }
    }
    // eslint-disable-next-line
  }, [watch("locationName")]);

  useEffect(() => {
    if (
      (caseInformation?.caseTypeName &&
        watch("categoryName") !== caseInformation.categoryName) ||
      !caseInformation?.caseTypeName
    ) {
      setValue("caseTypeName", "");
    }
    // eslint-disable-next-line
  }, [watch("categoryName")]);

  useEffect(() => {
    setCaseCrossReferenceType(null);
    setCaseCrossReferenceNumber("");
    setCrossReferenceErrorMessage("");

    if (
      (caseInformation?.caseTypeName &&
        watch("caseTypeName") !== caseInformation.caseTypeName) ||
      !caseInformation?.caseTypeName
    ) {
      setValue("caseCrossReferenceNumbers", []);
    }
    // eslint-disable-next-line
  }, [watch("caseTypeName")]);

  useEffect(() => {
    if (caseInformation?.caseCrossReferenceNumbers?.length) return;

    if (crossReferences?.locationCrossReferenceCodeList?.length) {
      setCaseCrossReferenceType(
        crossReferences.locationCrossReferenceCodeList[0]
      );

      if (crossReferences?.filerCrossReferenceMappingList?.length) {
        const crossReferenceNumberList = [];

        for (const filerCrossReferenceMapping of crossReferences.filerCrossReferenceMappingList) {
          const crossReferenceNumber =
            crossReferences?.locationCrossReferenceCodeList.find(
              (item) =>
                item.efmCrossreferencecodeName ===
                filerCrossReferenceMapping.efmCrossreferencecodeName
            );

          if (
            crossReferenceNumber &&
            filerCrossReferenceMapping.userCrossreferencecodeCode
          ) {
            crossReferenceNumberList.push({
              efmCrossreferencecodeCode:
                crossReferenceNumber.efmCrossreferencecodeCode,
              efmCrossreferencecodeName:
                crossReferenceNumber.efmCrossreferencecodeName,
              userCrossReferenceNumber:
                filerCrossReferenceMapping.userCrossreferencecodeCode,
            });
          }
        }

        setValue("caseCrossReferenceNumbers", crossReferenceNumberList);
      } else if (
        isIndividualUser() &&
        watch("locationName").toLowerCase().includes(DUPAGE_LOCATION_CODE)
      ) {
        setValue(`caseCrossReferenceNumbers`, [
          {
            efmCrossreferencecodeCode:
              crossReferences.locationCrossReferenceCodeList[0]
                .efmCrossreferencecodeCode,
            efmCrossreferencecodeName:
              crossReferences.locationCrossReferenceCodeList[0]
                .efmCrossreferencecodeName,
            userCrossReferenceNumber:
              INDIVIDUAL_USER_DEFAULT_CROSS_REFERENCE_NUMBER,
          },
        ]);
      }

      if (watch("caseCrossReferenceNumbers").length > 0) return;

      if (watch("locationName").toLowerCase().includes(DUPAGE_LOCATION_CODE)) {
        setShowCrossReferenceNumberDialog(true);
        setRequiredCrossReferenceCodeName("");
      } else {
        const requiredCrossReferenceNumber =
          crossReferences.locationCrossReferenceCodeList.find(
            (item) => item.efmCrossreferencecodeCode !== null && item.required
          );

        if (requiredCrossReferenceNumber) {
          setShowCrossReferenceNumberDialog(true);
          setRequiredCrossReferenceCodeName(
            watch("locationName").toLowerCase().startsWith("cook")
              ? ""
              : requiredCrossReferenceNumber.efmCrossreferencecodeName
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [crossReferences]);

  useEffect(() => {
    if (!locations?.length || !watch("locationName")) return;

    if (
      !locations.some(
        (location) => location.efm_Location_Code === watch("locationName")
      )
    )
      setValue("locationName", "");
    // eslint-disable-next-line
  }, [locations]);

  useEffect(() => {
    if (!caseCategories?.length || !watch("categoryName")) return;

    if (
      !caseCategories.some(
        (category) => category.efmCasecategoryCode === watch("categoryName")
      )
    )
      setValue("categoryName", "");
    // eslint-disable-next-line
  }, [caseCategories]);

  useEffect(() => {
    if (!caseTypes?.length || !watch("caseTypeName")) return;

    if (
      !caseTypes.some(
        (caseType) => caseType.efmCasetypeCode === watch("caseTypeName")
      )
    )
      setValue("caseTypeName", "");
    // eslint-disable-next-line
  }, [caseTypes]);

  useEffect(() => {
    if (!attorneys?.length || !watch("filingAttorney")) return;

    if (
      !attorneys.some(
        (attorney) => attorney.attorneyID === watch("filingAttorney")
      )
    )
      setValue("filingAttorney", "");
    // eslint-disable-next-line
  }, [attorneys]);

  useEffect(() => {
    if (!paymentAccounts?.length || !watch("paymentAccount")) return;

    if (
      !paymentAccounts.some(
        (paymentAccount) =>
          paymentAccount.paymentAccountID === watch("paymentAccount")
      )
    )
      setValue("paymentAccount", "");
    // eslint-disable-next-line
  }, [paymentAccounts]);

  return (
    <Box sx={{ p: 0 }}>
      <Typography variant="body1" className="notetoptext mb-4 pb-2">
        If you already have a Case Number,{" "}
        <Link className="theme-blue f-w-bold" to="/existingcase">
          Click here
        </Link>{" "}
        to file into an Existing Case.
      </Typography>

      <Typography variant="h3" className="dash-title-h3 mb-3">
        Case Information
      </Typography>

      {(categoryLoading ||
        locationLoading ||
        caseTypeLoading ||
        attorneyLoading ||
        paymentLoading ||
        crossReferenceLoading ||
        additionalPartiesIsLoading ||
        i2fLink.isLoading) && <Loader />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Item className="remove-item-css">
              <Controller
                name="locationName"
                control={control}
                rules={{
                  required: "Error: Location is required.",
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                      htmlFor: `${field.name}_id`,
                    }}
                    inputProps={{
                      id: `${field.name}_id`,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      SelectDisplayProps: {
                        "aria-required": true,
                      },
                    }}
                    {...field}
                    select
                    placeholder="Select Location"
                    label="Select Location"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    <MenuItem value="">Select Location</MenuItem>
                    {locations?.map((location) => (
                      <MenuItem
                        key={location.efm_Location_Code}
                        value={location.efm_Location_Code}
                      >
                        {location.efm_Location_Name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              className="btn button-outline-secondary"
              variant="contained"
              disabled={!watch("locationName")}
              onClick={setDefaultFilingLocation}
            >
              Set Default Filing Location
            </Button>
          </Grid>
          <>
            <Grid item xs={12} md={8}>
              <Controller
                name="categoryName"
                control={control}
                rules={{
                  required: "Error: Category is required.",
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                      htmlFor: `${field.name}_id`,
                    }}
                    inputProps={{
                      id: `${field.name}_id`,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      SelectDisplayProps: {
                        "aria-required": true,
                      },
                    }}
                    {...field}
                    select
                    label="Select Category"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    <MenuItem value="">Select Category</MenuItem>
                    {caseCategories?.map((category) => (
                      <MenuItem
                        key={category.efmCasecategoryCode}
                        value={category.efmCasecategoryCode}
                      >
                        {category.efmCasecategoryName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          </>
          <Grid item xs={12} md={4}>
            {showClassicI2FileNavigation && (
              <Item className="remove-item-css">
                <Typography variant="body2">
                  To file into case Category not listed below,
                  <br />
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={redirectToClassicI2File}
                    className="cursor-pointer theme-blue f-w-bold text-underline"
                  >
                    Click here
                  </span>{" "}
                  to file using classic i2file.
                </Typography>
              </Item>
            )}
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="caseTypeName"
              control={control}
              rules={{
                required: "Error: Case Type is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  inputRef={ref}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    htmlFor: `${field.name}_id`,
                  }}
                  inputProps={{
                    id: `${field.name}_id`,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    SelectDisplayProps: {
                      "aria-required": true,
                    },
                  }}
                  {...field}
                  select
                  label="Select Case Type"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                >
                  <MenuItem value="">Select Case Type</MenuItem>
                  {caseTypes?.map((caseType) => (
                    <MenuItem
                      key={caseType.efmCasetypeCode}
                      value={caseType.efmCasetypeCode}
                    >
                      {`${caseType.efmCasetypeName} (${getAmount(
                        caseType.fee
                      )})`}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          {Boolean(showCrossReferenceNumber) && (
            <>
              <Grid item xs={12}>
                {crossReferenceErrorMessage && (
                  <Alert severity="error">
                    <b>ERROR :</b> {crossReferenceErrorMessage}
                  </Alert>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  className="w-100"
                  id="crossReferenceType"
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    htmlFor: "crossReferenceType_id",
                  }}
                  inputProps={{
                    id: "crossReferenceType_id",
                  }}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  select
                  label="Case Cross Reference Type"
                  variant="standard"
                  value={
                    caseCrossReferenceType?.efmCrossreferencecodeCode || ""
                  }
                  onChange={onCaseCrossReferenceTypeChange}
                  error={
                    showCrossReferenceValidationError && !caseCrossReferenceType
                  }
                >
                  <MenuItem value="">Select Case Cross Reference Type</MenuItem>
                  {crossReferences?.locationCrossReferenceCodeList?.map(
                    (reference) => (
                      <MenuItem
                        key={reference.efmCrossreferencecodeCode}
                        value={reference.efmCrossreferencecodeCode}
                      >
                        {reference.efmCrossreferencecodeName}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  className="w-100 m-0 remove-item-css"
                  id="crossReferenceNumber"
                  InputLabelProps={{ required: true }}
                  placeholder="Case Cross Reference Number"
                  label="Case Cross Reference Number"
                  variant="standard"
                  value={caseCrossReferenceNumber}
                  onChange={onCaseCrossReferenceNumberChange}
                  error={
                    showCrossReferenceValidationError &&
                    !caseCrossReferenceNumber
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  type="button"
                  variant="contained"
                  className="btn button-outline-secondary"
                  onClick={onAddCrossReferenceNumber.bind(this, false)}
                >
                  Add
                </Button>
                {Boolean(showSaveForFuture) && (
                  <Button
                    type="button"
                    variant="contained"
                    className="btn primary-button ms-4"
                    onClick={onAddCrossReferenceNumber.bind(this, true)}
                  >
                    Save For Future
                  </Button>
                )}
              </Grid>
              <Grid item xs={12}>
                <GridTable
                  columns={columns}
                  rows={watch("caseCrossReferenceNumbers")}
                  hideFooter={false}
                  getRowId={(row) => row.efmCrossreferencecodeCode}
                />
              </Grid>
            </>
          )}
          {!isIndividualUser() && (
            <Grid item xs={12}>
              <Controller
                name="filingAttorney"
                control={control}
                rules={{
                  required: "Error: Filing Attorney is required.",
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                      htmlFor: `${field.name}_id`,
                    }}
                    inputProps={{
                      id: `${field.name}_id`,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      SelectDisplayProps: {
                        "aria-required": true,
                      },
                    }}
                    defaultValue={field.value}
                    {...field}
                    select
                    placeholder="Select Filing Attorney"
                    label="Select Filing Attorney"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    <MenuItem value="">Select Filing Attorney</MenuItem>
                    {attorneys?.map((attorney) => (
                      <MenuItem
                        key={attorney.attorneyID}
                        value={attorney.attorneyID}
                      >
                        {`${attorney.firstName} ${attorney.lastName}`}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name="paymentAccount"
              control={control}
              rules={{
                required: "Error: Payment Account is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  inputRef={ref}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    htmlFor: `${field.name}_id`,
                  }}
                  inputProps={{
                    id: `${field.name}_id`,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    SelectDisplayProps: {
                      "aria-required": true,
                    },
                  }}
                  defaultValue={field.value}
                  {...field}
                  select
                  placeholder="Select Payment Account"
                  label="Select Payment Account"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                >
                  <MenuItem value="">Select Payment Account</MenuItem>
                  {paymentAccounts?.map((paymentAccount) => (
                    <MenuItem
                      key={paymentAccount.paymentAccountID}
                      value={paymentAccount.paymentAccountID}
                    >
                      {paymentAccount.accountName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Box justifyContent="center" width="100%" display="flex">
              <Button
                className="btn button-outline-secondary me-3"
                variant="contained"
                type="button"
                onClick={goToDashboard}
              >
                Exit
              </Button>
              <Button
                className="btn primary-button"
                variant="contained"
                type="submit"
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>

      <ConfirmationDialog
        isOpen={showUpdateLocationConfirmation}
        title="Confirmation"
        content={locationConfirmationContent}
        onConfirmation={updateDefaultLocation}
        onClose={closeUpdateLocationConfirmation}
      />

      <FilingClosedForCountyDialog
        isOpen={showFilingClosedForCounty}
        locationName={
          locations?.find(
            (location) => location.efm_Location_Code === watch("locationName")
          )?.efm_Location_Name || ""
        }
        onOk={goToDashboard}
        onClose={closeFilingClosedForCountyModal}
      />

      <CrossReferenceNumberDialog
        isOpen={showCrossReferenceNumberModal}
        locationCode={watch("locationName")}
        locationName={
          locations?.find(
            (location) => location.efm_Location_Code === watch("locationName")
          )?.efm_Location_Name || ""
        }
        requiredCrossReferenceCodeName={requiredCrossReferenceCodeName}
        onClose={closeCrossReferenceNumberModal}
      />
    </Box>
  );
};

export default CaseInformation;
