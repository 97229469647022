import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { REVIEW_FILING } from "../../URL";
import { IFeeCalculationRequest } from "../../../interfaces";

interface IAxisErrorResponse {
  response?: {
    data?: string;
  };
}

const reviewFiling = async (filing: IFeeCalculationRequest) => {
  const response = await http.post<number>(REVIEW_FILING, filing);
  return response?.data;
};

const useReviewFiling = () =>
  useMutation<number, IAxisErrorResponse, IFeeCalculationRequest>({
    mutationKey: ["review-filing"],
    mutationFn: reviewFiling,
  });

export default useReviewFiling;
