import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

import Dialog from "../../../components/dialog";
import PaymentAccountRedirectModal from "../paymentAccountRedirectModal";
import CardDetailsModal from "../cardDetailsModal";
import BankDetailsModal from "../bankDetailsModal";
import usePaymentAccountTypes from "../../../apis/miscellaneous/usePaymentAccountTypes";
import useSavePaymentAccount from "../../../apis/miscellaneous/useSavePaymentAccount";
import useUpdatePaymentAccount from "../../../apis/miscellaneous/useUpdatePaymentAccount";
import { NotificationContext } from "../../../context/NotificationContext";
import { IPaymentAccount } from "../../../interfaces";

interface CreateEditPaymentAccountProps {
  paymentAccount?: IPaymentAccount;
  isOpen: boolean;
  onClose: (shouldUpdateGrid?: boolean) => void;
}

interface IPaymentAccountForm {
  accountName: string;
  accountToken: string;
  paymentAccountTypeCode: string;
  paymentAccountTypeCodeId: string;
  active: boolean;
}

interface IListItem {
  codeId: string;
  code: string;
  label: string;
}

const CreateEditPaymentAccount: FC<CreateEditPaymentAccountProps> = ({
  isOpen,
  paymentAccount,
  onClose,
}) => {
  const accountTypes = usePaymentAccountTypes();
  const savePaymentAccount = useSavePaymentAccount();
  const updatePaymentAccount = useUpdatePaymentAccount();
  const { watch, control, handleSubmit } = useForm<IPaymentAccountForm>({
    defaultValues: {
      accountName: paymentAccount?.accountName,
      paymentAccountTypeCode: paymentAccount?.paymentAccountTypeCode || "",
      paymentAccountTypeCodeId:
        paymentAccount?.paymentAccountTypeCodeId?.value || "",
      active: paymentAccount?.active?.value || false,
    },
  });

  const [isRedirectModalOpen, setIsRedirectModalOpen] = useState(false);
  const [isCardDetailsModalOpen, setIsCardDetailsModalOpen] = useState(false);
  const [isBankDetailsModalOpen, setIsBankDetailsModalOpen] = useState(false);
  const { createNotification } = useContext(NotificationContext);

  const paymentAccountTypes = useMemo(() => {
    const list: IListItem[] = [];

    if (!accountTypes.data) return list;

    accountTypes.data.forEach((accountType) => {
      if (list.some((item) => item.code === accountType.code)) return;

      if (accountType.code === "WV") {
        list.push({
          codeId: accountType.codeId,
          code: "WV",
          label: "Waiver",
        });
      } else if (accountType.code === "CC") {
        list.push({
          codeId: accountType.codeId,
          code: "CC",
          label: "Credit Card",
        });
      } else if (accountType.code === "BankAccount") {
        list.push({
          codeId: accountType.codeId,
          code: "BankAccount",
          label: "Bank Account",
        });
      }

      return list;
    });

    list.push({
      codeId: "",
      code: "DrawDown",
      label: "Draw Down",
    });

    return list;
  }, [accountTypes.data]);

  const waiverAccountTypes = useMemo(() => {
    const list: IListItem[] = [];

    if (!accountTypes.data) return list;

    accountTypes.data.forEach((accountType) => {
      if (accountType.code === "WV") {
        list.push({
          codeId: accountType.codeId,
          code: accountType.code,
          label: accountType.description,
        });
      }
    });

    return list;
  }, [accountTypes.data]);

  const onSubmit = useCallback(
    (data: IPaymentAccountForm) => {
      if (paymentAccount) {
        updatePaymentAccount.mutate({
          accountName: data.accountName,
          accountToken: paymentAccount.accountToken,
          cardType: paymentAccount.cardType,
          cardLast4: paymentAccount.cardLast4,
          cardMonth: paymentAccount.cardMonth,
          cardYear: paymentAccount.cardYear,
          cardHolderName: paymentAccount.cardHolderName,
          active: {
            value: data.active,
          },
          paymentAccountID: paymentAccount.paymentAccountID,
          paymentAccountTypeCode: paymentAccount.paymentAccountTypeCode,
          paymentAccountTypeCodeId:
            paymentAccount.paymentAccountTypeCodeId?.value,
        });
      } else {
        savePaymentAccount.mutate({
          accountName: data.accountName,
          paymentAccountTypeCode: data.paymentAccountTypeCode,
          paymentAccountTypeCodeId: data.paymentAccountTypeCodeId,
        });
      }
    },
    [paymentAccount, savePaymentAccount, updatePaymentAccount]
  );

  const openPaymentAccountModal = useCallback(() => {
    setIsRedirectModalOpen(true);
  }, []);

  const closePaymentAccountModal = useCallback(() => {
    setIsRedirectModalOpen(false);
  }, []);

  const openCardDetailsModal = useCallback(() => {
    setIsCardDetailsModalOpen(true);
  }, []);

  const closeCardDetailsModal = useCallback(() => {
    setIsCardDetailsModalOpen(false);
  }, []);

  const openBankDetailsModal = useCallback(() => {
    setIsBankDetailsModalOpen(true);
  }, []);

  const closeBankDetailsModal = useCallback(() => {
    setIsBankDetailsModalOpen(false);
  }, []);

  useEffect(() => {
    if (savePaymentAccount.isSuccess) {
      createNotification({
        title: "Payment Notification",
        type: "success",
        message: "Payment Account saved Successfully",
      });

      onClose(true);
    }
    // eslint-disable-next-line
  }, [savePaymentAccount.isSuccess]);

  useEffect(() => {
    if (savePaymentAccount.isError) {
      createNotification({
        title: "Payment Notification",
        type: "error",
        message:
          savePaymentAccount.error.response?.data?.userMessage ||
          "Something went wrong while saving Payment Account",
      });
    }
    // eslint-disable-next-line
  }, [savePaymentAccount.isError]);

  useEffect(() => {
    if (updatePaymentAccount.isSuccess) {
      createNotification({
        title: "Payment Notification",
        type: "success",
        message: "Payment Account saved Successfully",
      });

      onClose(true);
    }
    // eslint-disable-next-line
  }, [updatePaymentAccount.isSuccess]);

  useEffect(() => {
    if (updatePaymentAccount.isError) {
      createNotification({
        title: "Payment Notification",
        type: "error",
        message:
          updatePaymentAccount.error.response?.data?.userMessage ||
          "Something went wrong while saving Payment Account",
      });
    }
    // eslint-disable-next-line
  }, [updatePaymentAccount.isError]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose.bind(this, false)}
      title={paymentAccount ? "Edit Payment Account" : "Create Payment Account"}
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="accountName"
              control={control}
              rules={{
                required: "Error: Payment Account Name is required.",
              }}
              render={({ field, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  autoFocus
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{
                    required: true,
                  }}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  label="Payment Account Name"
                  placeholder="Your Payment Account Name"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="paymentAccountTypeCode"
              control={control}
              rules={{
                required: "Error: Payment Account Type is required.",
              }}
              render={({ field, fieldState }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  inputProps={{
                    "aria-required": true,
                    id: `${field.name}_id`,
                  }}
                  select
                  InputLabelProps={{
                    shrink: true,
                    htmlFor: `${field.name}_id`,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    SelectDisplayProps: {
                      "aria-required": true,
                    },
                  }}
                  {...field}
                  disabled={Boolean(paymentAccount)}
                  label="Payment Account Type"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                >
                  <MenuItem value="">Select Payment Account Type</MenuItem>
                  {paymentAccountTypes?.map((accountType) => (
                    <MenuItem key={accountType.code} value={accountType.code}>
                      {accountType.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {!paymentAccount &&
              watch("paymentAccountTypeCode") === "CC" &&
              watch("accountName") && (
                <Button
                  className="btn primary-button"
                  variant="contained"
                  type="button"
                  onClick={openPaymentAccountModal}
                >
                  Enter Credit Card Information
                </Button>
              )}
            {!paymentAccount &&
              watch("paymentAccountTypeCode") === "BankAccount" &&
              watch("accountName") && (
                <Button
                  className="btn primary-button"
                  variant="contained"
                  type="button"
                  onClick={openPaymentAccountModal}
                >
                  Enter Bank Information
                </Button>
              )}
            {watch("paymentAccountTypeCode") === "WV" && (
              <Controller
                name="paymentAccountTypeCodeId"
                control={control}
                rules={{
                  required: "Error: Waiver Account Type is required.",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    select
                    inputProps={{
                      "aria-required": true,
                      id: `${field.name}_id`,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      htmlFor: `${field.name}_id`,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      SelectDisplayProps: {
                        "aria-required": true,
                      },
                    }}
                    {...field}
                    disabled={Boolean(paymentAccount)}
                    label="Waiver Account Type"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    <MenuItem value="">Select Waiver Account Type</MenuItem>
                    {waiverAccountTypes?.map((accountType) => (
                      <MenuItem
                        key={accountType.codeId}
                        value={accountType.codeId}
                      >
                        {accountType.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            )}
          </Grid>

          {paymentAccount && (
            <Grid item xs={12} sm={6}>
              <Controller
                name="active"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputLabel>
                    <Checkbox
                      {...field}
                      inputRef={ref}
                      inputProps={{
                        "aria-required": true,
                      }}
                      className="ps-0"
                      defaultChecked={field.value}
                    />
                    Active
                  </InputLabel>
                )}
              />
            </Grid>
          )}

          <Grid item xs={12} pt={2} display="flex" justifyContent="end">
            <Button
              className="btn button-outline-secondary me-3"
              variant="contained"
              type="button"
              onClick={onClose.bind(this, false)}
            >
              Cancel
            </Button>

            {watch("paymentAccountTypeCode") !== "DrawDown" &&
              ((watch("paymentAccountTypeCode") !== "BankAccount" &&
                watch("paymentAccountTypeCode") !== "CC") ||
                paymentAccount) && (
                <Button
                  className="btn primary-button"
                  variant="contained"
                  type="submit"
                >
                  {savePaymentAccount.isLoading ||
                  updatePaymentAccount.isLoading ? (
                    <CircularProgress
                      sx={{
                        height: "25px !important",
                        width: "25px !important",
                        "--CircularProgress-size": "8px",
                        "--CircularProgress-trackThickness": "1px",
                        "--CircularProgress-progressThickness": "1px",
                      }}
                      color="inherit"
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              )}
          </Grid>
          {watch("paymentAccountTypeCode") === "CC" && paymentAccount && (
            <Grid item xs={12}>
              <Button
                className="btn primary-button"
                variant="contained"
                type="button"
                onClick={openCardDetailsModal}
              >
                View Credit Card Information
              </Button>
            </Grid>
          )}

          {watch("paymentAccountTypeCode") === "BankAccount" &&
            paymentAccount && (
              <Grid item xs={12}>
                <Button
                  className="btn primary-button"
                  variant="contained"
                  type="button"
                  onClick={openBankDetailsModal}
                >
                  View Account Information
                </Button>
              </Grid>
            )}
        </Grid>
      </form>

      {isRedirectModalOpen && (
        <PaymentAccountRedirectModal
          isOpen={isRedirectModalOpen}
          accountName={watch("accountName")}
          accountTypeCode={watch("paymentAccountTypeCode")}
          onClose={closePaymentAccountModal}
        />
      )}

      <CardDetailsModal
        isOpen={isCardDetailsModalOpen}
        paymentAccount={paymentAccount}
        onClose={closeCardDetailsModal}
      />

      <BankDetailsModal
        isOpen={isBankDetailsModalOpen}
        paymentAccount={paymentAccount}
        onClose={closeBankDetailsModal}
      />
    </Dialog>
  );
};

export default CreateEditPaymentAccount;
