import { useCallback, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import Page from "../../components/page";
import useProfile from "../../apis/auth/useProfile";
import useUpdateProfile from "../../apis/auth/useUpdateProfile";
import { NotificationContext } from "../../context/NotificationContext";

import myprofile_img from "../../assets/images/my-profile-img.svg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const MyProfile = () => {
  const profile = useProfile();
  const updateProfile = useUpdateProfile();
  const navigate = useNavigate();
  const { createNotification } = useContext(NotificationContext);

  const { control, handleSubmit } = useForm({
    defaultValues: profile.data || {},
  });

  const onSubmit = useCallback(
    async (data: {
      firstName: string;
      middleName: string;
      lastName: string;
    }) => {
      if (profile.data) {
        await updateProfile.mutateAsync({
          userId: profile.data.userID,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          email: profile.data.email,
          updatedEmail: profile.data.email,
        });
      }
    },
    [profile.data, updateProfile]
  );

  const navigateToDashboard = useCallback(
    () => navigate("/dashboard"),
    [navigate]
  );

  useEffect(() => {
    if (updateProfile.isSuccess) {
      createNotification({
        title: "My Profile Notification",
        type: "success",
        message:
          "Profile Updated Successfully. Your changes will be visible next time you log in.",
      });
    }
  }, [updateProfile.isSuccess, createNotification]);

  useEffect(() => {
    if (updateProfile.isError) {
      createNotification({
        title: "My Profile Notification",
        type: "error",
        message:
          updateProfile.error.response?.data?.userMessage ||
          "Something went wrong while updating profile",
      });
    }
    // eslint-disable-next-line
  }, [updateProfile.isError, createNotification]);

  return (
    <Page>
      <div className="content-wrapper">
        <h2 className="dash-title-h2">My Profile</h2>

        <div className="myprofile-wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={2}
              className="myprofile-wrapper2 text-center"
              sx={{ mt: 4 }}
            >
              <Grid xs={12} md={4}>
                <div>
                  <img src={myprofile_img} alt="an illustration of man" />
                </div>
              </Grid>
              <Grid className="" item xs={12} md={8}>
                <Item className="remove-item-css mb-4">
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: "Error: First Name is required.",
                    }}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        InputLabelProps={{
                          required: true,
                        }}
                        inputRef={ref}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        label="First Name"
                        placeholder="Your First Name"
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    )}
                  />
                </Item>
                <Item className="remove-item-css mb-4">
                  <Controller
                    name="middleName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        label="Middle Name"
                        placeholder="Your Middle Name"
                        variant="standard"
                      />
                    )}
                  />
                </Item>
                <Item className="remove-item-css mb-4">
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: "Error: Last Name is required.",
                    }}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        InputLabelProps={{
                          required: true,
                        }}
                        inputRef={ref}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        label="Last Name"
                        placeholder="Your Last Name"
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    )}
                  />
                </Item>
                <Item className="remove-item-css mb-4">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        InputLabelProps={{
                          required: true,
                        }}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        label="Email Address"
                        variant="standard"
                        disabled
                      />
                    )}
                  />
                </Item>
                <Item className="remove-item-css">
                  <div className="text-center bottom-button-row mt-5">
                    <Button
                      className="btn button-outline-secondary me-3"
                      variant="contained"
                      type="button"
                      onClick={navigateToDashboard}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="btn primary-button"
                      variant="contained"
                    >
                      {updateProfile.isLoading ? (
                        <CircularProgress
                          sx={{
                            height: "25px !important",
                            width: "25px !important",
                            "--CircularProgress-size": "8px",
                            "--CircularProgress-trackThickness": "1px",
                            "--CircularProgress-progressThickness": "1px",
                          }}
                          color="inherit"
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </Item>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </Page>
  );
};
export default MyProfile;
