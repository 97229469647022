import { HashRouter, Routes, Route } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Dashboard from "./pages/dashboard";
import LandingPage from "./pages/landingPage";
import FirmInformation from "./pages/Firm/information";
import MyProfile from "./pages/myProfile";
import ChangePassword from "./pages/changePassword";
import NewCase from "./pages/newCase";
import ExistingCase from "./pages/existingCase";
import NonIndexedCase from "./pages/nonIndexedCase";

import AuthenticatedRoute from "./containers/AuthenticatedRoute";
import FirmUsers from "./pages/Firm/Users";
import ServiceContacts from "./pages/serviceContacts";
import Notifications from "./pages/notifications";

// import { theme } from "./theme";
import "./App.scss";
import PaymentAccounts from "./pages/paymentAccounts";
import Attorneys from "./pages/Attorneys";
import FilingHistory from "./pages/FilingHistory";
import MyDrafts from "./pages/MyDrafts/MyDrafts";
import SearchCases from "./pages/SearchCases";
import TransactionReport from "./pages/TransactionReport/TransactionReport";
import Register from "./pages/Register";
import queryClient from "./queryClient";
import { NotificationProvider } from "./context/NotificationContext";
import AdminLandingPage from "./pages/AdminLogin";
import PendingRequests from "./pages/Admin/PendingRequests/PendingRequests";
import UserNotification from "./pages/Admin/UserNotification/UserNotification";
import AllUsers from "./pages/Admin/AllUsers/AllUsers";
import CountyAdmin from "./pages/Admin/CountyAdmin/CountyAdmin";
import ImportPayment from "./pages/Admin/ImportPayment/ImportPayment";
import Support from "./pages/Help/support";
import FilingFeesDetail from "./pages/filingFeesDetail";

function App() {
  return (
    // <ThemeProvider theme={theme}>

    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <NotificationProvider>
            <Routes>
              <Route path="/" element={<LandingPage />}></Route>
              <Route path="/admin" element={<AdminLandingPage />}></Route>
              <Route
                path="/dashboard"
                element={<AuthenticatedRoute element={Dashboard} />}
              ></Route>
              <Route
                path="/firm/users"
                element={<AuthenticatedRoute element={FirmUsers} />}
              ></Route>
              <Route
                path="/serviceContacts"
                element={<AuthenticatedRoute element={ServiceContacts} />}
              ></Route>
              <Route
                path="/paymentAccounts"
                element={<AuthenticatedRoute element={PaymentAccounts} />}
              ></Route>
              <Route
                path="/attorneys"
                element={<AuthenticatedRoute element={Attorneys} />}
              ></Route>
              <Route
                path="/firmInformation"
                element={<AuthenticatedRoute element={FirmInformation} />}
              ></Route>
              <Route
                path="/myprofile"
                element={<AuthenticatedRoute element={MyProfile} />}
              ></Route>
              <Route
                path="/notifications"
                element={<AuthenticatedRoute element={Notifications} />}
              ></Route>
              <Route
                path="/changePassword"
                element={<AuthenticatedRoute element={ChangePassword} />}
              ></Route>
              <Route
                path="/filingHistory"
                element={<AuthenticatedRoute element={FilingHistory} />}
              ></Route>
              <Route
                path="/myDrafts"
                element={<AuthenticatedRoute element={MyDrafts} />}
              ></Route>
              <Route
                path="/searchCases"
                element={<AuthenticatedRoute element={SearchCases} />}
              ></Route>
              <Route
                path="/transactionReport"
                element={<AuthenticatedRoute element={TransactionReport} />}
              ></Route>
              <Route
                path="/register/firmUser"
                element={<Register isFirmAccount />}
              ></Route>
              <Route
                path="/register/independentuser"
                element={<Register isFirmAccount={false} />}
              ></Route>
              <Route
                path="/admin/pendingrequests"
                element={<AuthenticatedRoute element={PendingRequests} />}
              ></Route>
              <Route
                path="/admin/usernotification"
                element={<AuthenticatedRoute element={UserNotification} />}
              ></Route>
              <Route
                path="/admin/allusers"
                element={<AuthenticatedRoute element={AllUsers} />}
              ></Route>
              <Route
                path="/admin/countyadmin"
                element={<AuthenticatedRoute element={CountyAdmin} />}
              ></Route>
              <Route
                path="/admin/transactionreport"
                element={<AuthenticatedRoute element={TransactionReport} />}
              ></Route>
              <Route
                path="/admin/importpayment"
                element={<AuthenticatedRoute element={ImportPayment} />}
              ></Route>
              <Route path="/newcase" element={<NewCase />}></Route>
              <Route
                path="/nonindexedcase"
                element={<NonIndexedCase />}
              ></Route>
              <Route path="/existingcase" element={<ExistingCase />}></Route>
              <Route path="/support" element={<Support />}></Route>
              <Route
                path="/filingfeesdetail"
                element={<FilingFeesDetail />}
              ></Route>
            </Routes>
          </NotificationProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </HashRouter>

    // </ThemeProvider>
  );
}

export default App;
