import { FC, useCallback, useContext, useEffect } from "react";

import ConfirmationDialog from "../../../../components/confirmationDialog";
import useEnableDisableCountyAdmin from "../../../../apis/countyAdmin/useEnableDisableCountyAdmin";
import { NotificationContext } from "../../../../context/NotificationContext";
import { ICountyAdmin } from "../../../../interfaces";

interface IEnableDisableCountyAdmin {
  isOpen: boolean;
  enableDisable: ICountyAdmin;
  onClose: (shouldUpdateGrid?: boolean) => void;
}

const EnableDisableCountyAdmin: FC<IEnableDisableCountyAdmin> = ({
  isOpen,
  enableDisable,
  onClose,
}) => {
  const enableDisableCountyAdmin = useEnableDisableCountyAdmin();
  const { createNotification } = useContext(NotificationContext);

  const onStatusChange = useCallback(() => {
    if (enableDisable) {
      enableDisableCountyAdmin.mutate({
        ...enableDisable,
        isactive: !enableDisable.isactive,
      });
    } else {
      onClose();
    }
  }, [enableDisable, enableDisableCountyAdmin, onClose]);

  useEffect(() => {
    if (enableDisableCountyAdmin.isSuccess) {
      createNotification({
        title: "County Admin Notification",
        type: "success",
        message: "County Admin status changed successfully",
      });
      onClose(true);
    }
    // eslint-disable-next-line
  }, [enableDisableCountyAdmin.isSuccess, createNotification]);

  useEffect(() => {
    if (enableDisableCountyAdmin.isError) {
      createNotification({
        title: "County Admin Notification",
        type: "error",
        message:
          enableDisableCountyAdmin.error.response?.data?.userMessage ||
          "Something went wrong while changing county admin status",
      });
    }
    // eslint-disable-next-line
  }, [enableDisableCountyAdmin.isError, createNotification]);

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title="Confirmation"
      content={
        enableDisable.isactive
          ? "Are you sure you want to Disable this County Admin?"
          : "Are you sure you want to Enable this County Admin?"
      }
      onConfirmation={onStatusChange}
      onClose={onClose.bind(this, false)}
    />
  );
};

export default EnableDisableCountyAdmin;
