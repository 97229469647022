import { FC, useCallback } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Loader from "../loader/Loader";

import Dialog from "../dialog";
import useI2FLink from "../../apis/auth/useI2FLink";
import useProfile from "../../apis/auth/useProfile";
import { isIndividualUser, isLinked } from "../../utills";

interface IRequestDocumentDialog {
  isOpen: boolean;
  onClose: () => void;
}

const RequestDocumentDialog: FC<IRequestDocumentDialog> = ({
  isOpen,
  onClose,
}) => {
  const i2fLink = useI2FLink();
  const profile = useProfile();

  const getI2FLink = useCallback(async () => {
    if (profile.data) {
      i2fLink.mutateAsync({
        liteUser: profile.data.email,
        defaultToSupremeCourtCounty: false,
        isUserProfileLinked: isLinked(),
        liteUserFirstName: profile.data.firstName,
        liteUserLastName: profile.data.lastName,
        liteUserRole: isIndividualUser() ? "prose" : "Attorney",
      });
    }
    onClose();
  }, [profile.data, i2fLink, onClose]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Request Document/Pay and Download Document"
      maxWidth="lg"
    >
      {i2fLink.isLoading && <Loader />}
      <Box padding={1}>
        <Card variant="outlined">
          <CardContent className="grey-card">
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item xs={12} md={8}>
                <Typography>
                  To request documents or to pay for previously requested documents from{" "}
                  <b className="theme-blue">
                    DuPage, Will and Kendall jurisdictions.
                  </b>
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} alignItems="end" className="text-right">
                <Button
                  type="button"
                  variant="contained"
                  className="btn button-outline-secondary"
                  aria-label="I2FileLinking"
                  onClick={getI2FLink}
                >
                  Click Here
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box padding={1}>
        <Card variant="outlined">
          <CardContent className="grey-card">
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item xs={12} md={8}>
                <Typography>
                  To read the procedure for requesting document from{" "}
                  <b className="theme-blue">2nd Appellate Court.</b>
                </Typography>
                <Typography>
                  Contact Jurisdiction directly for more information.
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} alignItems="end" className="text-right">
                <Button
                  type="button"
                  variant="contained"
                  className="btn button-outline-secondary"
                  href="/assets/RequestDocumentCopy_Appellate_Instruction.pdf"
                  aria-label="Request document procedure PDF"
                  target="_blank"
                  onClick={onClose}
                >
                  Click Here
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box padding={1}>
        <Card variant="outlined">
          <CardContent className="grey-card">
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item xs={12} md={8}>
                <Typography>
                  To read the procedure for requesting document from{" "}
                  <b className="theme-blue">all other Jurisdictions.</b>
                </Typography>
                <Typography>
                  Contact Jurisdiction directly for more information.
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} alignItems="end" className="text-right">
                <Button
                  type="button"
                  variant="contained"
                  className="btn button-outline-secondary"
                  href="/assets/RequestDocumentCopy_Other_Instruction.pdf"
                  target="_blank"
                  aria-label="Request document procedure PDF"
                  onClick={onClose}
                >
                  Click Here
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Dialog>
  );
};

export default RequestDocumentDialog;
