import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { GridRenderCellParams, GridColDef } from "@mui/x-data-grid/models";

import Page from "../../../components/page";
import GridTable from "../../../components/grid";
import useCountries from "../../../apis/common/useCountries";
import useStates from "../../../apis/common/useStates";
import useAllUsers from "../../../apis/allUsers/useAllUsers";
import { NotificationContext } from "../../../context/NotificationContext";

const AllUsers = () => {
  const [username, setUsername] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [userTypeCode, setuserTypeCode] = useState("");
  const [activeCode, setActiveCode] = useState("");
  const [isValidEmail, setValidEmail] = useState(true);
  const { createNotification } = useContext(NotificationContext);

  const allUsers = useAllUsers();
  const { data: countries } = useCountries();
  const { data: states } = useStates(countryCode);

  const onUsernameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setUsername(event.target.value);
    },
    []
  );

  const onCountryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCountryCode(event.target.value);
      setStateCode("");
    },
    []
  );

  const onStateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setStateCode(event.target.value);
  }, []);

  const onUserTypeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setuserTypeCode(event.target.value);
    },
    []
  );

  const onActiveChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setActiveCode(event.target.value);
  }, []);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "firstname",
        headerName: "First Name",
        width: 150,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "lastname",
        headerName: "Last Name",
        width: 150,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "email",
        headerName: "Username",
        width: 250,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "usertype",
        headerName: "User Type",
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
          if (params.row.usertype?.includes("FIRM_ADMINISTRATOR")) {
            if (params.row.usertype?.includes("FIRM_ADMIN_NEW_MEMBER")) {
              return "Firm Admin & Filer";
            } else {
              return "Firm Admin";
            }
          } else if (params.row.usertype?.includes("INDIVIDUAL")) {
            return "Individual";
          } else if (
            params.row.usertype?.includes("FIRM_ADMIN_NEW_MEMBER") &&
            !params.row.usertype?.includes("FIRM_ADMINISTRATOR")
          ) {
            return "Filer";
          } else {
            return params.row.usertype;
          }
        },
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "country",
        headerName: "Country",
        width: 150,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "state",
        headerName: "State",
        width: 150,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "city",
        headerName: "City",
        width: 150,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "active",
        headerName: "Active",
        width: 350,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.active ? (
            <Typography color="error">No</Typography>
          ) : (
            <Typography color="green">Yes</Typography>
          );
        },
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
    ],
    []
  );

  const getRowId = useCallback((row: any) => row.userProfileId, []);

  const onFilter = useCallback(() => {
    const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (username !== "") {
      if (!regEmail.test(username)) {
        setValidEmail(false);
      } else {
        setValidEmail(true);
        allUsers.mutate({
          username: username || "",
          efmCountryCode: countryCode || "",
          efmStateCode: stateCode || "",
          userTypeCode: userTypeCode || "",
          activeCode: activeCode || "",
        });
      }
    } else {
      setValidEmail(true);
      allUsers.mutate({
        username: username || "",
        efmCountryCode: countryCode || "",
        efmStateCode: stateCode || "",
        userTypeCode: userTypeCode || "",
        activeCode: activeCode || "",
      });
    }
  }, [username, countryCode, stateCode, userTypeCode, activeCode, allUsers]);

  useEffect(() => {
    allUsers.mutate({
      username: username || "",
      efmCountryCode: countryCode || "",
      efmStateCode: stateCode || "",
      userTypeCode: userTypeCode || "",
      activeCode: activeCode || "",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (allUsers.isError) {
      createNotification({
        title: "User Notification",
        type: "error",
        message:
          allUsers.error.response?.data?.userMessage ||
          "Something went wrong while getting users",
      });
    }
    // eslint-disable-next-line
  }, [allUsers.isError, createNotification]);

  return (
    <Page>
      <div className="content-wrapper">
        <h2 className="dash-title-h2 mb-4">List of All Users</h2>

        <div className="filter-wrapper lighter-blue-bg mb-4 ps-4 pe-4 pb-1">
          <Grid container spacing={2} paddingY={2}>
            <Grid item sm={12} md={6} lg={4} className="mb-3">
              <TextField
                className="w-100"
                InputLabelProps={{
                  shrink: true,
                }}
                value={username}
                onChange={onUsernameChange}
                label="Username"
                placeholder="Username"
                variant="standard"
                helperText={isValidEmail ? "" : "Username must be an email"}
                error={!isValidEmail}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4} className="mb-3">
              <TextField
                className="w-100"
                select
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  displayEmpty: true,
                }}
                value={countryCode}
                onChange={onCountryChange}
                label="Country"
                variant="standard"
              >
                <MenuItem value="">All</MenuItem>
                {countries?.map((country) => (
                  <MenuItem
                    key={country.efmCountryCode}
                    value={country.efmCountryCode}
                  >
                    {country.efmCountryName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={12} md={6} lg={4} className="mb-3">
              <TextField
                className="w-100"
                select
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  displayEmpty: true,
                }}
                value={stateCode}
                onChange={onStateChange}
                label="State"
                variant="standard"
              >
                <MenuItem value="">All</MenuItem>
                {states?.map((state) => (
                  <MenuItem key={state.efmStateCode} value={state.efmStateCode}>
                    {state.efmStateName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={12} md={6} lg={4} className="mb-3">
              <TextField
                className="w-100"
                select
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  displayEmpty: true,
                }}
                value={userTypeCode}
                onChange={onUserTypeChange}
                label="User Type"
                variant="standard"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="FIRM_ADMINISTRATOR">Firm Admin</MenuItem>
                <MenuItem value="FIRM_ADMIN_NEW_MEMBER">Filer</MenuItem>
                <MenuItem value="FIRM_ADMINISTRATOR,FIRM_ADMIN_NEW_MEMBER">
                  Firm Admin & Filer
                </MenuItem>
                <MenuItem value="INDIVIDUAL">Individual</MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={12} md={6} lg={4} className="mb-3">
              <TextField
                className="w-100"
                select
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  displayEmpty: true,
                }}
                value={activeCode}
                onChange={onActiveChange}
                label="Active"
                variant="standard"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="1">No</MenuItem>
                <MenuItem value="0">Yes</MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={12} md={6} lg={4} className="mb-3">
              <Button
                className="btn primary-button w-100"
                variant="contained"
                onClick={onFilter}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </div>

        <div className="themeDataTable">
          <GridTable
            columns={columns}
            rows={allUsers.data}
            getRowId={getRowId}
            isLoading={allUsers.isLoading}
            hideFooter={false}
          />
        </div>
      </div>
    </Page>
  );
};

export default AllUsers;
