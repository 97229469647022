import { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import { Radio, RadioGroup, FormControlLabel, Tooltip} from '@mui/material';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

import Component from "./component";
import OptionalServices from "./optionalServices";
import {
  IDocumentAttachment,
  IFiling,
  IFilingComponent,
  IFilingFees,
  IOptionalService,
  IUploadResponse,
} from "../../../../interfaces";
import { validateCourtesyCopies } from "../../../../utills";

interface FilingProps {
  index: number;
  locationCode: string;
  filingTypes?: {
    efmFilingtypeCode: string;
    efmFilingtypeName: string;
    efm_fee: string;
  }[];
  components?: IFilingComponent[];
  validateComponentFileRequired?: number;
  disableServiceUpdate: boolean;
  isExistingCase?: boolean;
  isNonIndexedCase?: boolean;
  addAttachment: (attachment: IDocumentAttachment) => void;
  updateAttachment: (updatedAttachment: IDocumentAttachment) => void;
  removeAttachment: (sequence: string) => void;
  updateOptionalServices: (services: IOptionalService[]) => void;
  control: Control<
    {
      paymentAccount: string;
      filingAttorney: string;
      partyResponsibleForFeesIndex: number | string;
      filings: IFiling[];
      filingFees: IFilingFees[];
      caseInitiationFee: number;
      totalProviderFee: number;
      conveninceFee: number;
      feeCalculationAmount: number;
    },
    any
  >;
  watch: UseFormWatch<{
    paymentAccount: string;
    filingAttorney: string;
    partyResponsibleForFeesIndex: number | string;
    filings: IFiling[];
    filingFees: IFilingFees[];
    caseInitiationFee: number;
    totalProviderFee: number;
    conveninceFee: number;
    feeCalculationAmount: number;
  }>;
  setValue: UseFormSetValue<{
    paymentAccount: string;
    filingAttorney: string;
    partyResponsibleForFeesIndex: number | string;
    filings: IFiling[];
    filingFees: IFilingFees[];
    caseInitiationFee: number;
    totalProviderFee: number;
    conveninceFee: number;
    feeCalculationAmount: number;
  }>;
  updateUploadResponseDataState: (uploadResponseData: IUploadResponse) => void;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Filing: FC<FilingProps> = ({
  isExistingCase = false,
  isNonIndexedCase = false,
  index,
  locationCode,
  filingTypes,
  components,
  validateComponentFileRequired,
  disableServiceUpdate,
  addAttachment,
  updateAttachment,
  removeAttachment,
  updateOptionalServices,
  control,
  watch,
  setValue,
  updateUploadResponseDataState
}) => {
  const getAmount = useCallback((amount: string) => {
    if (!amount || amount === "0" || isNaN(parseFloat(amount))) return "";

    return `($${parseFloat(amount).toFixed(2)})`;
  }, []);

  const isServiceOnly = useMemo(
    () => watch(`filings.${index}.service`) && !watch(`filings.${index}.eFile`),
    [
      // eslint-disable-next-line
      watch(`filings.${index}.service`),
      // eslint-disable-next-line
      watch(`filings.${index}.eFile`),
      index,
      watch,
    ]
  );

  const onEFileChange = useCallback(
      (selectedValue: string) => {

      if (!isExistingCase) return;
        setValue(`filings.${index}.filingCode`, "");
        setValue(`filings.${index}.eFile`, true);
        setValue(`filings.${index}.service`, false); 
      setValue(`filings.${index}.attachments`, []);
      setValue(`filings.${index}.optionalServices`, []);
    },
    [isExistingCase, index, setValue]
  );

  const onServiceChange = useCallback(
      (selectedValue: string) => {
      setValue(`filings.${index}.filingCode`, "SERVICE ONLY");
      setValue(`filings.${index}.eFile`, false);
      setValue(`filings.${index}.service`, true);

      setValue(`filings.${index}.attachments`, []);
      setValue(`filings.${index}.optionalServices`, []);
    },
    [index, setValue, watch]
  );


  const onEFileAndServiceChange = useCallback(
      (selectedValue: string) => {
      setValue(`filings.${index}.filingCode`, "");
      setValue(`filings.${index}.eFile`, true);
      setValue(`filings.${index}.service`, true);

      setValue(`filings.${index}.attachments`, []);
      setValue(`filings.${index}.optionalServices`, []);
    },
    [index, setValue, watch]
  );

  const onFilingCodeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(`filings.${index}.filingCode`, event.target.value);
      setValue(`filings.${index}.attachments`, []);
      setValue(`filings.${index}.optionalServices`, []);
    },
    [index, setValue]
  );

  return (
    <Box>
      <Box display="flex">
      <Controller
        name={`filings.${index}.eFileType`}
        control={control}
        render={({ field }) => (
        <RadioGroup
          {...field}
          row
          value={field.value}
          onChange={(e) => {   
            const selectedValue = e.target.value;
            field.onChange(selectedValue);
            
            if (selectedValue === "E-File") {
              onEFileChange(selectedValue); 
            } else if (selectedValue === "Service") {
              onServiceChange(selectedValue); 
            } else if (selectedValue === "EFileAndService") {
              onEFileAndServiceChange(selectedValue);
            } 
          }}
        >
          <Tooltip title="Pick &quot;E-File&quot; to just electronically file your papers" placement="bottom-start">
          <FormControlLabel
            value="E-File"
            control={<Radio size="small" />}
            label="E-File"
            disabled={isNonIndexedCase} 
          />
          </Tooltip>
          <Tooltip title="Your filing will only be sent to the selected contacts and will not be filed with the court." placement="bottom-start">
          <FormControlLabel
            value="Service"
            control={<Radio size="small" />}
            label="Service"
            disabled={!isExistingCase} 
          />
          </Tooltip>
          <Tooltip title="Electronically file your papers with the court and have your documents sent to the selected service contacts" placement="bottom-start">
          <FormControlLabel
            value="EFileAndService"
            control={<Radio size="small" />}
            label="E-File and Service"
            disabled={!isExistingCase}
          />
          </Tooltip>
        </RadioGroup>
      )}
    />
      </Box>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xl={4} md={6} xs={12}>
            <Item className="remove-item-css">
              <Controller
                name={`filings.${index}.filingCode`}
                control={control}
                rules={{
                  required: "Error: Filing Code is required",
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    select
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                      htmlFor: `${field.name}_id`,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      SelectDisplayProps: {
                        "aria-required": true,
                      },
                    }}
                    inputProps={{
                      id: `${field.name}_id`,
                    }}
                    {...field}
                    onChange={onFilingCodeChange}
                    label="Filing Code"
                    variant="standard"
                    disabled={isServiceOnly}
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    {!isServiceOnly && (
                      <MenuItem value="">Select Filing Name</MenuItem>
                    )}
                    {!isServiceOnly &&
                      filingTypes?.map((filingType) => (
                        <MenuItem
                          key={filingType.efmFilingtypeCode}
                          value={filingType.efmFilingtypeCode}
                        >
                          {`${filingType.efmFilingtypeName} ${getAmount(
                            filingType.efm_fee
                          )}`}
                        </MenuItem>
                      ))}

                    {isServiceOnly && (
                      <MenuItem value="SERVICE ONLY">Service Only</MenuItem>
                    )}
                  </TextField>
                )}
              />
            </Item>
          </Grid>
          <Grid item xl={4} md={6} xs={12}>
            <Item className="remove-item-css">
              <Controller
                name={`filings.${index}.documentDescriptionText`}
                control={control}
                render={({ field }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    {...field}
                    placeholder="Filing Description"
                    label="Filing Description"
                    variant="standard"
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid item xl={4} md={6} xs={12}>
            <Item className="remove-item-css">
              <Controller
                name={`filings.${index}.referenceNumber`}
                control={control}
                render={({ field }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    {...field}
                    placeholder="Reference Number"
                    label="Reference Number"
                    variant="standard"
                  />
                )}
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xl={6} sm={12}>
            <Item className="remove-item-css">
              {components?.map((component) => (
                <Component
                  key={component.locationFilingcomponentCode}
                  component={component}
                  filingCode={watch(`filings.${index}.filingCode`)}
                  filingName={
                    filingTypes?.find(
                      (filingType) =>
                        filingType.efmFilingtypeCode ===
                        watch(`filings.${index}.filingCode`)
                    )?.efmFilingtypeName || "Service Only"
                  }
                  attachments={watch(`filings.${index}.attachments`)}
                  locationCode={locationCode}
                  validateComponentFileRequired={validateComponentFileRequired}
                  isExistingCase={isExistingCase}
                  isNonIndexedCase={isNonIndexedCase}
                  addAttachment={addAttachment}
                  updateAttachment={updateAttachment}
                  removeAttachment={removeAttachment}
                  updateUploadResponseDataState = {updateUploadResponseDataState}
                />
              ))}
            </Item>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Item className="remove-item-css">
                    <Controller
                      name={`filings.${index}.filingCommentsText`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          className="w-100"
                          id={field.name}
                          {...field}
                          placeholder="Filing Comments"
                          label="Filing Comments"
                          variant="standard"
                        />
                      )}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Item className="remove-item-css">
                    <Controller
                      name={`filings.${index}.courtesyCopiesText`}
                      control={control}
                      rules={{
                        validate: validateCourtesyCopies,
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          className="w-100"
                          id={field.name}
                          {...field}
                          placeholder="Courtesy Copies (Enter Email addresses separated by the comma)"
                          label="Courtesy Copies"
                          variant="standard"
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                        />
                      )}
                    />
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xl={6} sm={12}>
            <Item className="remove-item-css pick-list">
              <OptionalServices
                key={watch(`filings.${index}.filingCode`)}
                locationCode={locationCode}
                filingCode={watch(`filings.${index}.filingCode`)}
                disableServiceUpdate={disableServiceUpdate}
                selectedServices={watch(`filings.${index}.optionalServices`)}
                updateOptionalServices={updateOptionalServices}
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Filing;
