import { useCallback, useState } from "react";

import Page from "../../components/page/Page";
import SearchCase from "../existingCase/searchCase";
import { ICaseResponse } from "../../interfaces";

const SearchCases = () => {
  const [selectedCase, setSelectedCase] = useState<ICaseResponse>();

  const updateCase = useCallback((caseResponse: ICaseResponse) => {
    setSelectedCase(caseResponse);
  }, []);

  return (
    <Page>
      <div className="content-wrapper">
        <SearchCase
          selectedCase={selectedCase}
          updateCase={updateCase}
          pageTitle="searchCases"
        />
      </div>
    </Page>
  );
};
export default SearchCases;
