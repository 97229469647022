import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_LOCATION_CASE_CATEGORIES } from "../../URL";
import { getCloseFillingForLocation } from "../../../utills";

interface ICategory {
  locationCasecategoryId: number;
  efmCasecategoryCode: string;
  efmCasecategoryName: string;
  efmEcfCasetype: string;
}

const getLocationCaseCategories = async (locationCode?: string) => {
  if (!locationCode) return [];

  const closeFillingForLocation = getCloseFillingForLocation();
  if (closeFillingForLocation) {
    const locations = closeFillingForLocation.split(",");
    if (locations.includes(locationCode)) {
      return [];
    }
  }

  const response = await http.post<ICategory[]>(GET_LOCATION_CASE_CATEGORIES, {
    efmLocationCode: locationCode,
  });
  return response.data;
};

const useLocationCaseCategories = (locationCode?: string) =>
  useQuery<ICategory[]>({
    queryKey: ["location-case-categories", locationCode],
    queryFn: () => getLocationCaseCategories(locationCode),
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default useLocationCaseCategories;
