import { FC, useMemo } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import Dialog from "../../../../components/dialog";
import useAttorneys from "../../../../apis/firm/useAttorneys";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface AdditionalAttorneysProps {
  isOpen: boolean;
  attorneys: string[];
  closeModal: () => void;
}

const AdditionalAttorneys: FC<AdditionalAttorneysProps> = ({
  isOpen,
  attorneys,
  closeModal,
}) => {
  const { data: allAttorneys } = useAttorneys();

  const attorneyList = useMemo(() => {
    return allAttorneys?.filter((attorney) =>
      attorneys?.includes(attorney.attorneyID)
    );
  }, [allAttorneys, attorneys]);

  return (
    <Dialog
      title="Additional Attorneys"
      maxWidth="md"
      isOpen={isOpen}
      onClose={closeModal}
    >
      <List dense>
        <Grid container spacing={2} alignItems="center">
          {attorneyList?.map((attorney) => (
            <Grid item xs={12} sm={4}>
              <ListItem key={attorney.attorneyID} className="remove-item-css">
                <Item className="remove-item-css attornery-list">
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faCircleUser} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${attorney.firstName} ${attorney.lastName}`}
                  />
                </Item>
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </List>
    </Dialog>
  );
};

export default AdditionalAttorneys;
