import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { UPDATE_ATTORNEY } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface ICreateAttorneyBody {
  barNumber: string;
  firstName: string;
  lastName: string;
  middleName: string;
  attorneyID: string;
  firmAdminCheckbox: boolean;
  filerCheckbox: boolean;
  email: string;
  userId: string;
  firmID: string;
}

const updateAttorney = async (fields: ICreateAttorneyBody) => {
  await http.post(UPDATE_ATTORNEY, fields);
};

const useUpdateAttorney = () =>
  useMutation<void, IAxisErrorResponse, ICreateAttorneyBody>({
    mutationKey: ["update-attorney"],
    mutationFn: updateAttorney,
  });

export default useUpdateAttorney;
