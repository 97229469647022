import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { IServiceResponse } from "../../../interfaces";
import { GET_SERVICE_INFORMATION } from "../../URL";

interface IServiceInformation {
  caseTrackingId?: string;
  countyId?: string;
}

const serviceInformation = async (filters: IServiceInformation) => {
  const response = await http.post<IServiceResponse[]>(
    GET_SERVICE_INFORMATION,
    filters
  );
  return response.data;
};

const useGetServiceInfromation = (filters: IServiceInformation) =>
  useQuery<IServiceResponse[]>({
    queryKey: [
      "getServiceInformation",
      filters.caseTrackingId,
      filters.countyId,
    ],
    queryFn: () => serviceInformation(filters),
  });

export default useGetServiceInfromation;
