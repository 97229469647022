import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { UPDATE_FIRM_INFORMATION } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface IUpdateFirmInformation {
  firmName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  phoneNumber: string;
  firmID: string;
  individual: boolean;
}

const getUpdateFirmInformation = async (fields: IUpdateFirmInformation) => {
  await http.post(UPDATE_FIRM_INFORMATION, fields);
};

const useUpdateFirmInformation = () =>
  useMutation<void, IAxisErrorResponse, IUpdateFirmInformation>({
    mutationKey: ["update-firm-information"],
    mutationFn: getUpdateFirmInformation,
  });

export default useUpdateFirmInformation;
