import { ChangeEvent, FC, useCallback } from "react";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import {
  IDocumentAttachment,
  ILocationType,
} from "../../../../../../interfaces";

interface IUploadedFile {
  file: IDocumentAttachment;
  documentTypes?: ILocationType[];
  updateAttachment: (updatedAttachment: IDocumentAttachment) => void;
  removeAttachment: (sequence: string, binaryCategory?: string) => void;
}

const UploadedFile: FC<IUploadedFile> = ({
  file,
  documentTypes,
  updateAttachment,
  removeAttachment,
}) => {
  const onDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      updateAttachment({ ...file, binaryDescriptionText: event.target.value });
    },
    [file, updateAttachment]
  );

  const onSecurityUpdate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let generalSecurityText = documentTypes?.find(
        (type) => type.efmDocumenttypeCode === event.target.value
      )?.efmDocumenttypeName;

      updateAttachment({
        ...file,
        generalSecurityText,
        binaryFormatStandardName: event.target.value,
      });
    },
    [file, documentTypes, updateAttachment]
  );

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xl={6} xs={12}>
          <TextField
            className="w-100"
            id={`file-desc-${file.attachmentSequenceID}`}
            placeholder="Document Description"
            label="Description"
            variant="standard"
            value={file.binaryDescriptionText}
            onChange={onDescriptionChange}
            error={Boolean(
              !file.binaryDescriptionText || !file.generalSecurityText
            )}
          />
        </Grid>
        <Grid item xl={6} xs={12}>
          <TextField
            className="w-100"
            select
            InputLabelProps={{
              shrink: true,
              required: true,
              htmlFor: `file-security-${file.attachmentSequenceID}`,
            }}
            SelectProps={{
              displayEmpty: true,
              SelectDisplayProps: {
                "aria-required": true,
              },
            }}
            inputProps={{
              id: `file-security-${file.attachmentSequenceID}`,
            }}
            label="Security"
            variant="standard"
            value={
              documentTypes?.find(
                (item) => item.efmDocumenttypeName === file.generalSecurityText
              )?.efmDocumenttypeCode || ""
            }
            onChange={onSecurityUpdate}
          >
            <MenuItem disabled value="">
              Select Security
            </MenuItem>
            {documentTypes?.map((documentType) => (
              <MenuItem
                key={documentType.efmDocumenttypeCode}
                value={documentType.efmDocumenttypeCode}
              >
                {documentType.efmDocumenttypeName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              className="btn button-outline-secondary mar-0 "
              variant="contained"
              component="label"
              startIcon={<AddIcon />}
              disabled={true}
            >
              Upload Document
              <input
                hidden
                accept="application/pdf"
                multiple
                type="file"
                value=""
              />
            </Button>
          </Box>
        </Grid>
      </Grid>

      <span
        key={file.attachmentSequenceID}
        className="upload-error-msg d-block d-flex align-items-center justify-content-between mb-4"
      >
        {file.binaryLocationURI}
        <IconButton
          onClick={removeAttachment.bind(
            this,
            file.attachmentSequenceID,
            file.binaryCategoryText
          )}
        >
          <CloseIcon className="close-error" />
        </IconButton>
      </span>
    </>
  );
};

export default UploadedFile;
