import { FC, useCallback } from "react";
import Box from "@mui/material/Box";

import ManageServiceContact from "../manageServiceContact/ManageServiceContact";
import useAttorneys from "../../../apis/firm/useAttorneys";
import {
  IAttorney,
  ICaseResponse,
  IFilingInformation,
  IServiceResponse,
} from "../../../interfaces";

interface IServiceContact {
  caseInfo: ICaseResponse;
  filingInfo: IFilingInformation;
  selectedServiceContacts: string[];
  updateServiceContacts: (
    serviceContactIds: string[],
    serviceContacts: IServiceResponse[],
    tabIndex: number
  ) => void;
  createDraft: (
    filingsInfo: IFilingInformation,
    paymentAccount: string,
    filingAttorneyId?: string,
    partyResponsibleForFees?: number | string,
    attorneys?: IAttorney[],
    serviceContactIds?: string[],
    serviceContacts?: IServiceResponse[]
  ) => void;
}

const ServiceContacts: FC<IServiceContact> = ({
  caseInfo,
  filingInfo,
  selectedServiceContacts,
  updateServiceContacts,
  createDraft,
}) => {
  const { data: attorneys } = useAttorneys();

  const onCreateDraft = useCallback(
    (serviceContactIds: string[], serviceContacts: IServiceResponse[]) => {
      createDraft(
        filingInfo,
        filingInfo.paymentAccount || "",
        filingInfo.filingAttorney,
        filingInfo.partyResponsibleForFeesIndex,
        attorneys,
        serviceContactIds,
        serviceContacts
      );
    },
    [filingInfo, createDraft, attorneys]
  );

  return (
    <Box sx={{ p: 0 }} className="existing-case-parties">
      <div className="filings-row">
        <div className="d-flex justify-content-center">
          <span className="case-id-title">
            {`CASE # ${caseInfo.caseNumber} (${caseInfo.jurisdiction}) - ${caseInfo.description}`}
          </span>
        </div>
      </div>

      <ManageServiceContact
        isServiceContactTab
        caseDetails={caseInfo}
        caseTrackingId={caseInfo.caseTrackingId}
        countyId={caseInfo.locationId}
        onClose={() => {}}
        selectedServiceContacts={selectedServiceContacts}
        updateServiceContacts={updateServiceContacts}
        createDraft={onCreateDraft}
      />
    </Box>
  );
};

export default ServiceContacts;
