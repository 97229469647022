import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Header from "../landingPage/Header";

const FilingFeesDetail = () => {
  return (
    <div>
      <Header />
      <div className="content-wrapper">
        <div className="essentials-support-row mt-4">
          <Grid container spacing={4}>
            <Grid item xs={12} md={9} lg={10}>
              <div className="text-center">
                <h2 className="red-pastel mb-2">I2file Filing Fee Details</h2>
              </div>
            </Grid>
          </Grid>

          <Typography color="#0f336d !important" variant="h4">
            Effective: 07/01/2024
          </Typography>

          <Box pt={2} display="flex">
            Effective{" "}
            <Typography color="#0f336d" variant="h5" px={1}>
              July 1st, 2024
            </Typography>{" "}
            a{" "}
            <Typography color="#0f336d" variant="h5" px={1}>
              $1.00
            </Typography>{" "}
            I2file filing fee will be accessed for all accepted filings for all
            active courts in Illinois with below exceptions.
          </Box>

          <Typography pt={4} variant="h4">
            Exceptions
          </Typography>

          <Box>
            <List component="nav">
              <ListItem>
                <ListItemIcon>
                  <ArrowForwardIcon color="action" />
                </ListItemIcon>
                <Box>
                  <Typography>
                    There is no I2file filing fee to file into any county if you
                    are government agency or select Waiver as Payment Type.
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArrowForwardIcon color="action" />
                </ListItemIcon>
                <Box>
                  <Typography>
                    There is no I2file filing fee if the Court rejects your
                    filing.
                  </Typography>
                </Box>
              </ListItem>
            </List>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default FilingFeesDetail;
