import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_LOCATION_OPTIONAL_SERVICES } from "../../URL";
import { IOptionalServiceType } from "../../../interfaces";

const getLocationOptionalServices = async (
  locationCode?: string,
  filingTypeCode?: string
) => {
  if (!locationCode || !filingTypeCode || filingTypeCode === "SERVICE ONLY")
    return [];

  const response = await http.post<IOptionalServiceType[]>(
    GET_LOCATION_OPTIONAL_SERVICES,
    {
      efmLocationCode: locationCode,
      efmFilingtypeCode: filingTypeCode,
    }
  );
  return response.data;
};

const useLocationOptionalServices = (
  locationCode?: string,
  filingTypeCode?: string
) =>
  useQuery<IOptionalServiceType[]>({
    queryKey: ["location-optinal-srervices", locationCode, filingTypeCode],
    queryFn: () => getLocationOptionalServices(locationCode, filingTypeCode),
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default useLocationOptionalServices;
