import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { SAVE_ADMIN_USER_NOTIFICATIONS_TO_FILE } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface INotificationRequest {
  homePageNotification: string;
  homePageTicker: string;
}

const getUpdateUserNotification = async (request: INotificationRequest) => {
  await http.post(SAVE_ADMIN_USER_NOTIFICATIONS_TO_FILE, request);
};

const useUpdateUserNotification = () =>
  useMutation<void, IAxisErrorResponse, INotificationRequest>({
    mutationKey: ["userNotification"],
    mutationFn: getUpdateUserNotification,
  });

export default useUpdateUserNotification;
