import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_LOCATION_FILING_TYPES } from "../../URL";

interface IFilingType {
  efmFilingtypeCode: string;
  efmFilingtypeName: string;
  efm_fee: string;
  amountincontroversy?: boolean;
}

const getLocationFilingTypes = async (
  locationCode?: string,
  caseCategoryCode?: string,
  caseTypeCode?: string,
  filingType?: string
) => {
  if (!locationCode || !caseCategoryCode || !caseTypeCode) return [];

  const response = await http.post<IFilingType[]>(GET_LOCATION_FILING_TYPES, {
    efmLocationCode: locationCode,
    efmCasecategoryCode: caseCategoryCode,
    efmCasetypeCode: caseTypeCode,
    filingType: filingType || "initial",
  });
  return response.data;
};

const useLocationFilingTypes = (
  locationCode?: string,
  caseCategoryCode?: string,
  caseTypeCode?: string,
  filingType?: string
) =>
  useQuery<IFilingType[]>({
    queryKey: [
      "location-filing-types",
      locationCode,
      caseCategoryCode,
      caseTypeCode,
      filingType,
    ],
    queryFn: () =>
      getLocationFilingTypes(
        locationCode,
        caseCategoryCode,
        caseTypeCode,
        filingType
      ),
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default useLocationFilingTypes;
