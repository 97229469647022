import { FC, useCallback, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import Dialog from "../../../../components/dialog";
import useCreateCountyAdmin from "../../../../apis/countyAdmin/createCountyAdmin/useCreateCountyAdmin";
import useUpdateCountyAdmin from "../../../../apis/countyAdmin/updateCountyAdmin/useUpdateCountyAdmin";
import useLocations from "../../../../apis/common/useLocations";
import { NotificationContext } from "../../../../context/NotificationContext";
import { ICountyAdmin } from "../../../../interfaces";

interface CreateEditCountyAdminProps {
  countyAdmin?: ICountyAdmin;
  isOpen: boolean;
  onClose: (shouldUpdateGrid?: boolean) => void;
}

const CreateEditCountyAdmin: FC<CreateEditCountyAdminProps> = ({
  isOpen,
  countyAdmin,
  onClose,
}) => {
  const { control, handleSubmit, reset } = useForm<ICountyAdmin>({
    defaultValues: {
      firstname: countyAdmin?.firstname,
      middlename: countyAdmin?.middlename,
      lastname: countyAdmin?.lastname,
      username: countyAdmin?.username,
      countycode: countyAdmin?.countycode || "",
    },
  });

  const { data: location } = useLocations();
  const createCountyAdmin = useCreateCountyAdmin();
  const updateCountyAdmin = useUpdateCountyAdmin();
  const { createNotification } = useContext(NotificationContext);

  const onSubmit = useCallback(
    (data: ICountyAdmin) => {
      if (countyAdmin) {
        updateCountyAdmin.mutate({
          adminprofileid: countyAdmin.adminprofileid,
          firstname: data.firstname,
          middlename: data.middlename,
          lastname: data.lastname,
          username: data.username,
          countycode: data.countycode,
          isactive: countyAdmin.isactive,
        });
      } else {
        createCountyAdmin.mutate({
          firstname: data.firstname,
          middlename: data.middlename,
          lastname: data.lastname,
          username: data.username,
          countycode: data.countycode,
        });
      }
    },
    [countyAdmin, createCountyAdmin, updateCountyAdmin]
  );

  useEffect(() => {
    if (createCountyAdmin.isSuccess) {
      createNotification({
        title: "County Admin Notification",
        type: "success",
        message: "County Admin created successfully",
      });
      onClose(true);
      reset();
    }
    // eslint-disable-next-line
  }, [createCountyAdmin.isSuccess, createNotification]);

  useEffect(() => {
    if (createCountyAdmin.isError) {
      createNotification({
        title: "County Admin Notification",
        type: "error",
        message:
          createCountyAdmin.error.response?.data?.userMessage ||
          "Something went wrong while creating County Admin",
      });
    }
    // eslint-disable-next-line
  }, [createCountyAdmin.isError, createNotification]);

  useEffect(() => {
    if (updateCountyAdmin.isSuccess) {
      createNotification({
        title: "County Admin Notification",
        type: "success",
        message: "County Admin updated successfully",
      });
      onClose(true);
    }
    // eslint-disable-next-line
  }, [updateCountyAdmin.isSuccess, createNotification]);

  useEffect(() => {
    if (updateCountyAdmin.isError) {
      createNotification({
        title: "County Admin Notification",
        type: "error",
        message:
          updateCountyAdmin.error.response?.data?.userMessage ||
          "Something went wrong while updating County Admin",
      });
    }
    // eslint-disable-next-line
  }, [updateCountyAdmin.isError, createNotification]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose.bind(this, false)}
      title={countyAdmin ? "Edit County Admin" : "Create County Admin"}
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              name="firstname"
              control={control}
              rules={{
                required: "Error: First Name is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  inputRef={ref}
                  autoFocus
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{
                    required: true,
                  }}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="First Name"
                  label="First Name"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="middlename"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Middle Name"
                  label="Middle Name"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="lastname"
              control={control}
              rules={{
                required: "Error: Last Name is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  inputRef={ref}
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{
                    required: true,
                  }}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Last Name"
                  label="Last Name"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="username"
              control={control}
              rules={{
                required: "Error: Email Address is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Error: Invalid email.",
                },
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  inputRef={ref}
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{ required: true }}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Email Address"
                  label="Email Address"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="countycode"
              control={control}
              rules={{
                required: "Error: Location is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  inputRef={ref}
                  inputProps={{
                    "aria-required": true,
                  }}
                  select
                  InputLabelProps={{
                    required: true,
                    shrink: true,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    SelectDisplayProps: {
                      "aria-required": true,
                    },
                  }}
                  {...field}
                  label="Location"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                >
                  <MenuItem value="">Select Location</MenuItem>
                  {location?.map((county) => (
                    <MenuItem
                      key={county.efm_Location_Code}
                      value={county.efm_Location_Code}
                    >
                      {county.efm_Location_Name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid xs={12} pt={4} display="flex" justifyContent="center">
            <Button
              className="btn button-outline-secondary me-3"
              variant="contained"
              type="button"
              onClick={onClose.bind(this, false)}
            >
              Cancel
            </Button>

            <Button
              className="btn primary-button"
              variant="contained"
              type="submit"
            >
              {createCountyAdmin.isLoading || updateCountyAdmin.isLoading ? (
                <CircularProgress
                  sx={{
                    height: "25px !important",
                    width: "25px !important",
                    "--CircularProgress-size": "8px",
                    "--CircularProgress-trackThickness": "1px",
                    "--CircularProgress-progressThickness": "1px",
                  }}
                  color="inherit"
                />
              ) : (
                "Save"
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default CreateEditCountyAdmin;
