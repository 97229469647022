import { useMutation } from "@tanstack/react-query";

import http from "../../http";
import { CASE_SEARCH_LIST } from "../URL";

interface ISearchCaseResponse {
  responseObject: {
    caseCategory: string;
    caseNumber: string;
    caseType: string;
    caseTypeName: string;
    description: string;
    partyAddress: string;
    partyFirstName: string;
    partyLastName: string;
    partyMiddleName: string;
    partyRoleCode: string;
  }[];
  tylerMessageCode: string;
  tylerMessageDescription: string;
  searchCaseCount: number;
}

interface ISearchCaseRequest {
  countyId: string;
  caseNumber: string;
  searchBy: string;
  location: string;
  firstName: string;
  middleName: string;
  surname: string;
  businessName: string;
  caseTypeFilter: string;
  sortByFilter: string;
  captchaValue?: string;
}

interface IAxisErrorResponse {
  response?: {
    data?: {
      userMessage?: string;
    };
  };
}

const getSearchCase = async (filters: ISearchCaseRequest) => {
  const response = await http.post<ISearchCaseResponse>(
    CASE_SEARCH_LIST,
    filters
  );
  return response.data;
};

const useSearchCase = () =>
  useMutation<ISearchCaseResponse, IAxisErrorResponse, ISearchCaseRequest>({
    mutationKey: ["search-case"],
    mutationFn: getSearchCase,
  });

export default useSearchCase;
