import { FC, useCallback, useContext, useEffect } from "react";

import DeleteDialog from "../../../components/DeleteDialog";
import useDeletePaymentAccount from "../../../apis/miscellaneous/useDeletePaymentAccount";
import { NotificationContext } from "../../../context/NotificationContext";
import { IPaymentAccount } from "../../../interfaces";

interface IDeleteAttorney {
  isOpen: boolean;
  paymentAccount?: IPaymentAccount;
  onClose: (shouldUpdateGrid?: boolean) => void;
}

const DeleteAttorney: FC<IDeleteAttorney> = ({
  isOpen,
  paymentAccount,
  onClose,
}) => {
  const deletePatmentAccount = useDeletePaymentAccount();
  const { createNotification } = useContext(NotificationContext);

  const onDelete = useCallback(() => {
    if (paymentAccount?.paymentAccountID) {
      deletePatmentAccount.mutate(paymentAccount?.paymentAccountID);
    } else {
      onClose();
    }
    // eslint-disable-next-line
  }, [paymentAccount?.paymentAccountID]);

  useEffect(() => {
    if (deletePatmentAccount.isSuccess) {
      createNotification({
        title: "Payment Notification",
        type: "success",
        message: "Payment Account removed successfully",
      });
      onClose(true);
    }
    // eslint-disable-next-line
  }, [deletePatmentAccount.isSuccess, createNotification]);

  useEffect(() => {
    if (deletePatmentAccount.isError) {
      createNotification({
        title: "Payment Notification",
        type: "error",
        message:
          deletePatmentAccount.error.response?.data?.userMessage ||
          "Something went wrong while removing Payment Account",
      });
    }
    // eslint-disable-next-line
  }, [deletePatmentAccount.isError, createNotification]);

  return (
    <DeleteDialog
      entity="Payment Account"
      isOpen={isOpen}
      isLoading={deletePatmentAccount.isLoading}
      onClose={onClose.bind(this, false)}
      onDelete={onDelete}
    />
  );
};

export default DeleteAttorney;
