import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { CREATE_NONINDEXED_CASE_DRAFT } from "../../URL";
import { IFeeCalculationRequest } from "../../../interfaces";

interface IAxisErrorResponse {
  response?: {
    data?: string;
  };
}

const createNonIndexDraft = async (filing: IFeeCalculationRequest) => {
  const response = await http.post<number>(
    CREATE_NONINDEXED_CASE_DRAFT,
    filing
  );
  return response?.data;
};

const useCreateNonIndexDraft = () =>
  useMutation<number, IAxisErrorResponse, IFeeCalculationRequest>({
    mutationKey: ["create-nonindex-case-draft"],
    mutationFn: createNonIndexDraft,
  });

export default useCreateNonIndexDraft;
