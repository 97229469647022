import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_ATTACHED_CASES } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface ICase {
  caseNumber: string;
  description: string;
}

const getAttachedCases = async (serviceContactId?: string) => {
  if (!serviceContactId) return [];

  const response = await http.post<ICase[]>(GET_ATTACHED_CASES, {
    serviceContactId,
  });

  return response.data;
};

const useAttachedCases = (serviceContactId?: string) =>
  useQuery<ICase[], IAxisErrorResponse>({
    queryKey: ["attached-cases", serviceContactId],
    queryFn: () => getAttachedCases(serviceContactId),
  });

export default useAttachedCases;
