import { FC } from "react";

import Dialog from "../dialog";
import Button from "@mui/material/Button";

interface ConfirmationDialogProps {
  isOpen: boolean;
  title: string;
  content: string;
  maxWidth?: "lg" | "sm" | "md" | "xs" | "xl";
  onClose: () => void;
  onConfirmation: () => void;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  isOpen,
  title,
  content,
  maxWidth,
  onClose,
  onConfirmation,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      maxWidth={maxWidth || "sm"}
      action={
        <>
          <Button
            type="button"
            variant="contained"
            className="btn button-outline-secondary"
            onClick={onClose}
          >
            No
          </Button>
          <Button
            type="button"
            variant="contained"
            className="btn primary-button ms-2"
            onClick={onConfirmation}
            autoFocus
          >
            Yes
          </Button>
        </>
      }
    >
      {content}
    </Dialog>
  );
};

export default ConfirmationDialog;
