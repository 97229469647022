import { useMutation } from "@tanstack/react-query";

import http from "../../../../http";
import { CREATE_FIRM_USER } from "../../../URL";
import { IAxisErrorResponse } from "../../../../interfaces";

interface ICreateFirmUserBody {
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  attorneyNumber?: string;
  firmAdminCheckbox: boolean;
  filerCheckbox: boolean;
  attorneyCheckbox: boolean;
}

const createFirmUser = async (fields: ICreateFirmUserBody) => {
  await http.post(CREATE_FIRM_USER, fields);
};

const useCreateFirmUser = () =>
  useMutation<void, IAxisErrorResponse, ICreateFirmUserBody>({
    mutationKey: ["create-firm-user"],
    mutationFn: createFirmUser,
  });

export default useCreateFirmUser;
