import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { COPY_ENVELOPE } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface IEnvelope {
  efmRequest: string;
}

const copyTransaction = async (efmEnvelopeId: string) => {
  const response = await http.post(COPY_ENVELOPE, { efmEnvelopeId });

  return response.data;
};

const useCopyEnvelope = () =>
  useMutation<IEnvelope, IAxisErrorResponse, string>({
    mutationKey: ["copy-transaction"],
    mutationFn: copyTransaction,
  });

export default useCopyEnvelope;
