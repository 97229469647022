import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_LOCATION_CASE_TYPES_SUBSEQUENT_FILING } from "../../URL";
import { getCloseFillingForLocation } from "../../../utills";

interface ICategorySubsequentFiling {
    locationCasetypeId: number;
    efmCasetypeCode: string;
    efmCasetypeName: string;
  }
  

const getLocationCaseCategorySubsequentFiling = async (locationCode?: string ) => {
    if (!locationCode) return [];
  
    const closeFillingForLocation = getCloseFillingForLocation();
    if (closeFillingForLocation) {
      const locations = closeFillingForLocation.split(",");
      if (locations.includes(locationCode)) {
        return [];
      }
    }
  
    const response = await http.post<ICategorySubsequentFiling[]>(GET_LOCATION_CASE_TYPES_SUBSEQUENT_FILING, {
      efmLocationCode: locationCode,
      efmCasecategoryCode : "-1",
    });
    return response.data;
  };
  
  const useLocationCaseCategoriesSubsequentFiling = (locationCode?: string) =>
    useQuery<ICategorySubsequentFiling[]>({
      queryKey: ["location-case-categories-subsequentFiling", locationCode],
      queryFn: () => getLocationCaseCategorySubsequentFiling(locationCode),
      networkMode: "offlineFirst",
      staleTime: Infinity,
    });
  
  export default useLocationCaseCategoriesSubsequentFiling;
  