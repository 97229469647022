import { useCallback, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import Page from "../../components/page";
import useChangePassword from "../../apis/auth/useChangePassword";
import { NotificationContext } from "../../context/NotificationContext";
import changepasswordimg from "../../assets/images/change-password-img.svg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface IChangePasswordForm {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  passwordQuestion: string;
  passwordAnswer: string;
}

const ChangePassword = () => {
  const changePassword = useChangePassword();
  const navigate = useNavigate();
  const { createNotification } = useContext(NotificationContext);

  const { control, handleSubmit, reset } = useForm<IChangePasswordForm>({
    resolver: yupResolver(
      yup
        .object({
          oldPassword: yup
            .string()
            .trim()
            .required("Error: Old Password is required."),
          newPassword: yup
            .string()
            .trim()
            .required("Error: New Password is required."),
          confirmNewPassword: yup
            .string()
            .trim()
            .required("Error: New Password is required.")
            .oneOf([yup.ref("newPassword")], "Password does not match!"),
          passwordQuestion: yup
            .string()
            .trim()
            .required("Error: Security Question is required."),
          passwordAnswer: yup
            .string()
            .trim()
            .required("Error: Security Answer is required."),
        })
        .required()
    ),
  });

  const onSubmit = useCallback(
    (data: IChangePasswordForm) => {
      changePassword.mutate({
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
        passwordQuestion: data.passwordQuestion,
        passwordAnswer: data.passwordAnswer,
      });
    },
    [changePassword]
  );

  const navigateToDashboard = useCallback(
    () => navigate("/dashboard"),
    [navigate]
  );

  useEffect(() => {
    if (changePassword.isSuccess) {
      if (changePassword.data.error.errorCode === "0") {
        createNotification({
          title: "Change Password Notification",
          type: "success",
          message: "Password changed successfully",
        });
        reset();
      } else {
        createNotification({
          title: "Change Password Notification",
          type: "error",
          message: changePassword.data.error.errorText,
        });
      }
    }
    // eslint-disable-next-line
  }, [changePassword.isSuccess, createNotification]);

  useEffect(() => {
    if (changePassword.isError) {
      createNotification({
        title: "Change Password Notification",
        type: "error",
        message:
          changePassword.error.response?.data?.userMessage ||
          "Something went wrong while updating password",
      });
    }
    // eslint-disable-next-line
  }, [changePassword.isError, createNotification]);

  return (
    <Page>
      <div className="content-wrapper">
        <h2 className="dash-title-h2">Change Password</h2>

        <div className="myprofile-wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={2}
              className="myprofile-wrapper2 text-center mt-4"
            >
              <Grid item xs={12} md={4}>
                <div>
                  <img
                    src={changepasswordimg}
                    alt="An illustration of man with Key, lock and mobile"
                    className="w-100"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <Item className="remove-item-css mb-4">
                  <Controller
                    name="oldPassword"
                    control={control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        InputLabelProps={{
                          required: true,
                        }}
                        inputRef={ref}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        label="Old Password"
                        placeholder="************"
                        type="password"
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    )}
                  />
                </Item>
                <Item className="remove-item-css mb-4">
                  <Controller
                    name="newPassword"
                    control={control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        InputLabelProps={{
                          required: true,
                        }}
                        inputRef={ref}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        label="New Password"
                        placeholder="************"
                        type="password"
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    )}
                  />
                </Item>
                <Item className="remove-item-css mb-4">
                  <Controller
                    name="confirmNewPassword"
                    control={control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        InputLabelProps={{
                          required: true,
                        }}
                        inputRef={ref}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        label="Re-enter New Password"
                        placeholder="************"
                        type="password"
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    )}
                  />
                </Item>
                <Item className="remove-item-css mb-4">
                  <Controller
                    name="passwordQuestion"
                    control={control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        InputLabelProps={{
                          required: true,
                        }}
                        inputRef={ref}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        label="Security Question"
                        placeholder="Security Question"
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    )}
                  />
                </Item>
                <Item className="remove-item-css mb-4">
                  <Controller
                    name="passwordAnswer"
                    control={control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        InputLabelProps={{
                          required: true,
                        }}
                        inputRef={ref}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        label="Security Answer"
                        placeholder="Security Answer"
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    )}
                  />
                </Item>
                <Item className="remove-item-css">
                  <div className="text-center bottom-button-row mt-5">
                    <Button
                      className="btn button-outline-secondary me-3"
                      variant="contained"
                      type="button"
                      onClick={navigateToDashboard}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn primary-button"
                      variant="contained"
                      type="submit"
                    >
                      {changePassword.isLoading ? (
                        <CircularProgress
                          sx={{
                            height: "25px !important",
                            width: "25px !important",
                            "--CircularProgress-size": "8px",
                            "--CircularProgress-trackThickness": "1px",
                            "--CircularProgress-progressThickness": "1px",
                          }}
                          color="inherit"
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </Item>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </Page>
  );
};
export default ChangePassword;
