import { FC, useCallback, useContext, useEffect } from "react";

import useDeleteDraft from "../../../apis/draft/useDeleteDraft";
import { NotificationContext } from "../../../context/NotificationContext";
import DeleteDialog from "../../../components/DeleteDialog";

interface DeleteDraftProps {
  isOpen: boolean;
  transactionId: number;
  onClose: (shouldUpdateGrid?: boolean) => void;
}

const DeleteDraft: FC<DeleteDraftProps> = ({
  isOpen,
  transactionId,
  onClose,
}) => {
  const deleteDraft = useDeleteDraft();
  const { createNotification } = useContext(NotificationContext);

  const onDelete = useCallback(async () => {
    if (transactionId) {
      await deleteDraft.mutateAsync(transactionId);
    } else {
      onClose();
    }
  }, [transactionId, deleteDraft, onClose]);

  useEffect(() => {
    if (deleteDraft.isSuccess) {
      createNotification({
        title: "Draft Notification",
        type: "success",
        message: "Draft discarded!",
      });
      onClose(true);
    }
    // eslint-disable-next-line
  }, [deleteDraft.isSuccess]);

  useEffect(() => {
    if (deleteDraft.isError) {
      createNotification({
        title: "Draft Notification",
        type: "error",
        message: "An Error occurred while cancelling your draft!",
      });
      onClose(true);
    }
    // eslint-disable-next-line
  }, [deleteDraft.isError]);

  return (
    <DeleteDialog
      entity="Draft"
      isOpen={isOpen}
      isLoading={deleteDraft.isLoading}
      onClose={onClose.bind(this, false)}
      onDelete={onDelete}
    />
  );
};

export default DeleteDraft;
