import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { CHANGE_PASSWORD } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface IChangrPasswordRequest {
  oldPassword: string;
  newPassword: string;
  passwordQuestion: string;
  passwordAnswer: string;
}

interface IProfileResponse {
  error: {
    errorCode: string;
    errorText: string;
  };
}

const changePassword = async (fields: IChangrPasswordRequest) => {
  const response = await http.post<IProfileResponse>(CHANGE_PASSWORD, fields);
  return response.data;
};

const useChangePassword = () =>
  useMutation<IProfileResponse, IAxisErrorResponse, IChangrPasswordRequest>({
    mutationKey: ["change-password"],
    mutationFn: changePassword,
  });

export default useChangePassword;
