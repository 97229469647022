import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_LOCATION_PARTY_TYPES } from "../../URL";

interface IParty {
  dateOfDeath: string;
  efmCasetypeCode: string;
  efmPartytypeCode: string;
  efmPartytypeName: string;
}

const getLocationPartyTypes = async (
  locationCode?: string,
  caseTypeCode?: string
) => {
  if (!locationCode || !caseTypeCode) return [];

  const response = await http.post<IParty[]>(GET_LOCATION_PARTY_TYPES, {
    efmLocationCode: locationCode,
    efmCasetypeCode: caseTypeCode,
  });
  return response.data;
};

const useLocationPartyTypes = (locationCode?: string, caseTypeCode?: string) =>
  useQuery<IParty[]>({
    queryKey: ["location-party-types", locationCode, caseTypeCode],
    queryFn: () => getLocationPartyTypes(locationCode, caseTypeCode),
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default useLocationPartyTypes;
