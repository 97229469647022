import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import Dialog from "../dialog";

interface DeleteDialogProps {
  isOpen: boolean;
  isLoading: boolean;
  entity: string;
  onClose: () => void;
  onDelete: () => void;
}

const FilingDeleteDialog: FC<DeleteDialogProps> = ({
  isOpen,
  isLoading,
  entity,
  onDelete,
  onClose,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Cancel Filing Confirmation"
      actionClassName="confirmation-dialog-action"
      maxWidth="sm"
      action={
        <>
          <div className="text-center">
            <Button
              type="button"
              variant="contained"
              className="btn button-outline-secondary"
              onClick={onClose}
            >
              No
            </Button>
            <Button
              type="button"
              variant="contained"
              className="btn primary-button ms-2"
              onClick={onDelete}
              autoFocus
            >
              {isLoading ? (
                <CircularProgress
                  sx={{
                    height: "25px !important",
                    width: "25px !important",
                    "--CircularProgress-size": "8px",
                    "--CircularProgress-trackThickness": "1px",
                    "--CircularProgress-progressThickness": "1px",
                  }}
                  color="inherit"
                />
              ) : (
                "Yes"
              )}
            </Button>
          </div>
        </>
      }
    >
      <Box>
        <div className="text-center mb-3">
          {/* <DeleteIcon className="dlt-error-color" sx={{ fontSize: 50 }} /> */}
          <span className="material-symbols-outlined dlt-error-color delete-icon-size">
            cancel
          </span>
        </div>
        <Typography className="remove-item-css text-center" pl={1}>
          <b>Are you sure you want to cancel this {entity} ?</b>
        </Typography>
      </Box>
    </Dialog>
  );
};

export default FilingDeleteDialog;
