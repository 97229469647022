import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { CREATE_SERVICE_CONTACT } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface ICreateServiceContact {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  administrativeCopy: string;
  addressType: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  };
}

const createServiceContact = async (fields: ICreateServiceContact) => {
  const response = await http.post(CREATE_SERVICE_CONTACT, {
    ...fields,
    inFirmMasterList: true,
  });

  return response.data.serviceContactID;
};

const useCreateServiceContact = () =>
  useMutation<string, IAxisErrorResponse, ICreateServiceContact>({
    mutationKey: ["create-service-contact"],
    mutationFn: createServiceContact,
  });

export default useCreateServiceContact;
