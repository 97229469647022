import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

import useCountries from "../../../apis/common/useCountries";
import useStates from "../../../apis/common/useStates/useStates";
import { IRegisterFirmInformation } from "../../../interfaces";
import { getZipCodePlaceholder, validateZipCode } from "../../../utills";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface FirmInformationProps {
  isFirmAccount?: boolean;
  firmInformation?: IRegisterFirmInformation;
  setFirmInformation: (data: IRegisterFirmInformation) => void;
}

const FirmInformation: FC<FirmInformationProps> = ({
  isFirmAccount,
  firmInformation,
  setFirmInformation,
}) => {
  const { watch, control, handleSubmit } = useForm<IRegisterFirmInformation>({
    defaultValues: firmInformation ? firmInformation : { countryCode: "US" },
  });

  const { data: countries } = useCountries();
  const { data: states } = useStates(watch("countryCode"));
  const navigate = useNavigate();

  const onSubmit = useCallback(
    (data: IRegisterFirmInformation) => {
      setFirmInformation(data);
    },
    [setFirmInformation]
  );

  return (
    <Box sx={{ p: 0 }}>
      <div className="helpful-information-sec">
        <h5 className="black-text">
          <InfoIcon className="vertical-align-text-top" />
          <span className="ps-1"> Helpful Information</span>
        </h5>
        <ul className="helpful-information-list">
          <li>
            Do not use this option if your firm has already created a firm
            account with ODYSSEY EFM System through certified service providers.
            If your firm has already created a firm account with us, ask your
            administrator to register you.
          </li>
          <li>
            If you are a single user, enter your name in Firm/Attorney Name
            field.
          </li>
        </ul>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="firm-info-main">
        <Grid container spacing={2}>
          {isFirmAccount && (
            <Grid item xs={12}>
              <Item className="remove-item-css">
                <Controller
                  name="firmName"
                  control={control}
                  rules={{
                    required: "Error: Firm/Attorney Name is required.",
                  }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      className="w-100"
                      id={field.name}
                      inputRef={ref}
                      inputProps={{
                        "aria-required": true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      {...field}
                      onBlur={() => {
                        field.onChange(field.value?.trim());
                        field.onBlur();
                      }}
                      placeholder="Firm/Attorney Name"
                      label="Firm/Attorney Name"
                      variant="standard"
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                    />
                  )}
                />
              </Item>
            </Grid>
          )}
          <Grid className="" item xs={6}>
            <Item className="remove-item-css">
              <Controller
                name="streetAddressLine1"
                control={control}
                rules={{
                  required: "Error: Address Line 1 is required.",
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    inputProps={{
                      "aria-required": true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    placeholder="Address Line 1"
                    label="Address Line 1"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid className="" item xs={6}>
            <Item className="remove-item-css">
              <Controller
                name="streetAddressLine2"
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    placeholder="Address Line 2"
                    label="Address Line 2"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid className="" item xs={6}>
            <Item className="remove-item-css">
              <Controller
                name="countryCode"
                control={control}
                rules={{
                  required: "Error: Country is required.",
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    inputProps={{
                      "aria-required": true,
                      id: `${field.name}_id`,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                      htmlFor: `${field.name}_id`,
                    }}
                    {...field}
                    select
                    SelectProps={{
                      SelectDisplayProps: {
                        "aria-required": true,
                      },
                    }}
                    placeholder="Country"
                    label="Country"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    {countries?.map((country) => (
                      <MenuItem
                        key={country.efmCountryCode}
                        value={country.efmCountryCode}
                      >
                        {country.efmCountryName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Item>
          </Grid>
          <Grid className="" item xs={6}>
            <Item className="remove-item-css">
              <Controller
                name="stateCode"
                control={control}
                rules={{
                  required: "Error: State is required.",
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    inputProps={{
                      "aria-required": true,
                      id: `${field.name}_id`,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                      htmlFor: `${field.name}_id`,
                    }}
                    {...field}
                    select
                    SelectProps={{
                      SelectDisplayProps: {
                        "aria-required": true,
                      },
                    }}
                    placeholder="State"
                    label="State"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    {states?.map((state) => (
                      <MenuItem
                        key={state.efmStateCode}
                        value={state.efmStateCode}
                      >
                        {state.efmStateName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Item>
          </Grid>
          <Grid className="" item xs={4}>
            <Item className="remove-item-css">
              <Controller
                name="city"
                control={control}
                rules={{
                  required: "Error: City is required.",
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    inputProps={{
                      "aria-required": true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    placeholder="City"
                    label="City"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid className="" item xs={4}>
            <Item className="remove-item-css">
              <Controller
                name="zipCode"
                control={control}
                rules={{
                  required: "Error: Zip Code is required.",
                  validate: validateZipCode.bind(this, "countryCode"),
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    inputProps={{
                      "aria-required": true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    placeholder={getZipCodePlaceholder(watch("countryCode"))}
                    label="Zip Code"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid className="" item xs={4}>
            <Item className="remove-item-css">
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: "Error: Phone Number is required.",
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    inputProps={{
                      "aria-required": true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    {...field}
                    onBlur={() => {
                      field.onChange(field.value?.trim());
                      field.onBlur();
                    }}
                    label="Phone Number"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Item>
          </Grid>
        </Grid>
        <div className="user-sgreement-sec mt-4 pt-2">
          <span className="mb-1 d-block">User Agreement*:</span>
          <ul className="helpful-information-list">
            <li>
              <Controller
                name="odysseyUserAgreement"
                control={control}
                rules={{
                  required: "required",
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <InputLabel>
                    <Checkbox
                      {...field}
                      inputRef={ref}
                      checked={field.value}
                      inputProps={{
                        "aria-required": true,
                      }}
                      sx={{
                        color: fieldState.error ? "#d32f2f" : undefined,
                        "& .MuiSvgIcon-root": { fontSize: 30 },
                      }}
                      className="ps-0"
                    />
                    <span className="checkbox-align">
                      I agree to the{" "}
                      <a
                        href="/assets/Odyssey_Terms_and_Conditions-Illinois.pdf"
                        target="_blank"
                        className="checkbox-link"
                        aria-label="Odyssey eFileIL User Agreement"
                      >
                        Odyssey eFileIL User Agreement
                      </a>
                    </span>
                  </InputLabel>
                )}
              />
            </li>
            <li>
              <Controller
                name="i2FileUserAgreement"
                control={control}
                rules={{
                  required: "required",
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <InputLabel>
                    <Checkbox
                      {...field}
                      inputRef={ref}
                      checked={field.value}
                      inputProps={{
                        "aria-required": true,
                      }}
                      sx={{
                        color: fieldState.error ? "#d32f2f" : undefined,
                        "& .MuiSvgIcon-root": { fontSize: 30 },
                      }}
                      className="ps-0"
                    />
                    <span className="checkbox-align">
                      I agree to the{" "}
                      <a
                        href="/assets/I2F_Terms_And_Conditions.pdf"
                        target="_blank"
                        className="checkbox-link"
                        aria-label="I2File User Agreement"
                      >
                        I2File User Agreement
                      </a>
                    </span>
                  </InputLabel>
                )}
              />
            </li>
          </ul>
        </div>
        <div className="text-center bottom-button-row">
          <Button
            className="btn button-outline-secondary me-3"
            variant="contained"
            type="button"
            onClick={() => navigate("/")}
          >
            Cancel
          </Button>
          <Button
            className="btn primary-button"
            variant="contained"
            type="submit"
          >
            Next
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default FirmInformation;
