import { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFormWatch,
} from "react-hook-form";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import SearchIcon from "@mui/icons-material/Search";

import AdditionalAttorneys from "../../../newCase/parties/additionalAttorneys";
import useAttorneys from "../../../../apis/firm/useAttorneys";
import { isIndividualUser } from "../../../../utills";
import { IParty } from "../../../../interfaces";
import Input from "@mui/material/Input/Input";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";

interface IExistingParty {
  control: Control<
    {
      parties: IParty[];
    },
    any
  >;
  field: FieldArrayWithId<
    {
      parties: IParty[];
    },
    "parties",
    "id"
  >;
  watch: UseFormWatch<{
    parties: IParty[];
  }>;
  index: number;
}

const ExistingParty: FC<IExistingParty> = ({
  control,
  field,
  watch,
  index,
}) => {
  const [isAdditionalAttorneysModalOpen, setIsAdditionalAttorneysModalOpen] =
    useState(false);
  const { data: attorneys } = useAttorneys();
  const [searchText, setSearchText] = useState("");

  const openAdditionalAttorneysModal = useCallback(() => {
    setIsAdditionalAttorneysModalOpen(true);
  }, []);

  const closeAdditionalAttorneysModal = useCallback(() => {
    setIsAdditionalAttorneysModalOpen(false);
  }, []);

  const onAttorneySearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  }, []);

  const getAttorneyName = useMemo(() => {
    return (selectedIds: string[]) => {
      const attorneyNames = selectedIds.map((selectedId) => {
        return `${
          attorneys?.find((e) => e.attorneyID === selectedId)?.firstName
        } ${attorneys?.find((e) => e.attorneyID === selectedId)?.lastName}`;
      });

      return attorneyNames;
    };
  }, [attorneys]);

  return (
    <div className="case-filer-dtl">
      <Box display="flex" justifyContent="space-between">
        <div className="case-filer-dtl-top">
          <h4>{field.name}</h4>
        </div>
      </Box>

      <div className="case-dtl-row">
        <Grid container spacing={4}>
          <Grid item md={6} sm={12} mt={2}>
            <p>
              <span className="d-block">Party Name</span>
              <label className="d-block">
                {field.businessName
                  ? field.businessName
                  : `${field.firstName || ""} ${field.lastName || ""}`}
              </label>
            </p>
          </Grid>

          <Grid item md={6} sm={12} mt={2}>
            <p>
              <span className="d-block">Address</span>
              <label className="d-block">
                {field.streetAddressLine1 || ""}
                {field.streetAddressLine1 && <br />}
                {field.streetAddressLine2 ? field.streetAddressLine2 + " " : ""}
                {field.city ? field.city + ", " : ""}
                {field.stateCode ? field.stateCode + " " : ""}
                {field.zipCode || ""}
              </label>
            </p>
          </Grid>

          <Grid item xs={6}>
            {field.existingLeadAttorney ? (
              <p>
                <span className="d-block">Lead Attorney</span>
                <label className="d-block">
                  {`${field.existingLeadAttorney.attorneyFirstName} ${field.existingLeadAttorney.attorneyMiddleName} ${field.existingLeadAttorney.attorneyLastName}`}
                </label>
              </p>
            ) : (
              <Controller
                name={`parties.${index}.leadAttorney`}
                control={control}
                render={({ field }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    select
                    InputLabelProps={{
                      shrink: true,
                      htmlFor: `${field.name}_id`,
                    }}
                    inputProps={{
                      id: `${field.name}_id`,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      SelectDisplayProps: {
                        "aria-required": true,
                      },
                    }}
                    {...field}
                    label="Lead Attorney"
                    variant="standard"
                  >
                    <MenuItem value="">Select Lead Attorney</MenuItem>

                    {!isIndividualUser() &&
                      attorneys?.map((attorney) => (
                        <MenuItem
                          key={attorney.attorneyID}
                          value={attorney.attorneyID}
                        >
                          {`${attorney.firstName} ${attorney.lastName}`}
                        </MenuItem>
                      ))}
                    <MenuItem value="prose">Pro Se</MenuItem>
                  </TextField>
                )}
              />
            )}
          </Grid>

          {field.existingAdditionalAttorneys &&
            Boolean(field.existingAdditionalAttorneys?.length) && (
              <Grid item xs={6}>
                <p>
                  <span className="d-block">Additional Attorney</span>
                  <label className="d-block">
                    {field.existingAdditionalAttorneys
                      .map(
                        (attorney) =>
                          `${attorney.attorneyFirstName} ${attorney.attorneyMiddleName} ${attorney.attorneyLastName}`
                      )
                      .join(", ")}
                  </label>
                </p>
              </Grid>
            )}

          <Grid item xs={4}>
            {(field.existingLeadAttorney ||
              watch(`parties.${index}.leadAttorney`)) &&
              !isIndividualUser() && (
                <Controller
                  name={`parties.${index}.additionalAttorneys`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      className="w-100"
                      id={field.name}
                      select
                      InputLabelProps={{
                        shrink: true,
                        htmlFor: `${field.name}_id`,
                      }}
                      inputProps={{
                        id: `${field.name}_id`,
                      }}
                      SelectProps={{
                        displayEmpty: true,
                        multiple: true,
                        SelectDisplayProps: {
                          "aria-required": true,
                        },
                        renderValue: (selected: any) =>
                          getAttorneyName(selected).join(","),
                      }}
                      {...field}
                      label="Add Additional Attorneys"
                      variant="standard"
                    >
                      <div>
                        <Input
                          id="search-grid"
                          className="material-input"
                          placeholder="Filter By Search"
                          value={searchText}
                          onChange={onAttorneySearch}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          inputProps={{
                            "aria-label": "Filter By Search",
                          }}
                        ></Input>
                      </div>
                      <InputLabel className="label-checkbox">
                        <Checkbox
                          checked={
                            field.value.length ===
                            (attorneys ? attorneys?.length - 1 : 0)
                          }
                          onChange={(e) => {
                            const checked = e.target.checked;
                            const selectedIDs = checked
                              ? attorneys
                                  ?.filter(
                                    (attorney) =>
                                      attorney.attorneyID !==
                                      watch(`parties.${index}.leadAttorney`)
                                  )
                                  .map((attorney) => attorney.attorneyID) || []
                              : [];
                            field.onChange(selectedIDs);
                          }}
                        />
                        Select All
                      </InputLabel>

                      {attorneys
                        ?.filter(
                          (attorney) =>
                            attorney.attorneyID !==
                            watch(`parties.${index}.leadAttorney`)
                        )
                        ?.filter((attorney) =>
                          `${attorney.firstName} ${attorney.lastName}`
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                        )
                        ?.map((attorney) => (
                          <MenuItem
                            key={attorney.attorneyID}
                            value={attorney.attorneyID}
                          >
                            <Checkbox
                              checked={field.value.includes(
                                attorney.attorneyID
                              )}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                const value = field.value || [];
                                const newValue = checked
                                  ? [...value, attorney.attorneyID]
                                  : value.filter(
                                      (id) => id !== attorney.attorneyID
                                    );

                                field.onChange(newValue);
                              }}
                            />
                            {`${attorney.firstName} ${attorney.lastName}`}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              )}
          </Grid>
          <Grid item xs={2}>
            {watch(`parties.${index}.leadAttorney`) && !isIndividualUser() && (
              <IconButton onClick={openAdditionalAttorneysModal}>
                <PersonAddAltIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </div>
      <AdditionalAttorneys
        isOpen={isAdditionalAttorneysModalOpen}
        closeModal={closeAdditionalAttorneysModal}
        attorneys={watch(`parties.${index}.additionalAttorneys`)}
      />
    </div>
  );
};

export default ExistingParty;
