import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { EXPORT_PAYMENT_REPORT_AS_PDF } from "../../URL";
import { IAxisErrorResponse, IPdf_CSV_Request } from "../../../interfaces";

const getPdfDownload = async (filters: IPdf_CSV_Request) => {
  const response = await http.post(
    EXPORT_PAYMENT_REPORT_AS_PDF,
    filters.transactions,
    {
      params: {
        totalFilingFees: filters.totalFilingFees,
        totalI2FileFees: filters.totalI2FileFees,
        envelopeCount: filters.envelopeCount,
      },
      responseType: "blob",
    }
  );

  const pdfBlob = new Blob([response.data], { type: "application/pdf" });
  const pdfUrl = URL.createObjectURL(pdfBlob);

  const link = document.createElement("a");
  link.href = pdfUrl;
  link.download = "Payment_Report.pdf";
  link.click();

  URL.revokeObjectURL(pdfUrl);
};

const usePdfDownload = () =>
  useMutation<void, IAxisErrorResponse, IPdf_CSV_Request>({
    mutationKey: ["pdf-download"],
    mutationFn: getPdfDownload,
  });

export default usePdfDownload;
