import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { DELETE_SERVICE_CONTACT } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

const deleteServiceContact = async (serviceContactID: string) => {
  await http.post(DELETE_SERVICE_CONTACT, {
    serviceContactID,
  });
};

const useDeleteServiceContact = () =>
  useMutation<void, IAxisErrorResponse, string>({
    mutationKey: ["delete-service-contact"],
    mutationFn: deleteServiceContact,
  });

export default useDeleteServiceContact;
