import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import get from "lodash.get";
import format from "date-fns/format";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GridRenderCellParams } from "@mui/x-data-grid/models/params/gridCellParams";

import Dialog from "../../../components/dialog";
import GridTable from "../../../components/grid";
import Loader from "../../../components/loader";
import CountyHearingDate from "../countyHearingDateDialog";
import usePDFDownloadDetails from "../../../apis/filingHistory/useViewPDF/useDownloadPDF";
import useTransactionDetails from "../../../apis/filingHistory/useTransactionDetails";
import useReservedCourtDate from "../../../apis/filingHistory/HearingDetails/useReservedCourtDate";
import { NotificationContext } from "../../../context/NotificationContext";

interface FilingDetailsDialogProps {
  isOpen: boolean;
  efmUserId: string;
  efmEnvelopeId: string;
  efmLocationId: string;
  filingId: string;
  efmFilingType: string;
  onClose: () => void;
  isDownloadModal: boolean;
  isReturnDate: boolean;
  isHearingDate: boolean;
  filedDate: string;
}

interface IOptionalService {
  efm_optionalservice_name: string;
  total: number;
  transactionoptionalservicesid: string;
  quantity: number;
}

const CASE_INFORMATIONS = [
  {
    label: "Location",
    key: "caseInformation.location",
  },
  {
    label: "Date Filed",
    key: "caseInformation.filedDate",
  },
  {
    label: "Case Number",
    key: "caseInformation.caseNumber",
  },
  {
    label: "Case Description",
    key: "caseInformation.caseDesc",
  },
  {
    label: "Assigned to Judge",
    key: "caseInformation.strJudge",
  },
  {
    label: "Attorney",
    key: "caseInformation.filingAttorneyName",
  },
  {
    label: "Firm Name",
    key: "caseInformation.efmFirmName",
  },
  {
    label: "Filed By",
    key: "caseInformation.efmUserId",
  },
];

const PAYMENT_INFORMATIONS = [
  {
    label: "Account Name",
    key: "caseInformation.paymentAccount",
  },
  {
    label: "Transaction Amount",
    key: "caseInformation.fees",
    isCurrency: true,
  },
  {
    label: "Transaction Response",
    key: "caseInformation.paymentTransactionStatus",
  },
  {
    label: "Transaction ID",
    key: "caseInformation.paymentTransactionId",
  },
  {
    label: "Order ID",
    key: "caseInformation.paymentTransactionOrderId",
  },
];

const FILING_INFORMATION = [
  {
    label: "Filing Type",
    key: "filingType",
  },
  {
    label: "Filing Code",
    key: "efmFilingTypeName",
  },
  {
    label: "Filing Description",
    key: "filingDescription",
  },
  {
    label: "Reference Number",
    key: "efmReferenceNumber",
  },
  {
    label: "Comments",
    key: "filingComments",
  },
  {
    label: "Preliminary Copies",
    key: "courtesyCopies",
  },
  {
    label: "Status",
    key: "efmFilingStatus",
    toUppercase: true,
  },
];

const FilingDetailsDialog: FC<FilingDetailsDialogProps> = ({
  isOpen,
  efmUserId,
  efmEnvelopeId,
  efmLocationId,
  filingId,
  efmFilingType,
  onClose,
  isDownloadModal,
  isReturnDate,
  isHearingDate,
  filedDate,
}) => {
  const {
    data: transaction,
    isLoading,
    isFetching,
    isError,
    error,
  } = useTransactionDetails({
    efmUserId,
    efmEnvelopeId,
    efmLocationId,
    filingId,
    efmFilingType,
  });
  const downloadPdf = usePDFDownloadDetails();
  const reservedCourtDate = useReservedCourtDate();

  const [selectedReturnDate, setSelectedReturnDate] = useState("");
  const [selectedCountyHearingTime, setSelectedCountyHearingTime] =
    useState("");
  const [isHearingDateModalOpen, setIsHearingDateModalOpen] = useState(false);
  const { createNotification } = useContext(NotificationContext);

  const onViewPDFDownload = useCallback(() => {
    if (!transaction) return;

    downloadPdf.mutate({
      efmEnvelopeId: transaction?.caseInformation.efmEnvelopeId,
      efmFilingType: transaction?.filingDetail?.[0]?.filingType,
      efmLocationId: transaction?.caseInformation.efmLocationId,
      efmUserId: transaction?.caseInformation.efmUserId,
      filingId: transaction?.filingDetail?.[0].efmFilingId,
    });
  }, [transaction, downloadPdf]);

  const viewHearingDateDetails = useCallback(() => {
    reservedCourtDate.mutate({
      envelopeId: transaction?.caseInformation.efmEnvelopeId || "",
      locationCode: transaction?.caseInformation.efmLocationId || "",
      scheduledStartDate:
        transaction?.caseInformation.scheduledHearingStartDateTime || "",
      scheduledStartTime:
        transaction?.caseInformation.scheduledHearingStartDateTime || "",
      schedeuledEndDate:
        transaction?.caseInformation.scheduledHearingEndDateTime || "",
      scheduledEndTime:
        transaction?.caseInformation.scheduledHearingEndDateTime || "",
    });
    setIsHearingDateModalOpen(true);
  }, [transaction, reservedCourtDate]);

  const viewHearingDateDetailsClose = useCallback(() => {
    setIsHearingDateModalOpen(false);
  }, []);

  const crossReferenceNumberColumns = useMemo(
    () => [
      {
        field: "codeName",
        headerName: "Case Cross Reference Type",
        width: 250,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "crossReferenceNumber",
        headerName: "Case Cross Reference Number",
        width: 250,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
    ],
    []
  );

  const rejectionColumns = useMemo(
    () => [
      {
        field: "rejectReason",
        headerName: "Rejection Reason",
        width: 250,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "rejectedDate",
        headerName: "Date / Time",
        width: 250,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "rejectedComments",
        headerName: "Rejection Comments",
        width: 250,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
    ],
    []
  );

  const partiesColumns = useMemo(
    () => [
      {
        field: "partyName",
        headerName: "Party Name",
        width: 220,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "efmPartytypeName",
        headerName: "Party Role",
        width: 170,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "address1",
        headerName: "Address",
        width: 160,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "phone",
        headerName: "Phone Number",
        width: 130,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "attorneys",
        headerName: "Attorneys",
        width: 150,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          const remainingAttorneys = params.row.attorneys.slice(2);

          return (
            <Box>
              <>{params.row.attorneys.slice(0, 2).join(", ")}</>
              {Boolean(remainingAttorneys.length) && (
                <Tooltip
                  title={
                    <Typography whiteSpace="pre-line">
                      {remainingAttorneys.join(",\n")}
                    </Typography>
                  }
                >
                  <Typography color="#0f336d" className="cursor-pointer">
                    ({remainingAttorneys.length} more)
                  </Typography>
                </Tooltip>
              )}
            </Box>
          );
        },
      },
    ],
    []
  );

  const eServiceDetailsColumn = useMemo(
    () => [
      {
        field: "Status",
        headerName: "Status",
        width: 80,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.statusCode;
        },
      },
      {
        field: "email",
        headerName: "Name/Email",
        width: 300,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return ` ${params.row.firstName} ${params.row.middleName || ""} ${
            params.row.lastName || ""
          } \n ${params.row.email}`;
        },
      },
      {
        field: "firmName",
        headerName: "Firm",
        width: 200,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.firmName;
        },
      },
      {
        field: "servedDate",
        headerName: "Served",
        width: 80,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.servedDate ? "Yes" : "No";
        },
      },
      {
        field: "openDate",
        headerName: "Date/Time Opened",
        width: 150,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return `${
            params.row.strOpened === "Opened"
              ? params.row.openedDate
              : params.row.strOpened
          }`;
        },
      },
    ],
    []
  );

  const parties = useMemo(() => {
    return transaction?.transactionPartyDetail?.map((party, index) => ({
      id: index + 1,
      partyName:
        party.partyType === "person"
          ? `${party.firstName} ${party.middleName || ""} ${party.lastName}`
          : party.businessName,
      efmPartytypeName: party.efmPartytypeName,
      address1: `${party.address1 || ""}${
        (party.address2 && `, ${party.address2}`) || ""
      }${(party.city && `, ${party.city}`) || ""}${
        (party.state && `, ${party.state}`) || ""
      }${(party.zipcode && `, ${party.zipcode}`) || ""}`,
      phone: party.phone,
      attorneys:
        party.attorneys?.map(
          (attorney: any) =>
            `${attorney.personGivenName || ""} ${
              attorney.personMiddleName || ""
            } ${attorney.personSurName || ""}`
        ) || [],
    }));
  }, [transaction]);

  const crossReferenceNumbers = useMemo(() => {
    if (!transaction) return [];

    const list: {
      id: string;
      codeName: string;
      crossReferenceNumber: string;
    }[] = [];

    const crossReferenceNumberList =
      transaction?.caseInformation?.crossReferenceNumberMapping?.split(";");

    if (crossReferenceNumberList?.length) {
      for (const crossReferenceNumber of crossReferenceNumberList) {
        const crossReferenceNumberItem = crossReferenceNumber.split("=");
        list.push({
          id: list.length.toString(),
          codeName: crossReferenceNumberItem[0],
          crossReferenceNumber: crossReferenceNumberItem[1],
        });
      }
    } else {
      const crossReferenceNumberItem =
        transaction?.caseInformation?.crossReferenceNumberMapping?.split(";");
      if (crossReferenceNumberItem?.length) {
        list.push({
          id: list.length.toString(),
          codeName: crossReferenceNumberItem[0],
          crossReferenceNumber: crossReferenceNumberItem[1],
        });
      }
    }
    return list;
  }, [transaction]);

  const filingDetails = transaction?.filingDetail || [];

  useEffect(() => {
    if (
      transaction?.caseInformation?.returnDate !== null &&
      transaction?.caseInformation?.returnDate !== undefined
    ) {
      const formatedDate = format(
        new Date(transaction?.caseInformation?.returnDate.replace("Z", "")),
        "MM/dd/yyyy",
        { timeZone: "UTC" } as any
      );
      setSelectedReturnDate(formatedDate);
    } else {
      setSelectedReturnDate("");
    }
  }, [isReturnDate, transaction]);

  useEffect(() => {
    if (
      transaction?.caseInformation.scheduledHearingStartDateTime != null &&
      transaction?.caseInformation.scheduledHearingEndDateTime != null
    ) {
      setIsHearingDateModalOpen(false);

      //browser specific code
      // if(this.showDateInMSIE) {
      //   this.selectedCookCountyHearingDateForMSIE = this.transactionDetail.caseInformation.scheduledHearingStartDateTime.replace('Z','');
      //   this.selectedCookCountyHearingTimeForMSIE = this.transactionDetail.caseInformation.scheduledHearingStartDateTime.replace('Z','');
      // } else{// the code below is an else part}

      const fomattedHearingTime =
        format(
          new Date(
            transaction?.caseInformation?.scheduledHearingStartDateTime?.replace(
              "Z",
              ""
            )
          ),
          "MM/dd/yyyy",
          { timeZone: "UTC" } as any
        ) +
        "at" +
        format(
          new Date(
            transaction?.caseInformation?.scheduledHearingStartDateTime?.replace(
              "Z",
              ""
            )
          ),
          "hh:mm a",
          { timeZone: "UTC" } as any
        ) +
        "-" +
        format(
          new Date(
            transaction?.caseInformation?.scheduledHearingEndDateTime?.replace(
              "Z",
              ""
            )
          ),
          "hh:mm a",
          { timeZone: "UTC" } as any
        );
      setSelectedCountyHearingTime(fomattedHearingTime);
    }
  }, [transaction]);

  const updatedOptionalService = useMemo(() => {
    const uniqueOptionalService =
      transaction?.filingDetail?.[0]?.optionalServiceList?.reduce(
        (accumulator: IOptionalService[], currentService: IOptionalService) => {
          if (
            !accumulator.find(
              (optionalserviceData: IOptionalService) =>
                optionalserviceData.transactionoptionalservicesid ===
                currentService.transactionoptionalservicesid
            ) &&
            currentService.transactionoptionalservicesid
          ) {
            accumulator.push({
              ...currentService,
              quantity: 1,
            });
          } else if (currentService.transactionoptionalservicesid) {
            return accumulator.map((service) =>
              service.transactionoptionalservicesid ===
              currentService.transactionoptionalservicesid
                ? {
                    ...service,
                    quantity: service.quantity + 1,
                  }
                : service
            );
          }
          return accumulator;
        },
        []
      );

    return uniqueOptionalService;
  }, [transaction]);

  useEffect(() => {
    if (downloadPdf.isError) {
      createNotification({
        title: "Filing Notification",
        type: "error",
        message: "Something went wrong while downloading pdf",
      });
    }
  }, [createNotification, downloadPdf.isError]);

  useEffect(() => {
    if (isError) {
      createNotification({
        title: "Filing Notification",
        type: "error",
        message:
          error?.response?.data?.userMessage ||
          "Something went wrong while getting filing detail",
      });
    }
    // eslint-disable-next-line
  }, [createNotification, isError]);

  useEffect(() => {
    if (reservedCourtDate.isError) {
      createNotification({
        title: "Filing Notification",
        type: "error",
        message:
          reservedCourtDate.error?.response?.data?.userMessage ||
          "No Scheduled Date and Time available at this moment. Please try again later.",
      });
    }
    // eslint-disable-next-line
  }, [reservedCourtDate.isError, createNotification]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={isDownloadModal ? "Download Document" : "Details"}
      maxWidth="md"
    >
      {isLoading || isFetching ? (
        <Loader />
      ) : (
        <div className="history-detail-popup popup-main">
          {downloadPdf.isLoading && <Loader></Loader>}
          <Typography variant="h4" className="mb-2 popup-title">
            {transaction?.caseInformation.caseNumber !== null &&
            transaction?.caseInformation.caseNumber !== ""
              ? transaction?.caseInformation.caseDesc !== null &&
                transaction?.caseInformation.caseDesc !== ""
                ? ` Case # ${transaction?.caseInformation.caseNumber} - ${transaction?.caseInformation.caseDesc}  `
                : ` Case # ${transaction?.caseInformation.caseNumber}`
              : ` Envelope # ${transaction?.caseInformation.efmEnvelopeId}`}
          </Typography>

          <div className="popup-content-slide">
            {!isDownloadModal && (
              <Accordion
                defaultExpanded
                className="remove-item-css accordian-style03"
              >
                <AccordionSummary
                  className="accordianStyle03-title light-grey-bg mb-2"
                  expandIcon={
                    <ExpandMoreIcon className="theme-blue accordion-arrow" />
                  }
                >
                  <h5 className="theme-blue f-w-bold">Case Information</h5>
                </AccordionSummary>
                <AccordionDetails className="mt-2 p-1">
                  <Grid container spacing={2} className="mb-2">
                    {CASE_INFORMATIONS.map((information) => (
                      <Grid item xs={12} lg={4} xl={3} key={information.key}>
                        <div className="label-print-name">
                          {information.label}
                        </div>
                        <div className="label-print-field">
                          {information.label === "Date Filed"
                            ? format(
                                new Date(filedDate),
                                "MM/dd/yyyy hh:mm:ss a"
                              )
                            : get(transaction, information.key) || ""}
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                  <Typography
                    variant="h5"
                    className="border-bottom-grey pb-1 mb-1 pt-2"
                  >
                    Fees
                  </Typography>
                  <Grid container spacing={2} className="mb-2">
                    {(transaction?.feeDetail || []).map((feeDetail, index) => (
                      <Grid item xs={12} lg={4} xl={3} key={index}>
                        <div className="label-print-name">
                          {feeDetail.feeType === "Total Provider Service Fees"
                            ? "I2FileFees"
                            : feeDetail.feeType === "Total Provider Tax Fees"
                            ? "I2File Tax"
                            : feeDetail.feeType}
                        </div>
                        <div className="label-print-field">
                          ${feeDetail.amount}
                        </div>
                      </Grid>
                    ))}
                  </Grid>

                  <Typography
                    variant="h5"
                    className="border-bottom-grey pb-1 mb-1 pt-2"
                  >
                    Payments
                  </Typography>
                  <Grid container spacing={2} className="mb-2">
                    {PAYMENT_INFORMATIONS.map((information) => (
                      <Grid item xs={12} lg={4} xl={3} key={information.key}>
                        <div className="label-print-name">
                          {information.label}
                        </div>
                        <div className="label-print-field">
                          {information.isCurrency &&
                          get(transaction, information.key)
                            ? `$${get(transaction, information.key) || " "}`
                            : get(transaction, information.key) || " "}
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            {transaction?.caseInformation?.crossReferenceNumberMapping !== "" &&
              !isDownloadModal && (
                <Accordion className="remove-item-css accordian-style03">
                  <AccordionSummary
                    className="accordianStyle03-title light-grey-bg mb-2"
                    expandIcon={
                      <ExpandMoreIcon className="theme-blue accordion-arrow" />
                    }
                  >
                    <h5 className="theme-blue f-w-bold">
                      Case Cross Reference Number
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails className="mt-2 p-1">
                    <GridTable
                      columns={crossReferenceNumberColumns}
                      rows={crossReferenceNumbers}
                      hideFooter={false}
                    />
                  </AccordionDetails>
                </Accordion>
              )}

            {Boolean(
              (transaction?.filingDetail != null &&
                transaction?.filingDetail?.length) ||
                isDownloadModal
            ) &&
              transaction?.filingDetail.map((filingDetail) => (
                <Accordion
                  className="remove-item-css accordian-style03"
                  defaultExpanded={isDownloadModal}
                >
                  <AccordionSummary
                    className="accordianStyle03-title light-grey-bg mb-2"
                    expandIcon={
                      <ExpandMoreIcon className="theme-blue accordion-arrow" />
                    }
                  >
                    <h5 className="theme-blue f-w-bold">
                      {filingDetail.efmFilingTypeName ?? "Filings"}
                    </h5>
                  </AccordionSummary>

                  <AccordionDetails className="mt-2 p-1">
                    <Grid container spacing={2} className="mb-2">
                      {FILING_INFORMATION.map(
                        (information) =>
                          !isDownloadModal && (
                            <Grid
                              item
                              xs={12}
                              lg={4}
                              xl={3}
                              key={information.key}
                            >
                              <div className="label-print-name">
                                {information.label}
                              </div>
                              <div
                                className={`label-print-field ${
                                  information.toUppercase
                                    ? "text-capitalize"
                                    : ""
                                }`}
                              >
                                {get(filingDetail, information.key) || ""}
                              </div>
                            </Grid>
                          )
                      )}

                      {filingDetail.acceptedDate &&
                        filingDetail.efmFilingStatus.toLowerCase() ===
                          "accepted" &&
                        !isDownloadModal && (
                          <Grid item xs={12} lg={4} xl={3}>
                            <div className="label-print-name">
                              Accepted Date
                            </div>
                            <div className="label-print-field">
                              {filingDetail.acceptedDate}
                            </div>
                          </Grid>
                        )}

                      {filingDetail.acceptedComments &&
                        filingDetail.efmFilingStatus.toLowerCase() ===
                          "accepted" &&
                        !isDownloadModal && (
                          <Grid item xs={12} lg={4} xl={3}>
                            <div className="label-print-name">
                              Accept Comments
                            </div>
                            <div className="label-print-field">
                              {filingDetail.acceptedComments}
                            </div>
                          </Grid>
                        )}
                    </Grid>
                    {filingDetail.rejectedComments &&
                      filingDetail.efmFilingStatus.toLowerCase() ===
                        "rejected" &&
                      !isDownloadModal && (
                        <>
                          <Typography variant="h4">
                            Rejection Information
                          </Typography>
                          <GridTable
                            columns={rejectionColumns}
                            rows={[filingDetail]}
                            hideFooter={false}
                            getRowId={(row) =>
                              `${row.efmFilingId}-${row.efmEnvelopeId}`
                            }
                          />
                        </>
                      )}

                    {!isDownloadModal && (
                      <div>
                        <Typography
                          variant="h5"
                          className="border-bottom-grey pb-1 mb-1 pt-2"
                        >
                          Fees
                        </Typography>

                        <Grid container spacing={2} className="mb-2">
                          {updatedOptionalService
                            ?.filter(
                              (updatedServiceData) =>
                                updatedServiceData.transactionoptionalservicesid !=
                                null
                            )
                            .map((optionalServiceList: IOptionalService) => (
                              <Grid
                                item
                                xs={12}
                                lg={4}
                                xl={3}
                                key={
                                  optionalServiceList.transactionoptionalservicesid
                                }
                              >
                                <div className="label-print-name">
                                  {optionalServiceList.efm_optionalservice_name}
                                </div>
                                <div className="label-print-field">
                                  $
                                  {optionalServiceList.total *
                                    optionalServiceList.quantity}{" "}
                                  ({optionalServiceList.quantity} X $
                                  {optionalServiceList.total} )
                                </div>
                              </Grid>
                            ))}

                          {transaction?.filingDetail.map((filingDetail) =>
                            (filingDetail?.optionalServiceList || [])
                              .filter(
                                (optionalService) =>
                                  optionalService.transactionoptionalservicesid ===
                                  null
                              )
                              .map((optionalServiceList, index) => (
                                <Grid item xs={12} lg={4} xl={3} key={index}>
                                  <div className="label-print-name">
                                    {
                                      optionalServiceList.efm_optionalservice_name
                                    }
                                  </div>
                                  <div className="label-print-field">
                                    ${optionalServiceList.total}
                                  </div>
                                </Grid>
                              ))
                          )}
                        </Grid>
                      </div>
                    )}
                    <Typography
                      variant="h5"
                      className="border-bottom-grey pb-1 mb-1 pt-2"
                    >
                      Documents
                    </Typography>
                    {filingDetails.map((filingDetail, index) => (
                      <div key={index}>
                        {filingDetail?.leadDoc && (
                          <Grid
                            item
                            xs={12}
                            lg={4}
                            xl={3}
                            sx={(theme) => ({
                              [theme.breakpoints.up("md")]: {
                                display: "flex",
                                alignItems: "center",
                              },
                            })}
                          >
                            <div className="label-print-name me-3">
                              <span>Lead Documents : </span>
                              <span className="ms-3">
                                {filingDetail?.leadDoc?.documentName}
                              </span>
                            </div>
                            {filingDetail.leadDoc.transactionDocumentsId !==
                              "" && (
                              <div>
                                <a
                                  className="theme-blue"
                                  target="_blank"
                                  rel="noreferrer"
                                  aria-label="Original Document"
                                  href={filingDetail?.leadDoc?.documentPath}
                                >
                                  [Original]
                                </a>
                              </div>
                            )}
                            {filingDetail?.tranmittedDoc && (
                              <Grid
                                item
                                xs={12}
                                lg={4}
                                xl={3}
                                pl={3}
                                display="flex"
                              >
                                {filingDetail?.tranmittedDoc
                                  ?.transactionDocumentsId !== "" &&
                                  filingDetail?.tranmittedDoc
                                    ?.transactionDocumentsId !== null && (
                                    <div>
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                          filingDetail?.tranmittedDoc
                                            ?.documentPath
                                        }
                                        aria-label="Court Copy Document"
                                      >
                                        [Court Copy]
                                      </a>
                                    </div>
                                  )}
                              </Grid>
                            )}
                          </Grid>
                        )}
                        {filingDetail?.attachement &&
                          filingDetail.attachement.length > 0 &&
                          filingDetail.attachement.map(
                            (attachment, attachmentIndex) => (
                              <div key={attachmentIndex}>
                                <Grid
                                  item
                                  xs={12}
                                  lg={4}
                                  xl={3}
                                  sx={(theme) => ({
                                    [theme.breakpoints.up("md")]: {
                                      display: "flex",
                                      alignItems: "center",
                                    },
                                  })}
                                >
                                  <div className="label-print-name me-3">
                                    <span className="group-label">
                                      Attachments :
                                    </span>

                                    <span className="ms-3">
                                      {attachment?.documentName}
                                    </span>
                                  </div>
                                  {attachment.transactionDocumentsId !== "" &&
                                    attachment.transactionDocumentsId !==
                                      null && (
                                      <div>
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={attachment?.documentPath}
                                          aria-label="Original Document"
                                        >
                                          [Original]
                                        </a>
                                      </div>
                                    )}
                                </Grid>
                              </div>
                            )
                          )}
                      </div>
                    ))}

                    <div className="mb-2 mt-2">
                      {transaction?.filingDetail?.[0]?.eserviceDetails.length >
                        0 && (
                        <>
                          <Typography variant="h5" className="pb-1 mb-1 pt-2">
                            eService Details:
                          </Typography>

                          <GridTable
                            columns={eServiceDetailsColumn}
                            rows={
                              transaction?.filingDetail?.[0]?.eserviceDetails
                            }
                            hideFooter={false}
                            getRowId={(row) => row.serviceContactId}
                          />
                        </>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            {!isDownloadModal && (
              <Accordion className="remove-item-css accordian-style03">
                <AccordionSummary
                  className="accordianStyle03-title light-grey-bg mb-2"
                  expandIcon={
                    <ExpandMoreIcon className="theme-blue accordion-arrow" />
                  }
                >
                  <h5 className="theme-blue f-w-bold">Parties Information</h5>
                </AccordionSummary>
                <AccordionDetails className="p-1">
                  <GridTable
                    columns={partiesColumns}
                    rows={parties}
                    hideFooter={false}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            {!isDownloadModal && isReturnDate && (
              <Accordion className="remove-item-css accordian-style03">
                <AccordionSummary
                  className="accordianStyle03-title light-grey-bg mb-2"
                  expandIcon={
                    <ExpandMoreIcon className="theme-blue accordion-arrow" />
                  }
                >
                  <h5 className="theme-blue f-w-bold">Return Date</h5>
                </AccordionSummary>
                <AccordionDetails className="p-1">
                  {transaction?.caseInformation.isOutOfStateServiceIndicator ===
                    "1" && <div className="mb-1">Out of State Service</div>}
                  {transaction?.caseInformation.returnDate !== null ? (
                    <p>{selectedReturnDate}</p>
                  ) : (
                    <p>Return Date Not Applicable</p>
                  )}
                </AccordionDetails>
              </Accordion>
            )}

            {!isDownloadModal && isHearingDate && (
              <Accordion className="remove-item-css accordian-style03">
                <AccordionSummary
                  className="accordianStyle03-title light-grey-bg mb-2"
                  expandIcon={
                    <ExpandMoreIcon className="theme-blue accordion-arrow" />
                  }
                >
                  <h5 className="theme-blue f-w-bold">Hearing Date</h5>
                </AccordionSummary>
                <AccordionDetails className="p-1">
                  <span>Hearing Date and Time Selected:</span>

                  {transaction?.caseInformation
                    .scheduledHearingStartDateTime !== null &&
                  transaction?.caseInformation.scheduledHearingEndDateTime !==
                    null ? (
                    <span>{selectedCountyHearingTime}</span>
                  ) : (
                    <>
                      <span className="f-w-semibold">&nbsp;None Selected</span>

                      <div className="mt-2">
                        <span
                          role="button"
                          tabIndex={0}
                          className="theme-blue text-underline cursor-pointer"
                          onClick={viewHearingDateDetails}
                        >
                          Request Hearing Date
                        </span>
                      </div>
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            )}
            <div>
              <div className="text-right mt-3">
                {!isDownloadModal && (
                  <>
                    <Button
                      className="btn button-outline-secondary me-2"
                      variant="contained"
                      type="button"
                      onClick={onViewPDFDownload}
                    >
                      View Receipt
                    </Button>
                    <Button
                      className="btn primary-button"
                      variant="contained"
                      type="button"
                      onClick={onClose}
                    >
                      Close
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isHearingDate && (
        <CountyHearingDate
          isOpen={isHearingDateModalOpen}
          onClose={viewHearingDateDetailsClose}
          envelopeId={efmEnvelopeId}
          locationCode={efmLocationId}
          reserveCourtList={reservedCourtDate.data}
        />
      )}
    </Dialog>
  );
};

export default FilingDetailsDialog;
