import { FC, useContext, useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import format from "date-fns/format";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import Dialog from "../../../../components/dialog";
import GridTable from "../../../../components/grid";
import { NotificationContext } from "../../../../context/NotificationContext";
import useServiceInfoHistory from "../../../../apis/existingCase/useServiceInfoHistory";

interface ServiceListHistoryProps {
  isOpen: boolean;
  onClose: () => void;
  caseTrackingId: string;
  locationCounty: string;
}
const ServiceListHistoryModalDialog: FC<ServiceListHistoryProps> = ({
  isOpen,
  onClose,
  caseTrackingId,
  locationCounty,
}) => {
  const getServiceInfoHistory = useServiceInfoHistory();
  const { createNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (caseTrackingId !== undefined) {
      getServiceInfoHistory.mutate({
        caseTrackingId,
        locationCounty,
      });
    }
    // eslint-disable-next-line
  }, [caseTrackingId]);

  useEffect(() => {
    if (getServiceInfoHistory.isError) {
      createNotification({
        message:
          getServiceInfoHistory.error?.response?.data?.userMessage ||
          "An Error occurred while getting service contact history!",
        type: "error",
        title: "Service Contact Notification",
      });
    }
    // eslint-disable-next-line
  }, [getServiceInfoHistory.isError]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "Name",
        headerName: "Name",
        width: 400,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <div>{`${params.row.firstName || ""}  ${
              params.row.middleName || ""
            } ${params.row.lastName || ""}`}</div>
          );
        },
      },
      {
        field: "action",
        headerName: "Action",
        width: 150,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <div className="w-100">
            {params.row.action.map((actionlabel: any, index: any) => (
              <div className="multiline-grid-column" key={index}>
                {actionlabel}
              </div>
            ))}
          </div>
        ),
      },
      {
        field: "actionTimeStamp",
        headerName: "Action TimeStamp",
        width: 280,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <div className="w-100">
            {params.row.actionTimeStamp.map(
              (actionTimestamp: any, index: any) => (
                <div className="multiline-grid-column" key={index}>
                  {format(new Date(actionTimestamp), "MM/dd/yyyy hh:mm:ss a")}
                </div>
              )
            )}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose.bind(false)}
      title="Service Contact History"
      maxWidth="md"
    >
      <div className="multiline-grid">
        <GridTable
          columns={columns}
          rows={
            getServiceInfoHistory.data?.map((row, index) => ({
              ...row,
              id: index,
            })) || []
          }
          isLoading={getServiceInfoHistory.isLoading}
          hideFooter={false}
          getRowId={(row) => row.id}
        />
      </div>
      <div className="text-right mt-3">
        <Button
          className="btn button-outline-secondary"
          variant="contained"
          onClick={() => onClose()}
        >
          Close
        </Button>
      </div>
    </Dialog>
  );
};

export default ServiceListHistoryModalDialog;
