import { FC, ReactNode } from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";

interface PageProps {
  children: ReactNode;
}

const Page: FC<PageProps> = ({ children }) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const [innerCompClassName, setInnerCompClassName] = useState("");

  const onToggle = () => {
    if (window.outerWidth < 1024 && !isSideBarOpen) {
      setInnerCompClassName("overlay-col");
    }

    setIsSideBarOpen(!isSideBarOpen);
  };

  return (
    <div className="main-comp">
      <Box
        component="main"
        className={`inner-comp ${isSideBarOpen ? innerCompClassName : ""}`}
      >
        {/* <div className="mobile-menu-close"><DisabledByDefaultIcon/></div> */}
        <Sidebar
          isSideBarOpen={isSideBarOpen}
          setIsSideBarOpen={setIsSideBarOpen}
        />
        <div className={isSideBarOpen ? "main_content" : "main_content toggle"}>
          <Header onToggleSideBar={onToggle} />
          {children}
        </div>
        <footer className="dash-footer text-center p-2">
          <span className="d-sm-inline-block">
            Copyright © 2007 - {new Date().getFullYear()} Conscisys Corporation
            <a
              href="https://www.conscisys.com"
              target="_blank"
              rel="noreferrer"
              aria-label="Consices"
            >
              Powered by Conscisys{" "}
            </a>
          </span>
        </footer>
      </Box>
    </div>
  );
};

export default Page;
