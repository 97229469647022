import { useState, useRef, useEffect, FC } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonIcon from "@mui/icons-material/Person";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@mui/material/styles/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import LoginForm from "./LoginForm";
import logo from "../../../assets/images/Brand-logo.svg";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;
const navItems = [
  {
    text: "About Us",
    link: "aboutus",
  },
  { text: "Features", link: "features" },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface IHeader {
  notification?: string;
}

const Header: FC<IHeader> = ({ notification }) => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isSelfRegisterModalOpen, setIsSelfRegisterModalOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsLoginModalOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      className="mobile-menu"
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center" }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        <a href="/#/admin">
          <img src={logo} alt="I2File Your electronics Path for filing cases" />
        </a>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.link} disablePadding>
            <ListItemButton
              onClick={() => scrollToSection(item.link)}
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => document.body : undefined;

  return (
    <Box>
      <CssBaseline />
      <AppBar component="nav" className="main-navigation pe-0">
        <Toolbar>
          <IconButton
            className="menu-bar ms-2"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <FontAwesomeIcon className="" icon={faBars} />
          </IconButton>
          <Box
            component="div"
            className="logo"
            sx={{ flexGrow: 1, display: { sm: "block" } }}
          >
            <a href="/#/admin">
              <img
                src={logo}
                alt="I2File Your electronics Path for filing cases"
              />
            </a>
          </Box>

          {/* <a href="#main" className="skip-to-main-content-link">
            Skip to main content
          </a> */}

          <Box
            className="nav-listing"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            {navItems.map((item) => (
              <Button
                key={item.link}
                onClick={() => scrollToSection(item.link)}
                sx={{ color: "#fff" }}
              >
                {item.text}
              </Button>
            ))}
          </Box>
          <Box className="login-col btn-col">
            <Button
              className="btn primary-button me-4"
              variant="contained"
              onClick={() => setIsLoginModalOpen(true)}
            >
              Admin Login
            </Button>
            <div
              className={
                isLoginModalOpen
                  ? "admin_login_box login-box-show"
                  : "admin_login_box d-none"
              }
              ref={wrapperRef}
            >
              <LoginForm onModalClose={() => setIsLoginModalOpen(false)} />
            </div>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box className="text-note text-center">
        <p className="newsite-msg">{notification}</p>
      </Box>

      {/* Firm Account Popup */}

      <BootstrapDialog
        onClose={() => {
          setIsRegisterModalOpen(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={isRegisterModalOpen}
        className="firm-account-popup popup-main"
        maxWidth="md"
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item className="text-left remove-item-css">
                <DialogTitle
                  className="firm-account-title red-pastel"
                  sx={{ m: 0, p: 2 }}
                >
                  <AccountBalanceIcon /> Firm Account
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setIsRegisterModalOpen(false);
                    }}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[900],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent className="popup-content">
                  <div className="popup-content-slide">
                    <h6>Perfect For :</h6>
                    <ul>
                      <li className="mb-1">
                        Attorneys, Firm with multiple filers, Solo Attorney
                        Practitioners
                      </li>
                      <li className="mb-1">
                        Once registered as Firm Administrator you can add other
                        attorneys and support person in the law firm.
                      </li>
                      <li>
                        You will be responsible for setting up and maintaining
                        all users, credit card accounts.
                      </li>
                    </ul>
                  </div>
                  <div className="popup-content-slide mt-4">
                    <h5 className="red-pastel mb-2">
                      Please do not register,{" "}
                    </h5>
                    <ul>
                      <li className="mb-3">
                        If you have already Registered to any certified service
                        providers (<b>ODYSSEY</b> eFileIL,{" "}
                        <b>
                          File & <br /> Serve, Filetime, Green Filing,
                          <br />
                          Legal e-File, My File Runner
                        </b>{" "}
                        or <b>US Legal Pro</b>), Please go to <b>Login</b> page
                        and Login.
                      </li>
                      <li>
                        If your firm has already created a firm account with{" "}
                        <b>ODYSSEY EFM System (efileIL)</b> through certified
                        service providers. If your firm has already has an
                        account, ask your administrator to add you.
                      </li>
                    </ul>
                  </div>
                </DialogContent>
                <DialogActions className="text-center w-100 d-inline-block mt-3 mb-4">
                  <Button
                    className="btn primary-button"
                    autoFocus
                    onClick={() => {
                      navigate("/register/firmUser");
                    }}
                  >
                    Register Account
                  </Button>
                </DialogActions>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </BootstrapDialog>

      {/* Self Representative Account Popup */}
      <BootstrapDialog
        onClose={() => {
          setIsSelfRegisterModalOpen(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={isSelfRegisterModalOpen}
        className="self-representative-popup popup-main"
        maxWidth="md"
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item className="text-left remove-item-css">
                <DialogTitle
                  className="firm-account-title red-pastel"
                  sx={{ m: 0, p: 2 }}
                >
                  <PersonIcon /> Self Representative Account
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setIsSelfRegisterModalOpen(false);
                    }}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[900],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent className="popup-content">
                  <div className="popup-content-slide">
                    <h6>Perfect For :</h6>
                    <ul>
                      <li className="mb-1">
                        ProSe filers, Non-Attorney users, Process Servers,
                        Landlords/Tenants
                      </li>
                      <li>
                        Select this option if you are an independent user, or
                        ProSe filer or court reporter and not associated with
                        law firm.
                      </li>
                    </ul>
                  </div>
                  <div className="popup-content-slide mt-4">
                    <h5 className="red-pastel mb-2">
                      Please do not register,{" "}
                    </h5>
                    <ul>
                      <li className="mb-3">
                        If you have already Registered to any certified service
                        providers (<b>ODYSSEY</b> eFileIL,{" "}
                        <b>
                          File & <br />
                          Serve, Filetime, Green Filing, Legal e-File, My File
                          Runner
                        </b>{" "}
                        or <b>US Legal Pro</b>), Please go to <b>Login</b> page
                        and Login.
                      </li>
                      <li className="mb-3">
                        Only register yourself as Self-Represented person, if
                        you are a{" "}
                        <b>Prose, a solo court reporter, solo process server</b>{" "}
                        or any individual{" "}
                        <b>not associated with an attorney or law firm.</b>
                      </li>
                      <li>
                        If you are a practicing attorney or support person in a
                        law firm or attorney please check with your Firm
                        administrator.
                      </li>
                    </ul>
                  </div>
                </DialogContent>
                <DialogActions className="text-center w-100 d-inline-block mt-3 mb-4">
                  <Button className="btn primary-button" autoFocus>
                    Register Account
                  </Button>
                </DialogActions>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </BootstrapDialog>
    </Box>
  );
};

export default Header;
