import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { IAxisErrorResponse } from "../../../interfaces";

const forgotPassword = async (email: string) => {
  await http.post(
    `${process.env.REACT_APP_USER_SERVICE_API_URL}/api/user/resetPassword`,
    {},
    {
      params: {
        email,
      },
    }
  );
};

const useForgotPassword = () =>
  useMutation<void, IAxisErrorResponse, string>({
    mutationKey: ["forgotPassword"],
    mutationFn: forgotPassword,
  });

export default useForgotPassword;
