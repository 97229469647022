import get from "lodash.get";

import { COUNTRY } from "./constants";

export const setAccessToken = (accessToken: string) =>
  localStorage.setItem("access_token", accessToken);

export const getAccessToken = () => localStorage.getItem("access_token");

export const clearLocalStorage = () => localStorage.clear();

export const setIndividualUser = (individual: string) =>
  localStorage.setItem("individual_user", individual);

export const isIndividualUser = () =>
  localStorage.getItem("individual_user") === "true";

export const setIsFirmAdmin = (firmAdmin: boolean) =>
  localStorage.setItem("firm_admin", firmAdmin.toString());

export const isFirmAdmin = () => localStorage.getItem("firm_admin") === "true";

export const setIsLinked = (linked: boolean) =>
  localStorage.setItem("linked", linked.toString());

export const isLinked = () => localStorage.getItem("linked") === "true";

export const setCloseFillingForLocation = (locations: string) =>
  localStorage.setItem("close_filling_for_location", locations);

export const getCloseFillingForLocation = () =>
  localStorage.getItem("close_filling_for_location");

export const setAllowanceChargeAmountValue = (amount: string) =>
  localStorage.setItem("allowance_charge_amount_value", amount);

export const setLocationsExcludedFromI2fileFee = (locations: string) =>
  localStorage.setItem("locations_excluded_from_i2file_fee", locations);

export const scrollToTop = () => {
  window.scrollTo({
    behavior: "smooth",
    top: 0,
  });
};

export const setIsAdmin = (isAdmin: string) =>
  localStorage.setItem("isAdmin", isAdmin);

export const getIsAdmin = () => localStorage.getItem("isAdmin");

export const validateZipCode = (
  countryKey: string,
  zipCode: string,
  form: any
) => {
  const country = get(form, countryKey);
  const errorMessage = "Error: Zip Code format is invalid";

  if (country === COUNTRY.US) {
    const part1 = zipCode.substring(0, 5);
    const part2 = zipCode.substring(zipCode.charAt(5) === "-" ? 6 : 5, 10);

    if (
      part1.length < 5 ||
      !/^\d+$/.test(part1) ||
      (part2.length > 0 && part2.length < 4) ||
      (part2.length > 0 && !/^\d+$/.test(part2)) ||
      zipCode.length > 10 ||
      parseInt(part1) === 0 ||
      parseInt(part2) === 0
    ) {
      return errorMessage;
    }
  } else if (country === COUNTRY.CA) {
    const isValid = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/.test(
      zipCode
    );

    if (!isValid) return errorMessage;
  } else if (country === COUNTRY.MX) {
    if (
      zipCode.length !== 5 ||
      !/^\d+$/.test(zipCode) ||
      parseInt(zipCode) === 0
    ) {
      return errorMessage;
    }
  }

  return true;
};

export const getZipCodePlaceholder = (country: string) => {
  if (country === COUNTRY.CA) return "A1A 1A1";
  else if (country === COUNTRY.MX) return "99999";
  else return "99999-9999";
};

export const renderAmount = (amount?: number | string) => {
  if (!amount || isNaN(parseFloat(amount.toString()))) return "0.00";

  return parseFloat(amount.toString()).toFixed(2);
};

export const validateCourtesyCopies = (value: string) => {
  if (!value) return true;

  if (/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/.test(value)) return true;

  return "Error: Please enter email addresses separated by the comma";
};

export const getAllowanceChargeAmountValue = (location: string) => {
  const amountValue = localStorage.getItem("allowance_charge_amount_value");
  const locations = localStorage
    .getItem("locations_excluded_from_i2file_fee")
    ?.toLocaleLowerCase()
    ?.split(",");

  if (!locations?.length || !amountValue) return "0";

  if (locations.includes(location.toLocaleLowerCase())) return "0";
  else return amountValue;
};

export const getProviderFee = (location: string) => {
  const amountValue = localStorage.getItem("allowance_charge_amount_value");
  const locations = localStorage
    .getItem("locations_excluded_from_i2file_fee")
    ?.toLocaleLowerCase()
    ?.split(",");

  if (!amountValue) return 0;

  if (!locations?.length || locations.includes(location.toLocaleLowerCase()))
    return 0;
  else return parseFloat(amountValue);
};

export const differenceInDays = (creationDate: string) => {
  const currentDate = new Date();
  const givencreationDate = new Date(creationDate);
  const oneDay = 1000 * 60 * 60 * 24;
  const daysDifference = parseInt(
    Math.round(
      (currentDate.getTime() - givencreationDate.getTime()) / oneDay
    ).toFixed(0)
  );
  if (daysDifference >= 30) return true;
  else return false;
};
