import { FC } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import Dialog from "../../../components/dialog";
import { IPaymentAccount } from "../../../interfaces";

interface BankDetailsModalProps {
  isOpen: boolean;
  paymentAccount?: IPaymentAccount;
  onClose: () => void;
}

const BankDetailsModal: FC<BankDetailsModalProps> = ({
  isOpen,
  paymentAccount,
  onClose,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Account Details"
      maxWidth="sm"
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="cardtype_id">Account Type</label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value={paymentAccount?.cardType?.value}
            disabled
            id="cardtype_id"
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="cardnumber_id">Account Number</label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value={`************${paymentAccount?.cardLast4}`}
            disabled
            id="cardnumber_id"
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="cardnumber_id">Routing Number</label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value="************"
            disabled
            id="cardnumber_id"
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="nameoncard_id">Name on Account</label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value={paymentAccount?.cardHolderName?.value}
            disabled
            id="nameoncard_id"
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="billing_address1_id">
              Billing Account Address Line 1
            </label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value="********************"
            disabled
            id="billing_address1_id"
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="billing_address2_id">
              Billing Account Address Line 2
            </label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value="********************"
            disabled
            id="billing_address2_id"
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="city_id">City</label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value="********"
            disabled
            id="city_id"
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="state_id">State</label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value="********"
            disabled
            id="state_id"
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="zip_id">Zip</label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value="********"
            disabled
            id="zip_id"
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default BankDetailsModal;
