import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { CREATE_EXISTING_CASE_DRAFT } from "../../URL";
import { IFeeCalculationRequest } from "../../../interfaces";

interface IAxisErrorResponse {
  response?: {
    data?: string;
  };
}

const ceateExistingCaseDraft = async (filing: IFeeCalculationRequest) => {
  const response = await http.post<number>(CREATE_EXISTING_CASE_DRAFT, filing);
  return response?.data;
};

const useCreateExistingCaseDraft = () =>
  useMutation<number, IAxisErrorResponse, IFeeCalculationRequest>({
    mutationKey: ["create-existing-case-draft"],
    mutationFn: ceateExistingCaseDraft,
  });

export default useCreateExistingCaseDraft;
