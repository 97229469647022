import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import Dialog from "../../../../components/dialog/Dialog";
import NewPassword from "../newPasswordModal";
import useCreateFirmUser from "../../../../apis/firm/users/useCreateFirmUser";
import useUpdateFirmUser from "../../../../apis/firm/users/useUpdateFirmUser";
import useResendFirmUserActivation from "../../../../apis/firm/users/useResendFirmUserActivation";
import { NotificationContext } from "../../../../context/NotificationContext";
import { IFirmUser } from "../../../../interfaces";

interface CreateEditUserProps {
  user?: IFirmUser;
  isOpen: boolean;
  onClose: (shouldUpdateGrid?: boolean) => void;
}

const CreateEditUser: FC<CreateEditUserProps> = ({ isOpen, onClose, user }) => {
  const [isNewPasswordModal, setIsNewPasswordModal] = useState(false);
  const createFirmUser = useCreateFirmUser();
  const updateFirmUser = useUpdateFirmUser();
  const resentActivation = useResendFirmUserActivation();
  const { createNotification } = useContext(NotificationContext);

  const {
    watch,
    control,
    handleSubmit,
    formState: { isSubmitted },
  } = useForm({
    defaultValues: {
      firstName: user?.firstName,
      middleName: user?.middleName,
      lastName: user?.lastName,
      email: user?.email,
      isAdmin: user?.role
        ? user.role.findIndex((role) => role.roleName === "FIRM_ADMIN") !== -1
        : false,
      isFiler: user?.role
        ? user.role.findIndex((role) => role.roleName === "FILER") !== -1
        : true,
      isAttorney: user?.role
        ? user.role.findIndex((role) => role.roleName === "ATTORNEY") !== -1
        : false,
      attorneyNumber: user?.attorneyNumber,
    },
  });

  const onSubmit = useCallback(
    async (data: {
      firstName?: string;
      middleName?: string;
      lastName?: string;
      email?: string;
      isAdmin: boolean;
      isFiler: boolean;
      isAttorney: boolean;
      attorneyNumber?: string;
    }) => {
      if (!data.firstName || !data.lastName || !data.email) return;

      if (!data.isAdmin && !data.isFiler) {
        // createNotification({
        //   title: "Firm User Notification",
        //   type: "error",
        //   message: "Please select at least one role from Firm Admin or Filer.",
        // });
        return;
      }

      if (!user) {
        createFirmUser.mutate({
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          email: data.email,
          firmAdminCheckbox: data.isAdmin,
          filerCheckbox: data.isFiler,
          attorneyCheckbox: data.isAttorney,
          attorneyNumber: data.attorneyNumber,
        });
      } else {
        updateFirmUser.mutate({
          userID: user.userID,
          firmID: user.firmID,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          email: data.email,
          firmAdminCheckbox: data.isAdmin,
          filerCheckbox: data.isFiler,
          attorneyCheckbox: data.isAttorney,
          attorneyNumber: data.attorneyNumber,
          role: user.role,
        });
      }
    },
    // eslint-disable-next-line
    [createFirmUser, updateFirmUser, user, createNotification]
  );

  const onResetPassword = useCallback(() => {
    setIsNewPasswordModal(true);
  }, []);

  const onResendActivation = useCallback(() => {
    if (user?.email) {
      resentActivation.mutate(user.userID);
    }
  }, [user, resentActivation]);

  useEffect(() => {
    if (createFirmUser.isSuccess) {
      createNotification({
        title: "Firm User Notification",
        type: "success",
        message: "New Firm User added.",
      });

      onClose(true);
    }
    // eslint-disable-next-line
  }, [createFirmUser.isSuccess, createNotification]);

  useEffect(() => {
    if (updateFirmUser.isSuccess) {
      createNotification({
        title: "Firm User Notification",
        type: "success",
        message: "Selected Firm User updated successfully.",
      });

      onClose(true);
    }
    // eslint-disable-next-line
  }, [updateFirmUser.isSuccess, createNotification]);

  useEffect(() => {
    if (resentActivation.isSuccess) {
      createNotification({
        title: "Firm User Notification",
        type: "success",
        message: "Activation link has been sent.",
      });
    }
    // eslint-disable-next-line
  }, [resentActivation.isSuccess]);

  useEffect(() => {
    if (createFirmUser.isError) {
      createNotification({
        title: "Firm User Notification",
        type: "error",
        message:
          createFirmUser?.error?.response?.data?.userMessage ||
          "Something went wrong while creating Firm User",
      });
    }
    // eslint-disable-next-line
  }, [createFirmUser.isError, createNotification]);

  useEffect(() => {
    if (updateFirmUser.isError) {
      createNotification({
        title: "Firm User Notification",
        type: "error",
        message:
          updateFirmUser?.error?.response?.data?.userMessage ||
          "Something went wrong while updating Firm User",
      });
    }
    // eslint-disable-next-line
  }, [updateFirmUser.isError, createNotification]);

  useEffect(() => {
    if (resentActivation.isError) {
      createNotification({
        title: "Firm User Notification",
        type: "error",
        message:
          resentActivation.error.response?.data?.userMessage ||
          "Something went wrong while sending Activation link.",
      });
    }
    // eslint-disable-next-line
  }, [resentActivation.isError]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose.bind(this, false)}
      title={user ? "Edit Firm User" : "Create Firm User"}
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: "Error: First Name is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  inputRef={ref}
                  autoFocus
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{
                    required: true,
                  }}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="First Name"
                  label="First Name"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="middleName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Middle Name"
                  label="Middle Name"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: "Error: Last Name is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  inputRef={ref}
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{
                    required: true,
                  }}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Last Name"
                  label="Last Name"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Error: Email Address is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email.",
                },
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  inputRef={ref}
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{ required: true }}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Email Address"
                  label="Email Address"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box className="roles-checkbox">
              <InputLabel sx={{ paddingRight: 1 }} required>
                Roles
              </InputLabel>
              <Controller
                name="isAdmin"
                control={control}
                render={({ field }) => (
                  <InputLabel className="label-checkbox">
                    <Checkbox
                      {...field}
                      checked={field.value}
                      inputProps={{
                        "aria-required": true,
                        "aria-label": "Firm Admin",
                      }}
                    />
                    <span>Firm Admin</span>
                  </InputLabel>
                )}
              />
              <Controller
                name="isFiler"
                control={control}
                render={({ field }) => (
                  <InputLabel className="label-checkbox">
                    <Checkbox
                      {...field}
                      checked={field.value}
                      inputProps={{
                        "aria-required": true,
                        "aria-label": "Filer",
                      }}
                    />
                    <span>Filer</span>
                  </InputLabel>
                )}
              />
              <Controller
                name="isAttorney"
                control={control}
                render={({ field }) => (
                  <InputLabel className="label-checkbox">
                    <Checkbox
                      {...field}
                      checked={field.value}
                      inputProps={{
                        "aria-required": true,
                        "aria-label": "Attorney",
                      }}
                    />
                    <span>Attorney</span>
                  </InputLabel>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              {watch("isAttorney") && (
                <Controller
                  name="attorneyNumber"
                  control={control}
                  rules={{
                    required: "Error: Attorney Number is required.",
                  }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      className="w-100 m-0 remove-item-css"
                      id={field.name}
                      inputRef={ref}
                      inputProps={{
                        "aria-required": true,
                      }}
                      InputLabelProps={{ required: true }}
                      {...field}
                      onBlur={() => {
                        field.onChange(field.value?.trim());
                        field.onBlur();
                      }}
                      placeholder="Attorney Number"
                      label="Attorney Number"
                      variant="standard"
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                    />
                  )}
                />
              )}
            </Box>
          </Grid>
          <Grid sx={{ paddingLeft: 2 }}>
            {isSubmitted && !(watch("isFiler") || watch("isAdmin")) && (
              <p
                style={{
                  color: "#d32f2f",
                  fontWeight: 400,
                  fontSize: "0.8rem",
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                }}
              >
                Error: Please select at least one role from Firm Admin or Filer.
              </p>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-between"
          mt={2}
        >
          <Grid item xs={12} md={6}>
            {user && !user.isActivated && (
              <Button
                type="button"
                variant="contained"
                className="btn primary-button"
                onClick={onResendActivation}
              >
                {resentActivation.isLoading ? (
                  <CircularProgress
                    sx={{
                      height: "25px !important",
                      width: "25px !important",
                      "--CircularProgress-size": "8px",
                      "--CircularProgress-trackThickness": "1px",
                      "--CircularProgress-progressThickness": "1px",
                    }}
                    color="inherit"
                  />
                ) : (
                  "Resend Activation"
                )}
              </Button>
            )}
            {user && user.isActivated && (
              <Button
                type="button"
                variant="contained"
                className="btn primary-button"
                onClick={onResetPassword}
              >
                Reset Password
              </Button>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display={"flex"} justifyContent={"end"}>
              <Button
                type="button"
                variant="contained"
                className="btn button-outline-secondary"
                onClick={onClose.bind(this, false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                className="btn primary-button ms-2"
                disabled={createFirmUser.isLoading || updateFirmUser.isLoading}
              >
                {createFirmUser.isLoading || updateFirmUser.isLoading ? (
                  <CircularProgress
                    sx={{
                      height: "25px !important",
                      width: "25px !important",
                      "--CircularProgress-size": "8px",
                      "--CircularProgress-trackThickness": "1px",
                      "--CircularProgress-progressThickness": "1px",
                    }}
                    color="inherit"
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>

      {user && (
        <NewPassword
          isOpen={isNewPasswordModal}
          user={user}
          onClose={() => setIsNewPasswordModal(false)}
        />
      )}
    </Dialog>
  );
};

export default CreateEditUser;
