import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_IMPORT_TYLER_PAYMENT_LIST } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface IGetImportList {
  fileData: string;
  fileName: string;
  paymentFieldId: number;
  status: number;
  uploadOn: string;
}

const getImportList = async () => {
  const response = await http.post<IGetImportList[]>(
    GET_IMPORT_TYLER_PAYMENT_LIST
  );
  return response.data;
};

const useImportPaymentList = () =>
  useQuery<IGetImportList[], IAxisErrorResponse>({
    queryKey: ["importList"],
    queryFn: getImportList,
  });

export default useImportPaymentList;
