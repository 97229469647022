import { FC, useCallback, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import Dialog from "../../../components/dialog/Dialog";
import useProfile from "../../../apis/auth/useProfile";
import useCreateAttorney from "../../../apis/attorneys/useCreateAttorney";
import useUpdateAttorney from "../../../apis/attorneys/useUpdateAttorney";
import { NotificationContext } from "../../../context/NotificationContext";
import { IAttorney } from "../../../interfaces";

interface ICreateEditAttorney {
  attorney?: IAttorney;
  isOpen: boolean;
  onClose: (shouldUpdateGrid?: boolean) => void;
}

const CreateEditAttorney: FC<ICreateEditAttorney> = ({
  isOpen,
  attorney,
  onClose,
}) => {
  const profile = useProfile();
  const createAttorney = useCreateAttorney();
  const updateAttorney = useUpdateAttorney();
  const { createNotification } = useContext(NotificationContext);

  const {
    watch,
    control,
    handleSubmit,
    formState: { isSubmitted },
  } = useForm({
    defaultValues: {
      firstName: attorney?.firstName || "",
      middleName: attorney?.middleName || "",
      lastName: attorney?.lastName || "",
      barNumber: attorney?.barNumber || "",
      isFirmUser: false,
      isAdmin: false,
      isFiler: false,
      email: "",
    },
  });
  const onSubmit = useCallback(
    async (data: {
      firstName: string;
      middleName: string;
      lastName: string;
      barNumber: string;
      isFirmUser: boolean;
      isAdmin: boolean;
      isFiler: boolean;
      email: string;
    }) => {
      if (!profile.data) return;

      if (data.isFirmUser && !data.isAdmin && !data.isFiler) {
        // createNotification({
        //   title: "Attorney Notification",
        //   type: "error",
        //   message: "Please select at least one role from Firm Admin or Filer.",
        // });
        return;
      }

      if (attorney) {
        updateAttorney.mutate({
          attorneyID: attorney.attorneyID,
          barNumber: data.barNumber,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          firmID: profile.data.firmID,
          filerCheckbox: data.isFiler,
          firmAdminCheckbox: data.isAdmin,
          email: data.email,
          userId: "",
        });
      } else {
        createAttorney.mutate({
          barNumber: data.barNumber,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          firmID: profile.data.firmID,
          filerCheckbox: data.isFiler,
          firmAdminCheckbox: data.isAdmin,
          email: data.email,
          userId: "",
        });
      }
    },
    [attorney, profile.data, createAttorney, updateAttorney]
  );

  console.log("isAdmin", watch("isAdmin"));
  console.log("isFiler", watch("isFiler"));
  console.log("isfrimUser", watch("isFirmUser"));

  useEffect(() => {
    if (createAttorney.isSuccess) {
      createNotification({
        title: "Attorney Notification",
        type: "success",
        message: "New Attorney Added",
      });

      onClose(true);
    }
    // eslint-disable-next-line
  }, [createAttorney.isSuccess, createNotification]);

  useEffect(() => {
    if (updateAttorney.isSuccess) {
      createNotification({
        title: "Attorney Notification",
        type: "success",
        message: "Selected Attorney updated",
      });

      onClose(true);
    }
    // eslint-disable-next-line
  }, [updateAttorney.isSuccess, createNotification]);

  useEffect(() => {
    if (createAttorney.isError) {
      createNotification({
        title: "Attorney Notification",
        type: "error",
        message:
          createAttorney?.error?.response?.data?.userMessage ||
          "Something went wrong while creating attorney",
      });
    }
    // eslint-disable-next-line
  }, [createAttorney.isError, createNotification]);

  useEffect(() => {
    if (updateAttorney.isError) {
      createNotification({
        title: "Attorney Notification",
        type: "error",
        message:
          updateAttorney?.error?.response?.data?.userMessage ||
          "Something went wrong while updating attorney",
      });
    }
    // eslint-disable-next-line
  }, [updateAttorney.isError, createNotification]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose.bind(this, false)}
      title={attorney ? "Edit Attorney" : "Create Attorney"}
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: "Error: First Name is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  inputRef={ref}
                  autoFocus
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{
                    required: true,
                  }}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="First Name"
                  label="First Name"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="middleName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Middle Name"
                  label="Middle Name"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: "Error: Last Name is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  inputRef={ref}
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{
                    required: true,
                  }}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Last Name"
                  label="Last Name"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="barNumber"
              control={control}
              rules={{
                required: "Error: Attorney Number is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  inputRef={ref}
                  inputProps={{
                    "aria-required": true,
                  }}
                  InputLabelProps={{ required: true }}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="1234567"
                  label="Attorney Number"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Controller
              name="isFirmUser"
              control={control}
              render={({ field }) => (
                <InputLabel className="label-checkbox">
                  <Checkbox className="ps-0" {...field} checked={field.value} />
                  <span>Save Attorney as Firm User</span>
                </InputLabel>
              )}
            />
          </Grid>
          {watch("isFirmUser") && (
            <>
              <Grid item xs={12} md={8}>
                <Box className="roles-checkbox">
                  <InputLabel sx={{ paddingRight: 1 }} required>
                    Roles
                  </InputLabel>
                  <Controller
                    name="isAdmin"
                    control={control}
                    render={({ field }) => (
                      <InputLabel className="label-checkbox">
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{
                            "aria-required": true,
                            "aria-label": "Firm Admin",
                          }}
                        />
                        <span>Firm Admin</span>
                      </InputLabel>
                    )}
                  />
                  <Controller
                    name="isFiler"
                    control={control}
                    render={({ field }) => (
                      <InputLabel className="label-checkbox">
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{
                            "aria-required": true,
                            "aria-label": "Filer",
                          }}
                        />
                        <span>Filer</span>
                      </InputLabel>
                    )}
                  />
                </Box>

                {isSubmitted &&
                  "isFirmUser" &&
                  !(watch("isFiler") || watch("isAdmin")) && (
                    <p
                      style={{
                        color: "#d32f2f",
                        fontWeight: 400,
                        fontSize: "0.8rem",
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                      }}
                    >
                      Error: Please select at least one role from Firm Admin or
                      Filer.
                    </p>
                  )}
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Error: Email Address is required.",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email.",
                    },
                  }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      className="w-100 m-0"
                      id={field.name}
                      inputRef={ref}
                      inputProps={{
                        "aria-required": true,
                      }}
                      InputLabelProps={{ required: true }}
                      {...field}
                      onBlur={() => {
                        field.onChange(field.value?.trim());
                        field.onBlur();
                      }}
                      placeholder="Email Address"
                      label="Email Address"
                      variant="standard"
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                    />
                  )}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} mt={2}>
            <Box display="flex" justifyContent="end">
              <Button
                type="button"
                variant="contained"
                className="btn button-outline-secondary"
                onClick={onClose.bind(this, false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                className="btn primary-button ms-2"
                disabled={createAttorney.isLoading || updateAttorney.isLoading}
              >
                {createAttorney.isLoading || updateAttorney.isLoading ? (
                  <CircularProgress
                    sx={{
                      height: "25px !important",
                      width: "25px !important",
                      "--CircularProgress-size": "8px",
                      "--CircularProgress-trackThickness": "1px",
                      "--CircularProgress-progressThickness": "1px",
                    }}
                    color="inherit"
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default CreateEditAttorney;
