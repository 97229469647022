import { useQuery } from "@tanstack/react-query";

import http from "../../http";
import { GET_DASHBOARD_COUNT } from "../URL";
import { IAxisErrorResponse, IDashboardCount } from "../../interfaces";

const getDashboardCount = async () => {
  const response = await http.post<IDashboardCount>(GET_DASHBOARD_COUNT);
  return response.data;
};

const useDashboardCount = () =>
  useQuery<IDashboardCount, IAxisErrorResponse>({
    queryKey: ["dashboardCount"],
    queryFn: getDashboardCount,
  });

export default useDashboardCount;
