import { useMutation } from "@tanstack/react-query";

import http from "../../http";
import { ADMIN_PANEL_REMOVE_BY_EMAIL_PENDING_REQUESTS } from "../URL";
import { IAxisErrorResponse } from "../../interfaces";

const deletePendingRequestByEmail = async (email: string) => {
  await http.post(ADMIN_PANEL_REMOVE_BY_EMAIL_PENDING_REQUESTS, {
    email,
  });
};

const useDeletePendingRequestByEmail = () =>
  useMutation<void, IAxisErrorResponse, string>({
    mutationKey: ["delete-pendingRequest"],
    mutationFn: deletePendingRequestByEmail,
  });

export default useDeletePendingRequestByEmail;
