import { FC, useCallback, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

import AddPartyModal from "./addParty";
import Party from "./party";
import useAttorneys from "../../../apis/firm/useAttorneys";
import {
  IAttorney,
  ICaseInformation,
  IFilingInformation,
  ILocationParty,
  IParty,
} from "../../../interfaces";

interface PartiesProps {
  caseInformation: ICaseInformation;
  locationParties: ILocationParty[];
  parties: IParty[];
  filingInformation?: IFilingInformation;
  updateParties: (parties: IParty[], tab: number) => void;
  createDraft: (
    paymentAccountId: string,
    filingAttorneyId: string,
    filingsInfo: IFilingInformation,
    partyResponsibleForFees: number | string,
    attorneys?: IAttorney[],
    caseParties?: IParty[]
  ) => void;
}

const Parties: FC<PartiesProps> = ({
  caseInformation,
  parties,
  locationParties,
  filingInformation,
  updateParties,
  createDraft,
}) => {
  const { data: attorneys } = useAttorneys();

  const [isAddPartyModalOpen, setIsAddPartyModalOpen] = useState(false);
  const { watch, control, setValue, handleSubmit } = useForm<{
    parties: IParty[];
  }>({
    defaultValues: {
      parties: parties.length
        ? parties
        : locationParties.map((party) => ({
            code: party.efmPartytypeCode,
            name: party.efmPartytypeName,
            type: "person",
            partyNotAvailable: false,
            firstName: "",
            middleName: "",
            lastName: "",
            businessName: "",
            suffix: "",
            leadAttorney: "",
            additionalAttorneys: [],
            streetAddressLine1: "",
            streetAddressLine2: "",
            city: "",
            phoneNumber: "",
            zipCode: "",
            countryCode: "US",
            stateCode: "",
            iAmThisParty: false,
            dateOfDeathCondition: party.dateOfDeath,
          })),
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "parties",
    control,
  });

  const openAddPartyModal = useCallback(() => {
    setIsAddPartyModalOpen(true);
  }, []);

  const closeAddPartyModal = useCallback(() => {
    setIsAddPartyModalOpen(false);
  }, []);

  const addAdditionalParty = useCallback(
    (party: IParty) => {
      append(party);
      setIsAddPartyModalOpen(false);
    },
    [append]
  );

  const onSubmit = useCallback(
    (formData: { parties: IParty[] }) => {
      if (formData.parties.length === 0) return;

      updateParties(formData.parties, 2);
    },
    [updateParties]
  );

  const onPrevious = useCallback(() => {
    updateParties(watch("parties"), 0);
  }, [updateParties, watch]);

  const onCreateDraft = useCallback(() => {
    createDraft(
      caseInformation.paymentAccount,
      caseInformation.filingAttorney,
      {
        filings: filingInformation?.filings || [
          {
            id: "Filing1",
            eFileType: "E-File",
            eFile: true,
            filingCode: "",
            documentDescriptionText: "",
            referenceNumber: "",
            filingCommentsText: "",
            courtesyCopiesText: "",
            service: false,
            attachments: [],
            optionalServices: [],
          },
        ],
        filingFees: filingInformation?.filingFees || [],
        conveninceFee: filingInformation?.conveninceFee || 0,
        caseInitiationFee: filingInformation?.caseInitiationFee || 0,
        totalProviderFee: filingInformation?.totalProviderFee || 0,
        feeCalculationAmount: filingInformation?.feeCalculationAmount || 0,
        partyResponsibleForFeesIndex:
          filingInformation?.partyResponsibleForFeesIndex || "",
      },
      "",
      attorneys,
      watch("parties")
    );
  }, [createDraft, caseInformation, attorneys, filingInformation, watch]);

  return (
    <Box className="new-case-party existing-case-parties" sx={{ p: 0 }}>
      <Box
        className="existing-case-parties-top"
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5">
          Enter the details for the Parties Involved in this Case
        </Typography>
        <Button
          variant="contained"
          className="btn button-outline-secondary"
          startIcon={<AddIcon />}
          onClick={openAddPartyModal}
        >
          Add Additional Party
        </Button>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => {
          return (
            <Party
              key={field.id}
              field={field}
              control={control}
              index={index}
              locationCode={caseInformation.locationName}
              watch={watch}
              setValue={setValue}
              remove={remove}
            />
          );
        })}

        <Box pt={4} pb={2} display="flex" justifyContent="center">
          <Button
            className="btn button-outline-secondary me-3"
            variant="contained"
            type="button"
            onClick={onPrevious}
          >
            Previous
          </Button>
          <Button
            className="btn primary-button me-3"
            variant="contained"
            type="button"
            onClick={onCreateDraft}
          >
            Save As Draft
          </Button>
          <Button
            className="btn primary-button"
            variant="contained"
            type="submit"
          >
            Next
          </Button>
        </Box>
      </form>

      <AddPartyModal
        isOpen={isAddPartyModalOpen}
        locationCode={caseInformation.locationName}
        addAdditionalParty={addAdditionalParty}
        closeModal={closeAddPartyModal}
      />
    </Box>
  );
};

export default Parties;
