import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/EditOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { GridRenderCellParams, GridColDef } from "@mui/x-data-grid/models";

import ServiceContactCases from "./serviceContactCases";
import CreateEditServiceContact from "./createEditServiceContact";
import ServiceContactDetails from "./serviceContactDetails";
import DeleteServiceContact from "./deleteServiceContact";
import Page from "../../components/page";
import GridTable from "../../components/grid";
import Loader from "../../components/loader";
import useServiceContact from "../../apis/serviceContacts/useServiceContact";
import queryClient from "../../queryClient";
import { NotificationContext } from "../../context/NotificationContext";
import { GET_SERVICE_CONTACTS } from "../../apis/URL";
import { IServiceContact } from "../../interfaces";

const ServiceContacts = () => {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [isAttachedCasesModalOpen, setIsAttachedCasesModalOpen] =
    useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedServiceContact, setSelectedServiceContact] = useState<
    IServiceContact | undefined
  >();
  const [selectedServiceContactId, setSelectedServiceContactId] = useState<
    string | undefined
  >();
  const { createNotification } = useContext(NotificationContext);
  const serviceContact = useServiceContact(selectedServiceContactId);

  const onCreate = useCallback(() => {
    setIsCreateEditModalOpen(true);
  }, []);

  const onDetails = useCallback((contact: IServiceContact) => {
    setIsDetailsModalOpen(true);
    setSelectedServiceContactId(contact.serviceContactID);
  }, []);

  const onEdit = useCallback((contact: IServiceContact) => {
    setIsCreateEditModalOpen(true);
    setSelectedServiceContactId(contact.serviceContactID);
  }, []);

  const onDelete = useCallback((contact: IServiceContact) => {
    setSelectedServiceContact(contact);
    setIsDeleteModalOpen(true);
  }, []);

  const onAttachedCases = useCallback((contact: IServiceContact) => {
    setIsAttachedCasesModalOpen(true);
    setSelectedServiceContact(contact);
  }, []);

  const onClose = useCallback((shouldUpdateGrid?: boolean) => {
    setIsDetailsModalOpen(false);
    setIsAttachedCasesModalOpen(false);
    setIsCreateEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setSelectedServiceContact(undefined);
    setSelectedServiceContactId(undefined);

    if (shouldUpdateGrid === true) {
      queryClient.refetchQueries(["grid", GET_SERVICE_CONTACTS]);
    }
  }, []);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "firstName",
        headerName: "First Name",
        width: 400,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "lastName",
        headerName: "Last Name",
        width: 400,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "email",
        headerName: "Email Address",
        width: 450,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span
              id={params.row.serviceContactID}
              title={`Email ${params.row.email}`}
            >
              {params.row.email}
            </span>
          );
        },
      },
      {
        field: "action",
        headerName: "Action",
        width: 250,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <div className="dataGridactionIcons">
              <Tooltip title="View Service Contact Details">
                <IconButton
                  aria-label="View Service Contact Details"
                  onClick={onDetails.bind(this, params.row)}
                  aria-describedby={params.row.serviceContactID}
                >
                  <span className="material-symbols-outlined theme-blue">
                    perm_contact_calendar
                  </span>
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit Service Contact">
                <IconButton
                  aria-label="Edit Service Contact"
                  onClick={onEdit.bind(this, params.row)}
                  aria-describedby={params.row.serviceContactID}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="View Attached Case List">
                <IconButton
                  aria-label="View Attached Case List"
                  onClick={onAttachedCases.bind(this, params.row)}
                  aria-describedby={params.row.serviceContactID}
                >
                  <AttachFileIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remove Service Contact">
                <IconButton
                  aria-label="delete"
                  onClick={() => onDelete(params.row)}
                  aria-describedby={params.row.serviceContactID}
                >
                  {/* <DeleteIcon className="datagrid-cancel" /> */}
                  <span className="material-symbols-outlined datagrid-cancel">
                    delete
                  </span>
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [onAttachedCases, onDelete, onDetails, onEdit]
  );

  const getRowId = useCallback((row: any) => row.serviceContactID, []);

  useEffect(() => {
    if (serviceContact.isError) {
      createNotification({
        title: "Service Contact Notification",
        type: "error",
        message:
          serviceContact.error.response?.data?.userMessage ||
          "Something went wrong while Getting service contact",
      });
    }
    // eslint-disable-next-line
  }, [serviceContact.isError]);

  return (
    <Page>
      <div className="content-wrapper">
        <h2 className="dash-title-h2 mb-4">Manage Service Contacts</h2>

        <div className="themeDataTable">
          <Box sx={{ height: "auto", overflow: "auto" }}>
            <GridTable
              url={GET_SERVICE_CONTACTS}
              columns={columns}
              searchKeys={["firstName", "lastName", "email"]}
              showSearch
              hideFooter={false}
              getRowId={getRowId}
              action={
                <Button
                  size="large"
                  variant="contained"
                  className="btn primary-button"
                  onClick={onCreate}
                >
                  Add Contact
                </Button>
              }
            />
          </Box>
        </div>
      </div>

      {serviceContact.isFetching && <Loader />}

      {serviceContact.data && !serviceContact.isFetching && (
        <ServiceContactDetails
          isOpen={isDetailsModalOpen}
          serviceContact={serviceContact.data}
          onClose={onClose}
        />
      )}

      {isAttachedCasesModalOpen && (
        <ServiceContactCases
          isOpen={isAttachedCasesModalOpen}
          serviceContact={selectedServiceContact}
          onClose={onClose}
        />
      )}

      {isCreateEditModalOpen && !serviceContact.isFetching && (
        <CreateEditServiceContact
          key={serviceContact.data?.serviceContactID}
          isOpen={isCreateEditModalOpen}
          serviceContact={serviceContact.data}
          onClose={onClose}
        />
      )}

      {selectedServiceContact && isDeleteModalOpen && (
        <DeleteServiceContact
          serviceContactId={selectedServiceContact.serviceContactID}
          isOpen={isDeleteModalOpen}
          onClose={onClose}
        />
      )}
    </Page>
  );
};

export default ServiceContacts;
