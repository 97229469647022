import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { GET_DRAFT_DETAILS } from "../../URL";
import {
  IAxisErrorResponse,
  IFeeCalculationRequest,
} from "../../../interfaces";

const getDraftDetails = async (transactionId: string) => {
  const response = await http.post<IFeeCalculationRequest>(GET_DRAFT_DETAILS, {
    transactionid: transactionId,
  });
  return response.data;
};

const useDraftDetails = () =>
  useMutation<IFeeCalculationRequest, IAxisErrorResponse, string>({
    mutationKey: ["draft-details"],
    mutationFn: getDraftDetails,
  });

export default useDraftDetails;
