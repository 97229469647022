import { FC, ReactNode, createContext, useCallback, useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";

interface ICreateNotification {
  title?: string;
  message: string;
  type: "error" | "info" | "success" | "warning";
}

interface INotification extends ICreateNotification {
  key: string;
}

interface NotificationProviderProps {
  children: ReactNode;
}

const TIMEOUT = 10000;

export const NotificationContext = createContext({
  createNotification: (notification: ICreateNotification) => {},
});

export const NotificationProvider: FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const createNotification = useCallback(
    (notification: ICreateNotification) => {
      const key = Date.now().toString();

      setNotifications((prevState) => [...prevState, { ...notification, key }]);

      setTimeout(() => {
        onClose(key);
      }, TIMEOUT);
    },
    // eslint-disable-next-line
    []
  );

  const onClose = useCallback((key: string) => {
    setNotifications((prevState) =>
      prevState.filter((notification) => notification.key !== key)
    );
  }, []);

  return (
    <NotificationContext.Provider value={{ createNotification }}>
      <Snackbar
        open={!!notifications.length}
        autoHideDuration={null}
        transitionDuration={0}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          mt: "env(safe-area-inset-top)",
          mb: "env(safe-area-inset-bottom)",
        }}
      >
        <Stack flexDirection="column" gap={1}>
          {notifications.map((notification) => (
            <Grow key={notification.key} in={true} timeout={300}>
              <Alert
                severity={notification.type}
                onClose={onClose.bind(this, notification.key)}
                variant="filled"
                sx={{
                  minWidth: 280,
                  width: { xs: 1, md: "auto" },
                  mb: 1,
                }}
              >
                {notification?.title && (
                  <AlertTitle>{notification.title}</AlertTitle>
                )}
                {notification?.message}
              </Alert>
            </Grow>
          ))}
        </Stack>
      </Snackbar>
      {children}
    </NotificationContext.Provider>
  );
};
