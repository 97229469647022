import { useContext, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Page from "../../../components/page";
import UpdateFirmInformation from "./updateFirmInformation";
import useFirmInformation from "../../../apis/firm/useFirmInformation";
import { NotificationContext } from "../../../context/NotificationContext";

const FirmInformation = () => {
  const firmInformation = useFirmInformation();
  const { createNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (firmInformation.isError) {
      createNotification({
        title: "Attorney Notification",
        type: "error",
        message:
          firmInformation?.error?.response?.data?.userMessage ||
          "Something went wrong while getting firm information",
      });
    }
    // eslint-disable-next-line
  }, [firmInformation.isError]);

  return (
    <Page>
      <div className="content-wrapper">
        <h2 className="dash-title-h2 mb-4">Firm Information</h2>

        {firmInformation.isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <UpdateFirmInformation
          key={firmInformation.data?.firmID}
          firm={firmInformation.data}
        />
      </div>
    </Page>
  );
};

export default FirmInformation;
