import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { UPLOAD_SUBSEQUENT_DOCUMENT } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface IUploadBody {
  filingCode: string;
  file: File;
}

interface IUploadResponse {
  status: string;
  responseObj: {
    fileContainsVirus?: string;
  };
}

const uploadSubsequentDocument = async ({ filingCode, file }: IUploadBody) => {
  const formData = new FormData();
  formData.append("docToUpload", file, file.name);

  const response = await http.post(UPLOAD_SUBSEQUENT_DOCUMENT, formData, {
    params: {
      filingCode,
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

const useUploadSubsequentDocument = () =>
  useMutation<IUploadResponse, IAxisErrorResponse, IUploadBody>({
    mutationKey: ["upload-subsequent-document"],
    mutationFn: uploadSubsequentDocument,
  });

export default useUploadSubsequentDocument;
