import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Page from "../../../components/page";
import useUpdateUserNotification from "../../../apis/userNotification/updateUserNotification/useUpdateUserNotification";
import useUserNotification from "../../../apis/userNotification/useUserNotification";
import { NotificationContext } from "../../../context/NotificationContext";

const UserNotification = () => {
  const userNotification = useUserNotification();
  const updateUserNotification = useUpdateUserNotification();
  const { createNotification } = useContext(NotificationContext);

  const [homePageNotification, setHomePageNotification] = useState("");
  const [homePageTicker, setHomePageTicker] = useState("");

  const homePageNotificationChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setHomePageNotification(event.target.value);
    },
    []
  );

  const homePageTickerChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setHomePageTicker(event.target.value);
    },
    []
  );

  const onSave = useCallback(() => {
    updateUserNotification.mutate({
      homePageNotification: homePageNotification || "",
      homePageTicker: homePageTicker || "",
    });
  }, [homePageNotification, homePageTicker, updateUserNotification]);

  useEffect(() => {
    if (userNotification.data) {
      setHomePageNotification(userNotification.data.homePageNotification);
      setHomePageTicker(userNotification.data.homePageTicker);
    }
  }, [userNotification.data]);

  useEffect(() => {
    if (updateUserNotification.isSuccess) {
      createNotification({
        title: "User Notification",
        type: "success",
        message: "User Notification Saved Successfully.",
      });
    }
  }, [updateUserNotification.isSuccess, createNotification]);

  useEffect(() => {
    if (userNotification.isError) {
      createNotification({
        title: "User Notification",
        type: "error",
        message:
          userNotification.error.response?.data?.userMessage ||
          "Something went wrong while getting User Notification",
      });
    }
    // eslint-disable-next-line
  }, [userNotification.isError, createNotification]);

  useEffect(() => {
    if (updateUserNotification.isError) {
      createNotification({
        title: "User Notification",
        type: "error",
        message:
          updateUserNotification.error.response?.data?.userMessage ||
          "Something went wrong while saving User Notification",
      });
    }
    // eslint-disable-next-line
  }, [updateUserNotification.isError, createNotification]);

  return (
    <Page>
      <div className="content-wrapper">
        <h2 className="dash-title-h2 mb-4">User Notification</h2>
        <TextField
          id="outlined-multiline-static"
          label="Homepage Notification"
          rows={5}
          placeholder="Homepage Notification"
          className="w-100 mb-5"
          value={homePageNotification}
          onChange={homePageNotificationChange}
          multiline
        />

        <TextField
          id="outlined-multiline-static"
          label="Homepage Ticker"
          multiline
          rows={5}
          placeholder="Homepage Ticker"
          className="w-100"
          value={homePageTicker}
          onChange={homePageTickerChange}
        />

        <div className="text-center mt-4">
          <Button
            className="btn primary-button me-3"
            variant="contained"
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </div>
    </Page>
  );
};

export default UserNotification;
