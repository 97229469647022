import { FC } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

interface ItemProps {
  title: string;
  selectedItems: string[];
  showSearch?: boolean;
  searchToolTipTitle?: string;
  items: {
    id: string;
    label: string;
    subTitle?: string;
  }[];
  toggleItem: (id: string) => void;
  onSearchClick?: (id: string) => void;
}

const Items: FC<ItemProps> = ({
  title,
  selectedItems,
  items,
  showSearch,
  searchToolTipTitle,
  toggleItem,
  onSearchClick,
}) => {
  return (
    <Card>
      <CardHeader
        component="span"
        sx={{
          px: 2,
          py: 1,
          ".MuiCardHeader-title": {
            fontSize: 14,
            fontWeight: 600,
          },
        }}
        title={title}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((item) => {
          return (
            <ListItem
              key={item.id}
              role="listitem"
              sx={{
                bgcolor:
                  selectedItems.indexOf(item.id) !== -1
                    ? "lightblue"
                    : "background.paper",
                ":hover": {
                  bgcolor:
                    selectedItems.indexOf(item.id) !== -1
                      ? "lightblue"
                      : "lightgray",
                },
              }}
              onClick={toggleItem.bind(this, item.id)}
            >
              <ListItemText primary={item.label} />
              <ListItemText primary={item.subTitle} />
              {showSearch && (
                <Tooltip title={searchToolTipTitle || ""}>
                  <IconButton
                    aria-label="View Service Contact Details"
                    onClick={() => onSearchClick && onSearchClick(item.id)}
                  >
                    <SearchIcon className="theme-blue" />
                  </IconButton>
                </Tooltip>
              )}
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
};

export default Items;
