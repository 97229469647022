import { useMutation } from "@tanstack/react-query";

import http from "../../../../http";
import { DELETE_FIRM_USER } from "../../../URL";
import { IAxisErrorResponse } from "../../../../interfaces";

const deleteFirmUser = async (userId: string) => {
  await http.post(DELETE_FIRM_USER, {
    userID: userId,
  });
};

const useDeleteFirmUser = () =>
  useMutation<void, IAxisErrorResponse, string>({
    mutationKey: ["delete-firm-user"],
    mutationFn: deleteFirmUser,
  });

export default useDeleteFirmUser;
