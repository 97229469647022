import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_STATES_URL } from "../../URL";

interface IState {
  stateId: number;
  efmStateCode: string;
  efmStateName: string;
}

const getStates = async (countryCode?: string) => {
  if (!countryCode) return;

  const response = await http.post<IState[]>(GET_STATES_URL, {
    efmCountryCode: countryCode,
  });
  return response.data;
};

const useStates = (countryCode?: string) =>
  useQuery<IState[] | undefined>({
    queryKey: ["states", countryCode || ""],
    queryFn: () => getStates(countryCode),
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default useStates;
