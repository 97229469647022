import { useCallback, useMemo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/EditOutlined";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { GridRenderCellParams, GridColDef } from "@mui/x-data-grid/models";

import Page from "../../components/page";
import GridTable from "../../components/grid";
import DeleteAttorney from "./deleteAttorney";
import CreateEditAttorney from "./createEditAttorney";
import queryClient from "../../queryClient";
import { GET_ATTORNEYS } from "../../apis/URL";
import { IAttorney } from "../../interfaces";

const Attorneys = () => {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedAttorney, setSelectedAttorney] = useState<
    IAttorney | undefined
  >();

  const onCreate = useCallback(() => {
    setIsCreateEditModalOpen(true);
  }, []);

  const onEdit = useCallback((attorney: IAttorney) => {
    setSelectedAttorney(attorney);
    setIsCreateEditModalOpen(true);
  }, []);

  const onDelete = useCallback((attorney: IAttorney) => {
    setSelectedAttorney(attorney);
    setIsDeleteModalOpen(true);
  }, []);

  const onCreateEditModalClose = useCallback((shouldUpdateGrid?: boolean) => {
    if (shouldUpdateGrid) queryClient.refetchQueries(["grid", GET_ATTORNEYS]);

    setSelectedAttorney(undefined);
    setIsCreateEditModalOpen(false);
  }, []);

  const onDeleteModalClose = useCallback((shouldUpdateGrid?: boolean) => {
    if (shouldUpdateGrid) queryClient.refetchQueries(["grid", GET_ATTORNEYS]);

    setSelectedAttorney(undefined);
    setIsDeleteModalOpen(false);
  }, []);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "firstName",
        headerName: "First Name",
        width: 450,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "lastName",
        headerName: "Last Name",
        width: 450,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "barNumber",
        headerName: "Attorney Number",
        width: 350,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span
              id={params.row.attorneyID}
              title={`Attorney Number ${params.row.barNumber}`}
            >
              {params.row.barNumber}
            </span>
          );
        },
      },
      {
        field: "action",
        headerName: "Action",
        width: 130,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <div className="dataGridactionIcons">
              <Tooltip title="Edit Attorney">
                <IconButton
                  aria-label="Edit Attorney"
                  onClick={() => onEdit(params.row)}
                  aria-describedby={params.row.attorneyID}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete Attorney">
                <IconButton
                  aria-label="Delete Attorney"
                  onClick={() => onDelete(params.row)}
                  aria-describedby={params.row.attorneyID}
                >
                  <span className="material-symbols-outlined datagrid-cancel">
                    delete
                  </span>
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [onEdit, onDelete]
  );

  const getRowId = useCallback((row: any) => row.attorneyID, []);

  return (
    <Page>
      <div className="content-wrapper">
        <h2 className="dash-title-h2 mb-4">Manage Attorneys</h2>

        <div className="themeDataTable">
          <GridTable
            url={GET_ATTORNEYS}
            columns={columns}
            searchKeys={["firstName", "lastName", "barNumber"]}
            showSearch
            rows={[]}
            hideFooter={false}
            getRowId={getRowId}
            action={
              <Button
                size="large"
                variant="contained"
                className="btn primary-button"
                onClick={onCreate}
              >
                Add Attorney
              </Button>
            }
          />
        </div>
      </div>

      {isCreateEditModalOpen && (
        <CreateEditAttorney
          key={selectedAttorney?.attorneyID}
          attorney={selectedAttorney}
          isOpen={isCreateEditModalOpen}
          onClose={onCreateEditModalClose}
        />
      )}

      <DeleteAttorney
        attorney={selectedAttorney}
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
      />
    </Page>
  );
};

export default Attorneys;
