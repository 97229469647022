import { ChangeEvent, FC, useCallback, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Control, Controller } from "react-hook-form";

interface IAdDamnum {
  amountInControversy?: number;
  updateAmountInControversy: (amount: number | undefined) => void;
  control: Control<any, any>;
}

const AdDamnum: FC<IAdDamnum> = ({
  amountInControversy,
  updateAmountInControversy,
  control,
}) => {
  const [amount, setAmount] = useState(amountInControversy);

  const updateAmount = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setAmount(parseInt(event.target.value));
  }, []);

  return (
    <Box
      mt={4}
      width="50%"
      marginLeft={2}
      border={1}
      borderColor="lightgray"
      position="relative"
    >
      <Accordion className="remove-item-css" defaultExpanded>
        <AccordionSummary
          className="accordion-info-title light-grey-bg"
          expandIcon={<ExpandMoreIcon className="theme-blue accordion-arrow" />}
          sx={{
            ".MuiAccordionSummary-content": {
              display: "block",
            },
          }}
        >
          <h5 className="theme-blue f-w-bold">Ad Damnum</h5>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" alignItems="end" pt={4}>
            <Box width="70%">
            <Controller
                name="amountInControversy"
                control={control}
                rules={{
                  required:
                    "Error: Damnum is required.",
                }}
                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    className="w-100"
                    label="Ad Damnum amount"
                    variant="standard"
                    type="number"
                    value={amount}
                    onChange={updateAmount}
                    onBlur={() => updateAmountInControversy(amount)}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Box>

            <Box pl={8}>
              <Button
                className="btn primary-button"
                variant="contained"
                type="button"
                onClick={() => amount && updateAmountInControversy(amount)}
              >
                Verify&nbsp;Fees
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AdDamnum;
