import { FC } from "react";
import Button from "@mui/material/Button";

import Dialog from "../../../../components/dialog";
import { DUPAGE_LOCATION_CODE } from "../../../../constants";

interface ICrossReferenceNumberDialog {
  isOpen: boolean;
  locationCode: string;
  locationName: string;
  requiredCrossReferenceCodeName: string;
  onClose: () => void;
}

const CrossReferenceNumberDialog: FC<ICrossReferenceNumberDialog> = ({
  isOpen,
  locationCode,
  locationName,
  requiredCrossReferenceCodeName,
  onClose,
}) => {
  return (
    <Dialog
      title={
        locationCode.toLowerCase().startsWith("cook")
          ? "Attention Cook County Filers!"
          : `Attention ${locationName} Filers!`
      }
      maxWidth="md"
      isOpen={isOpen}
      onClose={onClose}
      action={
        <Button className="btn primary-button" type="submit" onClick={onClose}>
          Ok
        </Button>
      }
    >
      {locationCode.toLowerCase().includes(DUPAGE_LOCATION_CODE) ||
      requiredCrossReferenceCodeName ? (
        <>
          <label>
            If you are an attorney, add "
            {requiredCrossReferenceCodeName || "DuPage Attorney/Firm Number"}"
            in the case cross reference number field and Click on 'Add';
            <br />
            <br />
            If you are not an attorney, use '99500'.
          </label>
        </>
      ) : (
        <>
          <label>
            If you are an attorney, Insert Cook County Attorney Code Number
            (5-digit number) in the case cross reference number field and Click
            on 'Add'; For Self-Represented Litigants, enter '99500'.
          </label>
          <br />
          <br />
          <label>
            Additionally, if you are filing a motion, use the case cross
            reference field to add the word 'motion' and choose a motion type
            from the drop down and click on 'Add'.
          </label>
        </>
      )}
    </Dialog>
  );
};

export default CrossReferenceNumberDialog;
