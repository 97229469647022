import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { UPDATE_SERVICE_CONTACT } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface IUpdateServiceContact {
  serviceContactID: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  administrativeCopy: string;
  addressType: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  };
  firmID: string;
  isSelected: boolean;
}

const updateServiceContact = async (fields: IUpdateServiceContact) => {
  await http.post(UPDATE_SERVICE_CONTACT, {
    ...fields,
    inFirmMasterList: true,
  });
};

const useUpdateServiceContact = () =>
  useMutation<void, IAxisErrorResponse, IUpdateServiceContact>({
    mutationKey: ["update-service-contact"],
    mutationFn: updateServiceContact,
  });

export default useUpdateServiceContact;
