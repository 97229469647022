import { useMutation } from "@tanstack/react-query";

import http from "../../../../http";
import { UPDATE_FIRM_USER } from "../../../URL";
import { IAxisErrorResponse } from "../../../../interfaces";

interface IUpdateFirmUserBody {
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  userID: string;
  firmID: string;
  attorneyNumber?: string;
  firmAdminCheckbox: boolean;
  filerCheckbox: boolean;
  attorneyCheckbox: boolean;
  role: {
    location: string;
    roleName: string;
  }[];
}

const updateFirmUser = async (fields: IUpdateFirmUserBody) => {
  await http.post(UPDATE_FIRM_USER, fields);
};

const useUpdateFirmUser = () =>
  useMutation<void, IAxisErrorResponse, IUpdateFirmUserBody>({
    mutationKey: ["update-firm-user"],
    mutationFn: updateFirmUser,
  });

export default useUpdateFirmUser;
