import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { CREATE_PAYMENT_ACCOUNT } from "../../URL";

interface ICreatePaymentAccount {
  accountName: string;
  paymentAccountTypeCode: string;
  paymentAccountTypeCodeId?: string;
}

interface IAccountResponse {
  secretTokenKey: string;
  uniqueId: string;
}

interface IAxisErrorResponse {}

const createPaymentAccount = async (fields: ICreatePaymentAccount) => {
  const response = await http.post<IAccountResponse>(CREATE_PAYMENT_ACCOUNT, {
    ...fields,
  });

  return response.data;
};

const useCreatePaymentAccount = () =>
  useMutation<IAccountResponse, IAxisErrorResponse, ICreatePaymentAccount>({
    mutationKey: ["create-payment-account"],
    mutationFn: createPaymentAccount,
  });

export default useCreatePaymentAccount;
