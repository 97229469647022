import { FC } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

import { ICaseResponse, IServiceResponse } from "../../../../interfaces";

interface IServiceContact {
  caseInfo: ICaseResponse;
  serviceContacts: IServiceResponse[];
  selectedServiceContacts: string[];
  changeTab: (tabIndex: number) => void;
}

const ServiceContacts: FC<IServiceContact> = ({
  caseInfo,
  serviceContacts,
  selectedServiceContacts,
  changeTab,
}) => {
  return (
    <div className="accordion-col">
      <Accordion className="remove-item-css">
        <AccordionSummary
          className="accordion-info-title light-grey-bg"
          expandIcon={<ExpandMoreIcon className="theme-blue accordion-arrow" />}
        >
          <h5 className="theme-blue f-w-bold">Service Contacts</h5>
          <span
            className="accordions-edit theme-blue f-w-bold"
            onClick={() => changeTab(3)}
          >
            <EditIcon className="vertical-align-middle" /> <span>Edit</span>
          </span>
        </AccordionSummary>
        <AccordionDetails>
          {caseInfo?.partyResponses?.map((partyResponse) => (
            <TreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              <TreeItem
                nodeId={partyResponse.efmPartyId}
                label={
                  <>
                    <Checkbox
                      checked={
                        !serviceContacts
                          .filter(
                            (node) =>
                              node.efmPartyId === partyResponse.efmPartyId
                          )
                          ?.some(
                            (childNode) =>
                              !selectedServiceContacts.includes(
                                childNode.serviceContactId
                              )
                          )
                      }
                      disableRipple
                    />

                    {`${partyResponse.efmPartytypeRoleName} ${
                      partyResponse.partyFirstName || ""
                    } ${partyResponse.partyMiddleName || ""} ${
                      partyResponse.partyLastName || ""
                    }`}
                  </>
                }
              >
                {serviceContacts
                  .filter(
                    (node) => node.efmPartyId === partyResponse.efmPartyId
                  )
                  ?.map((childNode) => (
                    <>
                      <div className="treetable-custom-list">
                        <span>
                          <TreeItem
                            key={childNode.serviceContactId}
                            nodeId={childNode.email}
                            label={
                              <>
                                <Checkbox
                                  checked={selectedServiceContacts.includes(
                                    childNode.serviceContactId
                                  )}
                                  disableRipple
                                />

                                {`${childNode.firstName} ${childNode.surName} (${childNode.email})`}
                              </>
                            }
                          />
                        </span>
                      </div>
                    </>
                  ))}
              </TreeItem>
            </TreeView>
          ))}

          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            <TreeItem
              nodeId="0"
              label={
                <>
                  <Checkbox
                    checked={
                      !serviceContacts
                        .filter((node) => node.efmPartyId === "0")
                        ?.some(
                          (childNode) =>
                            !selectedServiceContacts.includes(
                              childNode.serviceContactId
                            )
                        )
                    }
                    disableRipple
                  />
                  Other Service Contacts
                </>
              }
            >
              {serviceContacts
                .filter((node) => node.efmPartyId === "0")
                ?.map((childNode) => (
                  <>
                    <div className="treetable-custom-list">
                      <span>
                        <TreeItem
                          key={childNode.serviceContactId}
                          nodeId={childNode.email}
                          label={
                            <>
                              <Checkbox
                                checked={selectedServiceContacts.includes(
                                  childNode.serviceContactId
                                )}
                                disableRipple
                              />

                              {`${childNode.firstName} ${childNode.surName} (${childNode.email})`}
                            </>
                          }
                        />
                      </span>
                    </div>
                  </>
                ))}
            </TreeItem>
          </TreeView>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ServiceContacts;
