import { FC } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { ISearchCaseParty } from "../../../interfaces";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface PartyProps {
  party: ISearchCaseParty;
  partyIndex: number;
}

const SearchCaseParty: FC<PartyProps> = ({ party, partyIndex }) => {
  return (
    <div className="popup-content-slide">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} className="mb-2">
          <Grid item xs={12} sm={12} className="mb-2">
            <Item className="remove-item-css">
              <div className="h5 border-bottom-grey pb-1">
                Party {partyIndex + 1}
              </div>
            </Item>
          </Grid>
          {party.partyType === "person" && (
            <>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name"> First Name </div>
                  <div className="label-print-field">
                    {" "}
                    {party.partyFirstName}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name"> Middle Name </div>
                  <div className="label-print-field">
                    {" "}
                    {party.partyMiddleName}{" "}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name"> Last Name </div>
                  <div className="label-print-field">
                    {" "}
                    {party.partyLastName}{" "}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name"> Address </div>
                  <div className="label-print-field"> {party.country}</div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name"> Role Code </div>
                  <div className="label-print-field">
                    {party.efmPartytypeName}
                  </div>
                </Item>
              </Grid>
            </>
          )}
          {party.partyType === "business" && (
            <>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name">Business Name</div>
                  <div className="label-print-field">{party.businessName}</div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name"> Role Code </div>
                  <div className="label-print-field">
                    {party.efmPartytypeRoleName}
                  </div>
                </Item>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default SearchCaseParty;
