import { FC, useCallback, useContext, useEffect } from "react";

import DeleteDialog from "../../../components/DeleteDialog";
import useDeleteAttorney from "../../../apis/attorneys/useDeleteAttorney";
import { NotificationContext } from "../../../context/NotificationContext";
import { IAttorney } from "../../../interfaces";

interface IDeleteAttorney {
  isOpen: boolean;
  attorney?: IAttorney;
  onClose: (shouldUpdateGrid?: boolean) => void;
}

const DeleteAttorney: FC<IDeleteAttorney> = ({ isOpen, attorney, onClose }) => {
  const deleteAttorney = useDeleteAttorney();
  const { createNotification } = useContext(NotificationContext);

  const onDelete = useCallback(() => {
    if (attorney?.attorneyID) {
      deleteAttorney.mutate(attorney.attorneyID);
    } else {
      onClose();
    }
  }, [attorney?.attorneyID, deleteAttorney, onClose]);

  useEffect(() => {
    if (deleteAttorney.isSuccess) {
      createNotification({
        title: "Attorney Notification",
        type: "success",
        message: "Selected Attorney removed",
      });
      onClose(true);
    }
    // eslint-disable-next-line
  }, [deleteAttorney.isSuccess, createNotification]);

  useEffect(() => {
    if (deleteAttorney.isError) {
      createNotification({
        title: "Attorney Notification",
        type: "error",
        message:
          deleteAttorney.error.response?.data?.userMessage ||
          "Something went wrong while Removing attorney",
      });
    }
    // eslint-disable-next-line
  }, [deleteAttorney.isError, createNotification]);

  return (
    <DeleteDialog
      entity="Attorney"
      isOpen={isOpen}
      isLoading={deleteAttorney.isLoading}
      onClose={onClose.bind(this, false)}
      onDelete={onDelete}
    />
  );
};

export default DeleteAttorney;
