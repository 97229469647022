import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { IAxisErrorResponse } from "../../../interfaces";

const downloadExcel = async (paymentfileid: string) => {
  const response = await http.post<string>(
    `${process.env.REACT_APP_MISCELLANEOUS_SERVICE_API_URL}/api/miscellaneous/downloadExcel`,
    {},
    {
      params: {
        paymentfileid,
      },
      responseType: "blob",
    }
  );

  return response?.data;
};

const useDownloadExcel = () =>
  useMutation<string | undefined, IAxisErrorResponse, string>({
    mutationKey: ["downloadExcel"],
    mutationFn: downloadExcel,
  });

export default useDownloadExcel;
