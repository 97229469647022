import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { Controller, useForm } from "react-hook-form";
import { FC, useCallback, useContext, useEffect } from "react";

import Loader from "../../../../components/loader";
import { IServiceContact } from "../../../../interfaces";
import useStates from "../../../../apis/common/useStates";
import useCountries from "../../../../apis/common/useCountries";
import { getZipCodePlaceholder, validateZipCode } from "../../../../utills";
import { NotificationContext } from "../../../../context/NotificationContext";
import useServiceContact from "../../../../apis/serviceContacts/useServiceContact";
import useUpdateServiceContact from "../../../../apis/serviceContacts/useUpdateServiceContact";

interface ManageServiceProps {
  isServiceContactFormOpen: boolean;
  onClose: (shouldUpdate?: boolean) => void;
  selectedServiceContactId?: string;
  selectedServiceFirmId?: string;
}

const ServiceContactDetailUpdateForm: FC<ManageServiceProps> = ({
  isServiceContactFormOpen,
  onClose,
  selectedServiceContactId,
  selectedServiceFirmId,
}) => {
  const serviceContact = useServiceContact(selectedServiceContactId);

  const updatedServiceContact = useUpdateServiceContact();

  const { watch, control, handleSubmit, setValue } = useForm<IServiceContact>({
    defaultValues: {
      firstName: serviceContact?.data?.firstName || "",
      middleName: serviceContact?.data?.middleName || "",
      lastName: serviceContact?.data?.lastName || "",
      email: serviceContact?.data?.email || "",
      phoneNumber: serviceContact?.data?.phoneNumber || "",
      administrativeCopy: serviceContact?.data?.administrativeCopy || "",
      address: {
        addressLine1: serviceContact?.data?.address?.addressLine1 || "",
        addressLine2: serviceContact?.data?.address?.addressLine2 || "",
        country: serviceContact?.data?.address?.country || "US",
        state: serviceContact?.data?.address?.state || "",
        city: serviceContact?.data?.address?.city || "",
        zipCode: serviceContact?.data?.address?.zipCode || "",
      },
    },
  });

  const { data: countries } = useCountries();
  const { data: states } = useStates(watch("address.country"));
  const { createNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (serviceContact.isSuccess) {
      setValue("firstName", serviceContact?.data?.firstName || "");
      setValue("middleName", serviceContact?.data?.middleName || "");
      setValue("lastName", serviceContact?.data?.lastName || "");
      setValue("email", serviceContact?.data?.email || "");
      setValue(
        "administrativeCopy",
        serviceContact?.data?.administrativeCopy || ""
      );
      setValue(
        "address.addressLine1",
        serviceContact?.data?.address?.addressLine1 || ""
      );
      setValue(
        "address.addressLine2",
        serviceContact?.data?.address?.addressLine2 || ""
      );
      setValue("address.country", serviceContact?.data?.address?.country || "");
      setValue("address.city", serviceContact?.data?.address?.city || "");
      setValue("address.state", serviceContact?.data?.address?.state || "");
      setValue("address.zipCode", serviceContact?.data?.address?.zipCode || "");
      setValue("phoneNumber", serviceContact?.data?.phoneNumber || "");
    }
    // eslint-disable-next-line
  }, [serviceContact.isSuccess]);

  const onSubmitServiceContact = useCallback(
    (formData: IServiceContact) => {
      updatedServiceContact.mutate({
        serviceContactID: selectedServiceContactId || "",
        firmID: selectedServiceFirmId || "",
        firstName: formData.firstName || "",
        middleName: formData.middleName || "",
        lastName: formData.lastName || "",
        email: formData.email || "",
        administrativeCopy: formData.administrativeCopy || "",
        phoneNumber: formData.phoneNumber || "",
        isSelected: formData.isSelected,
        addressType: {
          addressLine1: formData.address.addressLine1,
          addressLine2: formData.address.addressLine2,
          city: formData.address.city,
          state: formData.address.state,
          zipCode: formData.address.zipCode,
          country: formData.address.country,
        },
      });
    },
    [selectedServiceContactId, selectedServiceFirmId, updatedServiceContact]
  );

  useEffect(() => {
    if (updatedServiceContact.isSuccess) {
      createNotification({
        title: "Service Contact Notification",
        type: "success",
        message: "Service Contact updated successfully",
      });
      onClose(true);
    }
    // eslint-disable-next-line
  }, [updatedServiceContact.isSuccess, createNotification]);

  useEffect(() => {
    if (updatedServiceContact.isError) {
      createNotification({
        title: "Service Contact Notification",
        type: "error",
        message: "Something went wrong while updating service contact",
      });
    }
  }, [updatedServiceContact.isError, createNotification]);

  return (
    <div>
      {(serviceContact.isLoading || serviceContact.isFetching) && <Loader />}
      <form onSubmit={handleSubmit(onSubmitServiceContact)}>
        {isServiceContactFormOpen && (
          <Box
            sx={{
              border: 1,
              borderColor: "lightgray",
            }}
            padding={2}
            borderRadius={2}
            marginBottom={2}
          >
            <Grid container spacing={2}>
              <>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: "Error: First Name is required.",
                    }}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        className="w-100 m-0"
                        id={field.name}
                        inputRef={ref}
                        autoFocus
                        inputProps={{
                          "aria-required": true,
                        }}
                        InputLabelProps={{
                          required: true,
                        }}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder="First Name"
                        label="First Name"
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="middleName"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        className="w-100 m-0"
                        id={field.name}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder="Middle Name"
                        label="Middle Name"
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: "Error: Last Name is required.",
                    }}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        className="w-100 m-0"
                        id={field.name}
                        inputRef={ref}
                        inputProps={{
                          "aria-required": true,
                        }}
                        InputLabelProps={{
                          required: true,
                        }}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder="Last Name"
                        label="Last Name"
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Error: Email Address is required.",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Error: Invalid email.",
                      },
                    }}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        className="w-100 m-0"
                        id={field.name}
                        inputRef={ref}
                        inputProps={{
                          "aria-required": true,
                        }}
                        InputLabelProps={{ required: true }}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder="Email Address"
                        label="Email Address"
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="administrativeCopy"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="w-100 m-0"
                        id={field.name}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder="Administrative Copy"
                        label="Administrative Copy"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="address.addressLine1"
                    control={control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        className="w-100 m-0"
                        id={field.name}
                        inputRef={ref}
                        inputProps={{
                          "aria-required": true,
                        }}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder="Address Line 1"
                        label="Address Line 1"
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="address.addressLine2"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="w-100 m-0"
                        id={field.name}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder="Address Line 2"
                        label="Address Line 2"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="address.country"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        inputRef={ref}
                        inputProps={{
                          "aria-required": true,
                          id: `${field.name}_id`,
                        }}
                        select
                        InputLabelProps={{
                          shrink: true,
                          htmlFor: `${field.name}_id`,
                        }}
                        SelectProps={{
                          displayEmpty: true,
                          SelectDisplayProps: {
                            "aria-required": true,
                          },
                        }}
                        {...field}
                        label="Country"
                        variant="standard"
                      >
                        <MenuItem value="">Select Country</MenuItem>
                        {countries?.map((country) => (
                          <MenuItem
                            key={country.efmCountryCode}
                            value={country.efmCountryCode}
                          >
                            {country.efmCountryName}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="address.city"
                    control={control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        className="w-100 m-0"
                        id={field.name}
                        inputRef={ref}
                        inputProps={{
                          "aria-required": true,
                        }}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder="City"
                        label="City"
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="address.state"
                    control={control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        inputRef={ref}
                        inputProps={{
                          "aria-required": true,
                          id: `${field.name}_id`,
                        }}
                        select
                        InputLabelProps={{
                          shrink: true,
                          htmlFor: `${field.name}_id`,
                        }}
                        SelectProps={{
                          displayEmpty: true,
                          SelectDisplayProps: {
                            "aria-required": true,
                          },
                        }}
                        {...field}
                        label="State"
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      >
                        <MenuItem value="">Select State</MenuItem>
                        {states?.map((state) => (
                          <MenuItem
                            key={state.efmStateCode}
                            value={state.efmStateCode}
                          >
                            {state.efmStateName}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="address.zipCode"
                    control={control}
                    rules={{
                      validate: validateZipCode.bind(this, "address.country"),
                    }}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        {...field}
                        inputRef={ref}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder={getZipCodePlaceholder(
                          watch("address.country")
                        )}
                        label="Zip Code"
                        variant="standard"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="w-100 m-0"
                        id={field.name}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder="999-999-9999"
                        label="Phone Number"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="isSelected"
                    control={control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <InputLabel>
                        <Checkbox
                          {...field}
                          inputRef={ref}
                          inputProps={{
                            "aria-required": true,
                          }}
                          sx={{
                            color: fieldState.error ? "#d32f2f" : undefined,
                            "& .MuiSvgIcon-root": { fontSize: 30 },
                          }}
                          className="ps-0"
                        />
                        <span className="checkbox-align">
                          Save contact in My Firm Master Service List{" "}
                        </span>
                      </InputLabel>
                    )}
                  />
                </Grid>
              </>
            </Grid>
            <div className="text-right mt-3">
              {isServiceContactFormOpen && (
                <Button
                  className="btn primary-button"
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              )}
            </div>
          </Box>
        )}
      </form>
    </div>
  );
};

export default ServiceContactDetailUpdateForm;
