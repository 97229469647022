import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { GET_VIEW_PRINT_PDF } from "../../URL";

interface IPdfDetails {
  efmUserId: string;
  efmLocationId: string;
  efmEnvelopeId: string;
  filingId: string;
  efmFilingType: string;
}

interface IAxisErrorResponse {
  response?: {
    data?: {
      userMessage?: string;
    };
  };
}

const getViewPDF = async (filters: IPdfDetails) => {
  const response = await http.post(GET_VIEW_PRINT_PDF, filters, {
    responseType: "blob",
  });
  const pdfBlob = new Blob([response.data], { type: "application/pdf" });
  const pdfUrl = URL.createObjectURL(pdfBlob);

  const link = document.createElement("a");
  link.href = pdfUrl;
  link.download = filters.efmEnvelopeId + `.pdf`;
  link.click();

  URL.revokeObjectURL(pdfUrl);
};

const usePDFDownloadDetails = () =>
  useMutation<void, IAxisErrorResponse, IPdfDetails>({
    mutationKey: ["getpdfViewDowbload"],
    mutationFn: getViewPDF,
  });

export default usePDFDownloadDetails;
