import { FC, useCallback, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Items from "./Items";

type ItemType = {
  id: string;
  label: string;
  isSelected: boolean;
  subTitle?: string;
};

interface TransferListProps {
  leftTitle: string;
  options: ItemType[];
  disableTransfer: boolean;
  rightTitle?: string;
  showSearch?: boolean;
  searchToolTipTitle?: string;
  onSelect: (ids: string[]) => void;
  onUnselect: (ids: string[]) => void;
  onSearchClick?: (id: string) => void;
}

const TransferList: FC<TransferListProps> = ({
  leftTitle,
  options,
  disableTransfer,
  rightTitle,
  showSearch,
  searchToolTipTitle,
  onSelect,
  onUnselect,
  onSearchClick,
}) => {
  const [selectedLeftItem, setSelectedLeftItem] = useState<string[]>([]);
  const [selectedRightItem, setSelectedRightItem] = useState<string[]>([]);

  const left = useMemo(() => {
    return options.filter((option) => !option.isSelected);
  }, [options]);

  const right = useMemo(() => {
    return options.filter((option) => option.isSelected);
  }, [options]);

  const toggleLeftItem = useCallback((id: string) => {
    setSelectedLeftItem((items) =>
      items.indexOf(id) === -1
        ? [...items, id]
        : items.filter((item) => item !== id)
    );
  }, []);

  const toggleRightItem = useCallback((id: string) => {
    setSelectedRightItem((items) =>
      items.indexOf(id) === -1
        ? [...items, id]
        : items.filter((item) => item !== id)
    );
  }, []);

  const tranferToRight = useCallback(() => {
    if (!selectedLeftItem.length) return;

    const selectedItems = left.filter(
      (item) => selectedLeftItem.indexOf(item.id) !== -1
    );
    if (!selectedItems.length) return;

    onSelect(selectedItems.map((item) => item.id));
    setSelectedLeftItem([]);

    // setRight((rightItems) => [...rightItems, ...selectedItems]);
    // setLeft((leftItems) =>
    //   leftItems.filter((item) => selectedLeftItem.indexOf(item.id) === -1)
    // );
  }, [selectedLeftItem, left, onSelect]);

  const tranferToLeft = useCallback(() => {
    if (!selectedRightItem.length) return;

    const selectedItems = right.filter(
      (item) => selectedRightItem.indexOf(item.id) !== -1
    );
    if (!selectedItems.length) return;

    onUnselect(selectedItems.map((item) => item.id));
    setSelectedRightItem([]);

    // setLeft((leftItems) => [...leftItems, ...selectedItems]);
    // setRight((rightItems) =>
    //   rightItems.filter((item) => selectedRightItem.indexOf(item.id) === -1)
    // );
  }, [selectedRightItem, right, onUnselect]);

  return (
    <Grid
      container
      justifyContent={{ xl: "end", sm: "center" }}
      alignItems="center"
    >
      <Grid item xs={12} sm={5}>
        <Items
          title={leftTitle}
          items={left}
          selectedItems={selectedLeftItem}
          showSearch={showSearch}
          searchToolTipTitle={searchToolTipTitle}
          toggleItem={toggleLeftItem}
          onSearchClick={onSearchClick}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="contained"
            size="small"
            onClick={tranferToRight}
            disabled={selectedLeftItem.length === 0 || disableTransfer}
            aria-label="Transfer to selected services"
            className="pick-drop-button left-button"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="contained"
            size="small"
            onClick={tranferToLeft}
            disabled={selectedRightItem.length === 0 || disableTransfer}
            aria-label="Transfer back to optional services"
            className="pick-drop-button right-button"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Items
          title={rightTitle || `Selected ${leftTitle}`}
          items={right}
          selectedItems={selectedRightItem}
          showSearch={showSearch}
          searchToolTipTitle={searchToolTipTitle}
          toggleItem={toggleRightItem}
          onSearchClick={onSearchClick}
        />
      </Grid>
    </Grid>
  );
};

export default TransferList;
