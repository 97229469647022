import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { UPDATE_PAYMENT_ACCOUNT } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface IEditPaymentAccount {
  accountName: string;
  accountToken: string;
  cardType?: {
    value?: string;
  };
  cardLast4?: string;
  cardMonth?: {
    value?: number;
  };
  cardYear?: {
    value?: number;
  };
  cardHolderName?: {
    value?: string;
  };
  paymentAccountID: string;
  paymentAccountTypeCode: string;
  paymentAccountTypeCodeId?: string;
  active: {
    value: boolean;
  };
}

const updatePaymentAccount = async (fields: IEditPaymentAccount) => {
  await http.post(UPDATE_PAYMENT_ACCOUNT, {
    ...fields,
  });
};

const useUpdatePaymentAccount = () =>
  useMutation<void, IAxisErrorResponse, IEditPaymentAccount>({
    mutationKey: ["update-payment-account"],
    mutationFn: updatePaymentAccount,
  });

export default useUpdatePaymentAccount;
