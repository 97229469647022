import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_USER_NOTIFICATIONS } from "../../URL";
import { IAxisErrorResponse, INotification } from "../../../interfaces";

const getNotifications = async () => {
  const response = await http.get<INotification[]>(GET_USER_NOTIFICATIONS);
  return response.data;
};

const useNotifications = () =>
  useQuery<INotification[], IAxisErrorResponse>({
    queryKey: ["notification"],
    queryFn: getNotifications,
  });

export default useNotifications;
