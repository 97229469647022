import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { ATTACHED_SERVICE_CONTACT } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface IAttachedServiceContact {
  caseTrackingId: string;

  listOfServiceContact: IAttachedServiceContactList[];
}

interface IAttachedServiceContactList {
  serviceContactID: string;
  partyId: string;
}

const attachedServiceContact = async (filters: IAttachedServiceContact) => {
  const response = await http.post(ATTACHED_SERVICE_CONTACT, filters);
  return response.data;
};

const useAttachedServicecontact = () =>
  useMutation<void, IAxisErrorResponse, IAttachedServiceContact>({
    mutationKey: ["attachedservicecontact"],
    mutationFn: attachedServiceContact,
  });

export default useAttachedServicecontact;
