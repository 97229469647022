import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { getIsAdmin } from "../../../utills";

interface IProfileResponse {
  userID: string;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  roles: {
    location: string;
    roleName: string;
  }[];
  firmID: string;
}

interface IAdminResponse {
  adminprofileid: string;
  firstname: string;
  middlename: string;
  lastname: string;
  username: string;
  userrole: string;
  firmID: string;
}

const getProfile = async () => {
  let profile: IProfileResponse;
  const isAdmin = getIsAdmin();

  if (isAdmin === "admin") {
    const response = await http.post<IAdminResponse>(
      process.env.REACT_APP_USER_SERVICE_API_URL +
        "/api/user/api/getAdminProfile"
    );
    const admin = response.data;
    profile = {
      email: admin.username,
      firstName: admin.firstname,
      lastName: admin.lastname,
      middleName: admin.middlename,
      roles: [{ location: "", roleName: admin.userrole }],
      userID: admin.adminprofileid,
      firmID: admin.firmID,
    };
  } else {
    const response = await http.get<{
      user: IProfileResponse;
    }>(process.env.REACT_APP_USER_SERVICE_API_URL + "/api/user/getUserProfile");
    profile = response.data.user;
  }

  return profile;
};

const useLogin = () =>
  useQuery<IProfileResponse | undefined>({
    queryKey: ["profile"],
    queryFn: getProfile,
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default useLogin;
