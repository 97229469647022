import { FC } from "react";
import { Navigate } from "react-router-dom";

import Loader from "../components/loader";
import useProfile from "../apis/auth/useProfile";

interface IAuthenticatedRouteProps {
  element: () => JSX.Element;
}

const AuthenticatedRoute: FC<IAuthenticatedRouteProps> = ({
  element: Component,
}) => {
  const profile = useProfile();

  if (profile.isLoading) {
    return <Loader />;
  }

  if (!profile.data) {
    return <Navigate to="/" />;
  }

  return <Component />;
};

export default AuthenticatedRoute;
