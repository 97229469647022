import { useMutation } from "@tanstack/react-query";

import http from "../../http";
import { ADMIN_PANEL_SEARCH_BY_EMAIL_PENDING_REQUESTS } from "../URL";

interface IPendingRequest {
  notifyeventid?: number;
  email?: string;
  userid?: string;
  eventtypecode?: string;
  url?: string;
  event_date: string;
}

interface IAxisErrorResponse {
  response?: {
    data?: {
      userMessage?: string;
    };
  };
}

const getPendingRequestByEmail = async (email: string) => {
  const response = await http.post<IPendingRequest[]>(
    ADMIN_PANEL_SEARCH_BY_EMAIL_PENDING_REQUESTS,
    {
      email,
    }
  );
  return response.data;
};

const usePendingRequestsByEmail = () =>
  useMutation<IPendingRequest[], IAxisErrorResponse, string>({
    mutationKey: ["pendingRequestsByEmail"],
    mutationFn: getPendingRequestByEmail,
  });

//   const usePendingRequest = () =>
//   useQuery<IPendingRequest[]>({
//     queryKey: ["pendingRequests"],
//     queryFn: getPendingRequest,
//     networkMode: "offlineFirst",
//     staleTime: Infinity,
//   });

export default usePendingRequestsByEmail;
