import { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import {
  Controller,
  Control,
  UseFormWatch,
  UseFormSetValue,
  UseFieldArrayRemove,
} from "react-hook-form";
import { FieldArrayWithId } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Accordion from "@mui/material/Accordion";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import AdditionalAttorneys from "../additionalAttorneys";
import ConfirmationDialog from "../../../../components/confirmationDialog";
import useAttorneys from "../../../../apis/firm/useAttorneys";
import useLocationNameSuffixes from "../../../../apis/common/useLocationNameSuffixes";
import useCountries from "../../../../apis/common/useCountries";
import useStates from "../../../../apis/common/useStates";
import useProfile from "../../../../apis/auth/useProfile";
import useFirmInformation from "../../../../apis/firm/useFirmInformation";
import { IParty } from "../../../../interfaces";
import { isIndividualUser } from "../../../../utills";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface PartyProps {
  control: Control<
    {
      parties: IParty[];
    },
    any
  >;
  field: FieldArrayWithId<
    {
      parties: IParty[];
    },
    "parties",
    "id"
  >;
  watch: UseFormWatch<{
    parties: IParty[];
  }>;
  setValue: UseFormSetValue<{
    parties: IParty[];
  }>;
  remove: UseFieldArrayRemove;
  index: number;
  locationCode: string;
}

const Party: FC<PartyProps> = ({
  control,
  field,
  index,
  locationCode,
  watch,
  setValue,
  remove,
}) => {
  const [isAdditionalAttorneysModalOpen, setIsAdditionalAttorneysModalOpen] =
    useState(false);
  const [
    isRemoveCurrentPartyDataModalOpen,
    setIsRemoveCurrentPartyDataModalOpen,
  ] = useState(false);
  const { data: attorneys } = useAttorneys();
  const { data: suffixes } = useLocationNameSuffixes(locationCode);
  const { data: countries } = useCountries();
  const { data: states } = useStates(watch(`parties.${index}.countryCode`));
  const { data: profile } = useProfile();
  const { data: firmInformaion } = useFirmInformation();

  const [searchText, setSearchText] = useState("");

  const removeParty = useCallback(() => {
    remove(index);
  }, [index, remove]);

  const isAdditinalParty = useMemo(
    () => watch(`parties.${index}.isAdditinalParty`),
    [index, watch]
  );

  const openAdditionalAttorneysModal = useCallback(() => {
    setIsAdditionalAttorneysModalOpen(true);
  }, []);

  const closeAdditionalAttorneysModal = useCallback(() => {
    setIsAdditionalAttorneysModalOpen(false);
  }, []);

  const closeRemoveCurrentPartyDataModal = useCallback(() => {
    setIsRemoveCurrentPartyDataModalOpen(false);
  }, []);

  const onPartyTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const partyType = event.target.value;

      setValue(`parties.${index}.type`, partyType);
      setValue(`parties.${index}.partyNotAvailable`, false);
      setValue(`parties.${index}.firstName`, "");
      setValue(`parties.${index}.lastName`, "");
      setValue(`parties.${index}.businessName`, "");
    },
    [index, setValue]
  );

  const onPartyNotAvailableChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isPartyNoteAvailable = event.target.checked;
      const partyType = watch(`parties.${index}.type`);

      setValue(`parties.${index}.partyNotAvailable`, isPartyNoteAvailable);

      if (partyType === "person") {
        setValue(
          `parties.${index}.firstName`,
          isPartyNoteAvailable ? "Not available" : ""
        );
        setValue(
          `parties.${index}.lastName`,
          isPartyNoteAvailable ? "Not available" : ""
        );
      } else {
        setValue(
          `parties.${index}.businessName`,
          isPartyNoteAvailable ? "Not available" : ""
        );
      }
    },
    [index, setValue, watch]
  );

  const onIamThisPartyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const iamThisParty = event.target.checked;

      if (iamThisParty) {
        setValue(`parties.${index}.iAmThisParty`, true);
        setValue(`parties.${index}.partyNotAvailable`, false);
        setValue(`parties.${index}.leadAttorney`, "prose");
        setValue(`parties.${index}.additionalAttorneys`, []);
        setValue(`parties.${index}.type`, "person");
        setValue(`parties.${index}.businessName`, "");

        if (profile) {
          setValue(`parties.${index}.firstName`, profile.firstName);
          setValue(`parties.${index}.middleName`, profile.middleName);
          setValue(`parties.${index}.lastName`, profile.lastName);
        }

        if (firmInformaion) {
          setValue(
            `parties.${index}.streetAddressLine1`,
            firmInformaion.address?.addressLine1
          );
          setValue(
            `parties.${index}.streetAddressLine2`,
            firmInformaion.address?.addressLine2
          );
          setValue(`parties.${index}.city`, firmInformaion.address?.city);
          setValue(`parties.${index}.stateCode`, firmInformaion.address?.state);
          setValue(`parties.${index}.zipCode`, firmInformaion.address?.zipCode);
        }
      } else {
        setIsRemoveCurrentPartyDataModalOpen(true);
      }
    },
    [profile, firmInformaion, index, setValue]
  );

  const clearPartyData = useCallback(() => {
    setIsRemoveCurrentPartyDataModalOpen(false);

    setValue(`parties.${index}.iAmThisParty`, false);
    setValue(`parties.${index}.partyNotAvailable`, false);
    setValue(`parties.${index}.leadAttorney`, "");
    setValue(`parties.${index}.firstName`, "");
    setValue(`parties.${index}.middleName`, "");
    setValue(`parties.${index}.lastName`, "");
    setValue(`parties.${index}.suffix`, "");
    setValue(`parties.${index}.streetAddressLine1`, "");
    setValue(`parties.${index}.streetAddressLine2`, "");
    setValue(`parties.${index}.city`, "");
    setValue(`parties.${index}.stateCode`, "");
    setValue(`parties.${index}.zipCode`, "");
    setValue(`parties.${index}.phoneNumber`, "");
    setValue(`parties.${index}.filerId`, "");
  }, [index, setValue]);

  const onAttorneySearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setSearchText(e.target.value);
  }, []);

  const getAttorneyName = useMemo(() => {
    return (selectedIds: string[]) => {
      if (selectedIds[0]) {
        const attorneyNames = selectedIds.map((selectedId) => {
          return `${
            attorneys?.find((e) => e.attorneyID === selectedId)?.firstName
          } ${attorneys?.find((e) => e.attorneyID === selectedId)?.lastName}`;
        });

        return attorneyNames;
      } else {
        return [];
      }
    };
  }, [attorneys]);

  return (
    <div className="case-filer-dtl" key={field.id}>
      <Box display="flex" justifyContent="space-between">
        <div className="case-filer-dtl-top">
          <h4>{field.name}</h4>
        </div>
        {isAdditinalParty && (
          <IconButton onClick={removeParty}>
            <CloseIcon color="error" />
          </IconButton>
        )}
      </Box>

      {isIndividualUser() && (
        <Box display="flex" alignItems="center">
          <Controller
            name={`parties.${index}.iAmThisParty`}
            control={control}
            render={({ field }) => (
              <InputLabel className="label-checkbox">
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={onIamThisPartyChange}
                  size="medium"
                  className="ps-0"
                />
                I am this Party
              </InputLabel>
            )}
          />
        </Box>
      )}

      <Box
        sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            display: "flex",
          },
        })}
        mt={1}
      >
        <Controller
          name={`parties.${index}.type`}
          control={control}
          render={({ field }) => (
            <RadioGroup row {...field} onChange={onPartyTypeChange}>
              <FormControlLabel
                value="person"
                control={<Radio />}
                label="Person"
              />
              <FormControlLabel
                value="business"
                disabled={watch(`parties.${index}.iAmThisParty`)}
                control={<Radio />}
                label="Business"
              />
            </RadioGroup>
          )}
        />

        <Controller
          name={`parties.${index}.partyNotAvailable`}
          control={control}
          render={({ field }) => (
            <InputLabel className="label-checkbox ms-4 checkbox-mob">
              <Checkbox
                {...field}
                disabled={watch(`parties.${index}.iAmThisParty`)}
                checked={field.value}
                onChange={onPartyNotAvailableChange}
                size="medium"
              />
              <strong className="pe-1">Not available </strong>
              {` `}
              (Check this if you don't know Person name or Business name)
            </InputLabel>
          )}
        />
      </Box>
      <Grid container spacing={4} pt={1}>
        {watch(`parties.${index}.type`) === "person" ? (
          <>
            <Grid item xs={3}>
              <Item className="remove-item-css">
                <Controller
                  name={`parties.${index}.firstName`}
                  control={control}
                  rules={{
                    required: "Error: First Name is required.",
                  }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      className="w-100"
                      id={field.name}
                      InputLabelProps={{ required: true }}
                      inputProps={{
                        "aria-required": true,
                      }}
                      {...field}
                      inputRef={ref}
                      onBlur={() => {
                        field.onChange(field.value?.trim());
                        field.onBlur();
                      }}
                      placeholder="First Name"
                      label="First Name"
                      variant="standard"
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                    />
                  )}
                />
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item className="remove-item-css">
                <Controller
                  name={`parties.${index}.middleName`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      className="w-100"
                      id={field.name}
                      {...field}
                      onBlur={() => {
                        field.onChange(field.value?.trim());
                        field.onBlur();
                      }}
                      placeholder="Middle Name"
                      label="Middle Name"
                      variant="standard"
                    />
                  )}
                />
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item className="remove-item-css">
                <Controller
                  name={`parties.${index}.lastName`}
                  control={control}
                  rules={{
                    required: "Error: Last Name is required.",
                  }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      className="w-100"
                      id={field.name}
                      inputRef={ref}
                      InputLabelProps={{ required: true }}
                      {...field}
                      onBlur={() => {
                        field.onChange(field.value?.trim());
                        field.onBlur();
                      }}
                      inputProps={{
                        "aria-required": true,
                      }}
                      placeholder="Last Name"
                      label="Last Name"
                      variant="standard"
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                    />
                  )}
                />
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item className="remove-item-css">
                <Controller
                  name={`parties.${index}.suffix`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      className="w-100"
                      id={field.name}
                      select
                      InputLabelProps={{
                        shrink: true,
                        htmlFor: `${field.name}_id`,
                      }}
                      inputProps={{
                        id: `${field.name}_id`,
                      }}
                      SelectProps={{
                        displayEmpty: true,
                        SelectDisplayProps: {
                          "aria-required": true,
                        },
                      }}
                      {...field}
                      label="Suffix"
                      variant="standard"
                    >
                      <MenuItem value="">Select Name Suffix</MenuItem>
                      {suffixes?.map((suffix) => (
                        <MenuItem
                          key={suffix.efmNamesuffixCode}
                          value={suffix.efmNamesuffixCode}
                        >
                          {suffix.efmNamesuffixName}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Item>
            </Grid>

            {["Available", "Required"].includes(
              watch(`parties.${index}.dateOfDeathCondition`) || ""
            ) && (
              <Grid item xs={12}>
                <Controller
                  name={`parties.${index}.dateOfDeath`}
                  control={control}
                  rules={
                    watch(`parties.${index}.dateOfDeathCondition`) ===
                      "Required" || true
                      ? {
                          required: "Error: Date of Death is required.",
                        }
                      : {}
                  }
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <DatePicker
                      label="Date of Death"
                      className="date-picker"
                      inputRef={ref}
                      {...field}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      maxDate={new Date()}
                      slotProps={{
                        textField: {
                          error: !!fieldState.error,
                          helperText: fieldState.error
                            ? fieldState.error.message
                            : null,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            )}
          </>
        ) : (
          <Grid item xs={12}>
            <Controller
              name={`parties.${index}.businessName`}
              control={control}
              rules={{
                required: "Error: Business Name is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  inputRef={ref}
                  InputLabelProps={{ required: true }}
                  {...field}
                  inputProps={{
                    "aria-required": true,
                  }}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Business Name"
                  label="Business Name"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <Box mt={4} className="mt-4 accordion-row">
        <Accordion className="remove-item-css">
          <AccordionSummary
            className="accordion-info-title light-grey-bg"
            expandIcon={
              <ExpandMoreIcon className="theme-blue accordion-arrow" />
            }
          >
            <h5 className="theme-blue f-w-bold">Additional Information</h5>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4} pt={1}>
              <Grid item xs={4}>
                <Item className="remove-item-css">
                  <Controller
                    name={`parties.${index}.leadAttorney`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        select
                        InputLabelProps={{
                          shrink: true,
                          htmlFor: `${field.name}_id`,
                        }}
                        inputProps={{
                          id: `${field.name}_id`,
                        }}
                        SelectProps={{
                          displayEmpty: true,
                          SelectDisplayProps: {
                            "aria-required": true,
                          },
                        }}
                        {...field}
                        onChange={(
                          event: ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) => {
                          field.onChange(event);
                          setValue(`parties.${index}.additionalAttorneys`, []);
                        }}
                        label="Lead Attorney"
                        variant="standard"
                      >
                        <MenuItem value="">Select Lead Attorney</MenuItem>

                        {!isIndividualUser() &&
                          attorneys?.map((attorney) => (
                            <MenuItem
                              key={attorney.attorneyID}
                              value={attorney.attorneyID}
                            >
                              {`${attorney.firstName} ${attorney.lastName}`}
                            </MenuItem>
                          ))}
                        <MenuItem value="prose">Pro Se</MenuItem>
                      </TextField>
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={4}>
                {watch(`parties.${index}.leadAttorney`) &&
                  !isIndividualUser() && (
                    <Item className="remove-item-css">
                      <Controller
                        name={`parties.${index}.additionalAttorneys`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            className="w-100"
                            id={field.name}
                            select
                            InputLabelProps={{
                              shrink: true,
                              htmlFor: `${field.name}_id`,
                            }}
                            inputProps={{
                              id: `${field.name}_id`,
                            }}
                            SelectProps={{
                              displayEmpty: true,
                              multiple: true,
                              SelectDisplayProps: {
                                "aria-required": true,
                              },
                              renderValue: (selected: any) =>
                                getAttorneyName(selected).join(","),
                            }}
                            {...field}
                            label="Add Additional Attorneys"
                            variant="standard"
                          >
                            <Input
                              id="search-grid"
                              className="material-input w-100"
                              placeholder="Filter By Search"
                              value={searchText}
                              onChange={onAttorneySearch}
                              onKeyDown={(e) => e.stopPropagation()}
                              onKeyUp={(e) => e.stopPropagation()}
                              startAdornment={
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              }
                              inputProps={{
                                "aria-label": "Filter By Search",
                              }}
                            ></Input>

                            <InputLabel className="label-checkbox">
                              <Checkbox
                                checked={
                                  field.value.length ===
                                  (attorneys ? attorneys?.length - 1 : 0)
                                }
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  const selectedIDs = checked
                                    ? attorneys
                                        ?.filter(
                                          (attorney) =>
                                            attorney.attorneyID !==
                                            watch(
                                              `parties.${index}.leadAttorney`
                                            )
                                        )
                                        .map(
                                          (attorney) => attorney.attorneyID
                                        ) || []
                                    : [];
                                  field.onChange(selectedIDs);
                                }}
                              />
                              Select All
                            </InputLabel>

                            {attorneys
                              ?.filter(
                                (attorney) =>
                                  attorney.attorneyID !==
                                  watch(`parties.${index}.leadAttorney`)
                              )
                              ?.filter((attorney) =>
                                `${attorney.firstName} ${attorney.lastName}`
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              )
                              ?.map((attorney) => (
                                <MenuItem
                                  key={attorney.attorneyID}
                                  value={attorney.attorneyID}
                                >
                                  <Checkbox
                                    checked={field.value.includes(
                                      attorney.attorneyID
                                    )}
                                    onChange={(e) => {
                                      const checked = e.target.checked;
                                      const value = field.value || [];
                                      const newValue = checked
                                        ? [...value, attorney.attorneyID]
                                        : value.filter(
                                            (id) => id !== attorney.attorneyID
                                          );

                                      field.onChange(newValue);
                                    }}
                                  />
                                  <span className="add-attorney-list">{`${attorney.firstName} ${attorney.lastName}`}</span>
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      />
                    </Item>
                  )}
              </Grid>
              <Grid item xs={4}>
                {watch(`parties.${index}.leadAttorney`) &&
                  !isIndividualUser() && (
                    <IconButton onClick={openAdditionalAttorneysModal}>
                      <PersonAddAltIcon className="theme-blue" />
                    </IconButton>
                  )}
              </Grid>
              <Grid item xs={4}>
                <Item className="remove-item-css">
                  <Controller
                    name={`parties.${index}.countryCode`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        select
                        InputLabelProps={{
                          shrink: true,
                          htmlFor: `${field.name}_id`,
                        }}
                        inputProps={{
                          id: `${field.name}_id`,
                        }}
                        SelectProps={{
                          displayEmpty: true,
                          SelectDisplayProps: {
                            "aria-required": true,
                          },
                        }}
                        {...field}
                        label="Country"
                        variant="standard"
                      >
                        <MenuItem value="">Select Country</MenuItem>
                        {countries?.map((country) => (
                          <MenuItem
                            key={country.efmCountryCode}
                            value={country.efmCountryCode}
                          >
                            {country.efmCountryName}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={4}>
                <Item className="remove-item-css">
                  <Controller
                    name={`parties.${index}.streetAddressLine1`}
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        {...field}
                        inputRef={ref}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder="Address Line 1"
                        label="Address Line 1"
                        variant="standard"
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={4}>
                <Item className="remove-item-css">
                  <Controller
                    name={`parties.${index}.streetAddressLine2`}
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        {...field}
                        inputRef={ref}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder="Address Line 2"
                        label="Address Line 2"
                        variant="standard"
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={4}>
                <Item className="remove-item-css">
                  <Controller
                    name={`parties.${index}.city`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder="City"
                        label="City"
                        variant="standard"
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={4}>
                <Item className="remove-item-css">
                  <Controller
                    name={`parties.${index}.stateCode`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        select
                        InputLabelProps={{
                          shrink: true,
                          htmlFor: `${field.name}_id`,
                        }}
                        inputProps={{
                          id: `${field.name}_id`,
                        }}
                        SelectProps={{
                          displayEmpty: true,
                          SelectDisplayProps: {
                            "aria-required": true,
                          },
                        }}
                        {...field}
                        label="State"
                        variant="standard"
                      >
                        <MenuItem value="">Select State</MenuItem>
                        {states?.map((state) => (
                          <MenuItem
                            key={state.efmStateCode}
                            value={state.efmStateCode}
                          >
                            {state.efmStateName}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={4}>
                <Item className="remove-item-css">
                  <Controller
                    name={`parties.${index}.zipCode`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder="99999-9999"
                        label="Zip Code"
                        variant="standard"
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="remove-item-css">
                  <Controller
                    name={`parties.${index}.phoneNumber`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder="999-999-9999"
                        label="Phone Number"
                        variant="standard"
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="remove-item-css">
                  <Controller
                    name={`parties.${index}.filerId`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="w-100"
                        id={field.name}
                        {...field}
                        onBlur={() => {
                          field.onChange(field.value?.trim());
                          field.onBlur();
                        }}
                        placeholder="(123) 456-7890 x12345"
                        label="Filer ID"
                        variant="standard"
                      />
                    )}
                  />
                </Item>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>

      <AdditionalAttorneys
        isOpen={isAdditionalAttorneysModalOpen}
        closeModal={closeAdditionalAttorneysModal}
        attorneys={watch(`parties.${index}.additionalAttorneys`)}
      />

      <ConfirmationDialog
        title="Confirmation"
        maxWidth="xs"
        content="Do you want to remove your current party data?"
        isOpen={isRemoveCurrentPartyDataModalOpen}
        onClose={closeRemoveCurrentPartyDataModal}
        onConfirmation={clearPartyData}
      />
    </div>
  );
};

export default Party;
