import { FC, useCallback, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import Dialog from "../../../../components/dialog/Dialog";
import useResetUserPassword from "../../../../apis/auth/useResetUserPassword";
import { NotificationContext } from "../../../../context/NotificationContext";
import { IFirmUser } from "../../../../interfaces";

interface INewPassword {
  isOpen: boolean;
  user: IFirmUser;
  onClose: () => void;
}

const NewPassword: FC<INewPassword> = ({ isOpen, user, onClose }) => {
  const resetUserPassword = useResetUserPassword();
  const { createNotification } = useContext(NotificationContext);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(
      yup
        .object({
          newPassword: yup
            .string()
            .trim()
            .required("Error: New Password is required."),
          confirmNewPassword: yup
            .string()
            .trim()
            .required("Error: New Password is required.")
            .oneOf([yup.ref("newPassword")], "Password does not match!"),
        })
        .required()
    ),
  });

  const onSubmit = useCallback(
    async (data: { newPassword: string }) => {
      resetUserPassword.mutate({
        email: user.email,
        userId: user.userID,
        password: data.newPassword,
      });
    },
    [resetUserPassword, user]
  );

  useEffect(() => {
    if (resetUserPassword.isSuccess) {
      createNotification({
        title: "Change Password Notification",
        type: "success",
        message: "Password changed successfully",
      });

      onClose();
    }
    // eslint-disable-next-line
  }, [resetUserPassword.isSuccess, createNotification]);

  useEffect(() => {
    if (resetUserPassword.isError) {
      createNotification({
        title: "Change Password Notification",
        type: "error",
        message:
          resetUserPassword.error.response?.data?.error?.errorText ||
          "Something went wrong while updating password",
      });
    }
    // eslint-disable-next-line
  }, [resetUserPassword.isError, createNotification]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Enter New Password"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="newPassword"
              control={control}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  InputLabelProps={{
                    required: true,
                  }}
                  inputRef={ref}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  label="New Password"
                  placeholder="************"
                  type="password"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="confirmNewPassword"
              control={control}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  InputLabelProps={{
                    required: true,
                  }}
                  inputRef={ref}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  label="Re-enter New Password"
                  placeholder="************"
                  type="password"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display={"flex"} justifyContent={"end"}>
              <Button
                type="button"
                variant="contained"
                className="btn button-outline-secondary"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                className="btn primary-button ms-2"
                disabled={resetUserPassword.isLoading}
              >
                {resetUserPassword.isLoading ? (
                  <CircularProgress
                    sx={{
                      height: "25px !important",
                      width: "25px !important",
                      "--CircularProgress-size": "8px",
                      "--CircularProgress-trackThickness": "1px",
                      "--CircularProgress-progressThickness": "1px",
                    }}
                    color="inherit"
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default NewPassword;
