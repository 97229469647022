import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { GridRenderCellParams, GridColDef } from "@mui/x-data-grid/models";

import Page from "../../components/page";
import GridTable from "../../components/grid";
import DeleteDraft from "./deleteDraft/DeleteDraft";
import Loader from "../../components/loader";
import useDraftDetails from "../../apis/draft/useDraftDetails";
import useSearchCase from "../../apis/searchCase";
import queryClient from "../../queryClient";
import WarningDialog from "../../components/warningDialog";
import { NotificationContext } from "../../context/NotificationContext";
import { GET_DRAFTS } from "../../apis/URL";
import { IMyDraft } from "../../interfaces";
import { differenceInDays } from "../../utills";

const MyDrafts = () => {
  const navigate = useNavigate();
  const getDraftDetails = useDraftDetails();
  const searchCase = useSearchCase();

  const [draftCount, setDraftCount] = useState<number>();
  const [selectedTransactionId, setSelectedTransactionId] = useState<
    number | null
  >(null);
  const [envelope, setSelectedEnvelopeData] = useState<IMyDraft | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const { createNotification } = useContext(NotificationContext);

  const updateDraftCount = useCallback((count: number) => {
    setDraftCount(count);
  }, []);

  const onDelete = useCallback((transactionId: number) => {
    setSelectedTransactionId(transactionId);
    setIsDeleteModalOpen(true);
  }, []);

  const closeModal = useCallback((shouldUpdateGrid?: boolean) => {
    setSelectedTransactionId(null);
    setIsDeleteModalOpen(false);
    setIsWarningModalOpen(false);

    if (shouldUpdateGrid) queryClient.refetchQueries(["grid", GET_DRAFTS]);
  }, []);

  const onDraftResume = useCallback(
    (mydraft: IMyDraft) => {
      if (differenceInDays(mydraft.draftCreationDate)) {
        setSelectedEnvelopeData(mydraft);
        setIsWarningModalOpen(true);
      } else {
        getDraftDetails.mutate(mydraft.transactionid.toString());
      }
    },
    [getDraftDetails]
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "transactionid",
        headerName: "Draft#",
        width: 100,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span
              id={params.row.transactionid}
              title={`Draft ${params.row.transactionid}`}
            >
              {params.row.transactionid}
            </span>
          );
        },
      },
      {
        field: "caseNumber",
        headerName: "Case No.",
        width: 150,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "filingType",
        headerName: "Filing Type",
        width: 100,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "filingName",
        headerName: "Filing Name",
        width: 400,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "filingDescription",
        headerName: "Filing Description",
        width: 400,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "draftCreationDate",
        headerName: "Creation Date",
        width: 200,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "action",
        headerName: "Action",
        width: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <div className="dataGridactionIcons">
              <Tooltip title="Resume Draft Envelope">
                <IconButton
                  aria-label="Resume Draft Envelope"
                  aria-describedby={params.row.transactionid}
                  onClick={() => onDraftResume(params.row)}
                >
                  <PlayCircleIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Discard">
                <IconButton
                  aria-label="discard"
                  aria-describedby={params.row.transactionid}
                  onClick={() => onDelete(params.row.transactionid)}
                >
                  <span className="material-symbols-outlined datagrid-cancel">
                    delete
                  </span>
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [onDelete, onDraftResume]
  );

  const getRowId = useCallback((row: any) => row.transactionid, []);

  useEffect(() => {
    if (getDraftDetails.isSuccess) {
      if (getDraftDetails.data?.generalCase?.caseNumber) {
        const locationCode =
          getDraftDetails.data?.generalCase?.caseCourt
            ?.organizationIdentification?.identificationID?.efmLocationCode;
        const caseNumber = getDraftDetails.data?.generalCase?.caseNumber;

        if (locationCode && caseNumber) {
          searchCase.mutate({
            location: locationCode,
            caseNumber: caseNumber,
            countyId: locationCode,
            searchBy: "Docket",
            firstName: "",
            middleName: "",
            surname: "",
            businessName: "",
            caseTypeFilter: "",
            sortByFilter: "caseDocketID",
          });
        } else {
          navigate("/existingcase", {
            state: {
              ...getDraftDetails.data,
              draftEnvelopeId: getDraftDetails.variables,
            },
          });
        }
      } else {
        navigate("/newcase", {
          state: {
            ...getDraftDetails.data,
            draftEnvelopeId: getDraftDetails.variables,
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [getDraftDetails.isSuccess]);

  useEffect(() => {
    if (searchCase.isSuccess) {
      if (searchCase.data.tylerMessageCode === "136") {
        navigate("/nonindexedcase", {
          state: {
            ...getDraftDetails.data,
            draftEnvelopeId: getDraftDetails.variables,
          },
        });
      } else {
        navigate("/existingcase", {
          state: {
            ...getDraftDetails.data,
            draftEnvelopeId: getDraftDetails.variables,
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [searchCase.isSuccess]);

  useEffect(() => {
    if (searchCase.isError) {
      createNotification({
        title: "Filing Notification",
        type: "error",
        message:
          searchCase.error.response?.data?.userMessage ||
          "An Error occurred while resuming your draft!",
      });
    }
    // eslint-disable-next-line
  }, [searchCase.isError]);

  useEffect(() => {
    if (getDraftDetails.isError) {
      createNotification({
        title: "Filing Notification",
        type: "error",
        message:
          getDraftDetails.error.response?.data?.userMessage ||
          "An Error occurred while resuming your draft!",
      });
    }
    // eslint-disable-next-line
  }, [getDraftDetails.isError]);

  return (
    <Page>
      <div className="content-wrapper">
        <h2 className="dash-title-h2 mb-4">My Drafts</h2>

        {(getDraftDetails.isLoading || searchCase.isLoading) && <Loader />}

        <div className="datagrid-table-wrap mt-5">
          <div className="datagrid-top mb-3 filing-history-grid-top">
            <div className="f-w-bold f-size18">
              Total No of Records :{" "}
              <span className="round-number">{draftCount || 0}</span>
            </div>
            <div className="f-size14 filing-history-right">
              <span className="ps-3 grid-top-icons">
                <PlayCircleIcon
                  className="vertical-align-text-top me-1"
                  sx={{ fontSize: 16 }}
                />
                Resume Draft Envelope
              </span>
              <span className="ps-3 grid-top-icons">
                <DeleteIcon
                  className="vertical-align-text-top me-1"
                  sx={{ fontSize: 16 }}
                  color="error"
                />
                Discard
              </span>
            </div>
          </div>
          <div className="themeDataTable">
            <Box sx={{ height: "auto", overflow: "auto" }}>
              <GridTable
                url={GET_DRAFTS}
                columns={columns}
                hideFooter={false}
                getRowId={getRowId}
                updateRowCount={updateDraftCount}
              />
            </Box>
          </div>
        </div>
      </div>

      {isDeleteModalOpen && selectedTransactionId && (
        <DeleteDraft
          isOpen={isDeleteModalOpen}
          transactionId={selectedTransactionId}
          onClose={closeModal}
        />
      )}
      {isWarningModalOpen && envelope && (
        <WarningDialog
          isOpen={isWarningModalOpen}
          onClose={closeModal}
          envelope={envelope}
          onResumeDraft={onDraftResume}
          content="This envelope is more than 1 month old. You can continue filing, but you need to submit the documents again."
        ></WarningDialog>
      )}
    </Page>
  );
};

export default MyDrafts;
