import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import Page from "../../components/page";
import Loader from "../../components/loader";
import useNotifications from "../../apis/auth/useNotifications";
import useUpdateNotifications from "../../apis/auth/useUpdateNotifications";
import { NotificationContext } from "../../context/NotificationContext";
import { INotification } from "../../interfaces";

const Notifications = () => {
  const navigate = useNavigate();
  const notificationHook = useNotifications();
  const updateNotification = useUpdateNotifications();
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const { createNotification } = useContext(NotificationContext);

  const onNotificationChange = useCallback((code: string) => {
    setNotifications((prevState) =>
      prevState.map((item) =>
        item.code === code ? { ...item, isActive: !item.isActive } : item
      )
    );
  }, []);

  const onSave = useCallback(() => {
    updateNotification.mutate(notifications);
  }, [notifications, updateNotification]);

  useEffect(() => {
    if (notificationHook.data) {
      setNotifications(notificationHook.data);
    }
  }, [notificationHook.data]);

  useEffect(() => {
    if (updateNotification.isSuccess) {
      createNotification({
        title: "Subscription Notification",
        type: "success",
        message: "Subscription updated successfully",
      });
    }
    // eslint-disable-next-line
  }, [updateNotification.isSuccess]);

  useEffect(() => {
    if (notificationHook.isError) {
      createNotification({
        title: "Subscription Notification",
        type: "error",
        message:
          notificationHook.error.response?.data?.userMessage ||
          "Something went wrong while getting subscription",
      });
    }
    // eslint-disable-next-line
  }, [notificationHook.isError]);

  useEffect(() => {
    if (updateNotification.isError) {
      createNotification({
        title: "Subscription Notification",
        type: "error",
        message:
          updateNotification.error.response?.data?.userMessage ||
          "Something went wrong while updating subscription",
      });
    }
    // eslint-disable-next-line
  }, [updateNotification.isError]);

  return (
    <Page>
      <div className="content-wrapper">
        <Typography className="dash-title-h2" variant="h2">
          Manage Notifications
        </Typography>

        <Typography py={2}>Email Notifications</Typography>

        {notificationHook.isLoading ? (
          <Loader />
        ) : (
          <Box>
            <Card variant="outlined">
              <Box padding={2}>
                <Typography>
                  Select the email notifications that you wish to receive
                </Typography>
                <Grid container>
                  {notifications?.map((notification) => (
                    <Grid key={notification.code} item xs={12} sm={6} md={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={notification.isActive}
                            onChange={onNotificationChange.bind(
                              this,
                              notification.code
                            )}
                          />
                        }
                        label={notification.description}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Card>

            <Box padding={2}>
              <div className="text-center bottom-button-row">
                <Button
                  className="btn button-outline-secondary me-3"
                  variant="contained"
                  type="button"
                  onClick={() => navigate("/dashboard")}
                >
                  Cancel
                </Button>
                <Button
                  className="btn primary-button"
                  variant="contained"
                  type="submit"
                  onClick={onSave}
                >
                  {updateNotification.isLoading ? (
                    <CircularProgress
                      sx={{
                        height: "25px !important",
                        width: "25px !important",
                        "--CircularProgress-size": "8px",
                        "--CircularProgress-trackThickness": "1px",
                        "--CircularProgress-progressThickness": "1px",
                      }}
                      color="inherit"
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </Box>
          </Box>
        )}
      </div>
    </Page>
  );
};

export default Notifications;
