import { useCallback, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/EditOutlined";
import { GridRenderCellParams, GridColDef } from "@mui/x-data-grid/models";

import Page from "../../components/page";
import GridTable from "../../components/grid";
import CreateEditPaymentAccount from "./createEditPaymentAccount";
import DeletePatmentAccount from "./deletePatmentAccount";
import queryClient from "../../queryClient";
import { GET_PAYMENT_ACCOUNTS } from "../../apis/URL";
import { IPaymentAccount } from "../../interfaces";

const PaymentAccount = () => {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState<
    IPaymentAccount | undefined
  >();

  const onCreate = useCallback(() => {
    setIsCreateEditModalOpen(true);
    setSelectedPaymentAccount(undefined);
  }, []);

  const onEdit = useCallback((paymentAccount: IPaymentAccount) => {
    setIsCreateEditModalOpen(true);
    setSelectedPaymentAccount(paymentAccount);
  }, []);

  const onDelete = useCallback((paymentAccount: IPaymentAccount) => {
    setSelectedPaymentAccount(paymentAccount);
    setIsDeleteModalOpen(true);
  }, []);

  const onClose = useCallback((shouldUpdateGrid?: boolean) => {
    setIsCreateEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setSelectedPaymentAccount(undefined);

    if (shouldUpdateGrid)
      queryClient.refetchQueries(["grid", GET_PAYMENT_ACCOUNTS]);
  }, []);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "accountName",
        headerName: "Account Name",
        width: 500,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span
              id={params.row.paymentAccountID}
              title={`Account Name ${params.row.accountName}`}
            >
              {params.row.accountName}
            </span>
          );
        },
      },
      {
        field: "paymentAccountTypeCode",
        headerName: "Account Type",
        renderCell: (params: GridRenderCellParams) => {
          if (
            params.row.paymentAccountTypeCode === "WV" ||
            params.row.paymentAccountTypeCode === "Waiver"
          ) {
            return "Waiver";
          } else if (
            params.row.paymentAccountTypeCode === "CC" ||
            params.row.paymentAccountTypeCode === "Credit Card"
          ) {
            return "Credit Card";
          } else if (
            params.row.paymentAccountTypeCode === "BankAccount" ||
            params.row.paymentAccountTypeCode === "eCheck"
          ) {
            return "eCheck";
          }
        },
        width: 350,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "active.value",
        headerName: "Active",
        renderCell: (params: GridRenderCellParams) => {
          return params.row.active?.value ? "Yes" : "No";
        },
        width: 300,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "action",
        headerName: "Action",
        width: 180,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <div className="dataGridactionIcons">
              <Tooltip title="Edit Payment Account">
                <IconButton
                  aria-label="Edit Payment Account"
                  onClick={onEdit.bind(this, params.row)}
                  aria-describedby={params.row.paymentAccountID}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Payment Account">
                <IconButton
                  aria-label="Delete Payment Account"
                  onClick={onDelete.bind(this, params.row)}
                  aria-describedby={params.row.paymentAccountID}
                >
                  <DeleteIcon className="datagrid-cancel" />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [onDelete, onEdit]
  );

  const getRowId = useCallback((row: any) => row.paymentAccountID, []);

  return (
    <Page>
      <div className="content-wrapper">
        <h2 className="dash-title-h2 mb-4">Manage Payment Accounts</h2>

        <div className="themeDataTable">
          <Box sx={{ height: "auto", overflow: "auto" }}>
            <GridTable
              url={GET_PAYMENT_ACCOUNTS}
              columns={columns}
              searchKeys={["accountName", "paymentAccountTypeCode"]}
              responseListKey="paymentAccountList"
              showSearch
              hideFooter={false}
              getRowId={getRowId}
              action={
                <Button
                  size="large"
                  variant="contained"
                  className="btn primary-button"
                  onClick={onCreate}
                >
                  Add Payment Account
                </Button>
              }
            />
          </Box>
        </div>
      </div>

      {isCreateEditModalOpen && (
        <CreateEditPaymentAccount
          key={selectedPaymentAccount?.paymentAccountID}
          isOpen={isCreateEditModalOpen}
          paymentAccount={selectedPaymentAccount}
          onClose={onClose}
        />
      )}

      <DeletePatmentAccount
        paymentAccount={selectedPaymentAccount}
        isOpen={isDeleteModalOpen}
        onClose={onClose}
      />
    </Page>
  );
};

export default PaymentAccount;
