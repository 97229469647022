import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";

import Loader from "../../../components/loader";
import useLocations from "../../../apis/common/useLocations";
import useLocationCaseCategories from "../../../apis/common/useLocationCaseCategories";
import useLocationCaseTypes from "../../../apis/common/useLocationCaseTypes";
import useLocationPartyTypes from "../../../apis/common/useLocationPartyTypes";
import useAttorneys from "../../../apis/firm/useAttorneys";
import usePaymentAccounts from "../../../apis/miscellaneous/usePaymentAccounts";
import { ICaseInfo, ILocationParty } from "../../../interfaces";
import { isIndividualUser } from "../../../utills";

interface ICaseInformation {
  caseInfo: ICaseInfo;
  updateCaseInfo: (
    caseInfo: ICaseInfo,
    locationParties: ILocationParty[]
  ) => void;
}

const CaseInformation: FC<ICaseInformation> = ({
  caseInfo,
  updateCaseInfo,
}) => {
  const navigate = useNavigate();
  const { watch, setValue, control, handleSubmit } = useForm<ICaseInfo>({
    defaultValues: caseInfo,
  });

  const { data: locations, isLoading: locationLoading } = useLocations();
  const { data: caseCategories, isLoading: categoryLoading } =
    useLocationCaseCategories(watch("location"));
  const { data: caseTypes, isLoading: caseTypeLoading } = useLocationCaseTypes(
    watch("location"),
    watch("category"),
    true
  );
  const { data: parties, isLoading: partyLoading } = useLocationPartyTypes(
    watch("location"),
    watch("caseType")
  );
  const { data: attorneys, isLoading: attorneyLoading } = useAttorneys();
  const { data: paymentAccounts, isLoading: paymentLoading } =
    usePaymentAccounts();

  const getAmount = useCallback((amount: string) => {
    if (!amount || isNaN(parseFloat(amount))) return;

    return `$${parseFloat(amount).toFixed(2)}`;
  }, []);

  const goToDashboard = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  const onSubmit = useCallback(
    (formData: ICaseInfo) => {
      formData.efmEcfCasetype = caseCategories?.find(
        (category) => category.efmCasecategoryCode === formData.category
      )?.efmEcfCasetype;

      updateCaseInfo(formData, parties || []);
    },
    [caseCategories, parties, updateCaseInfo]
  );

  useEffect(() => {
    if (!locations?.length || !watch("location")) return;

    if (
      !locations.some(
        (location) => location.efm_Location_Code === watch("location")
      )
    )
      setValue("location", "");
    // eslint-disable-next-line
  }, [locations]);

  useEffect(() => {
    if (!caseCategories?.length || !watch("category")) return;

    if (
      !caseCategories.some(
        (category) => category.efmCasecategoryCode === watch("category")
      )
    )
      setValue("category", "");
    // eslint-disable-next-line
  }, [caseCategories]);

  useEffect(() => {
    if (!caseTypes?.length || !watch("caseType")) return;

    if (
      !caseTypes.some(
        (caseType) => caseType.efmCasetypeCode === watch("caseType")
      )
    )
      setValue("caseType", "");
    // eslint-disable-next-line
  }, [caseTypes]);

  useEffect(() => {
    if (!attorneys?.length || !watch("filingAttorney")) return;

    if (
      !attorneys.some(
        (attorney) => attorney.attorneyID === watch("filingAttorney")
      )
    )
      setValue("filingAttorney", "");
    // eslint-disable-next-line
  }, [attorneys]);

  useEffect(() => {
    if (!paymentAccounts?.length || !watch("paymentAccount")) return;

    if (
      !paymentAccounts.some(
        (paymentAccount) =>
          paymentAccount.paymentAccountID === watch("paymentAccount")
      )
    )
      setValue("paymentAccount", "");
    // eslint-disable-next-line
  }, [paymentAccounts]);

  return (
    <Box sx={{ p: 0 }}>
      <Typography variant="h3" className="dash-title-h3 mb-3">
        Case Information
      </Typography>

      {(locationLoading ||
        categoryLoading ||
        caseTypeLoading ||
        attorneyLoading ||
        paymentLoading ||
        partyLoading) && <Loader />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              name="caseNumber"
              control={control}
              rules={{
                required: "Error: Case Number is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  inputRef={ref}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    htmlFor: `${field.name}_id`,
                  }}
                  inputProps={{
                    id: `${field.name}_id`,
                  }}
                  {...field}
                  placeholder="Case Number"
                  label="Case Number"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="location"
              control={control}
              rules={{
                required: "Error: Location is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  inputRef={ref}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    htmlFor: `${field.name}_id`,
                  }}
                  inputProps={{
                    id: `${field.name}_id`,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    SelectDisplayProps: {
                      "aria-required": true,
                    },
                  }}
                  disabled={Boolean(field.value)}
                  {...field}
                  select
                  placeholder="Select Location"
                  label="Select Location"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                >
                  <MenuItem value="">Select Location</MenuItem>
                  {locations?.map((location) => (
                    <MenuItem
                      key={location.efm_Location_Code}
                      value={location.efm_Location_Code}
                    >
                      {location.efm_Location_Name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="category"
              control={control}
              rules={{
                required: "Error: Category is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  inputRef={ref}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    htmlFor: `${field.name}_id`,
                  }}
                  inputProps={{
                    id: `${field.name}_id`,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    SelectDisplayProps: {
                      "aria-required": true,
                    },
                  }}
                  {...field}
                  select
                  label="Select Category"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                >
                  <MenuItem value="">Select Category</MenuItem>
                  {caseCategories?.map((category) => (
                    <MenuItem
                      key={category.efmCasecategoryCode}
                      value={category.efmCasecategoryCode}
                    >
                      {category.efmCasecategoryName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="caseType"
              control={control}
              rules={{
                required: "Error: Case Type is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  inputRef={ref}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    htmlFor: `${field.name}_id`,
                  }}
                  inputProps={{
                    id: `${field.name}_id`,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    SelectDisplayProps: {
                      "aria-required": true,
                    },
                  }}
                  {...field}
                  select
                  label="Select Case Type"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                >
                  <MenuItem value="">Select Case Type</MenuItem>
                  {caseTypes?.map((caseType) => (
                    <MenuItem
                      key={caseType.efmCasetypeCode}
                      value={caseType.efmCasetypeCode}
                    >
                      {`${caseType.efmCasetypeName} (${getAmount(
                        caseType.fee
                      )})`}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          {!isIndividualUser() && (
            <Grid item xs={12}>
              <Controller
                name="filingAttorney"
                control={control}
                rules={{
                  required: "Error: Filing Attorney is required.",
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    className="w-100"
                    id={field.name}
                    inputRef={ref}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                      htmlFor: `${field.name}_id`,
                    }}
                    inputProps={{
                      id: `${field.name}_id`,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      SelectDisplayProps: {
                        "aria-required": true,
                      },
                    }}
                    defaultValue={field.value}
                    {...field}
                    select
                    placeholder="Select Filing Attorney"
                    label="Select Filing Attorney"
                    variant="standard"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    <MenuItem value="">Select Filing Attorney</MenuItem>
                    {attorneys?.map((attorney) => (
                      <MenuItem
                        key={attorney.attorneyID}
                        value={attorney.attorneyID}
                      >
                        {`${attorney.firstName} ${attorney.lastName}`}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name="paymentAccount"
              control={control}
              rules={{
                required: "Error: Payment Account is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100"
                  id={field.name}
                  inputRef={ref}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                    htmlFor: `${field.name}_id`,
                  }}
                  inputProps={{
                    id: `${field.name}_id`,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    SelectDisplayProps: {
                      "aria-required": true,
                    },
                  }}
                  defaultValue={field.value}
                  {...field}
                  select
                  placeholder="Select Payment Account"
                  label="Select Payment Account"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                >
                  <MenuItem value="">Select Payment Account</MenuItem>
                  {paymentAccounts?.map((paymentAccount) => (
                    <MenuItem
                      key={paymentAccount.paymentAccountID}
                      value={paymentAccount.paymentAccountID}
                    >
                      {paymentAccount.accountName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Box justifyContent="center" width="100%" display="flex">
              <Button
                className="btn button-outline-secondary me-3"
                variant="contained"
                type="button"
                onClick={goToDashboard}
              >
                Exit
              </Button>
              <Button
                className="btn primary-button"
                variant="contained"
                type="submit"
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default CaseInformation;
