const COMMON_SERVICE_URL = process.env.REACT_APP_COMMON_SERVICE_API_URL;
const USER_SERVICE_URL = process.env.REACT_APP_USER_SERVICE_API_URL;
const MISCELLANEOUS_SERVICE_API_URL =
  process.env.REACT_APP_MISCELLANEOUS_SERVICE_API_URL;
const CASE_SERVICE_API_URL = process.env.REACT_APP_CASE_SERVICE_API_URL;

export const GET_FIRM_USERS = `${USER_SERVICE_URL}/api/user/api/firmUserList`;

export const CREATE_FIRM_USER = `${USER_SERVICE_URL}/api/user/api/createFirmUser`;

export const UPDATE_FIRM_USER = `${USER_SERVICE_URL}/api/user/api/updateFirmUser`;

export const DELETE_FIRM_USER = `${USER_SERVICE_URL}/api/user/api/removeFirmUser`;

export const RESEND_FIRM_USER_ACTIVATION = `${USER_SERVICE_URL}/api/user/api/resendActivation`;

export const GET_COUNTRIES_URL = `${COMMON_SERVICE_URL}/api/commondata/api/getCountry`;

export const GET_STATES_URL = `${COMMON_SERVICE_URL}/api/commondata/api/getState`;

export const GET_LOCATIONS_URL = `${COMMON_SERVICE_URL}/api/commondata/api/locationList`;

export const GET_DEFAULT_FILING_LOCATION_CODE_URL = `${COMMON_SERVICE_URL}/api/commondata/api/getDefaultFilingLocationCode`;

export const UPDATE_DEFAULT_FILING_LOCATION_CODE_URL = `${COMMON_SERVICE_URL}/api/commondata/api/setDefaultFilingLocationCode`;

export const GET_LOCATION_CASE_CATEGORIES = `${COMMON_SERVICE_URL}/api/commondata/api/locationCaseCategoryList`;

export const GET_LOCATION_CASE_TYPES = `${COMMON_SERVICE_URL}/api/commondata/api/locationCaseTypeList`;

export const GET_LOCATION_CROSS_REFERENCE_NUMBER = `${COMMON_SERVICE_URL}/api/commondata/api/getCrossReferenceNumber`;

export const GET_LOCATION_DISCLAIMER_REQUIREMENT = `${COMMON_SERVICE_URL}/api/commondata/api/getDisclaimerRequirement`;

export const GET_LOCATION_ALL_PARTY_TYPES = `${COMMON_SERVICE_URL}/api/commondata/api/locationAllPartyTypeList`;

export const GET_LOCATION_ADDITIONAL_PARTY_TYPES = `${COMMON_SERVICE_URL}/api/commondata/api/getAddAdditionalPartyTypeList`;

export const GET_LOCATION_PARTY_TYPES = `${COMMON_SERVICE_URL}/api/commondata/api/locationPartyTypeList`;

export const GET_LOCATION_NAME_SUFFIXES = `${COMMON_SERVICE_URL}/api/commondata/api/locationNameSuffixList`;

export const GET_LOCATION_FILING_TYPES = `${COMMON_SERVICE_URL}/api/commondata/api/locationFilingTypeList`;

export const GET_LOCATION_FILING_TYPES_FOR_SUBSEQUENT_FILING = `${COMMON_SERVICE_URL}/api/commondata/api/locationCaseTypeListForSubsequentFiling`;

export const GET_LOCATION_DOCUMENT_TYPES = `${COMMON_SERVICE_URL}/api/commondata/api/locationDocumentTypeList`;

export const GET_LOCATION_OPTIONAL_SERVICES = `${COMMON_SERVICE_URL}/api/commondata/api/locationOptionalServiceList`;

export const GET_LOCATION_FILING_COMPONENTS = `${COMMON_SERVICE_URL}/api/commondata/api/locationFilingComponentList`;

export const GET_ENVELOPE_CARD_COUNTS = `${COMMON_SERVICE_URL}/api/commondata/getAdminThreeEnvelopeCardCounts`;

export const GET_ENVELOPE_PAYMENTS = `${COMMON_SERVICE_URL}/api/commondata/getAdminEnvelopePaymentList`;

export const GET_PAYMENT_ACCOUNTS = `${MISCELLANEOUS_SERVICE_API_URL}/api/miscellaneous/api/paymentAccountList`;

export const GET_PAYMENT_ACCOUNT_TYPES = `${MISCELLANEOUS_SERVICE_API_URL}/api/miscellaneous/api/paymentAccountTypeList`;

export const GET_SERVICE_CONTACTS = `${USER_SERVICE_URL}/api/firmInformation/api/serviceContactList`;

export const GET_SERVICE_CONTACT = `${USER_SERVICE_URL}/api/firmInformation/api/getServiceContact`;

export const CREATE_SERVICE_CONTACT = `${USER_SERVICE_URL}/api/firmInformation/api/createServiceContact`;

export const UPDATE_SERVICE_CONTACT = `${USER_SERVICE_URL}/api/firmInformation/api/updateServiceContact`;

export const DELETE_SERVICE_CONTACT = `${USER_SERVICE_URL}/api/firmInformation/api/removeServiceContact`;

export const CREATE_PAYMENT_ACCOUNT = `${MISCELLANEOUS_SERVICE_API_URL}/api/miscellaneous/api/createPaymentAccount`;

export const UPDATE_PAYMENT_ACCOUNT = `${MISCELLANEOUS_SERVICE_API_URL}/api/miscellaneous/api/updatePaymentAccount`;

export const SAVE_PAYMENT_ACCOUNT = `${MISCELLANEOUS_SERVICE_API_URL}/api/miscellaneous/api/savePaymentAccount`;

export const DELETE_PAYMENT_ACCOUNT = `${MISCELLANEOUS_SERVICE_API_URL}/api/miscellaneous/api/removePaymentAccount`;

export const REGISTER_USER_URL = `${USER_SERVICE_URL}/api/user/registerUser`;

export const GET_ATTORNEYS = `${USER_SERVICE_URL}/api/user/api/attorneyList`;

export const GET_FIRM_INFORMATION = `${USER_SERVICE_URL}/api/user/getFirmInformation`;

export const UPDATE_FIRM_INFORMATION = `${USER_SERVICE_URL}/api/user/updateFirmInformation`;

export const CHANGE_PASSWORD = `${USER_SERVICE_URL}/api/user/changePassword`;

export const RESET_USER_PASSWORD = `${USER_SERVICE_URL}/api/user/api/resetUserPassword`;

export const GET_USER_NOTIFICATIONS = `${USER_SERVICE_URL}/api/user/getUserNotification`;

export const UPDATE_USER_NOTIFICATIONS = `${USER_SERVICE_URL}/api/user/updateUserNotification`;

export const GET_USER_I2F_LINK = `${USER_SERVICE_URL}/api/user/linkAndRedirectToI2F`;

export const CREATE_ATTORNEY = `${USER_SERVICE_URL}/api/user/api/createAttorney`;

export const UPDATE_ATTORNEY = `${USER_SERVICE_URL}/api/user/api/updateAttorney`;

export const DELETE_ATTORNEY = `${USER_SERVICE_URL}/api/user/api/removeAttorney`;

export const GET_ATTACHED_CASES = `${CASE_SERVICE_API_URL}/api/case/attachCaseList`;

export const UPLOAD_CASE_DOCUMENT = `${CASE_SERVICE_API_URL}/api/casefiling/uploadDocument`;

export const FETCH_CASE_DOCUMENT = `${CASE_SERVICE_API_URL}/api/casefiling/downloadPdf`

export const UPLOAD_SUBSEQUENT_DOCUMENT = `${CASE_SERVICE_API_URL}/api/existingCase/uploadSubsequentDocument`;

export const GET_FEE_CALCULATION = `${CASE_SERVICE_API_URL}/api/case/api/getFeesCalculation`;

export const REVIEW_FILING = `${CASE_SERVICE_API_URL}/api/casefiling/reviewFiling`;

export const SUBSEQUENT_FILING = `${CASE_SERVICE_API_URL}/api/existingCase/subsequentFiling`;

export const GET_RETURN_DATE = `${CASE_SERVICE_API_URL}/api/casefiling/api/getReturnDateAPINewCase`;

export const GET_SUBSEQUENT_RETURN_DATE = `${CASE_SERVICE_API_URL}/api/existingCase/getReturnDateForExistingCase`;

export const GET_SUBSEQUENT_FEE_CALCULATION = `${CASE_SERVICE_API_URL}/api/case/api/getSubsequentFeesCalculation`;

export const UPLOAD_NONINDEXED_DOCUMENT = `${CASE_SERVICE_API_URL}/api/subsequentNonIndexedCaseFiling/uploadDocumentSubseqNonIndexedCase`;

export const GET_NONINDEXED_FEE_CALCULATION = `${CASE_SERVICE_API_URL}/api/subsequentNonIndexedCaseFiling/getFeesCalculationSubsequentNonIndexCase`;

export const NONINDEXED_FILING = `${CASE_SERVICE_API_URL}/api/subsequentNonIndexedCaseFiling/subsequentFilingForNonIndexedCases`;

export const GET_TRANSACTIONS = `${CASE_SERVICE_API_URL}/api/fillingHistory/getTransactionList`;

export const GET_EFM_TRANSACTIONS = `${CASE_SERVICE_API_URL}/api/fillingHistory/getEFMTransactionList`;

export const GET_TRANSACTION_DETAILS = `${CASE_SERVICE_API_URL}/api/fillingHistory/getTransactionDetail`;

export const DELETE_TRANSACTION = `${CASE_SERVICE_API_URL}/api/fillingHistory/cancelEFMTransaction`;

export const GET_DRAFTS = `${CASE_SERVICE_API_URL}/api/draft/api/getDraftListByUserID`;

export const GET_DRAFT_DETAILS = `${CASE_SERVICE_API_URL}/api/draft/api/getDraftJSONByTransactionID`;

export const DELETE_DRAFT = `${CASE_SERVICE_API_URL}/api/draft/api/deleteDraftTransactionID`;

export const CREATE_DRAFT = `${CASE_SERVICE_API_URL}/api/case/api/saveDraftEnvelopeNewCase`;

export const CREATE_EXISTING_CASE_DRAFT = `${CASE_SERVICE_API_URL}/api/existingCase/saveDraftExistingCase`;

export const CREATE_NONINDEXED_CASE_DRAFT = `${CASE_SERVICE_API_URL}/api/subsequentNonIndexedCaseFiling/saveDraftEnvelopeNonIndexedCase`;

export const ADMIN_PANEL_FETCH_PENDING_REQUESTS = `${USER_SERVICE_URL}/api/user/api/getPendingRequests`;

export const ADMIN_PANEL_SEARCH_BY_EMAIL_PENDING_REQUESTS = `${USER_SERVICE_URL}/api/user/api/getPendingRequestsByEmail`;

export const ADMIN_PANEL_REMOVE_BY_EMAIL_PENDING_REQUESTS = `${USER_SERVICE_URL}/api/user/api/getRemovePendingRequestsByEmail`;

export const ALL_USERS_FILTER_LIST_WEBSERVICE_API = `${USER_SERVICE_URL}/api/user/api/filterAllUsersList`;

export const SAVE_ADMIN_USER_NOTIFICATIONS_TO_FILE = `${USER_SERVICE_URL}/api/user/api/saveAdminUserNotificationsToFile`;

export const READ_ADMIN_USER_NOTIFICATION_FILES = `${USER_SERVICE_URL}/api/user/api/readAdminUserNotificationFiles`;

export const COUNTY_ADMIN_LIST = `${USER_SERVICE_URL}/api/user/api/countyAdminList`;

export const COUNTY_ADMIN_CREATE = `${USER_SERVICE_URL}/api/user/api/createCountyAdmin`;

export const COUNTY_ADMIN_UPDATE = `${USER_SERVICE_URL}/api/user/api/updateCountyAdmin`;

export const GET_DASHBOARD_COUNT = `${CASE_SERVICE_API_URL}/api/case/api/getDashboardCount`;

export const COPY_ENVELOPE = `${CASE_SERVICE_API_URL}/api/existingCase/copyEnvelopeNewCase`;

export const MODIFY_FILER_CROSS_REFERENCE_MAPPING = `${COMMON_SERVICE_URL}/api/commondata/api/modifyFilerCrossReferenceMapping`;

export const CASE_SEARCH_LIST = `${COMMON_SERVICE_URL}/api/commondata/api/searchCaseList`;

export const GET_CASE = `${CASE_SERVICE_API_URL}/api/case/getCase`;

export const GET_LOCATION_CASE_TYPES_SUBSEQUENT_FILING = `${COMMON_SERVICE_URL}/api/commondata/api/locationCaseTypeListForSubsequentFiling`;

export const GET_VIEW_PRINT_PDF = `${CASE_SERVICE_API_URL}/api/fillingHistory/getViewPrintPDF`;

export const ATTACHED_SERVICE_CONTACT = `${CASE_SERVICE_API_URL}/api/case/attachServiceContact`;

export const DEATTACHED_SERVICE_CONTACT = `${CASE_SERVICE_API_URL}/api/case/detachServiceContact`;

export const GET_SERVICE_INFO_HISTORY = `${CASE_SERVICE_API_URL}/api/case/getServiceInformationHistory`;

export const GET_ATTACHED_CASES_LIST = `${CASE_SERVICE_API_URL}/api/case/attachCaseList`;

export const GET_SERVICE_INFORMATION = `${CASE_SERVICE_API_URL}/api/case/getServiceInformation`;

export const GET_RESERVE_COURT_DATE = `${CASE_SERVICE_API_URL}/api/fillingHistory/reserveCourtDate`;

export const GET_REQUEST_COURT_DATE = `${CASE_SERVICE_API_URL}/api/case/requestCourtDate`;

export const GET_SUPPORT_DATA = `${COMMON_SERVICE_URL}/api/commondata/getSupportData`;

export const EXPORT_PAYMENT_REPORT_AS_PDF = ` ${COMMON_SERVICE_URL}/api/commondata/api/exportPaymentReportAsPDF`;

export const GET_IMPORT_TYLER_PAYMENT_LIST = `${MISCELLANEOUS_SERVICE_API_URL}/api/miscellaneous/getImportList`;

export const IMPORT_TYLER_PAYMENT = `${MISCELLANEOUS_SERVICE_API_URL}/api/miscellaneous/importTylerPayment`;

export const GET_CASE_API_CALL_FLAG = `${CASE_SERVICE_API_URL}/api/case/getCaseApiCallFlag`;
