import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_LOCATIONS_URL } from "../../URL";
import { ILocation } from "../../../interfaces";

const getLocations = async () => {
  const response = await http.post<ILocation[]>(GET_LOCATIONS_URL);
  return response.data;
};

const useLocations = () =>
  useQuery<ILocation[]>({
    queryKey: ["locations"],
    queryFn: getLocations,
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default useLocations;
