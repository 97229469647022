import Page from "../../components/page";
import FilingHistoryGrid from "./filingHistoryGrid";

const FilingHistory = () => {
  return (
    <Page>
      <div className="content-wrapper">
        <h2 className="dash-title-h2 mb-4">Filing History</h2>

        <FilingHistoryGrid />
      </div>
    </Page>
  );
};

export default FilingHistory;
