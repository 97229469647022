import { FC, useCallback, useContext, useEffect } from "react";

import DeleteDialog from "../../../components/DeleteDialog";
import useDeleteTransaction from "../../../apis/filingHistory/useDeleteTransaction";
import { NotificationContext } from "../../../context/NotificationContext";
import { ITransation } from "../../../interfaces";
import FilingDeleteDialog from "../../../components/FilingDeleteDialog";

interface IDeleteFilingHistory {
  isOpen: boolean;
  filing: ITransation;
  email: string;
  onClose: (shouldUpdateGrid?: boolean) => void;
}

const DeleteFilingHistory: FC<IDeleteFilingHistory> = ({
  isOpen,
  filing,
  email,
  onClose,
}) => {
  const deleteTransaction = useDeleteTransaction();
  const { createNotification } = useContext(NotificationContext);

  const onDelete = useCallback(() => {
    deleteTransaction.mutateAsync({
      efmFilingId: filing.filingId,
      userId: email,
    });
  }, [filing.filingId, email, deleteTransaction]);

  useEffect(() => {
    if (deleteTransaction.isSuccess) {
      createNotification({
        title: "Filing Notification",
        type: "success",
        message: "Case canceled successfully.",
      });
      onClose(true);
    }
    // eslint-disable-next-line
  }, [deleteTransaction.isSuccess]);

  useEffect(() => {
    if (deleteTransaction.isError) {
      createNotification({
        title: "Filing Notification",
        type: "error",
        message:
          deleteTransaction.error.response?.data?.userMessage ||
          "Something went wrong while canceling case",
      });
    }
    // eslint-disable-next-line
  }, [deleteTransaction.isError]);

  return (
    <FilingDeleteDialog
      entity="filing"
      isOpen={isOpen}
      isLoading={deleteTransaction.isLoading}
      onClose={onClose.bind(this, false)}
      onDelete={onDelete}
    />
  );
};

export default DeleteFilingHistory;
