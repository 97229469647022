import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_FIRM_INFORMATION } from "../../URL";
import { IAxisErrorResponse, IFirmInformation } from "../../../interfaces";

const getFirmInformation = async () => {
  const response = await http.post(GET_FIRM_INFORMATION);
  return response.data;
};

const useFirmInformation = () =>
  useQuery<IFirmInformation, IAxisErrorResponse>({
    queryKey: ["firm-information"],
    queryFn: getFirmInformation,
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default useFirmInformation;
