import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import ConfirmationDialog from "../../../../components/confirmationDialog";
import useDefaultFilingLocationCode from "../../../../apis/common/useDefaultFilingLocationCode";
import useUpdateDefaultFilingLocationCode from "../../../../apis/common/useUpdateDefaultFilingLocationCode";
import { NotificationContext } from "../../../../context/NotificationContext";
import { ILocation, ISearchFilter } from "../../../../interfaces";

interface ISearchCaseForm {
  pageTitle: string;
  locations?: ILocation[];
  onAdvancedSearch: () => void;
  onSearch: (formData: ISearchFilter) => void;
}

const SearchCaseForm: FC<ISearchCaseForm> = ({
  pageTitle,
  locations,
  onSearch,
}) => {
  const { data: defaultLocationCode } = useDefaultFilingLocationCode();
  const updateDefaultFilingLocationCode = useUpdateDefaultFilingLocationCode();
  const [showUpdateLocationConfirmation, setShowUpdateLocationConfirmation] =
    useState(false);
  const [locationConfirmationContent, setLocationConfirmationContent] =
    useState("");
  const { createNotification } = useContext(NotificationContext);

  const { watch, control, setValue, handleSubmit } = useForm<ISearchFilter>({
    defaultValues: {
      locationName: "",
      caseNumber: "",
    },
  });

  const setDefaultFilingLocation = useCallback(() => {
    const selectedLocationCode = watch("locationName");

    if (defaultLocationCode === selectedLocationCode) {
      createNotification({
        title: "Existing Case Notification",
        type: "error",
        message: "Selected location is already set as a default location.",
      });
    } else {
      setShowUpdateLocationConfirmation(true);
      setLocationConfirmationContent(
        `Do you want to change default location from ${
          locations?.find(
            (location) => location.efm_Location_Code === defaultLocationCode
          )?.efm_Location_Name
        } to ${
          locations?.find(
            (location) => location.efm_Location_Code === selectedLocationCode
          )?.efm_Location_Name
        } ?`
      );
    }
    // eslint-disable-next-line
  }, [defaultLocationCode, locations]);

  const closeUpdateLocationConfirmation = useCallback(() => {
    setShowUpdateLocationConfirmation(false);
  }, []);

  const updateDefaultLocation = useCallback(async () => {
    await updateDefaultFilingLocationCode.mutateAsync(watch("locationName"));

    setShowUpdateLocationConfirmation(false);
    createNotification({
      title: "New Case Notification",
      type: "success",
      message: "Default Location set successfully.",
    });
  }, [updateDefaultFilingLocationCode, createNotification, watch]);

  useEffect(() => {
    if (pageTitle === "existing") {
      if (defaultLocationCode && defaultLocationCode.length > 0) {
        setValue("locationName", defaultLocationCode);
      }
    }
    // eslint-disable-next-line
  }, [defaultLocationCode]);

  return (
    <>
      <form onSubmit={handleSubmit(onSearch)}>
        <Grid container spacing={4}>
          <Grid item xl={8} lg={7} md={6} sm={12} xs={12}>
            <Grid container spacing={4}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Controller
                  name="locationName"
                  control={control}
                  rules={{
                    required: "Error: Location is required.",
                  }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      className="w-100"
                      id={field.name}
                      inputRef={ref}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                        htmlFor: `${field.name}_id`,
                      }}
                      inputProps={{
                        id: `${field.name}_id`,
                      }}
                      SelectProps={{
                        displayEmpty: true,
                        SelectDisplayProps: {
                          "aria-required": true,
                        },
                      }}
                      {...field}
                      select
                      placeholder="Select Location"
                      label="Select Location"
                      variant="standard"
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                    >
                      <MenuItem value="">Select Location</MenuItem>
                      {locations?.map((location) => (
                        <MenuItem
                          key={location.efm_Location_Code}
                          value={location.efm_Location_Code}
                        >
                          {location.efm_Location_Name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Controller
                  name="caseNumber"
                  control={control}
                  rules={{
                    required: "Error: Case Number is required.",
                  }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      className="w-100 m-0"
                      id={field.name}
                      inputRef={ref}
                      autoFocus
                      inputProps={{
                        "aria-required": true,
                      }}
                      InputLabelProps={{
                        required: true,
                      }}
                      {...field}
                      onBlur={() => {
                        field.onChange(field.value?.trim());
                        field.onBlur();
                      }}
                      placeholder="Case Number"
                      label="Case Number"
                      variant="standard"
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            className="bottom-btn justify-content-start d-flex align-items-end"
            item
            xl={4}
            lg={5}
            md={6}
            sm={12}
            xs={12}
          >
            <Button
              className="btn primary-button me-3"
              variant="contained"
              type="submit"
            >
              Search
            </Button>
            {/* {pageTitle == "searchCases" && (
              <a
                className="btn btn-yellow-bg d-inline-block cursor-pointer"
                onClick={onAdvancedSearch}
              >
                Advanced Search
                <FontAwesomeIcon
                  className="ms-2"
                  icon={faMagnifyingGlassPlus}
                />
              </a>
            )} */}
            {pageTitle === "existing" && (
              <Button
                className="btn button-outline-secondary"
                variant="contained"
                disabled={!watch("locationName")}
                onClick={setDefaultFilingLocation}
              >
                Set Default Filing Location
              </Button>
            )}
          </Grid>
        </Grid>
      </form>

      <ConfirmationDialog
        isOpen={showUpdateLocationConfirmation}
        title="Confirmation"
        content={locationConfirmationContent}
        onConfirmation={updateDefaultLocation}
        onClose={closeUpdateLocationConfirmation}
      />
    </>
  );
};

export default SearchCaseForm;
