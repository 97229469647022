import { useMutation } from "@tanstack/react-query";

import http from "../../../../http";
import { RESEND_FIRM_USER_ACTIVATION } from "../../../URL";
import { IAxisErrorResponse } from "../../../../interfaces";

const resendFirmUserActivation = async (userId: string) => {
  await http.post(RESEND_FIRM_USER_ACTIVATION, {
    userId,
  });
};

const useResendFirmUserActivation = () =>
  useMutation<void, IAxisErrorResponse, string>({
    mutationKey: ["resendActivation"],
    mutationFn: resendFirmUserActivation,
  });

export default useResendFirmUserActivation;
