import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import subDays from "date-fns/subDays";
import format from "date-fns/format";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GridRenderCellParams, GridColDef } from "@mui/x-data-grid/models";

import ExportCsv from "./ExportCsv";
import Page from "../../components/page";
import GridTable from "../../components/grid";
import { getIsAdmin, isIndividualUser } from "../../utills";
import useProfile from "../../apis/auth/useProfile";
import useLocations from "../../apis/common/useLocations";
import { renderFilingStatus } from "../../column_helper";
import usePDFDownload from "../../apis/payments/usePDFDownload";
import useEnvelopePayments from "../../apis/payments/useEnvelopePayments";
import useEnvelopeCardCounts from "../../apis/payments/useEnvelopeCardCounts";
import Loader from "../../components/loader/Loader";
import { NotificationContext } from "../../context/NotificationContext";
import { useLocation } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const TransactionReport = () => {
  const location = useLocation();
  const profile = useProfile();
  const { data: locations, isLoading: locationLoading } = useLocations();
  const envelopePayments = useEnvelopePayments();
  const envelopeCardCounts = useEnvelopeCardCounts();
  const pdfDownload = usePDFDownload();
  const { createNotification } = useContext(NotificationContext);

  const [paymentTypes, setPaymentTypes] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [envelopeId, setEnvelopeId] = useState("");
  const [firmId, setFirmId] = useState("-1");
  const [startDate, setStartDate] = useState<Date | null>(
    subDays(new Date(), 30)
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [locationId, setLocationId] = useState("-1");
  const [filingStatus, setFilingStatus] = useState("-1");
  const [paymentStatus, setPaymentStatus] = useState("-1");
  const [totalFilingFee, setTotalFilingFee] = useState(0);
  const [totalFilingCount, setTotalFilingCount] = useState(0);
  const [totalI2fileFee, setTotalI2fileFee] = useState(0);
  const [isEnvelopeHide, setIsEnvelopeHide] = useState(true);
  const [i2filefees, setI2FileFees] = useState("-1");
  const [i2filefeesReceived, setI2FileFeesReceived] = useState("-1");
  const [showPaymentSummaryNotes, setShowPaymentSummaryNotes] = useState(false);
  const minDate = subDays(new Date(), 180);
  const maxDate = new Date();

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "env_filing",
        headerName: "Envelope #",
        width: 100,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <b>{`${params.row.efmEnvelopeId}`}</b>
          );
        },
      },
      {
        field: "location",
        headerName: "Location",
        width: 200,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return locations?.find(
            (location) => location.efm_Location_Code === params.row.location
          )?.efm_Location_Name;
        },
      },
      {
        field: "caseNumber",
        headerName: "Case #",
        width: 150,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "fullName",
        headerName: "Filer Name",
        width: 150,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "userId",
        headerName: "Filer Email",
        width: 180,
        filterable: false,
        disableColumnMenu: true,
      },
      // {
      //   field: "status",
      //   headerName: "Filing Status",
      //   width: 150,
      //   filterable: false,
      //   disableColumnMenu: true,
      //   renderCell: renderFilingStatus.bind(this, "efmFilingStatus", null),
      // },
      {
        field: "efmSyncDate",
        headerName: "Accepted Date",
        width: 180,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "fees",
        headerName: "Envelope Total Fee",
        width: 150,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "convenienceFee",
        headerName: "Payment Service Fee",
        width: 180,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "amount",
        headerName: "I2File Fee",
        width: 150,
        filterable: false,
        disableColumnMenu: true,
      },
    ],
    [locations]
  );

  const getRowId = useCallback(
    (row: any) => `${row.efmEnvelopeId}-${row.efmFilingCode}`,
    []
  );

  const onEnvelopeIdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEnvelopeId(event.target.value);
    },
    []
  );

  const onFirmIdChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFirmId(event.target.value);
  }, []);

  const onLocationChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLocationId(event.target.value);
    },
    []
  );

  const onFilingStatusChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilingStatus(event.target.value);
    },
    []
  );

  const onPaymentStatusChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPaymentStatus(event.target.value);
    },
    []
  );

  const onI2fileFeesReceived = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setI2FileFeesReceived(event.target.value);
    },
    []
  );

  const onI2fileFeeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setI2FileFees(event.target.value);
    },
    []
  );

  const onPdfDownload = useCallback(() => {
    if (!envelopePayments?.data?.length) return;

    pdfDownload.mutate({
      transactions: envelopePayments.data.map((paymentdata) => ({
        transactionid: paymentdata.efmEnvelopeId,
        userId: paymentdata.userId,
        fees: paymentdata.fees,
        convenienceFee: paymentdata.convenienceFee,
        location: paymentdata.location,
        efmLocationId: paymentdata.efmLocationId,
        efmEnvelopeId: paymentdata.efmEnvelopeId,
        caseNumber: paymentdata.caseNumber,
        efmFilingStatus: paymentdata.efmFilingStatus,
        efmSyncDate: paymentdata.efmSyncDate,
        amount: paymentdata.amount,
        efmFilingId: paymentdata.efmFilingId,
        startDate: paymentdata.startDate || "",
        endDate: paymentdata.endDate,
        efmFirmId: paymentdata.efmFirmId,
        fullName: paymentdata.fullName,
        filterFilingstatus: paymentdata.filterFilingstatus,
        filterPaymentStatus: paymentdata.filterPaymentStatus,
        isI2FAdminUser: paymentdata.isI2FAdminUser,
        efmFilingCode: paymentdata.efmFilingCode,
        category: paymentdata.category,
        efmFilingTypeName: paymentdata.efmFilingTypeName,
        i2FileFeeStatus: paymentdata.i2FileFeeStatus,
        i2FileFeeReceivedStatus: paymentdata.i2FileFeeReceivedStatus,
        efmUserId: paymentdata.efmUserId,
      })),
      totalFilingFees: totalFilingFee.toFixed(2),
      totalI2FileFees: totalI2fileFee,
      envelopeCount: totalFilingCount,
    });
  }, [
    envelopePayments,
    totalFilingCount,
    totalFilingFee,
    totalI2fileFee,
    pdfDownload,
  ]);

  useEffect(() => {
    if (location.pathname != "/admin/transactionreport") {
      envelopePayments.mutate({
        efmEnvelopeId: envelopeId.trim(),
        efmFirmId: firmId,
        startDate: startDate ? format(startDate, "yyyy-MM-dd") : "",
        endDate: endDate ? format(endDate, "yyyy-MM-dd") : "",
        efmLocationId: locationId,
        filterFilingstatus: filingStatus,
        filterPaymentStatus: paymentStatus,
        isI2FAdminUser: getIsAdmin() ? "1" : "0",
        i2fileFeeStatus: i2filefees,
        i2fileFeeReceivedStatus: i2filefeesReceived,
        efmUserId: getIsAdmin() ? "" : profile.data?.userID || "",
        userId: getIsAdmin() ? "" : profile?.data?.email || "",
      });

      envelopeCardCounts.mutate({
        efmEnvelopeId: envelopeId.trim(),
        efmFirmId: firmId,
        startDate: startDate ? format(startDate, "yyyy-MM-dd") : "",
        endDate: endDate ? format(endDate, "yyyy-MM-dd") : "",
        efmLocationId: locationId,
        filterFilingstatus: filingStatus,
        filterPaymentStatus: paymentStatus,
        isI2FAdminUser: "0",
        i2fileFeeStatus: i2filefees,
        i2fileFeeReceivedStatus: i2filefeesReceived,
        efmUserId: getIsAdmin() ? "" : profile?.data?.userID || "",
        userId: getIsAdmin() ? "" : profile?.data?.email || "",
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const filingCount = envelopePayments.data?.length;
    setTotalFilingCount(filingCount || 0);

    const filingFees = envelopePayments.data?.reduce(
      (total, filingFee) =>
        filingFee.fees !== ""
          ? total + parseFloat(filingFee.fees.replace("$", ""))
          : total,
      0
    );
    setTotalFilingFee(filingFees || 0);

    const i2FileTotal = envelopePayments.data?.reduce(
      (total, totalfees) =>
        totalfees.amount !== ""
          ? total + parseFloat(totalfees.amount.replace("$", ""))
          : total,
      0
    );

    setTotalI2fileFee(i2FileTotal || 0);
  }, [envelopePayments, totalFilingCount, totalFilingCount, totalI2fileFee]);

  const onSearch = useCallback(() => {
    if (!profile.data) return;

    envelopePayments.mutate({
      efmEnvelopeId: envelopeId.trim(),
      efmFirmId: firmId,
      startDate: startDate ? format(startDate, "yyyy-MM-dd") : "",
      endDate: endDate ? format(endDate, "yyyy-MM-dd") : "",
      efmLocationId: locationId,
      filterFilingstatus: filingStatus,
      filterPaymentStatus: paymentStatus,
      isI2FAdminUser: "0",
      i2fileFeeStatus: i2filefees,
      i2fileFeeReceivedStatus: i2filefeesReceived,
      efmUserId: getIsAdmin() ? "" : profile.data?.userID || "",
      userId: getIsAdmin() ? "" : profile?.data?.email || "",
    });

    envelopeCardCounts.mutate({
      efmEnvelopeId: envelopeId.trim(),
      efmFirmId: firmId,
      startDate: startDate ? format(startDate, "yyyy-MM-dd") : "",
      endDate: endDate ? format(endDate, "yyyy-MM-dd") : "",
      efmLocationId: locationId,
      filterFilingstatus: filingStatus,
      filterPaymentStatus: paymentStatus,
      isI2FAdminUser: "0",
      i2fileFeeStatus: i2filefees,
      i2fileFeeReceivedStatus: i2filefeesReceived,
      efmUserId: getIsAdmin() ? "" : profile.data?.userID || "",
      userId: getIsAdmin() ? "" : profile?.data?.email || "",
    });

    setShowPaymentSummaryNotes(true);
  }, [
    envelopeId,
    firmId,
    startDate,
    endDate,
    locationId,
    filingStatus,
    paymentStatus,
    envelopeCardCounts,
    envelopePayments,
    i2filefees,
    i2filefeesReceived,
    profile.data,
  ]);

  useEffect(() => {
    const types = [{ label: "My Payment Report", value: "-1" }];
    if (
      profile.data?.roles?.findIndex(
        (role) => role.roleName === "FIRM_ADMIN"
      ) !== -1 &&
      !isIndividualUser()
    ) {
      types.push({
        label: "Firm Payment Report",
        value: profile.data?.firmID || "",
      });
    }

    setPaymentTypes(types);
  }, [profile.data]);

  const hideEnvelope = useCallback(() => {
    setIsEnvelopeHide((prevState) => !prevState);
  }, []);

  const csvData = useMemo(() => {
    if (!envelopePayments?.data?.length) return;

    return envelopePayments.data.map((paymentdata) => ({
      transactionid: paymentdata.efmEnvelopeId,
      userId: paymentdata.userId,
      fees: paymentdata.fees,
      convenienceFee: paymentdata.convenienceFee,
      location: paymentdata.location,
      efmLocationId: paymentdata.efmLocationId,
      efmEnvelopeId: paymentdata.efmEnvelopeId,
      caseNumber: paymentdata.caseNumber,
      efmFilingStatus: paymentdata.efmFilingStatus,
      efmSyncDate: paymentdata.efmSyncDate,
      amount: paymentdata.amount,
      efmFilingId: paymentdata.efmFilingId,
      startDate: paymentdata.startDate || "",
      endDate: paymentdata.endDate,
      efmFirmId: paymentdata.efmFirmId,
      fullName: paymentdata.fullName,
      filterFilingstatus: paymentdata.filterFilingstatus,
      filterPaymentStatus: paymentdata.filterPaymentStatus,
      isI2FAdminUser: paymentdata.isI2FAdminUser,
      efmFilingCode: paymentdata.efmFilingCode,
      category: paymentdata.category,
      efmFilingTypeName: paymentdata.efmFilingTypeName,
      i2FileFeeStatus: paymentdata.i2FileFeeStatus,
      i2FileFeeReceivedStatus: paymentdata.i2FileFeeReceivedStatus,
      efmUserId: paymentdata.efmUserId,
    }));
  }, [envelopePayments]);

  useEffect(() => {
    if (envelopePayments.isError) {
      createNotification({
        title: "Report Notification",
        type: "error",
        message:
          envelopePayments.error.response?.data?.userMessage ||
          "An Error occurred while getting envelopes",
      });
    }
    // eslint-disable-next-line
  }, [envelopePayments.isError]);

  useEffect(() => {
    if (envelopeCardCounts.isError) {
      createNotification({
        title: "Report Notification",
        type: "error",
        message:
          envelopeCardCounts.error.response?.data?.userMessage ||
          "An Error occurred while getting envelope counts",
      });
    }
    // eslint-disable-next-line
  }, [envelopeCardCounts.isError]);

  useEffect(() => {
    if (pdfDownload.isError) {
      createNotification({
        title: "Report Notification",
        type: "error",
        message:
          pdfDownload.error.response?.data?.userMessage ||
          "An Error occurred while downloading file",
      });
    }
    // eslint-disable-next-line
  }, [pdfDownload.isError]);

  return (
    <Page>
      {(envelopeCardCounts.isLoading ||
        envelopePayments.isLoading ||
        locationLoading) && <Loader />}

      <div className="content-wrapper">
        <h2 className="dash-title-h2 mb-4">Transaction Report</h2>
        <div className="card-sec mb-3">
          <Grid item xs={12} md={6}>
            <Item className="remove-item-css">
              <div>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                      <Item className="remove-item-css">
                        <div className="box-card accepted_filing_card green-pastel-bg mb-4 text-center">
                          <h3>
                            {(envelopeCardCounts?.data || [])
                              .filter(
                                (data) =>
                                  data.status.toLowerCase() === "accepted"
                              )
                              .map((data) => data.count).length > 0
                              ? (envelopeCardCounts?.data || [])
                                  .filter(
                                    (data) =>
                                      data.status.toLowerCase() === "accepted"
                                  )
                                  .map((data) => data.count)
                              : 0}
                          </h3>
                          <h4 className="box-card-title">Accepted Filings</h4>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Item className="remove-item-css">
                        <div className="box-card rejected-card red-pastel-bg mb-4 text-center">
                          <h3>
                            {(envelopeCardCounts?.data || [])
                              .filter(
                                (data) =>
                                  data.status.toLowerCase() === "rejected"
                              )
                              .map((data) => data.count).length > 0
                              ? (envelopeCardCounts?.data || [])
                                  .filter(
                                    (data) =>
                                      data.status.toLowerCase() === "rejected"
                                  )
                                  .map((data) => data.count)
                              : 0}
                          </h3>

                          <h4 className="box-card-title">Rejected Filings</h4>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Item className="remove-item-css">
                        <div className="box-card pending_filing_card theme-yellow-bg mb-4 text-center">
                          <h3>
                            {(envelopeCardCounts?.data || [])
                              .filter(
                                (data) =>
                                  data.status.toLowerCase() === "pending"
                              )
                              .map((data) => data.count).length > 0
                              ? (envelopeCardCounts?.data || [])
                                  .filter(
                                    (data) =>
                                      data.status.toLowerCase() === "pending"
                                  )
                                  .map((data) => data.count)
                              : 0}
                          </h3>

                          <h4 className="box-card-title">Pending Filings</h4>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Item>
          </Grid>
        </div>

        <div className="filter-wrapper lighter-blue-bg ps-4 pe-4 pb-1 mt-xs-3">
          <Grid container spacing={2} paddingY={2} alignItems={"end"}>
            <Grid item xs={12} md={3} className="mb-3">
              <TextField
                className="w-100"
                label="Case or Envelope"
                placeholder="Envelope#"
                variant="standard"
                value={envelopeId}
                onChange={onEnvelopeIdChange}
              />
            </Grid>
            {getIsAdmin() && (
              <Grid item xs={12} md={3} className="mb-3">
                <TextField
                  className="w-100"
                  label="UserName"
                  placeholder="UserName"
                  variant="standard"
                />
              </Grid>
            )}
            {!getIsAdmin() && (
              <Grid item xs={12} md={3} className="mb-3">
                <TextField
                  className="w-100"
                  select
                  InputLabelProps={{
                    shrink: true,
                    htmlFor: "filter_payment_report_id",
                  }}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  inputProps={{
                    id: "filter_payment_report_id",
                  }}
                  label="Payment Report"
                  variant="standard"
                  value={firmId}
                  onChange={onFirmIdChange}
                >
                  {paymentTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12} md={3} className="mb-3">
              <DatePicker
                className="w-100 date-picker"
                label="From"
                minDate={minDate}
                maxDate={endDate ? endDate : undefined}
                value={startDate}
                onChange={setStartDate}
              />
            </Grid>
            <Grid item xs={12} md={3} className="mb-3">
              <DatePicker
                className="w-100 date-picker"
                label="To"
                minDate={startDate ? startDate : minDate}
                maxDate={maxDate}
                value={endDate}
                onChange={setEndDate}
              />
            </Grid>
            <Grid item xs={12} md={3} className="mb-3">
              <TextField
                className="w-100"
                select
                InputLabelProps={{
                  shrink: true,
                  htmlFor: "filter_all_locations_id",
                }}
                SelectProps={{
                  displayEmpty: true,
                }}
                inputProps={{
                  id: "filter_all_locations_id",
                }}
                label="All Locations"
                variant="standard"
                value={locationId}
                onChange={onLocationChange}
              >
                <MenuItem value="-1">Select Location</MenuItem>
                {locations?.map((location) => (
                  <MenuItem
                    key={location.efm_Location_Code}
                    value={location.efm_Location_Code}
                  >
                    {location.efm_Location_Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3} className="mb-3">
              <TextField
                className="w-100"
                select
                InputLabelProps={{
                  shrink: true,
                  htmlFor: "filter_filing_status_id",
                }}
                inputProps={{
                  id: "filter_filing_status_id",
                }}
                SelectProps={{
                  displayEmpty: true,
                }}
                label="Filing Status"
                variant="standard"
                value={filingStatus}
                onChange={onFilingStatusChange}
              >
                <MenuItem value="-1">All</MenuItem>
                <MenuItem value="accepted">Accepted</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
                <MenuItem value="reviewed">Reviewed</MenuItem>
                <MenuItem value="submitted">Submitted</MenuItem>
                <MenuItem value="submitting">Submitting</MenuItem>
                <MenuItem value="failed">Submission Failed</MenuItem>
                <MenuItem value="under-review">Under Review</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={3} className="mb-3">
              <TextField
                className="w-100"
                select
                InputLabelProps={{
                  shrink: true,
                  htmlFor: "filter_payment_status_id",
                }}
                SelectProps={{
                  displayEmpty: true,
                }}
                inputProps={{
                  id: "filter_payment_status_id",
                }}
                label="Payment Status"
                variant="standard"
                value={paymentStatus}
                onChange={onPaymentStatusChange}
              >
                <MenuItem value="-1">All</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
                <MenuItem value="unpaid">Unpaid</MenuItem>
              </TextField>
            </Grid>
            {getIsAdmin() && (
              <Grid item xs={12} md={3} className="mb-3">
                <TextField
                  className="w-100"
                  select
                  InputLabelProps={{
                    shrink: true,
                    htmlFor: "filter_i2files_fees",
                  }}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  inputProps={{
                    id: "filter_i2files_fees",
                  }}
                  label="I2file Fees"
                  variant="standard"
                  value={i2filefees}
                  onChange={onI2fileFeeChange}
                >
                  <MenuItem value="-1">All</MenuItem>
                  <MenuItem value="YES">YES</MenuItem>
                  <MenuItem value="NO">NO</MenuItem>
                </TextField>
              </Grid>
            )}
            {getIsAdmin() && (
              <Grid item xs={12} md={3} className="mb-3">
                <TextField
                  className="w-100"
                  select
                  InputLabelProps={{
                    shrink: true,
                    htmlFor: "filter_i2file_fees_received",
                  }}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  inputProps={{
                    id: "filter_i2file_fees_received",
                  }}
                  label="I2file Fees Received"
                  variant="standard"
                  value={i2filefeesReceived}
                  onChange={onI2fileFeesReceived}
                >
                  <MenuItem value="-1">All</MenuItem>
                  <MenuItem value="YES">YES</MenuItem>
                  <MenuItem value="NO">NO</MenuItem>
                </TextField>
              </Grid>
            )}
            <Grid item xs={12} md={3} className="text-right mb-3">
              <Button
                className="btn primary-button"
                variant="contained"
                fullWidth
                onClick={onSearch}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={2} paddingY={2} marginTop={1}>
          <Grid item xs={12} md={5} lg={4} xl={3}>
            <Button
              className="btn button-outline-secondary"
              variant="contained"
              startIcon={<TextSnippetIcon />}
              onClick={hideEnvelope}
            >
              {isEnvelopeHide
                ? "HIDE ENVELOPE DETAILS"
                : "SHOW ENVELOPE DETAILS"}
            </Button>
          </Grid>
          <Grid item xs={12} md={7} lg={8} xl={9}>
            {isEnvelopeHide && (envelopePayments?.data?.length || 0) > 0 && (
              <Box>
                <Box className="text-right">
                  <Button
                    className="btn button-outline-secondary export-csv"
                    variant="contained"
                    startIcon={<TextSnippetIcon />}
                  >
                    <ExportCsv
                      data={csvData || []}
                      fileTitle="Transacton_Report.csv"
                      totalFilingCount={totalFilingCount.toString()}
                      totalFilingFees={totalFilingFee.toFixed(2).toString()}
                      totalI2FileFees={totalI2fileFee.toString()}
                    ></ExportCsv>
                  </Button>
                  <Button
                    className="btn button-outline-secondary ms-3"
                    variant="contained"
                    startIcon={<PictureAsPdfIcon />}
                    onClick={onPdfDownload}
                  >
                    Export As PDF
                  </Button>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>

        {isEnvelopeHide && (
          <Box>
            <div className="themeDataTable">
              <GridTable
                // isLoading={envelopePayments.isLoading}
                columns={columns}
                rows={envelopePayments.data}
                hideFooter={false}
                getRowId={getRowId}
              />
            </div>
          </Box>
        )}

        <Box>
          <Typography variant="h5" className="mt-4 mb-2">
            Payment Summary
            <span className="last-days">
              {!showPaymentSummaryNotes ? "(Last 30 Days)" : ""}
            </span>
          </Typography>
          <Grid container>
            <Grid xs={12} md={6}>
              <div className="payment-summary">
                <Grid container item className="mb-2">
                  <Grid item xs={6} sm={6}>
                    <Item className="remove-item-css">
                      <label>Total Filing Count</label>
                    </Item>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Item className="remove-item-css text-right">
                      <span className="float-left">:</span>
                      <label>{totalFilingCount.toFixed(2)}</label>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container item className="mb-2">
                  <Grid item xs={6} sm={6}>
                    <Item className="remove-item-css">
                      <label>Total Filing Fees</label>
                    </Item>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Item className="remove-item-css text-right">
                      <span className="float-left">:</span>
                      <label>${totalFilingFee.toFixed(2)}</label>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container item className="mb-2">
                  <Grid item xs={6} sm={6}>
                    <Item className="remove-item-css">
                      <label>Total I2File Fees</label>
                    </Item>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Item className="remove-item-css text-right">
                      <span className="float-left">:</span>
                      <label>${totalI2fileFee}</label>
                    </Item>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Box>

        {showPaymentSummaryNotes ? (
          <Typography
            variant="h6"
            fontSize={14}
            className="red-error"
            color="error"
            paddingTop={1}
          >
            {" "}
            Note : Payment summary displays amount as per the current search
            criteria.
          </Typography>
        ) : (
          ""
        )}
      </div>
    </Page>
  );
};

export default TransactionReport;
