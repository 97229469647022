import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_LOCATION_NAME_SUFFIXES } from "../../URL";

interface ISuffix {
  efmNamesuffixCode: string;
  efmNamesuffixName: string;
  locationNamesuffixId: string;
}

const getLocationNameSuffixes = async (locationCode?: string) => {
  if (!locationCode) return [];

  const response = await http.post<ISuffix[]>(GET_LOCATION_NAME_SUFFIXES, {
    efmLocationCode: locationCode,
  });
  return response.data;
};

const useLocationNameSuffixes = (locationCode?: string) =>
  useQuery<ISuffix[]>({
    queryKey: ["location-name-suffixes", locationCode],
    queryFn: () => getLocationNameSuffixes(locationCode),
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default useLocationNameSuffixes;
