import { FC, useContext, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Dialog from "../../../components/dialog";
import Loader from "../../../components/loader";
import useAttachedCases from "../../../apis/serviceContacts/useAttachedCases";
import { NotificationContext } from "../../../context/NotificationContext";
import { IServiceContact } from "../../../interfaces";

interface ServiceContactDetailsProps {
  isOpen: boolean;
  serviceContact?: IServiceContact;
  onClose: () => void;
}

const ServiceContactDetails: FC<ServiceContactDetailsProps> = ({
  isOpen,
  serviceContact,
  onClose,
}) => {
  const attachedCases = useAttachedCases(serviceContact?.serviceContactID);
  const { createNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (attachedCases.isError) {
      createNotification({
        title: "Service Contact Notification",
        type: "error",
        message:
          attachedCases.error.response?.data?.userMessage ||
          "Something went wrong while fecthing attached Case List",
      });
    }
    // eslint-disable-next-line
  }, [attachedCases.isError]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={`Attached Cases for : ${serviceContact?.firstName} ${serviceContact?.lastName}`}
      maxWidth="md"
    >
      <div className="mt-2 attached-cases-list">
        {isOpen && attachedCases.isLoading && <Loader />}

        {attachedCases.data?.map((attachedCase) => (
          <Box
            key={attachedCase.caseNumber}
            sx={{
              border: 1,
              borderColor: "lightgray",
            }}
            padding={1}
            borderRadius={2}
          >
            <Typography>
              <b>
                {attachedCase.caseNumber
                  ? `Case #${attachedCase.caseNumber} - `
                  : ""}
              </b>{" "}
              {`${attachedCase.description}`}
            </Typography>
          </Box>
        ))}
      </div>
    </Dialog>
  );
};

export default ServiceContactDetails;
