import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { IAxisErrorResponse, ICountyAdmin } from "../../../interfaces";
import { COUNTY_ADMIN_UPDATE } from "../../URL";

const enableDisableCountyAdmin = async (enableDisable: ICountyAdmin) => {
  await http.post(COUNTY_ADMIN_UPDATE, {
    ...enableDisable,
  });
};

const useEnableDisableCountyAdmin = () =>
  useMutation<void, IAxisErrorResponse, ICountyAdmin>({
    mutationKey: ["enable-disable-countyAdmin"],
    mutationFn: enableDisableCountyAdmin,
  });

export default useEnableDisableCountyAdmin;
