import { FC, Fragment, useMemo } from "react";
import get from "lodash.get";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import Dialog from "../../../components/dialog";
import { IServiceContact } from "../../../interfaces";

interface ServiceContactDetailsProps {
  isOpen: boolean;
  serviceContact?: IServiceContact;
  onClose: () => void;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ServiceContactDetails: FC<ServiceContactDetailsProps> = ({
  isOpen,
  serviceContact,
  onClose,
}) => {
  const detailsList = useMemo(
    () => [
      {
        label: "First Name",
        key: "firstName",
      },
      {
        label: "Middle Name",
        key: "middleName",
      },
      {
        label: "Last Name",
        key: "lastName",
      },
      {
        label: "Email Address",
        key: "email",
      },
      {
        label: "Administrative Copy",
        key: "administrativeCopy",
      },
      {
        label: "Country",
        key: "address.country",
      },
      {
        label: "Address Line 1",
        key: "address.addressLine1",
      },
      {
        label: "Address Line 2",
        key: "address.addressLine2",
      },
      {
        label: "City",
        key: "address.city",
      },
      {
        label: "State",
        key: "address.state",
      },

      {
        label: "Zip Code",
        key: "address.zipCode",
      },
      {
        label: "Phone",
        key: "phoneNumber",
      },
    ],

    []
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Service Contact Details"
      maxWidth="lg"
    >
      <Grid container spacing={2}>
        {detailsList.map((item) => (
          <Fragment key={item.key}>
            <Grid item xs={12} sm={6} lg={4} className="mb-2">
              <Item className="remove-item-css">
                <Typography className="label-print-name remove-item-css">
                  {item.label}
                </Typography>
                <Typography className="label-print-field remove-item-css">
                  {get(serviceContact, item.key)}
                </Typography>
              </Item>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Dialog>
  );
};

export default ServiceContactDetails;
