import { FC } from "react";
import Button from "@mui/material/Button";

import Dialog from "../../../../components/dialog";
import useServiceContact from "../../../../apis/serviceContacts/useServiceContact";
import Loader from "../../../../components/loader";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

interface ServiceContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceContactId: string;
}
const ServiceContactDetailsModal: FC<ServiceContactModalProps> = ({
  isOpen,
  onClose,
  serviceContactId,
}) => {
  const {
    data: serviceContatDetailsData,
    isLoading: serviceContactLoading,
    isFetching: serviceContcatFetching,
  } = useServiceContact(serviceContactId);

  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
  }));

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose.bind(false)}
      title={`Service Contact Details: ${
        serviceContatDetailsData?.firstName || ""
      } ${serviceContatDetailsData?.lastName || ""} `}
      maxWidth="sm"
    >
      {serviceContactLoading || serviceContcatFetching ? (
        <Loader />
      ) : (
        <div className="popup-content-slide">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} className="mb-2">
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name"> First Name</div>
                  <div className="label-print-field">
                    {serviceContatDetailsData?.firstName}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name"> Middle Name</div>
                  <div className="label-print-field">
                    {serviceContatDetailsData?.middleName || ""}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name"> Last Name</div>
                  <div className="label-print-field">
                    {serviceContatDetailsData?.lastName || ""}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name"> Address Line 1</div>
                  <div className="label-print-field">
                    {serviceContatDetailsData?.address?.addressLine2 || ""}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name">City</div>
                  <div className="label-print-field">
                    {serviceContatDetailsData?.address?.city || ""}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name">State</div>
                  <div className="label-print-field">
                    {serviceContatDetailsData?.address?.state || ""}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name">ZipCode</div>
                  <div className="label-print-field">
                    {serviceContatDetailsData?.address?.zipCode || ""}
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="mb-2">
                <Item className="remove-item-css">
                  <div className="label-print-name">Email</div>
                  <div className="label-print-field">
                    {serviceContatDetailsData?.email || ""}
                  </div>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
      <div className="text-right mt-3">
        <Button
          className="btn button-outline-secondary me-2"
          variant="contained"
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Dialog>
  );
};

export default ServiceContactDetailsModal;
