import { FC } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import Dialog from "../../../components/dialog";
import { IPaymentAccount } from "../../../interfaces";

interface CardDetailsModalProps {
  isOpen: boolean;
  paymentAccount?: IPaymentAccount;
  onClose: () => void;
}

const CardDetailsModal: FC<CardDetailsModalProps> = ({
  isOpen,
  paymentAccount,
  onClose,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Card Details"
      maxWidth="sm"
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="cardtype_id">Card Type</label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value={paymentAccount?.cardType?.value}
            disabled
            id="cardtype_id"
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="cardnumber_id">Card Number</label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value={`************${paymentAccount?.cardLast4}`}
            disabled
            id="cardnumber_id"
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label>Expires</label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <label htmlFor="month_id">Month</label>
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                className="w-100 m-0"
                variant="standard"
                value={paymentAccount?.cardMonth?.value}
                disabled
                id="month_id"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <label htmlFor="year_id">Year</label>
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                className="w-100 m-0"
                variant="standard"
                value={paymentAccount?.cardYear?.value}
                disabled
                id="year_id"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="nameoncard_id">Name on Card</label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value={paymentAccount?.cardHolderName?.value}
            disabled
            id="nameoncard_id"
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="billing_address1_id">
              Billing Account Address Line 1
            </label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value="********************"
            disabled
            id="billing_address1_id"
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="billing_address2_id">
              Billing Account Address Line 2
            </label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value="********************"
            disabled
            id="billing_address2_id"
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="city_id">City</label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value="********"
            disabled
            id="city_id"
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="state_id">State</label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value="********"
            disabled
            id="state_id"
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <label htmlFor="zip_id">Zip</label>
            <Typography>:</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="w-100 m-0"
            variant="standard"
            value="********"
            disabled
            id="zip_id"
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CardDetailsModal;
