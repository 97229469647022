import { FC, useCallback } from "react";
import Reaptcha from "react-google-recaptcha";

import Dialog from "../../../../components/dialog";

interface ICaptchaVerification {
  isOpen: boolean;
  onClose: () => void;
  onVerify: (captchaValue: string) => void;
}

const CaptchaVerification: FC<ICaptchaVerification> = ({
  isOpen,
  onClose,
  onVerify,
}) => {
  const onChange = useCallback(
    (value: string | null) => {
      value && onVerify(value);
    },
    [onVerify]
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Captcha Verification"
      maxWidth="sm"
    >
      <Reaptcha
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
        onChange={onChange}
      />
    </Dialog>
  );
};

export default CaptchaVerification;
