import { FC } from "react";
import { CSVLink } from "react-csv";

import { IIransaction } from "../../../interfaces";

const headers = [
  { label: "Envelope # - Filing", key: "transactionid" },
  { label: "Location", key: "location" },
  { label: "Case #", key: "caseNumber" },
  { label: "Filer Name", key: "fullName" },
  { label: "Filer Email", key: "userId" },
  { label: "Filing Status", key: "filterFilingstatus" },
  { label: "Accepted Date", key: "efmSyncDate" },
  { label: "Envelope Total Fee", key: "fees" },
  { label: "Payment Service Fee", key: "convenienceFee" },
  { label: "I2File Fee", key: "amount" },
];
interface IExportCSV {
  data: IIransaction[];
  fileTitle: string;
  totalI2FileFees: string;
  totalFilingFees: string;
  totalFilingCount: string;
}

const ExportCsv: FC<IExportCSV> = ({
  data,
  fileTitle,
  totalI2FileFees,
  totalFilingFees,
  totalFilingCount,
}) => {
  const summaryRows: IIransaction[] = [
    {
      transactionid: "Payment Summary :",
      userId: "",
      fees: "",
      convenienceFee: "",
      location: "",
      efmLocationId: "",
      efmEnvelopeId: "",
      caseNumber: "",
      efmFilingStatus: "",
      efmSyncDate: "",
      amount: "",
      efmFilingId: "",
      startDate: "",
      endDate: "",
      efmFirmId: "",
      fullName: "",
      filterFilingstatus: "",
      filterPaymentStatus: "",
      isI2FAdminUser: "",
      efmFilingCode: "",
      category: "",
      efmFilingTypeName: "",
      i2FileFeeStatus: "",
      i2FileFeeReceivedStatus: "",
      efmUserId: "",
    },
    {
      transactionid: "Total Filing Count:",
      userId: "",
      fees: "",
      convenienceFee: "",
      location: totalFilingCount,
      efmLocationId: "",
      efmEnvelopeId: "",
      caseNumber: "",
      efmFilingStatus: "",
      efmSyncDate: "",
      amount: "",
      efmFilingId: "",
      startDate: "",
      endDate: "",
      efmFirmId: "",
      fullName: "",
      filterFilingstatus: "",
      filterPaymentStatus: "",
      isI2FAdminUser: "",
      efmFilingCode: "",
      category: "",
      efmFilingTypeName: "",
      i2FileFeeStatus: "",
      i2FileFeeReceivedStatus: "",
      efmUserId: "",
    },
    {
      transactionid: "Total Filing Fees :",
      userId: "",
      fees: "",
      convenienceFee: "",
      location: `$${totalFilingFees}`,
      efmLocationId: "",
      efmEnvelopeId: "",
      caseNumber: "",
      efmFilingStatus: "",
      efmSyncDate: "",
      amount: "",
      efmFilingId: "",
      startDate: "",
      endDate: "",
      efmFirmId: "",
      fullName: "",
      filterFilingstatus: "",
      filterPaymentStatus: "",
      isI2FAdminUser: "",
      efmFilingCode: "",
      category: "",
      efmFilingTypeName: "",
      i2FileFeeStatus: "",
      i2FileFeeReceivedStatus: "",
      efmUserId: "",
    },
    {
      transactionid: "Total I2File Fees :",
      userId: "",
      fees: "",
      convenienceFee: "",
      location: `$${totalI2FileFees}`,
      efmLocationId: "",
      efmEnvelopeId: "",
      caseNumber: "",
      efmFilingStatus: "",
      efmSyncDate: "",
      amount: "",
      efmFilingId: "",
      startDate: "",
      endDate: "",
      efmFirmId: "",
      fullName: "",
      filterFilingstatus: "",
      filterPaymentStatus: "",
      isI2FAdminUser: "",
      efmFilingCode: "",
      category: "",
      efmFilingTypeName: "",
      i2FileFeeStatus: "",
      i2FileFeeReceivedStatus: "",
      efmUserId: "",
    },
  ];

  return (
    <CSVLink
      data={[...data, ...summaryRows]}
      headers={headers}
      filename={fileTitle}
    >
      Export As CSV
    </CSVLink>
  );
};

export default ExportCsv;
