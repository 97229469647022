import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/Button";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import InputAdornment from "@mui/material/InputAdornment";
import KeyIcon from "@mui/icons-material/Key";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import useLogin from "../../../../apis/auth/useLogin/useLogin";
import ForgotPasswordModal from "../ForgotPasswordModal/ForgotPasswordModal";
import ResendActivationEmail from "../ResendActivationEmail/ResendActivationEmail";
import {
  clearLocalStorage,
  setAccessToken,
  setAllowanceChargeAmountValue,
  setCloseFillingForLocation,
  setIndividualUser,
  setIsFirmAdmin,
  setIsLinked,
  setLocationsExcludedFromI2fileFee,
} from "../../../../utills";

interface LoginFormProps {
  isLoginModalOpen: boolean;
  onModalClose: () => void;
}

const LoginForm: FC<LoginFormProps> = ({ isLoginModalOpen, onModalClose }) => {
  const signInWrapperRef = useRef<HTMLDivElement>(null);
  const login = useLogin();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);
  const [
    isResendActivationEmailModalOpen,
    setIsResendActivationEmailModalOpen,
  ] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: { emailAddress: "", password: "" },
  });

  const onSubmit = useCallback(
    async (data: { emailAddress: string; password: string }) => {
      login.mutate({
        emailId: data.emailAddress.trim(),
        password: data.password.trim(),
      });
    },
    [login]
  );

  const openForgotPasswordModal = useCallback(() => {
    setIsForgotPasswordModalOpen(true);
  }, []);

  const closeForgotPasswordModal = useCallback(() => {
    setIsForgotPasswordModalOpen(false);
  }, []);

  const openResendActivationEmailModal = useCallback(() => {
    setIsResendActivationEmailModalOpen(true);
  }, []);

  const closeResendActivationEmailModal = useCallback(() => {
    setIsResendActivationEmailModalOpen(false);
  }, []);

  const clearErrorMessage = useCallback(() => {
    setErrorMessage("");
  }, []);

  const mouseDownListener = useCallback(
    (event: any) => {
      if (
        signInWrapperRef.current &&
        !signInWrapperRef.current.contains(event.target as Node)
      ) {
        onModalClose();
      }
    },
    [onModalClose]
  );

  const keyDownListener = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onModalClose();
      }
    },
    [onModalClose]
  );

  useEffect(() => {
    if (login.isError && login.error?.response?.data?.userMessage) {
      setErrorMessage(login.error?.response?.data?.userMessage);
    }
    // eslint-disable-next-line
  }, [login.isError]);

  useEffect(() => {
    if (login.isSuccess) {
      const user = login.data;

      clearLocalStorage();
      setAccessToken(user.accessToken);
      setIsFirmAdmin(
        user.roles?.findIndex((role) => role === "FIRM_ADMIN") !== -1
      );
      setIndividualUser(user.individual.toString());
      setIsLinked(user.linked);
      setCloseFillingForLocation(user.closeFillingForLocation);
      setAllowanceChargeAmountValue(user.allowanceChargeAmountValue);
      setLocationsExcludedFromI2fileFee(user.locationsExcludedFromI2fileFee);

      reset();
      onModalClose();
      navigate("/dashboard");
    }
    // eslint-disable-next-line
  }, [login.isSuccess]);

  useEffect(() => {
    document.addEventListener("mousedown", mouseDownListener);
    document.addEventListener("keydown", keyDownListener);

    return () => {
      document.removeEventListener("mousedown", mouseDownListener);
      document.removeEventListener("keydown", keyDownListener);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className={
          isLoginModalOpen ? "login_box login-box-show" : "login_box d-none"
        }
        ref={signInWrapperRef}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div className="filed-slide mb-4">
            <Controller
              name="emailAddress"
              control={control}
              rules={{
                required: "Error: Email is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address.",
                },
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  className="w-100 m-0"
                  id={field.name}
                  autoFocus
                  inputRef={ref}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Your eFileIL email address"
                  label="eFileIL email address"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <MarkunreadIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="filed-slide">
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Error: Password is required.",
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  id={field.name}
                  inputRef={ref}
                  {...field}
                  onBlur={() => {
                    field.onChange(field.value?.trim());
                    field.onBlur();
                  }}
                  placeholder="Your ODYSSEY efileIL Password"
                  className="w-100 m-0"
                  label="Your eFileIL Password"
                  variant="standard"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  type="password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="mt-4 mb-4 ">
            <div className="forgot-link justify-content-between d-flex">
              <span
                role="button"
                className="theme-grey cursor-pointer"
                onClick={openResendActivationEmailModal}
              >
                Resend Activation Email?
              </span>
              <span
                role="button"
                className="theme-grey cursor-pointer"
                onClick={openForgotPasswordModal}
              >
                Forgot Password?
              </span>
            </div>
          </div>
          <div className="filed-slide text-center">
            <IconButton
              className="btn primary-button"
              type="submit"
              disabled={login.isLoading}
            >
              {login.isLoading ? (
                <CircularProgress
                  sx={{
                    height: "25px !important",
                    width: "25px !important",
                    "--CircularProgress-size": "8px",
                    "--CircularProgress-trackThickness": "1px",
                    "--CircularProgress-progressThickness": "1px",
                  }}
                  color="inherit"
                />
              ) : (
                "Login"
              )}
            </IconButton>
          </div>
        </form>
      </div>

      <ForgotPasswordModal
        isModalOpen={isForgotPasswordModalOpen}
        closeModal={closeForgotPasswordModal}
      />
      <ResendActivationEmail
        isModalOpen={isResendActivationEmailModalOpen}
        closeModal={closeResendActivationEmailModal}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!errorMessage}
        autoHideDuration={10000}
        onClose={clearErrorMessage}
      >
        <Alert onClose={clearErrorMessage} severity="error" variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoginForm;
