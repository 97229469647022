import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { GET_NONINDEXED_FEE_CALCULATION } from "../../URL";
import { IFeeCalculationRequest } from "../../../interfaces";

interface IFeeResponse {}

interface IAxisErrorResponse {
  response?: {
    data?: {
      allowanceCharge: [];
      messageStatus?: {
        messageContentError: IMessageContentErrorItem[];
      };
    };
  };
}

interface IMessageContentErrorItem {
  errorDescription?: {
    errorCodeDescriptionText?: {
      value?: string;
    };
  };
}

const getNonIndexedFeeCalculation = async (filing: IFeeCalculationRequest) => {
  const response = await http.post<IFeeResponse>(
    GET_NONINDEXED_FEE_CALCULATION,
    filing
  );

  return response?.data;
};

const useNonIndexedFeeCalculation = () =>
  useMutation<any, IAxisErrorResponse, IFeeCalculationRequest>({
    mutationKey: ["nonindexed-fee-calculation"],
    mutationFn: getNonIndexedFeeCalculation,
  });

export default useNonIndexedFeeCalculation;
