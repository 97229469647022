import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import format from "date-fns/format";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { GridRenderCellParams } from "@mui/x-data-grid/models/params/gridCellParams";

import Page from "../../../components/page";
import GridTable from "../../../components/grid";
import Loader from "../../../components/loader/Loader";
import queryClient from "../../../queryClient";
import useDownloadExcel from "../../../apis/payments/useDownloadExcel";
import useImportTylerPayment from "../../../apis/admin/useImportPayement";
import useImportPaymentList from "../../../apis/admin/useImportPayementList";
import { NotificationContext } from "../../../context/NotificationContext";
import { GET_IMPORT_TYLER_PAYMENT_LIST } from "../../../apis/URL";

const ImportPayment = () => {
  const importPayment = useImportPaymentList();
  const downloadExcel = useDownloadExcel();
  const importTylerPayment = useImportTylerPayment();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const { createNotification } = useContext(NotificationContext);

  const downloadFile = useCallback(
    (paymentFileId: string | null, fileName: string | null) => {
      if (paymentFileId !== null && paymentFileId !== "0") {
        downloadExcel.mutate(paymentFileId);
      }
    },
    [downloadExcel]
  );

  const onFileSelect = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files?.length) return;
    setUploadedFile(event.target.files[0]);
  }, []);

  const onUploadDoc = useCallback(() => {
    if (!uploadedFile) return;
    importTylerPayment.mutate(uploadedFile);
  }, [uploadedFile, importTylerPayment]);

  const onUploadDocRemove = useCallback(() => {
    setUploadedFile(null);
  }, []);

  useEffect(() => {
    if (importTylerPayment.isSuccess) {
      setUploadedFile(null);
      queryClient.refetchQueries(["grid", GET_IMPORT_TYLER_PAYMENT_LIST]);
    }
  }, [importTylerPayment.isSuccess]);

  useEffect(() => {
    if (downloadExcel.isSuccess && downloadExcel.data) {
      const str = downloadExcel.data;
      const blob = new Blob([str], { type: "application/.xlsx" });
      const fileURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = fileURL;
      a.download = "binaryLocationURI.xlsx";
      a.click();
      createNotification({
        title: "Import Payment Notification",
        type: "success",
        message: "Excel downloaded successfully",
      });
    }
    // eslint-disable-next-line
  }, [downloadExcel.isSuccess, createNotification]);

  useEffect(() => {
    if (importTylerPayment.isSuccess) {
      createNotification({
        title: "Import Payment Notification",
        type: "success",
        message: "File Uploaded Successfully",
      });
    }
  }, [importTylerPayment.isSuccess, createNotification]);

  useEffect(() => {
    if (importPayment.isError) {
      createNotification({
        title: "Import Payment Notification",
        type: "error",
        message:
          importPayment.error.response?.data?.userMessage ||
          "Something went wrong while getting payments",
      });
    }
    // eslint-disable-next-line
  }, [importPayment.isError, createNotification]);

  useEffect(() => {
    if (downloadExcel.isError) {
      createNotification({
        title: "Import Payment Notification",
        type: "error",
        message:
          downloadExcel.error.response?.data?.userMessage ||
          "Something went wrong downloading file",
      });
    }
    // eslint-disable-next-line
  }, [downloadExcel.isError, createNotification]);

  useEffect(() => {
    if (importTylerPayment.isError) {
      createNotification({
        title: "Import Payment Notification",
        type: "error",
        message:
          importTylerPayment.error.response?.data?.userMessage ||
          "Something went wrong while uploading File",
      });
    }
    // eslint-disable-next-line
  }, [importTylerPayment.isError, createNotification]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "fileName",
        headerName: "File Name",
        width: 300,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "status",
        headerName: "Status",
        width: 200,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.status ? "Yes" : "No";
        },
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "uploadedOn",
        headerName: "Uploaded On",
        width: 250,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return format(
            new Date(params.row.uploadedOn),
            "MM/dd/yyyy hh:mm:ss a"
          );
        },
      },
      {
        field: "action",
        headerName: "Action",
        width: 250,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <div className="dataGridactionIcons">
              <Tooltip title={"Download"}>
                <IconButton
                  aria-label="download"
                  onClick={() =>
                    downloadFile(params.row.paymentFileId, params.row.fileName)
                  }
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [downloadFile]
  );

  const getRowId = useCallback((row: any) => row.paymentFileId, []);

  return (
    <Page>
      {(importTylerPayment.isLoading || importPayment.isLoading) && <Loader />}

      <div className="content-wrapper">
        <h2 className="dash-title-h2 mb-4">Import Payment</h2>
        <Box display="flex" justifyContent="flex-end">
          <Button
            className="btn button-outline-secondary me-3"
            variant="contained"
            component="label"
            startIcon={<AddIcon />}
            disabled={false}
          >
            Click To Browse
            <input
              hidden
              accept=".xls, .xlsx"
              multiple
              type="file"
              value=""
              onChange={onFileSelect}
            />
          </Button>

          <Button
            className="btn button-outline-secondary mar-0 "
            variant="contained"
            component="label"
            size="large"
            startIcon={<UploadIcon />}
            onClick={onUploadDoc}
            disabled={uploadedFile ? false : true}
          >
            Upload
          </Button>

          <Button
            size="large"
            variant="outlined"
            className="btn button-outline-secondary ms-3"
            startIcon={<CloseIcon />}
            disabled={uploadedFile ? false : true}
            onClick={onUploadDocRemove}
          >
            Cancel
          </Button>
        </Box>
        {uploadedFile && (
          <span
            key={uploadedFile?.name}
            className="upload-error-msg d-block d-flex align-items-center justify-content-between mb-4"
          >
            {uploadedFile?.name}
            <CloseIcon className="close-error" onClick={onUploadDocRemove} />
          </span>
        )}
        <div className="themeDataTable mt-3">
          <Box sx={{ height: "auto", overflow: "auto" }}>
            <GridTable
              url={GET_IMPORT_TYLER_PAYMENT_LIST}
              columns={columns}
              rows={importPayment.data}
              hideFooter={false}
              getRowId={getRowId}
            />
          </Box>
        </div>
      </div>
    </Page>
  );
};

export default ImportPayment;
