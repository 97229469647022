import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { GET_FEE_CALCULATION } from "../../URL";
import { IFeeCalculationRequest } from "../../../interfaces";


interface IFeeResponse {}

interface IAxisErrorResponse {
  response?: {
  data?: {
  allowanceCharge:[];
  messageStatus?: {
         messageContentError:IMessageContentErrorItem[];
      };
    };
  };
}

interface IMessageContentErrorItem{
  errorDescription?:{
    errorCodeDescriptionText?:{
      value?:string;
    };
  }
}

const getFeeCalculation = async (filing: IFeeCalculationRequest) => {
  const response = await http.post<IFeeResponse>(GET_FEE_CALCULATION, filing);

  return response?.data;
};

const useFeeCalculation = () =>
  useMutation<any, IAxisErrorResponse, IFeeCalculationRequest>({
    mutationKey: ["fee-calculation"],
    mutationFn: getFeeCalculation,
  });

export default useFeeCalculation;
