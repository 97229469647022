import { useMutation } from "@tanstack/react-query";

import http from "../../../http";

interface ILoginBody {
  emailId: string;
  password: string;
}

interface ILoginResponse {
  adminprofileid: string;
  firstName: string;
  middleName: string;
  lastName: string;
  username: string;
  accessToken: string;
  userrole: string;
  countrycode: string;
  creationDate: string;
  lastLoginRime: string;
  isacive: boolean;
}

interface IAxisResponse {
  response?: {
    data?: {
      userMessage?: string;
    };
  };
}

const adminLogin = async (fields: ILoginBody) => {
  const response = await http.post<ILoginResponse>(
    process.env.REACT_APP_USER_SERVICE_API_URL +
      "/api/user/api/loginAdminPanel",
    fields
  );
  return response.data;
};

const useAdminLogin = () =>
  useMutation<ILoginResponse, IAxisResponse, ILoginBody>({
    mutationKey: ["adminLogin"],
    mutationFn: adminLogin,
  });

export default useAdminLogin;
