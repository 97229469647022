import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { GET_ENVELOPE_CARD_COUNTS } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface ICardCount {
  status: string;
  count: string;
}

interface IFilters {
  efmEnvelopeId: string;
  efmFirmId: string;
  startDate: string;
  endDate: string;
  efmLocationId: string;
  filterFilingstatus: string;
  filterPaymentStatus: string;
  isI2FAdminUser: string;
  i2fileFeeStatus: string;
  i2fileFeeReceivedStatus: string;
  efmUserId: string;
  userId: string;
}

const getEnvelopeCardCounts = async (filters: IFilters) => {
  const response = await http.post<ICardCount[]>(
    GET_ENVELOPE_CARD_COUNTS,
    filters
  );
  return response.data;
};

const useEnvelopeCardCounts = () =>
  useMutation<ICardCount[], IAxisErrorResponse, IFilters>({
    mutationKey: ["envelope-card-counts"],
    mutationFn: getEnvelopeCardCounts,
  });

export default useEnvelopeCardCounts;
