import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { COUNTY_ADMIN_CREATE } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface ICreateCountyAdmin {
  firstname: string;
  middlename: string;
  lastname: string;
  username: string;
  countycode: string;
}

const createCountyAdmin = async (fields: ICreateCountyAdmin) => {
  const response = await http.post(COUNTY_ADMIN_CREATE, {
    ...fields,
  });

  return response.data;
};

const useCreateCountyAdmin = () =>
  useMutation<void, IAxisErrorResponse, ICreateCountyAdmin>({
    mutationKey: ["create-County-admin"],
    mutationFn: createCountyAdmin,
  });

export default useCreateCountyAdmin;
