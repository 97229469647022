import { FC, useCallback, useEffect, useState } from "react";

import FeeMultipliers from "../../feeMultipliers";
import TransferList from "../../../../../components/transferList";
import useLocationOptionalServices from "../../../../../apis/common/useLocationOptionalServices";
import {
  IOptionalService,
  IOptionalServiceType,
} from "../../../../../interfaces";
import Loader from "../../../../../components/loader";

interface IOptionalServices {
  locationCode: string;
  filingCode: string;
  disableServiceUpdate: boolean;
  selectedServices: IOptionalService[];
  updateOptionalServices: (services: IOptionalService[]) => void;
}

const OptionalServices: FC<IOptionalServices> = ({
  locationCode,
  filingCode,
  disableServiceUpdate,
  selectedServices: propsSelectedServices,
  updateOptionalServices,
}) => {
  const [selectedServices, setSelectedServices] = useState<
    IOptionalServiceType[]
  >([]);
  const [services, setServices] = useState<IOptionalServiceType[]>([]);

  const locationOptionalServices = useLocationOptionalServices(
    locationCode,
    filingCode
  );

  const onServiceSelect = useCallback(
    (ids: string[]) => {
      if (services?.length && ids.length) {
        const serviceList = services.filter(
          (service) =>
            parseInt(service.multiplier) &&
            ids.includes(service.efmOptionalserviceCode)
        );

        if (serviceList.length) setSelectedServices(serviceList);

        setServices((prevProps) =>
          prevProps.map((service) =>
            parseInt(service.multiplier) === 0 &&
            ids.includes(service.efmOptionalserviceCode)
              ? { ...service, isSelected: true }
              : { ...service }
          )
        );
      }
    },
    [services]
  );

  const onServiceUnselect = useCallback((ids: string[]) => {
    setServices((prevProps) =>
      prevProps.map((service) =>
        ids.includes(service.efmOptionalserviceCode)
          ? { ...service, isSelected: false }
          : { ...service }
      )
    );
  }, []);

  const closeFeeMultiplierModal = useCallback(() => {
    setSelectedServices([]);
  }, []);

  const onServiceQuantityUpdate = useCallback(
    (updatedServices: IOptionalServiceType[]) => {
      setServices((prevProps) =>
        prevProps.map((service) => {
          const selectedService = updatedServices.find(
            (updatedService) =>
              updatedService.efmOptionalserviceCode ===
              service.efmOptionalserviceCode
          );

          if (selectedService) {
            return {
              ...service,
              quantity: selectedService.quantity,
              isSelected: true,
            };
          }

          return { ...service };
        })
      );

      setSelectedServices([]);
    },
    []
  );

  useEffect(() => {
    if (locationOptionalServices.isSuccess) {
      setServices(
        locationOptionalServices.data.map((service) => {
          const selectedService = propsSelectedServices.find(
            (item) => item.identificationID === service.efmOptionalserviceCode
          );

          return {
            ...service,
            quantity: selectedService?.multiplier
              ? parseInt(selectedService.multiplier)
              : undefined,
            isSelected: Boolean(selectedService),
          };
        })
      );
    }
    // eslint-disable-next-line
  }, [locationOptionalServices.isSuccess]);

  useEffect(() => {
    const selectedServices = services
      .filter((service) => service.isSelected)
      .map((service) => ({
        identificationID: service.efmOptionalserviceCode,
        multiplier:
          service.multiplier === "0"
            ? null
            : service.quantity?.toString() || service.multiplier,
        efmOptionalserviceName: service.efmOptionalserviceName,
      }));

    if (
      selectedServices.length !== (propsSelectedServices?.length || 0) &&
      locationOptionalServices.isSuccess
    ) {
      updateOptionalServices(selectedServices);
    }

    if (
      locationOptionalServices.isSuccess &&
      propsSelectedServices.some((service) => !service.efmOptionalserviceName)
    ) {
      updateOptionalServices(selectedServices);
    }
    // eslint-disable-next-line
  }, [propsSelectedServices, services]);

  if (
    filingCode &&
    !locationOptionalServices.isLoading &&
    !locationOptionalServices.data?.length
  )
    return <></>;

  return (
    <>
      {locationOptionalServices.isLoading && (
        <Loader className="section-loader" />
      )}

      <TransferList
        leftTitle="Optional Service"
        key={services.length}
        options={services.map((item) => ({
          id: item.efmOptionalserviceCode,
          label: `${item.efmOptionalserviceName} (${
            item.quantity ? `${item.quantity} x ` : ""
          }$${item.fee})`,
          isSelected: item.isSelected || false,
        }))}
        disableTransfer={disableServiceUpdate}
        onSelect={onServiceSelect}
        onUnselect={onServiceUnselect}
      />

      {Boolean(selectedServices.length) && (
        <FeeMultipliers
          isOpen
          services={selectedServices}
          onServiceQuantityUpdate={onServiceQuantityUpdate}
          closeModal={closeFeeMultiplierModal}
        />
      )}
    </>
  );
};

export default OptionalServices;
