import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { SAVE_PAYMENT_ACCOUNT } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface ISavePaymentAccount {
  accountName: string;
  paymentAccountTypeCode: string;
  paymentAccountTypeCodeId: string;
}

const savePaymentAccount = async (fields: ISavePaymentAccount) => {
  await http.post(SAVE_PAYMENT_ACCOUNT, {
    ...fields,
  });
};

const useSavePaymentAccount = () =>
  useMutation<void, IAxisErrorResponse, ISavePaymentAccount>({
    mutationKey: ["save-payment-account"],
    mutationFn: savePaymentAccount,
  });

export default useSavePaymentAccount;
