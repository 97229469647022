import {
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import get from "lodash.get";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import useGrid from "./useGrid";

interface GridProps<T> {
  url?: string;
  columns: GridColDef[];
  rows?: T[];
  hideFooter?: boolean;
  searchKeys?: string[];
  showSearch?: boolean;
  addUniqueId?: boolean;
  responseListKey?: string;
  isLoading?: boolean;
  getRowId?: (row: T) => string;
  updateRowCount?: (count: number) => void;
  onRowClick?: (row: GridRowParams<any>) => void;
  action?: ReactNode;
}

const Grid: FC<GridProps<any>> = ({
  url,
  columns,
  rows = [],
  searchKeys,
  hideFooter = true,
  showSearch = false,
  addUniqueId = false,
  isLoading = false,
  responseListKey,
  getRowId,
  updateRowCount,
  onRowClick,
  action,
}) => {
  const {
    data,
    isLoading: apiIsLoading,
    isRefetching,
  } = useGrid(url, addUniqueId, responseListKey);

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [searchText, setSearchText] = useState("");

  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  }, []);

  const selectFirstRow = useCallback(
    (model: GridPaginationModel) => {
      if (!getRowId || !data?.length) return;

      setRowSelectionModel([getRowId(data[model.page * model.pageSize])]);
    },
    [data, getRowId]
  );

  const gridRows = useMemo<any[] | void>(() => {
    if (!data?.length) return;
    if (!searchText || !searchKeys?.length) return data;

    return data.filter((row) =>
      searchKeys.some((key) =>
        get(row, key)?.toLowerCase()?.includes(searchText.toLowerCase())
      )
    );
  }, [data, searchText, searchKeys]);

  useEffect(() => {
    if (!gridRows?.length) return;

    updateRowCount?.(gridRows.length);
  }, [gridRows?.length, updateRowCount]);

  useEffect(() => {
    if (data?.length && getRowId) {
      setRowSelectionModel([getRowId(data[0])]);
    }
  }, [data, getRowId]);

  return (
    <>
      {showSearch && (
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={3}
          className="grid-topbar"
        >
          <Input
            id="search-grid"
            className="material-input"
            placeholder="Filter By Search"
            value={searchText}
            onChange={onSearchChange}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            inputProps={{
              "aria-label": "Filter By Search",
            }}
          />
          <Box className="add-data-btn">{action}</Box>
        </Box>
      )}

      <Box sx={{ height: "auto", overflow: "auto" }}>
        <DataGrid
          autoHeight={true}
          sx={{
            overflow: "auto",
            ".MuiDataGrid-virtualScroller": {
              height: "auto",
              overflow: "auto",
            },
            ".MuiDataGrid-main > div:nth-child(2)": {
              overflowY: "auto !important",
              flex: "unset !important",
            },
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            // ".MuiDataGrid-cell": {
            //   paddingY: "5px",
            //   borderRight: "1px solid lightgray",
            // },
            // ".MuiDataGrid-columnHeader": {
            //   borderRight: "1px solid lightgray",
            // },
            // ".MuiDataGrid-iconButtonContainer": {
            //   visibility: "visible",
            // },
            // ".MuiDataGrid-sortIcon": {
            //   opacity: "inherit !important",
            // },
          }}
          getRowId={getRowId}
          rows={gridRows || rows}
          columns={columns}
          hideFooter={hideFooter}
          getRowHeight={() => "auto"}
          pageSizeOptions={[10, 50, 100]}
          onPaginationModelChange={selectFirstRow}
          loading={isLoading || apiIsLoading || isRefetching}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
                page: 0,
              },
            },
          }}
          slots={{
            noRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No records found
              </Stack>
            ),
          }}
          slotProps={{
            pagination: {
              labelRowsPerPage: <span id="rows_per_page">Rows per page:</span>,
              slotProps: {
                select: {
                  componentsProps: {
                    input: {
                      "aria-labelledby": "rows_per_page",
                    },
                  },
                },
              },
            },
          }}
          onRowClick={onRowClick}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
        />
      </Box>
    </>
  );
};

export default Grid;
