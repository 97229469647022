import { FC, useCallback, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import useGetReturnDate from "../../../../apis/newCase/useGetReturnDate";
import { IFeeCalculationRequest } from "../../../../interfaces";
import useSubsequentReturnDate from "../../../../apis/existingCase/useSubsequentReturnDate";

interface IReturnDate {
  isSubsequentFiling?: boolean;
  hasFilingCode: boolean;
  returnDate?: Date;
  outOfStateService?: boolean;
  isReturnDateVerified?: boolean;
  returnDateErrorMessage?: string;
  updateReturnDate: (
    returnDate?: Date,
    outOfStateService?: boolean,
    isReturnDateVerified?: boolean
  ) => void;
  getReturnDateRequest: () => IFeeCalculationRequest | undefined;
}

const ReturnDate: FC<IReturnDate> = ({
  isSubsequentFiling,
  hasFilingCode,
  returnDate,
  isReturnDateVerified,
  returnDateErrorMessage,
  updateReturnDate,
  outOfStateService,
  getReturnDateRequest,
}) => {
  const getReturnDate = useGetReturnDate();
  const getSubsequentReturnDate = useSubsequentReturnDate();

  const onVerify = useCallback(() => {
    if (getReturnDate.isLoading || getSubsequentReturnDate.isLoading) return;

    const requestObj = getReturnDateRequest();
    if (!requestObj) return;

    if (isSubsequentFiling) getSubsequentReturnDate.mutate(requestObj);
    else getReturnDate.mutate(requestObj);
  }, [
    getReturnDateRequest,
    getReturnDate,
    getSubsequentReturnDate,
    isSubsequentFiling,
  ]);

  useEffect(() => {
    if (getReturnDate.isSuccess) {
      // const returnDate =
      //   getReturnDate.data?.returnDateResponseMessage?.documentPostDate
      //     ?.dateRepresentation?.value?.value;
      
      const returnDate = getReturnDate.data?.returnDate;

      updateReturnDate(
        returnDate ? new Date(returnDate) : undefined,
        outOfStateService,
        true
      );
    }
    // eslint-disable-next-line
  }, [getReturnDate.isSuccess]);

  useEffect(() => {
    if (getSubsequentReturnDate.isSuccess) {
      // const returnDate =
      //   getSubsequentReturnDate.data?.returnDateResponseMessage
      //     ?.documentPostDate?.dateRepresentation?.value?.value;

      const returnDate = getSubsequentReturnDate.data?.returnDate;

      updateReturnDate(
        returnDate ? new Date(returnDate) : undefined,
        outOfStateService,
        true
      );
    }
    // eslint-disable-next-line
  }, [getSubsequentReturnDate.isSuccess]);

  useEffect(() => {
    if (getReturnDate.isError) {
      updateReturnDate(undefined, false, false);
    }
    // eslint-disable-next-line
  }, [getReturnDate.isError]);

  useEffect(() => {
    if (getSubsequentReturnDate.isError) {
      updateReturnDate(undefined, false, false);
    }
    // eslint-disable-next-line
  }, [getSubsequentReturnDate.isError]);

  return (
    <Box
      mt={4}
      width="50%"
      marginRight={2}
      border={1}
      borderColor="lightgray"
      position="relative"
    >
      {!hasFilingCode && (
        <div className="section-message-container">
          <label className="text-danger">Please select Filing Code.</label>
        </div>
      )}
      <Accordion className="remove-item-css" defaultExpanded>
        <AccordionSummary
          className="accordion-info-title light-grey-bg"
          expandIcon={<ExpandMoreIcon className="theme-blue accordion-arrow" />}
          sx={{
            ".MuiAccordionSummary-content": {
              display: "block",
            },
          }}
        >
          <h5 className="theme-blue f-w-bold">Return Date</h5>
          <div>
            (Click on Verify button even if return date is not selected /
            required)
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <InputLabel className="label-checkbox">
            <Checkbox
              inputProps={{
                "aria-required": true,
                "aria-label": "Out of State Service",
              }}
              checked={outOfStateService || false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateReturnDate(returnDate, event.target.checked, false);
              }}
            />
            <span>Out of State Service</span>
          </InputLabel>
          <Box display="flex" pt={2}>
            <DatePicker
              className="w-100 date-picker"
              label="Select a Return Date and Verify"
              value={returnDate || null}
              minDate={new Date()}
              onChange={(date: Date | null) => {
                updateReturnDate(
                  date ? date : undefined,
                  outOfStateService,
                  false
                );
              }}
            />

            <Box pl={4}>
              <Button
                className="btn primary-button"
                variant="contained"
                type="button"
                onClick={onVerify}
              >
                {getReturnDate.isLoading ||
                getSubsequentReturnDate.isLoading ? (
                  <CircularProgress
                    sx={{
                      height: "25px !important",
                      width: "25px !important",
                      "--CircularProgress-size": "8px",
                      "--CircularProgress-trackThickness": "1px",
                      "--CircularProgress-progressThickness": "1px",
                    }}
                    color="inherit"
                  />
                ) : (
                  "Verify"
                )}
              </Button>
            </Box>
          </Box>

          {returnDateErrorMessage && !returnDate && !isReturnDateVerified && (
            <Typography color="red" paddingTop={2}>
              {returnDateErrorMessage}
            </Typography>
          )}

          {returnDate && !isReturnDateVerified && (
            <Typography color="red" paddingTop={2}>
              Date must be verified, Please click on Verify button.
            </Typography>
          )}

          {isReturnDateVerified && (
            <Typography paddingTop={2}
              style={{
                color: returnDate ? 'green' : 'red',
              }}>
                {returnDate
                  ? "Return Date has been verified."
                  : "Return Date is not applicable."}
            </Typography>
          )}

        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ReturnDate;
