import { FC } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

interface ILoader {
  className?: string;
}

const Loader: FC<ILoader> = ({ className }) => (
  <Backdrop
    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={true}
    className={className}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);

export default Loader;
