import { FC, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

import useLocationFilingComponents from "../../../../apis/common/useLocationFilingComponents";
import { IDocumentAttachment, IUploadResponse } from "../../../../interfaces";
import Button from "@mui/material/Button";
import useUploadDocument from "../../../../apis/newCase/useUploadDocument";
import useFetchDocument from "../../../../apis/newCase/useFetchDocument/useFetchDocument";

interface IComponentSummary {
  locationCode: string;
  filingTypeCode: string;
  attachments: IDocumentAttachment[];
  draftEnvelopeId: string;
}

interface DocumentViewerProps {
  userEmail: string;
  uploadDate: string;
  filingCode: string;
  fileName: string;
}

const ComponentSummary: FC<IComponentSummary> = ({
  locationCode,
  filingTypeCode,
  attachments,
  draftEnvelopeId
}) => {
  const { data: components } = useLocationFilingComponents(
    locationCode,
    filingTypeCode
  );

  const [pdfUrl, setPdfUrl] = useState<string | "">("");
  const [attachmentToView, setAttachmentToView] = useState<IDocumentAttachment | null>(null);
  const [viewUploadedPdf, setViewUploadedPdf] = useState<boolean>(false);
  const [envelopeId, setEnvelopeId] = useState<string | "">("");

  const { data: documentData, isLoading, error } = useFetchDocument(
    (attachmentToView) ? {
      binaryLocationURI: attachmentToView?.binaryLocationURI || '',
      viewUploadedPdf: viewUploadedPdf,
      date: attachmentToView?.uploadResponse?.uploadDate || '',
      filingCode: attachmentToView?.uploadResponse?.filingCode || '',
      fileName: attachmentToView?.uploadResponse?.fileName || '',
      draftEnvelopeId: envelopeId || '',
    } : { binaryLocationURI: '', viewUploadedPdf: false, date: '', filingCode: '', fileName: '',draftEnvelopeId:'' }
  );
  
  useEffect(() => {
    if (documentData && documentData.fileUrl) {
      setPdfUrl(documentData.fileUrl);
      const pdfWindow = window.open(documentData.fileUrl, '_blank');
      if (pdfWindow) {
        pdfWindow.focus();
      }
    }
    setViewUploadedPdf(false);
  }, [documentData]);
  
  const handleViewFile = (attachment: IDocumentAttachment, draftEnvelopeId: string) => {
    setViewUploadedPdf(true); 
    setPdfUrl("");
    setEnvelopeId(draftEnvelopeId);
    setAttachmentToView(attachment);
    
  };
  

  return (
    <>
      {components?.map((component) => (
        <div className="case-dtl-row mt-3">
          <h6>{component.locationFilingcomponentName}</h6>

          <Grid container>
            {attachments
              ?.filter(
                (attachment) =>
                  attachment.binaryCategoryText ===
                  component.locationFilingcomponentCode
              )
              ?.map((attachment) => (
                <>
                  <Grid item sm={4} xs={12} className="mb-xs-3">
                    <p>
                      <span className="d-block">File Name</span>
                      <label className="d-block">
                        {attachment.binaryLocationURI}
                        <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: '#f0f0f0', color: 'black', marginLeft: '16px', padding: '4px 8px', fontSize: '12px', height: '30px' }} 
                        onClick={() => handleViewFile(attachment, draftEnvelopeId)}
                      >
                        View File
                      </Button>
                      </label>
                      
                    </p>
                  </Grid>

                <div>
              </div>
                  <Grid item sm={4} xs={12} className="mb-xs-3">
                    <p>
                      <span className="d-block">Status</span>
                      <label className="d-block">OK</label>
                    </p>
                  </Grid>
                  <Grid item sm={4} xs={12} className="mb-xs-3">
                    <p>
                      <span className="d-block">Security</span>
                      <label className="d-block">
                        {attachment.generalSecurityText}
                      </label>
                    </p>
                  </Grid>
                </>
              ))}
          </Grid>
        </div>
      ))}
    </>
  );
};

export default ComponentSummary;
