import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { GET_CASE } from "../../URL";
import { ICaseResponse } from "../../../interfaces";

interface IFilter {
  countyId?: string;
  caseTrackingId?: string;
}

interface IAxisErrorResponse {
  response?: {
    data?: {
      userMessage?: string;
    };
  };
}

const getCase = async (filters: IFilter) => {
  if (!filters.caseTrackingId) return;
  const response = await http.post<ICaseResponse>(GET_CASE, filters);
  return response.data;
};

const useGetCase = () =>
  useMutation<ICaseResponse | void, IAxisErrorResponse, IFilter>({
    mutationKey: ["getCaseDetails"],
    mutationFn: getCase,
  });

export default useGetCase;
