import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { UPLOAD_NONINDEXED_DOCUMENT } from "../../URL";
import { IAxisErrorResponse } from "../../../interfaces";

interface IUploadBody {
  filingCode: string;
  file: File;
}

interface IUploadResponse {
  status: string;
  responseObj: {
    fileContainsVirus?: string;
  };
}

const uploadNonIndexedCaseDocument = async ({
  filingCode,
  file,
}: IUploadBody) => {
  const formData = new FormData();
  formData.append("docToUpload", file, file.name);

  const response = await http.post(UPLOAD_NONINDEXED_DOCUMENT, formData, {
    params: {
      filingCode,
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

const useUploadNonIndexedCaseDocument = () =>
  useMutation<IUploadResponse, IAxisErrorResponse, IUploadBody>({
    mutationKey: ["upload-nonindexed-document"],
    mutationFn: uploadNonIndexedCaseDocument,
  });

export default useUploadNonIndexedCaseDocument;
