import { useMutation } from "@tanstack/react-query";

import http from "../../../http";
import { DELETE_DRAFT } from "../../URL";

interface IAxisResponse {
  error?: {
    errorCode?: string;
    errorText?: string;
  };
}

interface IAxisErrorResponse {
  response?: IAxisResponse;
}

const deleteDraft = async (transactionId: number) => {
  const response = await http.post<IAxisResponse>(DELETE_DRAFT, {
    transactionid: transactionId,
  });

  return response?.data.error?.errorText;
};

const useDeleteDraft = () =>
  useMutation<string | undefined, IAxisErrorResponse, number>({
    mutationKey: ["delete-draft"],
    mutationFn: deleteDraft,
  });

export default useDeleteDraft;
