import { FC, useCallback, useContext, useEffect } from "react";

import DeleteDialog from "../../../components/DeleteDialog";
import useDeleteServiceContact from "../../../apis/serviceContacts/useDeleteServiceContact";
import { NotificationContext } from "../../../context/NotificationContext";

interface IDeleteServiceContact {
  isOpen: boolean;
  serviceContactId: string;
  onClose: (shouldUpdateGrid?: boolean) => void;
}

const DeleteServiceContact: FC<IDeleteServiceContact> = ({
  isOpen,
  serviceContactId,
  onClose,
}) => {
  const deleteServiceContact = useDeleteServiceContact();
  const { createNotification } = useContext(NotificationContext);

  const onDelete = useCallback(() => {
    if (serviceContactId) {
      deleteServiceContact.mutate(serviceContactId);
    } else {
      onClose();
    }
  }, [serviceContactId, deleteServiceContact, onClose]);

  useEffect(() => {
    if (deleteServiceContact.isSuccess) {
      createNotification({
        title: "Service Contact Notification",
        type: "success",
        message: "Service Contact removed successfully",
      });
      onClose(true);
    }
    // eslint-disable-next-line
  }, [deleteServiceContact.isSuccess, createNotification]);

  useEffect(() => {
    if (deleteServiceContact.isError) {
      createNotification({
        title: "Service Contact Notification",
        type: "error",
        message:
          deleteServiceContact.error.response?.data?.userMessage ||
          "Something went wrong while removing service contact",
      });
    }
    // eslint-disable-next-line
  }, [createNotification, deleteServiceContact.isError]);

  return (
    <DeleteDialog
      entity="Service Contact"
      isOpen={isOpen}
      isLoading={deleteServiceContact.isLoading}
      onClose={onClose.bind(this, false)}
      onDelete={onDelete}
    />
  );
};

export default DeleteServiceContact;
