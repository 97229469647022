import { ICaseOtherEntityAttorney, ICasePerson } from "../../interfaces";

const getLeadAttorney = (locationState: any, partyId: string) => {
  const attorneyId = locationState?.generalCase?.caseOtherEntityAttorney?.find(
    (attorney: ICaseOtherEntityAttorney) =>
      attorney.caseRepresentedPartyReference.reference === partyId &&
      attorney.caseOfficialRoleText === "LEAD"
  )?.roleOfPersonReference?.reference;

  if (!attorneyId) return "";

  return (
    locationState?.generalCase?.caseParticipant?.find(
      (party: ICasePerson) => party.entityPerson?.id === attorneyId
    )?.entityPerson?.personOtherIdentification?.identificationID || ""
  );
};

const getadditionalAttorneys = (locationState: any, partyId: string) => {
  const attorneyReferences = locationState?.generalCase?.caseOtherEntityAttorney
    ?.filter(
      (attorney: ICaseOtherEntityAttorney) =>
        attorney.caseRepresentedPartyReference.reference === partyId &&
        attorney.caseOfficialRoleText !== "LEAD"
    )
    .map(
      (attorney: ICaseOtherEntityAttorney) =>
        attorney.roleOfPersonReference.reference
    );

  return locationState?.generalCase?.caseParticipant
    ?.filter(
      (party: ICasePerson) =>
        party.entityPerson?.personOtherIdentification?.identificationID &&
        party.entityPerson?.id &&
        attorneyReferences.includes(party.entityPerson.id)
    )
    ?.map(
      (party: ICasePerson) =>
        party.entityPerson?.personOtherIdentification?.identificationID
    );
};

export const parseParties = (locationState: any) => {
  return (
    locationState?.generalCase?.caseParticipant
      ?.filter(
        (party: ICasePerson) =>
          party.entityPerson?.id?.includes("Party") ||
          party.entityOrganization?.id?.includes("Party")
      )
      ?.map?.((party: ICasePerson) => ({
        code: party.caseParticipantRoleCode,
        type: party.entityOrganization?.id ? "business" : "person",
        firstName: party.entityPerson?.personName?.personGivenName,
        middleName: party.entityPerson?.personName?.personMiddleName,
        lastName: party.entityPerson?.personName?.personSurName,
        suffix: party.entityPerson?.personName?.personNameSuffixText,
        businessName: party.entityOrganization?.organizationName,
        leadAttorney: getLeadAttorney(
          locationState,
          party.entityPerson?.id || party.entityOrganization?.id || ""
        ),
        additionalAttorneys: getadditionalAttorneys(
          locationState,
          party.entityPerson?.id || party.entityOrganization?.id || ""
        ),
        countryCode:
          party.entityPerson?.personAugmentation?.contactInformation
            ?.contactMailingAddress?.structuredAddress?.locationCountry ||
          party.entityOrganization?.organizationLocation?.locationAddress
            ?.structuredAddress?.locationCountry,
        streetAddressLine1:
          party.entityPerson?.personAugmentation?.contactInformation
            ?.contactMailingAddress?.structuredAddress?.locationStreet
            ?.streetFullText ||
          party.entityOrganization?.organizationLocation?.locationAddress
            ?.structuredAddress?.locationStreet?.streetFullText,
        streetAddressLine2:
          party.entityPerson?.personAugmentation?.contactInformation
            ?.contactMailingAddress?.structuredAddress
            ?.addressSecondaryUnitText ||
          party.entityOrganization?.organizationLocation?.locationAddress
            ?.structuredAddress?.addressSecondaryUnitText,
        city:
          party.entityPerson?.personAugmentation?.contactInformation
            ?.contactMailingAddress?.structuredAddress?.locationCityName ||
          party.entityOrganization?.organizationLocation?.locationAddress
            ?.structuredAddress?.locationCityName,
        stateCode:
          party.entityPerson?.personAugmentation?.contactInformation
            ?.contactMailingAddress?.structuredAddress?.locationStateName ||
          party.entityOrganization?.organizationLocation?.locationAddress
            ?.structuredAddress?.locationStateName,
        zipCode:
          party.entityPerson?.personAugmentation?.contactInformation
            ?.contactMailingAddress?.structuredAddress?.locationPostalCode ||
          party.entityOrganization?.organizationLocation?.locationAddress
            ?.structuredAddress?.locationPostalCode,
        phoneNumber:
          party.entityPerson?.personAugmentation?.contactInformation
            ?.contactTelephoneNumber?.fullTelephoneNumber
            ?.telephoneNumberFullID ||
          party.entityOrganization?.organizationPrimaryContactInformation
            ?.contactTelephoneNumber?.fullTelephoneNumber
            ?.telephoneNumberFullID,
        // isAdditinalParty?: boolean;
        iAmThisParty: party.entityPerson?.iAmThisParty || false,
        // dateOfDeathCondition?: string;
        // dateOfDeath?: Date;
        efmPartyId:
          party?.entityPerson?.personOtherIdentification?.identificationID ||
          party?.entityOrganization?.organizationIdentification,
      })) || []
  );
};

const getEFileType = (filing: any, locationState: any) => {
  if (filing.type) {
    return filing.type === "EFileAndServe"
      ? "EFileAndServe"
      : filing.type === "isService"
      ? "Serve"
      : "EFile";
  }

  return locationState?.generalCase?.isEFileAndServe
    ? "EFileAndServe"
    : locationState?.generalCase?.isService
    ? "Serve"
    : "EFile";
};

const getIsEFile = (filing: any, locationState: any) => {
  if (filing.type) {
    return filing.type === "EFileAndServe" || filing.type === "E-File";
  }

  return (
    locationState?.generalCase?.isEFileAndServe ||
    locationState?.generalCase?.isEFile
  );
};

const getIsService = (filing: any, locationState: any) => {
  if (filing.type) {
    return filing.type === "EFileAndServe" || filing.type === "isService";
  }

  return (
    locationState?.generalCase?.isEFileAndServe ||
    locationState?.generalCase?.isService
  );
};

export const parseFilingInformation = (locationState: any) => {
  const leadDocument = locationState?.filingLeadDocument;
  if (!leadDocument) return undefined;

  const filings = [
    {
      id: leadDocument.id,
      eFileType: getEFileType(leadDocument, locationState),
      filingCode: leadDocument.documentMetadata.registerActionDescriptionText,
      documentDescriptionText: leadDocument.documentDescriptionText,
      referenceNumber: leadDocument.referenceNumber,
      filingCommentsText: leadDocument.filingCommentsText,
      courtesyCopiesText: leadDocument.courtesyCopiesText,
      eFile: getIsEFile(leadDocument, locationState),
      service: getIsService(leadDocument, locationState),
      attachments:
        leadDocument.documentRendition?.documentRenditionMetadata?.documentAttachment
          ?.filter(
            (attachment: any) =>
              attachment.generalDocType && attachment.binaryCategoryText
          )
          ?.map((attachment: any) => ({
            attachmentSequenceID: attachment.attachmentSequenceID,
            binaryCategoryText: attachment.binaryCategoryText,
            binaryDescriptionText: attachment.binaryDescriptionText,
            binaryFormatStandardName: attachment.binaryFormatStandardName,
            binaryLocationURI: attachment.binaryLocationURI,
            binarySizeValue: attachment.binarySizeValue,
            generalDocType: attachment.generalDocType,
            generalSecurityText: attachment.generalSecurityText,
          })) || [],
      optionalServices: leadDocument.documentOptionalService || [],
    },
  ];

  for (const connectedDocument of locationState.filingConnectedDocument || []) {
    filings.push({
      id: connectedDocument.id,
      eFileType: getEFileType(connectedDocument, locationState),
      filingCode:
        connectedDocument.documentMetadata.registerActionDescriptionText,
      documentDescriptionText: connectedDocument.documentDescriptionText,
      referenceNumber: connectedDocument.referenceNumber,
      filingCommentsText: connectedDocument.filingCommentsText,
      courtesyCopiesText: connectedDocument.courtesyCopiesText,
      eFile: getIsEFile(connectedDocument, locationState),
      service: getIsService(connectedDocument, locationState),
      attachments:
        connectedDocument.documentRendition?.documentRenditionMetadata?.documentAttachment
          ?.filter(
            (attachment: any) =>
              attachment.generalDocType && attachment.binaryCategoryText
          )
          ?.map((attachment: any) => ({
            attachmentSequenceID: attachment.attachmentSequenceID,
            binaryCategoryText: attachment.binaryCategoryText,
            binaryDescriptionText: attachment.binaryDescriptionText,
            binaryFormatStandardName: attachment.binaryFormatStandardName,
            binaryLocationURI: attachment.binaryLocationURI,
            binarySizeValue: attachment.binarySizeValue,
            generalDocType: attachment.generalDocType,
            generalSecurityText: attachment.generalSecurityText,
          })) || [],
      optionalServices: connectedDocument.documentOptionalService || [],
    });
  }

  let partyResponsibleForFeesIndex = 0;
  if (locationState?.generalCase?.responsiblePartyRefernceValue) {
    const index = locationState?.generalCase?.caseParticipant?.findIndex(
      (party: ICasePerson) =>
        party.entityPerson?.id ===
          locationState.generalCase.responsiblePartyRefernceValue ||
        party.entityOrganization?.id ===
          locationState.generalCase.responsiblePartyRefernceValue
    );

    if (index >= 0) partyResponsibleForFeesIndex = index + 1;
  }

  return {
    filings,
    filingFees: [],
    caseInitiationFee: 0,
    totalProviderFee: 0,
    conveninceFee: 0,
    feeCalculationAmount: 0,
    partyResponsibleForFeesIndex: partyResponsibleForFeesIndex,
  };
};
