import { FC, useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/Button";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import InputAdornment from "@mui/material/InputAdornment";
import KeyIcon from "@mui/icons-material/Key";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import ForgotPasswordModal from "../ForgotPasswordModal/ForgotPasswordModal";
import {
  clearLocalStorage,
  setAccessToken,
  setIsAdmin,
} from "../../../../utills";
import useAdminLogin from "../../../../apis/auth/useAdminLogin";

interface LoginFormProps {
  onModalClose: () => void;
}

const AdminLoginForm: FC<LoginFormProps> = ({ onModalClose }) => {
  const login = useAdminLogin();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: { emailAddress: "", password: "" },
  });

  const onSubmit = useCallback(
    (data: { emailAddress: string; password: string }) => {
      login.mutate({
        emailId: data.emailAddress.trim(),
        password: data.password.trim(),
      });
    },
    [login]
  );

  const openForgotPasswordModal = useCallback(() => {
    setIsForgotPasswordModalOpen(true);
    onModalClose();
  }, [onModalClose]);

  const closeForgotPasswordModal = useCallback(() => {
    setIsForgotPasswordModalOpen(false);
  }, []);

  const clearErrorMessage = useCallback(() => {
    setErrorMessage("");
  }, []);

  useEffect(() => {
    if (login.isSuccess) {
      const user = login.data;

      clearLocalStorage();
      setAccessToken(user.accessToken);
      if (user.userrole === "admin") {
        setIsAdmin("admin");
      }

      // setIndividualUser(user.individual.toString());
      //Add admin var to local Storage
      reset();
      onModalClose();
      navigate("/admin/pendingrequests");
    }
    // eslint-disable-next-line
  }, [login.isSuccess]);

  useEffect(() => {
    if (login.isError && login.error?.response?.data) {
      setErrorMessage(login.error?.response?.data as string);
    }
    // eslint-disable-next-line
  }, [login.isError]);

  return (
    <>
      <h2 className="text-center">Admin Area</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <div className="filed-slide mb-4">
          <Controller
            name="emailAddress"
            control={control}
            rules={{
              required: "Email is required.",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address.",
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                className="w-100 m-0"
                id={field.name}
                {...field}
                onBlur={() => {
                  field.onChange(field.value?.trim());
                  field.onBlur();
                }}
                placeholder="Your eFileIL admin email address"
                label="eFileIL admin email address"
                variant="standard"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <MarkunreadIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
        <div className="filed-slide">
          <Controller
            name="password"
            control={control}
            rules={{
              required: "Password is required.",
            }}
            render={({ field, fieldState }) => (
              <TextField
                id={field.name}
                {...field}
                onBlur={() => {
                  field.onChange(field.value?.trim());
                  field.onBlur();
                }}
                placeholder="Your ODYSSEY efileIL admin Password"
                className="w-100 m-0"
                label="Your eFileIL admin Password"
                variant="standard"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                type="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <KeyIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
        <div className="mt-4 mb-4 ">
          <div className="forgot-link justify-content-between d-flex">
            <span
              role="button"
              className="theme-grey cursor-pointer"
              onClick={openForgotPasswordModal}
            >
              Forgot Password?
            </span>
          </div>
        </div>
        <div className="filed-slide text-center">
          <IconButton
            className="btn primary-button"
            type="submit"
            disabled={login.isLoading}
          >
            {login.isLoading ? (
              <CircularProgress
                sx={{
                  height: "25px !important",
                  width: "25px !important",
                  "--CircularProgress-size": "8px",
                  "--CircularProgress-trackThickness": "1px",
                  "--CircularProgress-progressThickness": "1px",
                }}
                color="inherit"
              />
            ) : (
              "Login"
            )}
          </IconButton>
        </div>
      </form>

      <ForgotPasswordModal
        isModalOpen={isForgotPasswordModalOpen}
        closeModal={closeForgotPasswordModal}
      />

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!errorMessage}
        autoHideDuration={10000}
        onClose={clearErrorMessage}
      >
        <Alert onClose={clearErrorMessage} severity="error" variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AdminLoginForm;
