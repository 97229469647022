import { useQuery } from "@tanstack/react-query";

import http from "../../../http";
import { GET_LOCATION_ADDITIONAL_PARTY_TYPES } from "../../URL";
import { IAdditionalParty } from "../../../interfaces";

const getLocationAdditionalPartyTypes = async (locationCode?: string) => {
  if (!locationCode) return [];

  const response = await http.post<IAdditionalParty[]>(
    GET_LOCATION_ADDITIONAL_PARTY_TYPES,
    {
      efmLocationCode: locationCode,
    }
  );
  return response.data;
};

const useLocationAdditionalPartyTypes = (locationCode?: string) =>
  useQuery<IAdditionalParty[]>({
    queryKey: ["location-additinal-party-types", locationCode],
    queryFn: () => getLocationAdditionalPartyTypes(locationCode),
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

export default useLocationAdditionalPartyTypes;
