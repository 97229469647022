import { FC, useCallback, useContext, useEffect } from "react";

import DeleteDialog from "../../../../components/DeleteDialog";
import useDeleteFirmUser from "../../../../apis/firm/users/useDeleteFirmUser";
import { NotificationContext } from "../../../../context/NotificationContext";
import { IFirmUser } from "../../../../interfaces";

interface DeleteUserProps {
  isOpen: boolean;
  user?: IFirmUser;
  onClose: (shouldUpdateGrid?: boolean) => void;
}

const DeleteUser: FC<DeleteUserProps> = ({ isOpen, user, onClose }) => {
  const deleteFirmUser = useDeleteFirmUser();
  const { createNotification } = useContext(NotificationContext);

  const onDelete = useCallback(() => {
    if (user?.userID) {
      deleteFirmUser.mutate(user.userID);
    } else {
      onClose();
    }
  }, [user?.userID, onClose, deleteFirmUser]);

  useEffect(() => {
    if (deleteFirmUser.isSuccess) {
      createNotification({
        title: "Firm User Notification",
        type: "success",
        message: "Firm User removed Successfully",
      });
      onClose(true);
    }
    // eslint-disable-next-line
  }, [deleteFirmUser.isSuccess, createNotification]);

  useEffect(() => {
    if (deleteFirmUser.isError) {
      createNotification({
        title: "Firm User Notification",
        type: "error",
        message:
          deleteFirmUser.error.response?.data?.userMessage ||
          "Something went wrong while Removing Firm User",
      });
    }
    // eslint-disable-next-line
  }, [deleteFirmUser.isError, createNotification]);

  return (
    <DeleteDialog
      entity="Firm User"
      isOpen={isOpen}
      isLoading={deleteFirmUser.isLoading}
      onClose={onClose.bind(this, false)}
      onDelete={onDelete}
    />
  );
};

export default DeleteUser;
