import { ChangeEvent, FC, useCallback, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

import useCountries from "../../../../apis/common/useCountries";
import useStates from "../../../../apis/common/useStates";
import useUpdateFirmInformation from "../../../../apis/firm/useUpdateFirmInformation";
import queryClient from "../../../../queryClient";
import { NotificationContext } from "../../../../context/NotificationContext";
import { getZipCodePlaceholder, validateZipCode } from "../../../../utills";
import { IFirmInformation } from "../../../../interfaces";

interface UpdateFirmInformationProps {
  firm?: IFirmInformation;
}

const UpdateFirmInformation: FC<UpdateFirmInformationProps> = ({ firm }) => {
  const { watch, control, handleSubmit, setValue } = useForm({
    defaultValues: firm,
  });
  const { data: countries } = useCountries();
  const { data: states } = useStates(watch("address.country"));
  const updateFirmInformation = useUpdateFirmInformation();
  const navigate = useNavigate();
  const { createNotification } = useContext(NotificationContext);

  const onCountryChange = useCallback(() => {
    setValue("address.state", "");
    setValue("address.zipCode", "");
  }, [setValue]);

  const onCancel = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  const onSubmit = useCallback(
    async (firmInformation: IFirmInformation) => {
      await updateFirmInformation.mutateAsync({
        firmID: firmInformation.firmID,
        firmName: firmInformation.firmName,
        individual: firmInformation.isIndividual,
        phoneNumber: firmInformation.phoneNumber,
        addressLine1: firmInformation.address.addressLine1,
        addressLine2: firmInformation.address.addressLine2,
        city: firmInformation.address.city,
        state: firmInformation.address.state,
        country: firmInformation.address.country,
        zipCode: firmInformation.address.zipCode,
      });
    },
    [updateFirmInformation]
  );

  useEffect(() => {
    if (updateFirmInformation.isSuccess) {
      createNotification({
        title: "Firm Information Notification",
        type: "success",
        message: "Firm Information Updated Successfully",
      });

      queryClient.refetchQueries({
        queryKey: ["firm-information"],
        exact: true,
      });
    }
  }, [updateFirmInformation.isSuccess, createNotification]);

  useEffect(() => {
    if (updateFirmInformation.isError) {
      createNotification({
        title: "Firm Information Notification",
        type: "error",
        message:
          updateFirmInformation.error.response?.data?.userMessage ||
          "Something went wrong while updating Firm Information",
      });
    }
    // eslint-disable-next-line
  }, [updateFirmInformation.isError, createNotification]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            name="firmName"
            control={control}
            rules={{
              required: "Error: Name is required.",
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                className="w-100 m-0"
                id={field.name}
                autoFocus
                inputProps={{
                  "aria-required": true,
                }}
                InputLabelProps={{
                  required: true,
                }}
                {...field}
                onBlur={() => {
                  field.onChange(field.value?.trim());
                  field.onBlur();
                }}
                inputRef={ref}
                placeholder="Name"
                label="Name"
                variant="standard"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="address.addressLine1"
            control={control}
            rules={{
              required: "Error: Address Line 1 is required.",
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                className="w-100 m-0"
                id={field.name}
                inputProps={{
                  "aria-required": true,
                }}
                InputLabelProps={{
                  required: true,
                }}
                {...field}
                inputRef={ref}
                onBlur={() => {
                  field.onChange(field.value?.trim());
                  field.onBlur();
                }}
                placeholder="Address Line 1"
                label="Address Line 1"
                variant="standard"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="address.addressLine2"
            control={control}
            render={({ field }) => (
              <TextField
                className="w-100 m-0"
                id={field.name}
                {...field}
                onBlur={() => {
                  field.onChange(field.value?.trim());
                  field.onBlur();
                }}
                placeholder="Address Line 2"
                label="Address Line 2"
                variant="standard"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="address.country"
            control={control}
            rules={{
              required: "Error: Country is required.",
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                className="w-100"
                id={field.name}
                select
                InputLabelProps={{
                  shrink: true,
                  required: true,
                  htmlFor: `${field.name}_id`,
                }}
                inputProps={{
                  id: `${field.name}_id`,
                }}
                SelectProps={{
                  displayEmpty: true,
                  SelectDisplayProps: {
                    "aria-required": true,
                  },
                }}
                {...field}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e);
                  onCountryChange();
                }}
                inputRef={ref}
                label="Country"
                variant="standard"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              >
                <MenuItem value="">Select Country</MenuItem>
                {countries?.map((country) => (
                  <MenuItem
                    key={country.efmCountryCode}
                    value={country.efmCountryCode}
                  >
                    {country.efmCountryName}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            name="address.city"
            control={control}
            rules={{
              required: "Error: City is required.",
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                className="w-100 m-0"
                id={field.name}
                inputProps={{
                  "aria-required": true,
                }}
                InputLabelProps={{
                  required: true,
                }}
                {...field}
                inputRef={ref}
                onBlur={() => {
                  field.onChange(field.value?.trim());
                  field.onBlur();
                }}
                placeholder="City"
                label="City"
                variant="standard"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            name="address.state"
            control={control}
            rules={{
              required: "Error: State is required.",
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                className="w-100"
                id={field.name}
                select
                inputProps={{
                  id: `${field.name}_id`,
                }}
                InputLabelProps={{
                  shrink: true,
                  required: true,
                  htmlFor: `${field.name}_id`,
                }}
                SelectProps={{
                  displayEmpty: true,
                  SelectDisplayProps: {
                    "aria-required": true,
                  },
                }}
                {...field}
                inputRef={ref}
                label="State"
                variant="standard"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              >
                <MenuItem value="">Select State</MenuItem>
                {states?.map((state) => (
                  <MenuItem key={state.efmStateCode} value={state.efmStateCode}>
                    {state.efmStateName}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            name="address.zipCode"
            control={control}
            rules={{
              required: "Error: Zip Code is required.",
              validate: validateZipCode.bind(this, "address.country"),
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                className="w-100 m-0"
                id={field.name}
                inputProps={{
                  "aria-required": true,
                }}
                InputLabelProps={{
                  required: true,
                }}
                {...field}
                inputRef={ref}
                onBlur={() => {
                  field.onChange(field.value?.trim());
                  field.onBlur();
                }}
                placeholder={getZipCodePlaceholder(watch("address.country"))}
                label="Zip Code"
                variant="standard"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              required: "Error: Phone Number is required.",
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                className="w-100 m-0"
                id={field.name}
                inputProps={{
                  "aria-required": true,
                }}
                InputLabelProps={{
                  required: true,
                }}
                {...field}
                inputRef={ref}
                onBlur={() => {
                  field.onChange(field.value?.trim());
                  field.onBlur();
                }}
                label="Phone Number"
                variant="standard"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
        </Grid>
        <Grid xs={12} pt={4} display="flex" justifyContent="center">
          <Button
            className="btn button-outline-secondary me-3"
            variant="contained"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button
            className="btn primary-button"
            variant="contained"
            type="submit"
          >
            {updateFirmInformation.isLoading ? (
              <CircularProgress
                sx={{
                  height: "25px !important",
                  width: "25px !important",
                  "--CircularProgress-size": "8px",
                  "--CircularProgress-trackThickness": "1px",
                  "--CircularProgress-progressThickness": "1px",
                }}
                color="inherit"
              />
            ) : (
              "Save"
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdateFirmInformation;
